import React from "react";
import { Modal, Grid, Typography, CircularProgress } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styles from "../../Styles/image.module.css";

const ImageModal = ({
  open,
  onClose,
  title,
  loading,
  isImage,
  imgData,
  documentName,
  imageDimensions,
}: any) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      aria-labelledby="image-modal"
      aria-describedby="image-modal-description"
    >
      <Grid
        width={isImage ? imageDimensions?.width : "100%"}
        className={styles.modalContainer}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={styles.modalContentContainer}
        >
          <Grid container className={styles.headerContainer}>
            <Grid item>
              <Typography className={styles.headerText}>
                {documentName || title}
              </Typography>
            </Grid>
            <Grid item>
              <CloseOutlinedIcon
                onClick={onClose}
                className={styles.closeIcon}
              />
            </Grid>
          </Grid>
          {loading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {isImage ? (
                <img
                  src={imgData}
                  alt="Modal"
                  className={styles.imgContainer}
                />
              ) : (
                <iframe
                  src={imgData}
                  title="doc"
                  width="100%"
                  height="700px"
                  scrolling="no"
                  frameBorder={0}
                ></iframe>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ImageModal;
