import React from "react";
import {
  Grid,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import styles from "src/Styles/EmployeDetailsModel.module.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButtons from "../CustomButton/Button";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UPDATE_PASSWORD } from "src/graphql/user";
import { useMutation } from "@apollo/client";
import { Toast } from "../ToastMessage";
import { useAppSelector } from "src/store/hooks";
import type { RootState } from "src/store/store";

const EmployeeChangePassword = () => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const [UpdateEmployee] = useMutation(UPDATE_PASSWORD);

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  //we should hanlde validation from seperate page
  const schema = yup.object().shape({
    currentPassword: yup
      .string()
      .required("Please enter your current password"),
    newPassword: yup.string().required("Please enter a new password"),
    confirmPassword: yup
      .string()
      .required("Please confirm your new password")
      .oneOf([yup.ref("newPassword"), ""], "Passwords must match")
      .test(
        "passwords-match",
        "Current password and new password cannot be the same",
        function (value) {
          const currentPassword = this.parent.currentPassword;
          const newPassword = this.parent.newPassword;
          if (
            currentPassword &&
            newPassword &&
            currentPassword === newPassword
          ) {
            return false;
          }
          return true;
        }
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = async (data: any) => {
    try {
      const response = await UpdateEmployee({
        variables: {
          input: {
            currentPassword: data?.currentPassword,
            newPassword: data?.newPassword,
            userId: userInfo?.id,
          },
        },
      });

      if (response.data) {
        Toast("success", "Password Updated Successfully");
        reset();
      }
    } catch (error: any) {
      console.error("Error", error);
      Toast("error", error?.message);
    }
  };

  return (
    <Grid className={styles.infromation}>
      <Grid container className={styles.infromation_div}>
        <Grid xs={12}>
          <Typography className={styles.sectionHeader}>
            Change Password
          </Typography>
        </Grid>
      </Grid>
      <Divider className={styles.infromation_div1} />
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Grid container className={styles.infromation_div}>
          <FormControl
            sx={{ m: 1 }}
            className={styles.inputField}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              className={styles.inputLabelCss}
            >
              Current Password
            </InputLabel>
            <Controller
              control={control}
              name="currentPassword"
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-password"
                  type={showCurrentPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="currentPassword"
                />
              )}
            />
            {errors.currentPassword && (
              <Typography color="error">
                {errors.currentPassword.message}
              </Typography>
            )}
          </FormControl>
          <FormControl
            sx={{ m: 1 }}
            className={styles.inputField}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              className={styles.inputLabelCss}
            >
              New Password
            </InputLabel>
            <Controller
              control={control}
              name="newPassword"
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-password"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="newPassword"
                />
              )}
            />
            {errors.newPassword && (
              <Typography color="error">
                {errors.newPassword.message}
              </Typography>
            )}
          </FormControl>
          <FormControl
            sx={{ m: 1 }}
            className={styles.inputField}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              className={styles.inputLabelCss}
            >
              Confirm Password
            </InputLabel>

            <Controller
              control={control}
              name="confirmPassword"
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        // className={`${styles.myIconButton} ${styles.iconBtn}`}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="confirmPassword"
                />
              )}
            />
            {errors.confirmPassword && (
              <Typography color="error">
                {errors.confirmPassword.message}
              </Typography>
            )}
          </FormControl>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1 }}>
              <CustomButtons name="Save" className={styles.btn} type="submit" />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EmployeeChangePassword;
