import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "src/store/store";

interface userState {
  userInfo: Object;
  forgotPasswordUserInfo: Object;
}

const initialState: userState = {
  userInfo: {},
  forgotPasswordUserInfo: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserDetails: (state, action: PayloadAction<Object>) => {
      state.userInfo = action.payload;
    },
    forgotPasswordDetails: (state, action: PayloadAction<Object>) => {
      state.forgotPasswordUserInfo = action.payload;
    },
  },
});

export const { getUserDetails, forgotPasswordDetails } = userSlice.actions;

export const getUserInfo = (state: RootState) => state.userSlice;

export default userSlice.reducer;
