import React from "react";
import styles from "../../Styles/Login.module.css";
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import xperia from "../../images/assets/Arokee Logo Black .png";
import loginLogo from "../../images/assets/loginLogo.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "src/graphql/resetPassword";
import { ResetPasswordSchema } from "src/utils/validations";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { useForm, Controller } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ResetPassword = () => {
  const { forgotPasswordUserInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const [ResetPassword] = useMutation(RESET_PASSWORD);

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [newPasswordRes, setNewPasswordRes] = React.useState("");
  const [newPasswordSuccessFlag, setNewPasswordSuccessFlag] =
    React.useState(false);

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isValid },
    reset,
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(ResetPasswordSchema),
  });

  const handleOnSubmit = async (evt: any) => {
    try {
      const response = await ResetPassword({
        variables: {
          input: {
            email: forgotPasswordUserInfo?.email,
            password: evt?.confirmPassword,
            userId: forgotPasswordUserInfo?.userId,
          },
        },
      });

      if (response.data) {
        setNewPasswordSuccessFlag(true);
        setNewPasswordRes(response?.data?.resetPassword?.message);
        reset();
      }
    } catch (err: any) {
      // Handle any errors here
      setNewPasswordSuccessFlag(false);
      setNewPasswordRes(err?.message);
      console.error("Error:", err);
    }
  };

  //if (loading) return <Typography>loading......</Typography>;
  return (
    <Grid container component="main" className={styles.mainPage}>
      <Grid item md={6} lg={6} sm={12} xs={12} className={styles.leftGrid}>
        <img src={loginLogo} alt="loginLogo" className={styles.loginLogo} />
      </Grid>
      <Grid
        md={6}
        lg={6}
        sm={12}
        xs={12}
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.right_half}
      >
        <Grid container className={styles.rightHalfWrapper}>
          <Grid container>
            <Grid item md={9} sm={9} lg={9} xs={9}>
              <img
                src={xperia}
                alt="front_image"
                className={styles.xperia_image}
              />
            </Grid>

            <Grid item md={3} sm={3} lg={3} xs={3} />
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="h4" className={styles.WelcomeText}>
                Change Password
              </Typography>
            </Grid>

            <Card className={styles.signInContainer}>
              <CardContent>
                <Typography className={styles.sigin_text}>
                  Update your password, to get started
                </Typography>
                <Divider />
                <Grid className={styles.cardBody}>
                  <Typography className={styles.emailText}>
                    New Password
                  </Typography>

                  <Controller
                    control={control}
                    name="newpassword"
                    defaultValue=""
                    // rules={{
                    //   required: true,
                    //   pattern: /^[a-zA-Z ]*$/,
                    //   minLength: 5,
                    // }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={styles.textField}
                        type={showNewPassword ? "text" : "password"}
                        placeholder="New Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label=""
                      />
                    )}
                  />
                  {errors.newpassword && (
                    <Typography color="error" sx={{ fontSize: "12px" }}>
                      {errors.newpassword.message}
                    </Typography>
                  )}

                  <Typography className={styles.emailText}>
                    Confirm Password
                  </Typography>

                  <Controller
                    control={control}
                    name="confirmPassword"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={styles.textField}
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label=""
                      />
                    )}
                  />
                  {errors.confirmPassword && (
                    <Typography color="error" sx={{ fontSize: "12px" }}>
                      {errors.confirmPassword.message}
                    </Typography>
                  )}
                  <CustomButtons
                    name="Save"
                    variant="contained"
                    className={styles.sendButton}
                    type="submit"
                  />

                  <Grid m={3} textAlign={"center"}>
                    {newPasswordSuccessFlag ? (
                      <Typography sx={{ color: "green" }}>
                        {newPasswordRes}
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "red" }}>
                        {newPasswordRes}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid className={styles.termsBody}>
                  <Typography className={styles.termsLinks}>
                    <span className={styles.termsOfService}>
                      Terms of Service
                    </span>
                    <span className={styles.privacy}>Privacy Policy</span>
                  </Typography>
                  <Typography className={styles.details}>
                    For details of how we use personal data see our privacy and
                    data processing statement
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
