import { useState, useMemo, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { IconButton, Typography } from '@mui/material';
import FullScreenDialog from 'src/components/SliderModal/index';
import styles from 'src/Styles/AttendanceSheet.module.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomTable from 'src/components/TableComponent';
import { getFormattedTime } from 'src/utils/getFormattedTime';
import moment from 'moment';
import { GET_CALENDER_DATA } from 'src/graphql/calendar';
import { useQuery } from '@apollo/client';

const AttendanceSheet = ({ onClick, open, onClose, label }: any) => {
  const [monthView, setMonthView] = useState<string | number | Date>(moment(label).format('MMMM YYYY'));

  const userId = localStorage.getItem('userId');
  const { data, refetch } = useQuery(GET_CALENDER_DATA, {
    variables: {
      input: {
        userId: userId,
        monthOfYear: monthView,
      },
    },
  });

  const events = useMemo(() => {
    return (
      data?.getCalendarData?.calendarData?.map(
        ({ date, isAbsent, isHoliday, holidayName, isWeekend, attendance }: any) => {
          return {
            date,
            start: moment(date).toDate(),
            end: moment(date).toDate(),
            isAbsent,
            isHoliday,
            holidayName,
            isWeekend,
            loginTime: attendance?.loginTime,
            logoutTime: attendance?.logoutTime,
            status: attendance?.status,
            totalTime: attendance?.totalTime,
            attendance,
          };
        }
      ) || []
    );
  }, [data]);

  const refetchMonth = useCallback(
    async (direction: any) => {
      let currentDate: any = moment(`01 ${monthView.toString()}`).format('DD MMMM YYYY');

      if (direction === 'NEXT') {
        currentDate = moment(currentDate).add(1, 'months').toDate();
      }
      if (direction === 'PREV') {
        currentDate = moment(currentDate).subtract(1, 'months').toDate();
      }

      setMonthView(moment(currentDate).format('MMMM YYYY').toString());
      await refetch({
        input: {
          userId: userId,
          monthOfYear: moment(currentDate).format('MMMM YYYY').toString(),
        },
      });
    },
    [monthView, refetch, userId]
  );

  return (
    <>
      <FullScreenDialog
        onClick={onClick}
        open={open}
        onClose={onClose}
        direction="up"
        className={styles.attendanceSheet}
        dialogClassName={styles.attendanceDialog}
        PaperProps={{
          className: styles.attendanceMainWrapper,
        }}
        content={
          <>
            <Grid item container className={styles.mainContainer}>
              <Grid item container className={styles.attendanceHeader}>
                <Grid item md={11} sm={11} lg={11} xs={11} className={styles.textGrid}>
                  <Typography className={styles.text}>Attendance</Typography>
                  <IconButton onClick={() => refetchMonth('PREV')}>
                    <ArrowBackIosIcon className={styles.arrowIcons} />
                  </IconButton>
                  <IconButton onClick={() => refetchMonth('NEXT')}>
                    <ArrowForwardIosIcon className={styles.arrowIcons} />
                  </IconButton>
                  <Typography className={styles.monthText}>{monthView.toString()}</Typography>
                </Grid>
                <Grid item md={1} sm={1} xs={1} lg={1} className={styles.closeIcons}>
                  <IconButton onClick={onClose}>
                    <CloseOutlinedIcon className={styles.closeIcon} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item container md={12} className={styles.tableContainer}>
                <CustomTable
                  data={events}
                  hidePagination={true}
                  tableCellFirstColStyle={styles.tableCellFirstColStyles}
                  tableHeader={styles.tableHeader}
                  columns={[
                    {
                      Header: <Grid> Date </Grid>,
                      Cell: ({ item }: any) => {
                        return <Grid>{item.date}</Grid>;
                      },
                    },
                    {
                      Header: <Grid> In Time </Grid>,
                      Cell: ({ item }: any) => {
                        return <Grid>{getFormattedTime(item.loginTime)}</Grid>;
                      },
                    },
                    {
                      Header: <Grid> Out Time </Grid>,
                      Cell: ({ item }: any) => {
                        return <Grid>{getFormattedTime(item.logoutTime)}</Grid>;
                      },
                    },
                    {
                      Header: <Grid>Total Hours</Grid>,
                      Cell: ({ item }: any) => {
                        return <Grid>{item.totalTime}</Grid>;
                      },
                    },
                    {
                      Header: <Grid> Type </Grid>,
                      Cell: ({ item }: any) => {
                        return (
                          <Grid>
                            {item?.isHoliday
                              ? `Holiday - ${item?.holidayName}`
                              : item?.isWeekend
                                ? 'Week-off'
                                : item?.isAbsent
                                  ? 'Absent'
                                  : item?.attendance?.isRegularized
                                    ? `Regularisation ${item?.attendance?.regularizationStatus}`
                                    : 'Attendance'}
                          </Grid>
                        );
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default AttendanceSheet;
