// Import necessary hooks and functions
import React from 'react';
import { Typography } from '@mui/material';
import CustomButtons from 'src/components/CustomButton/Button';
import ModalBox from 'src/components/ReusableModal/modal';
import { Divider, Grid, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from 'src/Styles/AddLegalEntity.module.css';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import CustomSelect from 'src/components/CustomSelect/customSelectValidations';
import LegalEntityData from './LegalEntityData.json';
import { useMutation } from '@apollo/client';
import { CREATE_ENTITY, GET_ENTITIES, UPDATE_ENTITY } from 'src/graphql/entity';
import PhoneInput from '../PhoneInput';
import axios from 'axios';
import { useAppSelector } from 'src/store/hooks';
import { RootState } from 'src/store/store';
import { AddEntitySchema } from '../../utils/validations';

const AddLegalEntityModel = ({ selectedData, open, onClose, handleClose }: any) => {
  // Get user info from Redux store using useAppSelector
  const { userInfo }: any = useAppSelector((state: RootState) => state.userSlice);

  // Define mutations for updating and creating entities
  const [updateEntity] = useMutation(UPDATE_ENTITY, {
    refetchQueries: [GET_ENTITIES, 'getWorkplaces'],
  });
  const [createEntity, { loading: createEntityLoading }] = useMutation(CREATE_ENTITY, {
    refetchQueries: [GET_ENTITIES, 'getEntities'],
  });
  // State to manage logo data
  const [logoData, setLogoData] = React.useState<any>({
    file: '',
    documentName: '',
  });
  // State to manage file name and selected file
  const [fileName, setFileName] = React.useState<string>('');
  const [selectedFile, setSelectedFile] = React.useState<any>({});

  React.useEffect(() => {
    if (selectedData?.id) {
      const defaultValues = {
        selectOptionLanguage: selectedData?.language,
        selectOptionIndustry: selectedData?.industry,
        name: selectedData?.companyName,
        companyLogo: selectedData?.companyLogo,
        companyLogoKey: selectedData?.companyLogoKey,
        website: selectedData?.website,
        description: selectedData?.overview,
        email: selectedData?.companyEmail,
        mobileNumber: selectedData?.contactNumber,
        documentName: selectedData?.companyLogo,
      };
      reset(defaultValues);
    }
    setFileName(selectedData?.companyLogoKey)
    // eslint-disable-next-line
  }, [selectedData]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(AddEntitySchema),
  });

  //Function for submit form data
  const handleOnSubmit = async (data: any) => {
    try {
      if (!selectedData.id) {
        const response: any = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload`, selectedFile);

        if (response) {
          // eslint-disable-next-line
          const input = {
            userId: userInfo.id,
            documentName: logoData?.documentName,
            assetId: response?.data?._id,
            assetKey: response?.data?.key,
          };

          setLogoData({
            file: '',
            documentName: '',
          });

          await createEntity({
            variables: {
              input: {
                companyName: data?.name,
                companyLogo: data?.documentName,
                companyLogoKey: response?.data?.fileName,
                companyBgImg: data?.companyBgImg,
                language: data?.selectOptionLanguage,
                industry: data?.selectOptionIndustry,
                contactNumber: data?.mobileNumber,
                companyEmail: data?.email,
                overview: data?.description,
                website: data?.website,
              },
            },
            refetchQueries: [{ query: GET_ENTITIES }],
          });

          handleClose();
        }
      } else {
        if (!selectedFile) {
          setLogoData({
            file: '',
            documentName: '',
          });

          const input = {
            companyName: data?.name,
            companyBgImg: data?.companyBgImg,
            language: data?.selectOptionLanguage,
            industry: data?.selectOptionIndustry,
            contactNumber: data?.mobileNumber,
            companyEmail: data?.email,
            overview: data?.description,
            website: data?.website,
          };

          await updateEntity({
            variables: {
              workplaceId: selectedData?.id,
              input: input,
            },
          });

          handleClose();
        } else {
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload`, selectedFile);

          setLogoData({
            file: '',
            documentName: '',
          });

          const input = {
            companyName: data?.name,
            companyLogo: data?.documentName,
            companyLogoKey: response?.data?.fileName,
            companyBgImg: data?.companyBgImg,
            language: data?.selectOptionLanguage,
            industry: data?.selectOptionIndustry,
            contactNumber: data?.mobileNumber,
            companyEmail: data?.email,
            overview: data?.description,
            website: data?.website,
          };

          await updateEntity({
            variables: {
              workplaceId: selectedData?.id,
              input: input,
            },
          });

          handleClose();
        }
      }
    } catch (error) {}
  };

  // Function for handle uploaded file
  const uploadFile = async (evt: any) => {
    try {
      const selectedFile = evt.target.files[0];
      const allowedFileTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];

      if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
        const newFormData: any = new FormData();
        newFormData.append('file', selectedFile);
        newFormData.append('hostname', 'localhost');
        setValue('file', newFormData);
        setFileName(selectedFile.name);
        setSelectedFile(newFormData);
        // setUploadLogoData((prevData: any) => ({
        //   ...prevData,
        //   file: newFormData,
        // }));
      } else {
        setError('file', {
          type: 'manual',
          message: 'Invalid file type. Please select a PNG, JPEG, or SVG file.',
        });
      }
    } catch (error) {}
  };
  return (
    <>
      <ModalBox
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
        // className={styles.add_dept_modal}
        content={
          <Grid component="form" className={styles.modalContent}>
            <Grid container item md={12} sm={12} lg={12} xs={12}>
              <Grid container item md={9} sm={9} lg={9} xs={9}>
                <Typography className={styles.header}>
                  {!selectedData.id ? 'Add Legal Entity' : 'Edit Legal Entity'}
                </Typography>
                <Typography className={styles.typography_color}>
                  {!selectedData.id
                    ? 'Create new Entity using different locations to arrange employee geographically'
                    : 'Edit new Entity using different locations to arrange employee geographically'}
                </Typography>
              </Grid>
              <Grid md={3} lg={3} sm={3} xs={3} xl={3} className={styles.iconMargin} container>
                <CloseIcon onClick={onClose} className={styles.CloseIcon} />
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '5px' }} />
            <Grid className={styles.parentGrid}>
              <Grid spacing={1} container className={styles.inputtexts}>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Company Name</Typography>
                  <Controller
                    control={control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z0-9 .@_()/# ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        {...field}
                        type="name"
                        fullWidth={true}
                        className={styles.common_width}
                        error={error !== undefined}
                        placeholder="Arokee"
                        helperText={error ? 'Please enter a Company Name' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={`${styles.inputHeading} ${styles.resHeading}`}>Language</Typography>
                  <CustomSelect
                    error={Boolean(errors.selectOptionLanguage)}
                    errorCondition={
                      errors.selectOptionLanguage && (
                        <Typography color="error" className={styles.LanguageErrorText}>
                          Please Select a Language
                        </Typography>
                      )
                    }
                    control={control}
                    name="selectOptionLanguage"
                    selectData={LegalEntityData.language}
                    placeHolder="English (US)"
                    fullWidth={true}
                    className={styles.customSelectCss}
                    selectFieldCss={styles.dropdownWorkPlace}
                  />
                </Grid>
              </Grid>
              <Grid spacing={1} container className={styles.inputtexts}>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Website</Typography>
                  <Controller
                    control={control}
                    name="website"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern:
                        /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}([a-zA-Z]{2,})(\/[a-zA-Z0-9-]+)*(\/?)(\?[a-zA-Z0-9-]+=[a-zA-Z0-9-%]+&?)*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        {...field}
                        type="website"
                        fullWidth={true}
                        error={error !== undefined}
                        placeholder="www.Arokee.com"
                        helperText={error ? 'Please enter a Website' : ''}
                        className={styles.common_width}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={`${styles.inputHeading} ${styles.resHeading}`}>Industry</Typography>
                  <CustomSelect
                    error={Boolean(errors.selectOptionIndustry)}
                    errorCondition={
                      errors.selectOptionIndustry && (
                        <Typography color="error" className={styles.LanguageErrorText}>
                          Please Select a Industry
                        </Typography>
                      )
                    }
                    control={control}
                    name="selectOptionIndustry"
                    selectData={LegalEntityData.IndustryData}
                    placeHolder="Industry"
                    className={styles.customSelectCss}
                    selectFieldCss={styles.dropdownWorkPlace}
                  />
                </Grid>
              </Grid>
              <Grid spacing={1} container className={styles.inputtexts}>
                <Grid item md={6} sm={6} xs={12} lg={6} className={styles.phoneNumberField}>
                  <Typography className={styles.inputHeading}>Contact Number</Typography>
                  <PhoneInput
                    error={Boolean(errors.mobileNumber)}
                    helperText={errors.mobileNumber && 'Please enter a Phone Number'}
                    control={control}
                    name="mobileNumber"
                    form="form"
                    containerClass={
                      errors.mobileNumber
                        ? `${styles.containerPhnLeagal} ${styles.containerPhnField}`
                        : styles.containerPhnLeagal
                    }
                    inputClass={styles.inputPhn}
                    placeholder="+91 8219941918"
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={`${styles.inputHeading} ${styles.resHeading}`}>Contact Email</Typography>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        {...field}
                        type="email"
                        fullWidth={true}
                        error={error !== undefined}
                        placeholder="Arokee@gmail.com"
                        helperText={error ? 'Please enter a valid Email' : ''}
                        className={styles.common_width}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid spacing={1} container className={styles.inputtexts}>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Company Logo</Typography>
                  <Controller
                    name="file"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'File upload is required.',
                      validate: {
                        validFile: (value) => {
                          if (!value || value.length === 0) {
                            return 'Please upload a file.';
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Grid className={`${styles.fileGrid} ${error ? styles.errorBorder : ''}`}>
                          <label
                            htmlFor="files"
                            className={
                              error && fileName == ''
                                ? `${styles.logoButton} ${styles.logoUploadBtn}`
                                : styles.logoButton
                            }
                          >
                            <span className={styles.textFile1}>Upload Logo</span>

                            <span className={styles.textFile2}>{fileName ? fileName : 'No file choosen'}</span>
                            <input
                              id="files"
                              type="file"
                              accept=".png, .jpeg, .svg"
                              className={styles.chooseInput}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => uploadFile(event)}
                            />
                          </label>
                        </Grid>
                        {fileName == '' && (
                          <Typography color="error" className={styles.fileErrorText}>
                            {error && error.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={`${styles.inputHeading} ${styles.resHeading}`}>Document Name</Typography>
                  <Controller
                    control={control}
                    name="documentName"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z0-9 .@_()/# ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        size="medium"
                        {...field}
                        type="documentName"
                        fullWidth={true}
                        error={error !== undefined}
                        placeholder="Document Name"
                        helperText={error ? 'Please enter a Document Name' : ''}
                        className={styles.common_width}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container item md={12} sm={12} lg={12} xs={12} className={styles.companyOverviewField}>
                <Grid container item md={12} sm={12} lg={12} xs={12}>
                  <Typography className={styles.inputHeading}>Company Overview</Typography>
                </Grid>
                <Grid item md={12} sm={12} lg={12} xs={12}>
                  <Controller
                    control={control}
                    name="description"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z0-9 .@ ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        multiline
                        rows={4}
                        fullWidth={true}
                        {...field}
                        type="description"
                        error={error !== undefined}
                        placeholder="Full Time"
                        helperText={error ? 'Please write the  Company Overview' : ''}
                        className={styles.companyField}
                        InputProps={{
                          className: styles.inputCss,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={12}
                sm={12}
                lg={12}
                xs={12}
                mt={4}
                justifyContent={'flex-end'}
                className={styles.buttonAlignment}
              >
                <Grid container item md={6} sm={6} lg={8} xs={12} />

                <Grid item md={3} sm={4} lg={3} xs={12} className={styles.addEntityButtonContainer} pb={1}>
                  <CustomButtons
                    name={!selectedData.id ? 'Add Entity' : 'Edit Entity'}
                    className={styles.submitbtn}
                    variant="contained"
                    loading={createEntityLoading}
                    disabled={createEntityLoading}
                    loaderClass={styles.buttonLoader}
                    onClick={handleSubmit(handleOnSubmit)}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default AddLegalEntityModel;
