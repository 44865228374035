import React from 'react';
import { Grid, Typography, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButtons from 'src/components/CustomButton/Button';
import styles from 'src/Styles/AddnewWorkplacesModal.module.css';
import { useForm, Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import CustomSelect from 'src/components/CustomSelect/customSelectValidations';
import ModalBox from '../ReusableModal/modal';
import PhoneInput from 'src/components/PhoneInput';
import { useMutation } from '@apollo/client';
import { CREATE_WORKSPACE } from 'src/graphql/workplace';
import { GET_WORKPLACES, UPDATE_WORKSPACE } from 'src/graphql/workplace';
import addWorkplaceData from './AddWorkplaceData.json';

function AddNewWorkplacesModal({ Data, handleClose, open, handleCloseModal }: any) {
  React.useEffect(() => {
    if (Data.id) {
      const defaultValues = {
        // Set default values for your form fields here
        city: Data?.city,
        workplaceName: Data?.workplaceName,
        workplaceCode: Data?.workplaceCode,
        primaryAddress: Data?.addressLine1,
        secondaryAddress: Data?.addressLine2,
        zipCode: Data?.zipCode,
        State: Data?.state,
        Country: Data?.country,
        phone: Data?.mobileNumber,
        timeZone: Data?.timeZone,
      };
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [Data]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE, {
    refetchQueries: [GET_WORKPLACES, 'getWorkplaces'],
  });
  const [createWorkspace] = useMutation(CREATE_WORKSPACE);

  //Function for submit form data
  const handleOnSubmit = async (data: any) => {
    if (!Data.id) {
      try {
        await createWorkspace({
          variables: {
            input: {
              workplaceName: data?.workplaceName,
              workplaceCode: data?.workplaceCode,
              addressLine1: data?.primaryAddress,
              addressLine2: data?.secondaryAddress,
              city: data?.city,
              zipCode: data?.zipCode,
              state: data?.State,
              country: data?.Country,
              mobileNumber: data?.phone,
              timeZone: data?.timeZone,
            },
          },
          refetchQueries: [{ query: GET_WORKPLACES }], // Refetch the users data after the mutation
        }).then((data) => {
          handleClose();
        });
      } catch (error) {}
    } else {
      try {
        const input = {
          workplaceName: data?.workplaceName,
          workplaceCode: data?.workplaceCode,
          addressLine1: data?.primaryAddress,
          addressLine2: data?.secondaryAddress,
          city: data?.city,
          zipCode: data?.zipCode,
          state: data?.State,
          country: data?.Country,
          mobileNumber: data?.phone,
          timeZone: data?.timeZone,
        };
        await updateWorkspace({
          variables: {
            workplaceId: Data?.id,
            input: input,
          },
        }).then((data) => {
          handleClose();
        });
      } catch (error) {}
    }
  };

  return (
    <ModalBox
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
      className={styles.modalDailog}
      content={
        <Grid className={styles.modalContent}>
          <Grid
            component="form"
            // onSubmit={handleSubmit(handleOnSubmit)}
            className={styles.parent_Grid}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
          >
            <Grid className={styles.fixedSection}>
              <Grid container md={12} lg={12} sm={12} xs={12} xl={12} className={styles.flex_box_property}>
                <Grid md={9} lg={9} sm={9} xs={9} xl={9}>
                  <Typography className={styles.header}>{!Data.id ? 'Add Workplace' : 'Edit Workplace'}</Typography>
                  <Typography className={styles.typography_color}>
                    {!Data.id
                      ? 'Create new workplaces using different locations to arrange employees geographically.'
                      : 'Edit workplaces using different locations to arrange employees geographically.'}
                  </Typography>
                </Grid>
                <Grid md={3} lg={3} sm={3} xs={3} xl={3} className={styles.close_button} container>
                  <CloseIcon onClick={handleClose} className={styles.closeIcon} />
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ marginTop: '10px', marginBottom: '5px' }} />
            <Grid className={styles.parentGrid}>
              <Grid spacing={1} container className={styles.inputtexts}>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Workplace name</Typography>

                  <Controller
                    control={control}
                    name="workplaceName"
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z0-9 .@_()/#]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={styles.common_width}
                        {...field}
                        type="workplaceName"
                        placeholder="Workplace Name"
                        error={error !== undefined}
                        helperText={error ? 'Please enter a valid Workplace Name' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Workplace code</Typography>

                  <Controller
                    control={control}
                    name="workplaceCode"
                    // defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z0-9 ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={styles.common_width}
                        {...field}
                        type="workplaceCode"
                        placeholder="AK047"
                        error={error !== undefined}
                        helperText={error ? 'Please enter a valid Workplace Code' : ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid spacing={1} className={styles.inputtexts} container>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Address 1</Typography>
                  <Controller
                    control={control}
                    name="primaryAddress"
                    rules={{
                      required: true,
                      // eslint-disable-next-line
                      pattern: /^[\w\s\d\(\)\-,:;.]+$/i,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={styles.common_width}
                        {...field}
                        type="primaryAddress"
                        placeholder="Enter your Address"
                        error={error !== undefined}
                        helperText={error ? 'Please enter your Address' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Address 2</Typography>
                  <Controller
                    control={control}
                    name="secondaryAddress"
                    rules={{
                      required: true,
                      // eslint-disable-next-line
                      pattern: /^[\w\s\d\(\)\-,:;.]+$/i,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={styles.common_width}
                        {...field}
                        type="secondaryAddress"
                        placeholder="Enter your Address"
                        error={error !== undefined}
                        helperText={error ? 'Please enter your temporary Address' : ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid spacing={1} className={styles.inputtexts} container>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>City</Typography>
                  <Controller
                    control={control}
                    name="city"
                    rules={{
                      required: true,
                      // eslint-disable-next-line
                      pattern: /^[\w\s\d\(\)\-,:;.]+$/i,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={styles.common_width}
                        {...field}
                        type="secondaryAddress"
                        placeholder="Enter your City Address"
                        error={error !== undefined}
                        // inputRef={cityInputRef}
                        helperText={error ? 'Please enter your City Address' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Zip code</Typography>
                  <Controller
                    control={control}
                    name="zipCode"
                    rules={{
                      required: true,
                      // eslint-disable-next-line
                      pattern: /^[\w\s\d\(\)\-,:;.]+$/i,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={styles.common_width}
                        {...field}
                        type="zipCode"
                        placeholder="Enter your zip-code"
                        error={error !== undefined}
                        helperText={error ? 'Please enter your zip-code' : ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid spacing={1} className={styles.inputtexts} container>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>State/ Province/ Region</Typography>
                  <CustomSelect
                    error={Boolean(errors.State)}
                    errorCondition={errors.State && <Typography color="error">Select your State</Typography>}
                    control={control}
                    name="State"
                    selectData={addWorkplaceData.State}
                    placeHolder="Select your State"
                    selectFieldCss={styles.dropdownWorkPlace}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Country</Typography>
                  <CustomSelect
                    error={Boolean(errors.Country)}
                    errorCondition={errors.Country && <Typography color="error">Select your Country</Typography>}
                    control={control}
                    name="Country"
                    selectData={addWorkplaceData.Country}
                    placeHolder="Select your Country"
                    selectFieldCss={styles.dropdownWorkPlace}
                  />
                </Grid>
              </Grid>

              <Grid spacing={1} container className={styles.inputtexts}>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Mobile number</Typography>

                  <PhoneInput
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && 'Enter your Phone-Number'}
                    control={control}
                    name="phone"
                    form="form"
                    containerClass={styles.containerPhn}
                    inputClass={styles.inputPhn}
                    placeholder="+91 8219941918"
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Time Zone</Typography>
                  <CustomSelect
                    error={Boolean(errors.timeZone)}
                    errorCondition={errors.timeZone && <Typography color="error">Select Time-Zone</Typography>}
                    control={control}
                    name="timeZone"
                    selectData={addWorkplaceData.timeZone}
                    placeHolder="Select your timeZone"
                    selectFieldCss={styles.dropdownWorkPlace}
                  />
                </Grid>
              </Grid>

              <Grid
                md={12}
                sm={12}
                xs={12}
                justifyContent={'flex-end'}
                container
                item
                className={styles.bottom_button}
                pb={1}
              >
                <CustomButtons
                  name={!Data.id ? 'Add Workplace' : 'Edit Workplace'}
                  variant="contained"
                  className={styles.ActBtn}
                  onClick={handleSubmit(handleOnSubmit)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}

export default AddNewWorkplacesModal;
