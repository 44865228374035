import { Grid, Typography } from "@mui/material";
import styles from "src/Styles/Workplace.module.css";
import React from "react";

function SettingCustomHeader({ header, paragraph, className }: any) {
  return (
    <>
      <Grid className={`${styles.upperGrid_padding} ${className}`}>
        <Typography className={styles.headerCss}>{header}</Typography>
        <Typography className={styles.headerFooterCss}>{paragraph}</Typography>
      </Grid>
    </>
  );
}

export default SettingCustomHeader;
