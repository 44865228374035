import { Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ExcelExport from "src/components/ExcelExport";
import AttendanceSheet from "src/components/AttendanceSheet/AttendanceSheet";
import Styles from "src/Styles/Calendar.module.css";
import React from "react";

interface CustomToolbarProps {
  label: string;
  handlePrevClick: () => void;
  handleNextClick: () => void;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  label,
  handlePrevClick,
  handleNextClick,
}) => {
  const [isattendance, setIsAttendance] = React.useState(false);

  const handleClickOpen = () => {
    setIsAttendance(true);
  };

  const handleClose = () => {
    setIsAttendance(false);
  };
  return (
    <Grid item container justifyContent={"space-between"}>
      <Grid item lg={6} md={6} xs={9} className={Styles.ToolBar}>
        <IconButton onClick={handlePrevClick}>
          <ArrowBackIosIcon className={Styles.Icon} />
        </IconButton>
        <IconButton onClick={handleNextClick}>
          <ArrowForwardIosIcon className={Styles.Icon} />
        </IconButton>
        <Typography className={Styles.label}>{label}</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent={"space-between"}
        textAlign={"right"}
        lg={6}
        md={6}
        xs={3}
      >
        <Grid lg={6} md={6} xs={6}>
          <ExcelExport />
        </Grid>
        <Grid lg={6} md={6} xs={6}>
          <IconButton>
            <FullscreenIcon
              onClick={handleClickOpen}
              className={Styles.ScreenIcon}
            />
            {isattendance && (
              <AttendanceSheet
                onClose={handleClose}
                open={isattendance}
                label={label}
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomToolbar;
