import CustomTab from "src/components/CustomTab";
import { Stack, Typography } from "@mui/material";
import Styles from "src/Styles/informationTab.module.css";
import DocumentsSetting from "../DocumentsSetting";
import ManageAllowances from "src/components/ManageAllowances/index";
import Workplaces from "../Workplace";
import LegalEntites from "../LegalEntities/LegalEntites";
import Departments from "../Departments";
import JobTitle from "../JobTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@material-ui/core";
import TimeOffTabs from "../TimeOff/TimeOffTabs";
import CompanyUsers from "../CompanyUsers";

const SettingsHorizontalTab = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileMd = useMediaQuery(theme.breakpoints.down('md'));
  const data = [
    {
      id: 0,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Work Places</Typography>
        </Stack>
        // <WorkplaceIcon
        //   strokeColor={strokeColor}
        //   handleCircleClick={handleCircleClick}
        //   classes={classes}
        // />
      ),
      component: <Workplaces />,
    },
    {
      id: 1,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Legal Entities</Typography>
        </Stack>
        // <LeagalEntityIcon
        //   strokeColor={strokeColor}
        //   handleCircleClick={handleCircleClick}
        //   classes={classes}
        // />
      ),
      component: <LegalEntites />,
    },
    {
      id: 2,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Departments</Typography>
        </Stack>
        // <DepartmentIcon
        //   strokeColor={strokeColor}
        //   handleCircleClick={handleCircleClick}
        //   classes={classes}
        // />
      ),
      component: <Departments />,
    },
    {
      id: 3,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Job Title</Typography>
        </Stack>
        // <JobTitlesIcon
        //   strokeColor={strokeColor}
        //   handleCircleClick={handleCircleClick}
        //   classes={classes}
        // />
      ),
      component: <JobTitle />,
    },
    {
      id: 4,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Time off</Typography>
        </Stack>
      ),
      component: <TimeOffTabs />,
      // href: "/hrms/settings/timeOff",
    },
    {
      id: 5,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Documents</Typography>
        </Stack>
      ),
      component: <DocumentsSetting />,
      // href: "/hrms/settings/documents",
    },
    {
      id: 6,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>Manage Allowances</Typography>
        </Stack>
      ),
      component: <ManageAllowances />,
      // href: "/hrms/settings/manageAllowances",
    },
    {
      id: 7,
      label: (
        <Stack alignSelf="start">
          <Typography className={Styles.tabs_typography}>
            Company Users
          </Typography>
        </Stack>
      ),
      component: <CompanyUsers />,
      // href: "/hrms/settings/manageAllowances",
    },
  ];

  return (
    <>
      <Typography className={Styles.header}>Settings</Typography>
      <CustomTab
        myTabData={data}
        orientation={isMobile || isMobileMd ? 'horizontal' : 'vertical'}
        // moreSideIcon={false}
        // component={children}
        customBtnStyle={isMobile ? Styles.customTabStyleHorizontal : Styles.customTabStyle}
      />
    </>
  );
};

export default SettingsHorizontalTab;
