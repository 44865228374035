import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  IconButton,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import Styles from "src/Styles/DashBoardCard.module.css";
import pendingRequest from "src/images/assets/pending_placeholder (copy).svg";
import celebrations from "src/images/assets/birthday_placeholder.svg";
import AddTaskModalBox from "src/components/DashboardCards/ModalBox/AddTaskModalBox";
import AddQuickLinkModalBox from "src/components/DashboardCards/ModalBox/AddQuickLinkModalBox";
import myRequest from "src/images/assets/myrequests_placeholder.svg";
import { CardContent, CardActions } from "@material-ui/core";
import AllTaskSlider from "src/components/DashboardCards/AllTaskSlider";
import { useMutation, useQuery } from "@apollo/client";
import { ATTENDANCE_IN_OUT } from "src/graphql/attendance";
import { GET_ATTENDANCE_BY_ID } from "src/graphql/attendance";
import { toast, ToastOptions } from "react-toastify";
import moment from "moment";
import { GET_DASHBOARD_INSIGHTS_BY_ID } from "src/graphql/dashboardInsights";
import { GET_ALL_HOLIDAYS } from "src/graphql/holidays";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SubCircularLoader from "../ActivityIndicator/SubIndicator";
import ModalBox from "../ReusableModal/modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PlaceIcon from "@mui/icons-material/Place";

const DashboardCards = () => {
  const navigate = useNavigate();
  const [mopen, setOpen] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = React.useState(new Date());
  const [loginData, setLoginData] = React.useState<Record<string, any>>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [leave, leaveSetOpen] = React.useState(false);
  const [openQuickLinkTaskModal, setOpenQuickLinkTaskModal] = useState(false);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openAllTaskSlider, setOpenAllTaskSlider] = useState(false);
  const [yearView, setYearView] = useState<string | number | Date>(
    moment().format("YYYY")
  );
  const [locationMessage, setLocationMessage] = useState({
    error: false,
    message: "",
  });

  // State to keep track of elapsed time
  const [elapsedTime, setElapsedTime] = useState({
    percentage: 0,
    formattedTime: "0h 0m 0s",
  });

  const userId = localStorage.getItem("userId");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [AttendanceInOut, { data, loading: attendanceLoading, error }] =
    useMutation(ATTENDANCE_IN_OUT);

  const {
    data: dashboardInsights,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: dashboardInsightsLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: dashboardInsightsRefetch,
  } = useQuery(GET_DASHBOARD_INSIGHTS_BY_ID, {
    variables: {
      dashboardInsightsByIdId: userId,
    },
    fetchPolicy:'no-cache'
  });

  const dashboardInsightsData = dashboardInsights?.dashboardInsightsById;

  const hasPendingRMRequests = dashboardInsightsData?.pendingRequestOfRM?.some(
    (request: any) => request.count > 0
  );

  const {
    data: holidaysData,
    loading: holidaysLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: holidaysRefetch,
  } = useQuery(GET_ALL_HOLIDAYS, {
    variables: {
      year: yearView,
    },
  });

  const {
    data: attendanceData,
    loading: attendanceDataLoading,
    refetch: attendanceDataRefetch,
  } = useQuery(GET_ATTENDANCE_BY_ID, {
    variables: {
      userId: userId,
      attendanceDate: moment(date).format("YYYY-MM-DD"),
    },
  });

  useEffect(() => {
    setLoginData(attendanceData?.getAttendanceById);
  }, [attendanceData]);

  const handleOnSubmit = async (evt: any) => {
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const response = await AttendanceInOut({
              variables: {
                input: {
                  userId,
                  source: "Web",
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                },
              },
            });
            const toastOptions: ToastOptions = {
              position: "top-right",
              autoClose: 9000,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            };

            if (response?.data) {
              setLoginData(response?.data?.attendanceInOut);
              attendanceDataRefetch();
            }
            if (response?.data?.attendanceInOut?.isLoggedIn === false) {
              toast.error("Clock out successfully", toastOptions);
            } else {
              toast.success("Clocked in successfully", toastOptions);
            }
          },
          (error: any) => {
            if (error.code === error.PERMISSION_DENIED) {
              setLocationMessage({
                error: true,
                message: "Please turn on the device's location.",
              });
            } else if (error.code === error.POSITION_UNAVAILABLE) {
              setLocationMessage({
                error: true,
                message: "Location information is unavailable.",
              });
            } else if (error.code === error.TIMEOUT) {
              setLocationMessage({
                error: true,
                message: "The request to get user location timed out.",
              });
            } else if (error.code === error.UNKNOWN_ERROR) {
              setLocationMessage({
                error: true,
                message: "An unknown error occurred.",
              });
            } else {
              setLocationMessage({
                error: true,
                message: "Geolocation is not supported by this browser.",
              });
            }
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        setLocationMessage({
          error: true,
          message: "Geolocation is not supported by this browser.",
        });
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const refetchYear = async (direction: "PREV" | "NEXT") => {
    let currentYear = moment(yearView, "YYYY");

    if (direction === "NEXT") {
      currentYear = currentYear.add(1, "years");
    } else if (direction === "PREV") {
      currentYear = currentYear.subtract(1, "years");
    }

    setYearView(currentYear.format("YYYY"));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickOpen2 = () => {
    leaveSetOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose2 = () => {
    leaveSetOpen(false);
  };

  const toggleQuickLinkTaskModal = () => {
    setOpenQuickLinkTaskModal(!openQuickLinkTaskModal);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickOpenQuickLink = () => {
    setOpenQuickLinkTaskModal(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseQuickLink = () => {
    setOpenQuickLinkTaskModal(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleAllTaskSlider = () => {
    setOpenTaskModal(!openAllTaskSlider);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickOpen1 = () => {
    setOpenTaskModal(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose1 = () => {
    setOpenTaskModal(false);
  };

  const toggleTaskModal = () => {
    setOpenTaskModal(!openTaskModal);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openOnClick = () => {
    setOpenAllTaskSlider(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeOnClick = () => {
    setOpenAllTaskSlider(false);
  };

  // Function to calculate elapsed time
  const calculateElapsedTime = (
    startTime: any,
    isLoggedIn: boolean | undefined
  ) => {
    if (!isLoggedIn || !startTime) {
      // If user is not logged in or startTime is undefined or null
      return {
        percentage: 0,
        formattedTime: "0h 0m 0s",
      };
    }
    const start = moment(startTime);
    const now = moment();
    const elapsedTime = moment.duration(now.diff(start));

    if (elapsedTime.isValid()) {
      const percentage = (elapsedTime.asHours() / 9) * 100;

      // Display the elapsed time
      const elapsedHours = Math.floor(elapsedTime.asHours());
      const elapsedMinutes = elapsedTime.minutes();
      const elapsedSeconds = elapsedTime.seconds();
      const formattedElapsedTime = `${elapsedHours}h ${elapsedMinutes}m ${elapsedSeconds}s`;

      return {
        percentage: Math.min(100, percentage),
        formattedTime: formattedElapsedTime,
      };
    } else {
      return {
        percentage: 0,
        formattedTime: "0h 0m 0s",
      };
    }
  };

  // Effect to update elapsed time at regular intervals
  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsedTimeResult = calculateElapsedTime(
        loginData?.loginTime,
        loginData?.isLoggedIn
      );
      setElapsedTime(elapsedTimeResult);
    }, 1000); // Update every 1000ms (1 second)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [loginData?.loginTime, loginData?.isLoggedIn]);

  const handlePendingOnMeNavigate = (requestType: any) => {
    localStorage.setItem("sidebarIndex", "timeOff");
    if (requestType === "Leave") {
      navigate("/admin/hrms/timeOff/leave");
    } else if (requestType === "Regularization") {
      navigate("/admin/hrms/timeOff/regularization");
    } else if (requestType === "Work From Home") {
      navigate("/admin/hrms/timeOff/work-from-home");
    }
  };

  const handleMyPendingRequestNavigate = (requestType: any) => {
    localStorage.setItem("sidebarIndex", "employeeTasks");
    if (requestType === "Leave") {
      navigate("/hrms/tasks/leave");
    } else if (requestType === "Regularization") {
      navigate("/hrms/tasks/regularization");
    } else if (requestType === "Work From Home") {
      navigate("/hrms/tasks/work-from-home");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data1 = [
    {
      AttandenceID: "1",
      Subject: "Present",
      Number: "7",
    },
    {
      AttandenceID: "2",
      Subject: "Absent",
      Number: "1",
    },
    {
      AttandenceID: "3",
      Subject: "Holiday",
      Number: "",
    },
    {
      AttandenceID: "4",
      Subject: "Leaves",
      Number: "",
    },
    {
      AttandenceID: "5",
      Subject: "Weekend",
      Number: "4",
    },
    {
      AttandenceID: "6",
      Subject: "Penalty",
      Number: "",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Data9 = [
    {
      InsightsID: "1",
      OnLeaveName: "User One(AK0108)",
      leaveDate: "27-Jul-2023 (Earned Leave)",
    },
  ];
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid container className={Styles.mainContainerGrid} spacing={3}>
        {/* CRAD  (ClockIn Task) */}
        <Grid item lg={4} md={6} xs={12}>
          <Card className={Styles.cardContainer}>
            <CardActions className={Styles.cardHeader}>
              <Grid item md={8}>
                <Typography className={Styles.heading}>
                  Let's get to work
                </Typography>
              </Grid>
            </CardActions>
            <CardContent className={Styles.cardContent}>
              <Grid container className={Styles.clockInTodayContainer}>
                <Grid>
                  <Typography className={Styles.clockInTodayTypo}>
                    Today
                  </Typography>
                  <Typography className={Styles.clockInTodaySubTypo}>
                    {moment(date).format(" ddd MMM DD YYYY")}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={Styles.clockInTodayTypo}>
                    {loginData?.totalTime && loginData?.totalTime !== "-"
                      ? loginData?.totalTime + " Hrs"
                      : "00:00 Hrs"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={12} lg={12} className={Styles.parentGridTime}>
                <Grid className={Styles.clockinStartTime}>
                  {loginData?.loginTime && (
                    <Typography className={Styles.clockinStartTimeTypo}>
                      Start Time:&nbsp;
                      {moment(loginData?.loginTime).format("h:mm A")}
                    </Typography>
                  )}
                </Grid>
                <Grid className={Styles.clockinStartTime}>
                  {loginData?.isLoggedIn === false && loginData?.logoutTime ? (
                    <Typography className={Styles.clockinStartTimeTypo}>
                      End Time:&nbsp;
                      {moment(loginData?.logoutTime).format("h:mm A")}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              {/* Progress bar to show time elapsed since clocking in */}
              <Grid className={Styles.progressBarContainer}>
                <LinearProgress
                  variant="determinate"
                  value={elapsedTime.percentage}
                  color="success"
                  className={Styles.progressBar}
                />
                <Typography className={Styles.elapsedTimeText}>
                  {elapsedTime.formattedTime}
                </Typography>
              </Grid>

              <Grid className={Styles.footerShiftContainer}>
                <Grid container className={Styles.shiftTimeContainer}>
                  <Typography className={Styles.shiftTimeTypo}>
                    Shift 09:30 AM - 06:30 PM
                  </Typography>
                </Grid>
                <Button
                  onClick={handleOnSubmit}
                  sx={{
                    backgroundColor: attendanceLoading
                      ? "grey"
                      : loginData?.isLoggedIn === true
                      ? "red"
                      : "green",
                    "&:hover": {
                      backgroundColor: attendanceLoading
                        ? "grey"
                        : loginData?.isLoggedIn === true
                        ? "darkred"
                        : "darkgreen",
                    },
                  }}
                  className={Styles.clockInButton}
                  disabled={
                    attendanceLoading &&
                    moment(loginData?.attendanceDate).isSame(moment(), "day")
                  }
                >
                  {attendanceLoading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : loginData?.isLoggedIn === true ? (
                    "Clock Out"
                  ) : (
                    "Clock In"
                  )}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* CRAD (Holidays) */}
        <Grid item lg={4} md={6} xs={12}>
          <Card className={Styles.cardContainer}>
            <CardActions className={Styles.cardHeader}>
              <Grid item>
                <Typography className={Styles.heading}>Holidays</Typography>
              </Grid>
              <Grid item className={Styles.yearViewContainer}>
                <IconButton
                  onClick={() => refetchYear("PREV")}
                  disabled={yearView <= "2023"}
                >
                  <ArrowBackIosIcon className={Styles.yearViewArrowIcons} />
                </IconButton>
                <Typography className={Styles.subText}>
                  {yearView.toString()}
                </Typography>
                <IconButton onClick={() => refetchYear("NEXT")}>
                  <ArrowForwardIosIcon className={Styles.yearViewArrowIcons} />
                </IconButton>
              </Grid>
            </CardActions>
            <CardContent className={Styles.cardContent}>
              {holidaysLoading ? (
                <SubCircularLoader
                  size={30}
                  className={Styles.subCircularLoader}
                />
              ) : (
                <Grid>
                  {holidaysData?.getAllHolidays?.length ? (
                    holidaysData?.getAllHolidays?.map(
                      (holiday: any, index: any) => (
                        <>
                          <Grid
                            container
                            className={Styles.celebrationSubContainer}
                            key={`holidays-${index}`}
                          >
                            <Grid
                              item
                              md={2}
                              sm={2}
                              xs={2}
                              lg={2}
                              className={Styles.celebrationsRightContainer}
                            >
                              <Grid className={Styles.celebrationsRightText}>
                                <Grid>
                                  {holiday.date &&
                                    moment(holiday.date).format("DD")}
                                </Grid>
                                <Grid>
                                  {holiday.date &&
                                    moment(holiday.date).format("MMM")}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item md={6} sm={6} xs={6} lg={6}>
                              <Typography
                                key={index}
                                className={Styles.holidayName}
                              >
                                {holiday.name}
                              </Typography>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4} lg={4}>
                              <Typography
                                key={index}
                                className={Styles.holidayType}
                              >
                                {holiday.type &&
                                  holiday.type.charAt(0).toUpperCase() +
                                    holiday.type.slice(1).toLowerCase()}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )
                    )
                  ) : (
                    <Alert severity="info">No Holidays to show</Alert>
                  )}
                </Grid>
              )}
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
        {/* CARD  (LEAVE) */}
        <Grid item lg={4} md={6} xs={12}>
          <Card className={Styles.cardContainer}>
            <CardActions className={Styles.cardHeader}>
              <Grid item>
                <Typography className={Styles.heading}>Leaves</Typography>
              </Grid>
            </CardActions>
            {attendanceDataLoading ? (
              <SubCircularLoader
                size={30}
                className={Styles.subCircularLoader}
              />
            ) : (
              <>
                <CardContent className={Styles.currentBalanceContainer}>
                  <Grid item>
                    <Typography className={Styles.balanceText}>
                      Current Balance
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={Styles.currentBalanceCount}>
                      {dashboardInsightsData?.currentBalance}
                    </Typography>
                  </Grid>
                </CardContent>

                <CardContent className={Styles.cardContent}>
                  <Grid>
                    {dashboardInsightsData?.leaveInsights.map(
                      (item: any, index: any) => (
                        <Grid
                          className={Styles.leavesSubContainer}
                          key={`leaves-${index}`}
                        >
                          <Grid item>
                            <Typography key={index} className={Styles.subText}>
                              {item.policyName}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              key={index}
                              className={Styles.leaveNumText}
                            >
                              {item.allowancesAvailable}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </CardContent>
              </>
            )}
            <CardActions></CardActions>
          </Card>
        </Grid>
        {/* CRAD (Pending on Me) */}
        <Grid item lg={4} md={6} xs={12}>
          <Card className={Styles.cardContainer}>
            <CardActions className={Styles.cardHeader}>
              <Grid item md={12}>
                <Typography className={Styles.heading}>
                  Pending on Me
                </Typography>
              </Grid>
            </CardActions>
            <CardContent className={Styles.cardContent}>
              {attendanceDataLoading ? (
                <SubCircularLoader
                  size={30}
                  className={Styles.subCircularLoader}
                />
              ) : (
                <Grid>
                  {hasPendingRMRequests ? (
                    <>
                      {dashboardInsightsData?.pendingRequestOfRM.map(
                        (request: any) =>
                          request.count ? (
                            <>
                              <Grid
                                className={Styles.PendingRequest}
                                style={{ display: "flex" }}
                                onClick={() =>
                                  handlePendingOnMeNavigate(request.type)
                                }
                              >
                                <Grid>
                                  <Typography className={Styles.subText}>
                                    {request.type}
                                  </Typography>
                                </Grid>

                                <Grid>
                                  <Typography
                                    className={Styles.pendingOnMeCount}
                                  >
                                    {request.count}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            ""
                          )
                      )}
                    </>
                  ) : (
                    <Grid item md={12} className={Styles.NoDataImgContainer}>
                      <Grid>
                        <img
                          alt="pending-request"
                          src={pendingRequest}
                          className={Styles.NoDataImg}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Typography className={Styles.noDataText}>
                          No Pending Request
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </CardContent>
            <CardActions className={Styles.cardFooter}></CardActions>
          </Card>
        </Grid>
        {/* CRAD (My Pending Requests) */}
        <Grid item lg={4} md={6} xs={12}>
          <Card className={Styles.cardContainer}>
            <CardActions className={Styles.cardHeader}>
              <Grid item md={12}>
                <Typography className={Styles.heading}>
                  My Pending Request
                </Typography>
              </Grid>
            </CardActions>
            <CardContent className={Styles.cardContent}>
              {attendanceDataLoading ? (
                <SubCircularLoader
                  size={30}
                  className={Styles.subCircularLoader}
                />
              ) : (
                <Grid>
                  {dashboardInsightsData?.pendingRequestOfEmployee?.length ? (
                    <>
                      {dashboardInsightsData?.pendingRequestOfEmployee.map(
                        (request: any) => (
                          <>
                            <Grid
                              className={Styles.PendingRequest}
                              onClick={() =>
                                handleMyPendingRequestNavigate(
                                  request.requestType
                                )
                              }
                            >
                              <Grid>
                                <Grid
                                  className={Styles.currentBalanceContainer}
                                >
                                  <Grid item>
                                    <Typography
                                      className={Styles.celebrationsText}
                                    >
                                      {request.requestType}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={Styles.myPRequestStatusText}
                                    >
                                      {request.status.charAt(0).toUpperCase() +
                                        request.status.slice(1).toLowerCase()}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Typography className={Styles.celebrationsType}>
                                  {moment(request.appliedFrom).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <Grid className={Styles.NoDataImgContainer}>
                      <Grid>
                        <img
                          alt="my-request"
                          src={myRequest}
                          className={Styles.NoDataImg}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Typography className={Styles.noDataText}>
                          No Request Raised
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </CardContent>
            <CardActions className={Styles.cardFooter}></CardActions>
          </Card>
        </Grid>
        {/* CRAD  (Celebrations) */}
        <Grid item lg={4} md={6} xs={12}>
          <Card className={Styles.cardContainer}>
            <CardActions className={Styles.cardHeader}>
              <Grid item md={8}>
                <Typography className={Styles.heading}>Celebrations</Typography>
              </Grid>
              <Grid item md={4}></Grid>
            </CardActions>
            <CardContent className={Styles.cardContent}>
              {attendanceDataLoading ? (
                <SubCircularLoader
                  size={30}
                  className={Styles.subCircularLoader}
                />
              ) : (
                <Grid>
                  {dashboardInsightsData?.celebrations?.length ? (
                    <>
                      {dashboardInsightsData.celebrations.map(
                        (celebration: any) => (
                          <Grid className={Styles.celebrationSubContainer}>
                            <Grid className={Styles.celebrationsLeftContainer}>
                              <Grid>
                                <Typography className={Styles.celebrationsText}>
                                  {celebration.displayName}
                                </Typography>
                                <Typography className={Styles.celebrationsType}>
                                  {celebration.type === "BIRTHDAY"
                                    ? "Birthday"
                                    : celebration.type === "WORKANNIVERSARY"
                                    ? "Work Anniversary"
                                    : ""}
                                </Typography>{" "}
                              </Grid>
                            </Grid>
                            <Grid
                              className={
                                celebration.date || celebration.date
                                  ? Styles.celebrationsRightContainer
                                  : ""
                              }
                            >
                              <Grid className={Styles.celebrationsRightText}>
                                <Grid>
                                  {celebration.date &&
                                    moment(celebration.date).format("DD")}
                                </Grid>
                                <Grid>
                                  {celebration.date &&
                                    moment(celebration.date).format("MMM")}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </>
                  ) : (
                    <Grid item md={12} className={Styles.NoDataImgContainer}>
                      <img
                        alt="celebration"
                        src={celebrations}
                        width={200}
                        height={200}
                        className={Styles.NoDataImg}
                      />
                      <Grid item md={12}>
                        <Typography className={Styles.noDataText}>
                          No Celebrations Found
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </CardContent>
            <CardActions className={Styles.cardFooter}></CardActions>
          </Card>
        </Grid>
        <Grid container className={Styles.mainContainerGrid} spacing={3}></Grid>
      </Grid>

      {openTaskModal && (
        <AddTaskModalBox
          openTaskModal={openTaskModal}
          toggleTaskModal={toggleTaskModal}
          key="add-task-modal"
        />
      )}
      {mopen && <AllTaskSlider onClose={handleClose} open={mopen} />}
      {openQuickLinkTaskModal && (
        <AddQuickLinkModalBox
          openQuickLinkTaskModal={openQuickLinkTaskModal}
          toggleQuickLinkTaskModal={toggleQuickLinkTaskModal}
          key="add-quick-link-modal"
        />
      )}
      {locationMessage && (
        <ModalBox
          width={500}
          height={250}
          open={locationMessage.error}
          onClose={() => {
            setLocationMessage({ error: false, message: "" });
          }}
          content={
            <Grid className={Styles.locModalContainer}>
              <Grid className={Styles.locModalHeader}>
                <CloseOutlinedIcon
                  className={Styles.locCloseIcon}
                  onClick={() => {
                    setLocationMessage({ error: false, message: "" });
                  }}
                />
              </Grid>
              <Grid className={Styles.locIcon}>
                <PlaceIcon fontSize="large" />
              </Grid>
              <Grid>
                <Typography variant="h5" className={Styles.loctext}>
                  {locationMessage.message}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
};

export default DashboardCards;
