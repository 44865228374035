import React, { useEffect, useState, ChangeEvent } from "react";
import styles from "src/Styles/addEmployee.module.css";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Button,
} from "@mui/material";
import CustomButtons from "src/components/CustomButton/Button";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "src/components/CustomSelect/customSelectValidations";
import perInfoData from "./PersonalInfoData.json";
import PhoneInput from "src/components/PhoneInput";
import CustomTextField from "src/components/CustomTextField";
import CustomDatePicker from "src/components/customDatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { PersonalInfoSchema } from "src/utils/validations";
import { Toast } from "src/components/ToastMessage";
import {
  UPDATE_EMPLOYEE,
  GET_ADDRESS_BY_ZIPCODE,
  UPDATE_EMPLOYEE_BY_QUERY_TYPE,
} from "src/graphql/user";
import { useMutation, useQuery } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import SubCircularLoader from "src/components/ActivityIndicator/SubIndicator";
import CustomTable from "src/components/TableComponent";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import tableStyle from "../../../Styles/commonTable.module.css";
import MenuItems from "src/components/MenuItems/MenuItems";
import CustomMultiSelect from "src/components/CustomMultiSelect";
import EmergencyContactModal from "src/components/EmergencyContactModal";
import avatar from "src/images/assets/avatar.png";
import axios from "axios";

const PersonalInformation = ({ editEmpRecord, editEmpRefetch }: any) => {
  const [checked, setChecked] = useState(false);
  const [loaderForCurtAdrs, setLoaderForCurtAdrs] = useState(false);
  const [loaderForPerntAdrs, setLoaderForPerntAdrs] = useState(false);
  const [loaderForProfileImg, setLoaderForProfileImg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [defaultLanguages, setDefaultLanguages] = useState<any>([]);

  const [
    updateEmployee,
    // eslint-disable-next-line
    { data: updateEmployeeData, loading: updateEmployeeLoading },
  ] = useMutation(UPDATE_EMPLOYEE);

  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeRes, loading: updateEmployeeResLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  const {
    // eslint-disable-next-line
    data: addressData,
    // eslint-disable-next-line
    loading: addressDataLoading,
    // eslint-disable-next-line
    error: addressDataError,
    refetch: addressDataRefetch,
  } = useQuery(GET_ADDRESS_BY_ZIPCODE, {
    variables: { zipCode: "" },
  });

  useEffect(() => {
    const formattedLanguages = (editEmpRecord?.languagesKnown || []).map(
      (item: any) => ({
        title: item,
        id: item,
      })
    );
    setDefaultLanguages(formattedLanguages);

    const FormatedEditEmployeeData = {
      firstName: editEmpRecord?.firstName || "",
      lastName: editEmpRecord?.lastName || "",
      personalEmail: editEmpRecord?.personalEmail || "",
      phone: editEmpRecord?.phone || "",
      dob: editEmpRecord?.dob || "",
      bloodGroup: editEmpRecord?.bloodGroup || "",
      motherName: editEmpRecord?.motherName || "",
      fatherName: editEmpRecord?.fatherName || "",
      maritalStatus: editEmpRecord?.maritalStatus || "",
      dateOfMarriage: editEmpRecord?.dateOfMarriage || "",
      nationality: editEmpRecord?.nationality || "",
      languageKnown: formattedLanguages || [],
      gender: editEmpRecord?.gender || "",
      currentAddress: editEmpRecord?.address?.addressDetails || "",
      zipCode: editEmpRecord?.address?.zipCode || "",
      state: editEmpRecord?.address?.state || "",
      city: editEmpRecord?.address?.city || "",
      country: editEmpRecord?.address?.country || "",
      permanentAddress: editEmpRecord?.permanentAddress?.addressDetails || "",
      zipCode2: editEmpRecord?.permanentAddress?.zipCode || "",
      state2: editEmpRecord?.permanentAddress?.state || "",
      city2: editEmpRecord?.permanentAddress?.city || "",
      country2: editEmpRecord?.permanentAddress?.country || "",
    };
    reset(FormatedEditEmployeeData);

    // eslint-disable-next-line
  }, [editEmpRecord]);

  const {
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ resolver: yupResolver(PersonalInfoSchema) });

  const handleUpdatePersonalInfo = async (data: any) => {
    try {
      let formatedLanguages = [];
      if (data.languageKnown.length) {
        formatedLanguages = data.languageKnown.map((item: any) => item.title);
      }

      const userData = {
        firstName: data.firstName,
        lastName: data.lastName,
        personalEmail: data.personalEmail,
        phone: data.phone,
        dob: data.dob,
        bloodGroup: data.bloodGroup,
        motherName: data.motherName,
        fatherName: data.fatherName,
        maritalStatus: data.maritalStatus,
        dateOfMarriage: data.dateOfMarriage,
        nationality: data.nationality,
        languagesKnown: formatedLanguages,
        gender: data.gender,
        address: {
          addressDetails: data.currentAddress,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          country: data.country,
        },
        permanentAddress: {
          addressDetails: data.permanentAddress,
          city: data.city2,
          state: data.state2,
          country: data.country2,
          zipCode: data.zipCode2,
        },
      };

      const updateResponse: any = await updateEmployee({
        variables: {
          updateEmployeeId: editEmpRecord?.id,
          input: userData,
        },
      });

      if (updateResponse) {
        editEmpRefetch();
        Toast("success", "Personal Information updated Successfully");
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  useEffect(() => {
    if (checked) {
      setValue("permanentAddress", watch("currentAddress"));
      setValue("zipCode2", watch("zipCode"));
      setValue("state2", watch("state"));
      setValue("city2", watch("city"));
      setValue("country2", watch("country"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checked,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch("currentAddress"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch("zipCode"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch("state"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch("city"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch("country"),
  ]);

  const onZipcodeChange = async (evt: any) => {
    try {
      if (evt.target.value) {
        setLoaderForCurtAdrs(true);
        const response = await addressDataRefetch({
          zipCode: evt.target.value,
        });

        const data = response?.data?.getAddressByZipcode;

        if (data) {
          setValue("state", data.state);
          setValue("city", data.city);
          setValue("country", data.country);
        }

        setLoaderForCurtAdrs(false);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  const onZipcode2Change = async (evt: any) => {
    try {
      if (evt.target.value) {
        setLoaderForPerntAdrs(true);
        const response = await addressDataRefetch({
          zipCode: evt.target.value,
        });

        const data = response?.data?.getAddressByZipcode;

        if (data) {
          setValue("state2", data.state);
          setValue("city2", data.city);
          setValue("country2", data.country);
        }
        setLoaderForPerntAdrs(false);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  //on checkbox click
  const handleCheckBoxChange = (evt: any) => {
    setChecked(!checked);
    if (evt.target.checked) {
      setValue("permanentAddress", watch("currentAddress"));
      setValue("zipCode2", watch("zipCode"));
      setValue("state2", watch("state"));
      setValue("city2", watch("city"));
      setValue("country2", watch("country"));
    } else {
      setValue(
        "permanentAddress",
        editEmpRecord?.permanentAddress?.addressDetails || ""
      );
      setValue("zipCode2", editEmpRecord?.permanentAddress?.zipCode || "");
      setValue("state2", editEmpRecord?.permanentAddress?.state || "");
      setValue("city2", editEmpRecord?.permanentAddress?.city || "");
      setValue("country2", editEmpRecord?.permanentAddress?.country || "");
    }
  };

  const addContactModalOpen = () => {
    setSelectedItem(null);
    setOpenModal(!openModal);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleMenuClick = async (item: any, event: any) => {
    if (event.target.textContent === "Edit") {
      setSelectedItem(item);
      setOpenModal(!openModal);
    } else if (event.target.textContent === "Delete") {
      try {
        const response: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "deleteEmergencyContact",
              emergencyContactDetails: { id: item.id },
            },
          },
        });
        if (response.data) {
          Toast("success", "Emergency contact deleted Successfully");
          editEmpRefetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      try {
        e.preventDefault();
        setLoaderForProfileImg(true);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("hostname", "localhost");
        formData.append("userId", editEmpRecord?.id);

        const responseUpload = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/uploadProfileImage`,
          formData
        );
        if (responseUpload) {
          Toast("success", "Profile picture updated Successfully");
          editEmpRefetch();
          setLoaderForProfileImg(false);
          e.target.value = "";
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };
  return (
    <Grid
      className={styles.createEmployeeContainer}
      container
      md={12}
      sm={12}
      lg={12}
      xl={12}
      xs={12}
    >
      <Grid
        container
        className={styles.infoWraper}
        component="form"
        onSubmit={handleSubmit(handleUpdatePersonalInfo)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Grid container>
          <Grid container mb={3} className={styles.headerGrid}>
            <Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subHeading}>
                  Personal Information
                </Typography>
              </Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subText}>
                  Here are all the employees at your company.
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <CustomButtons
                name="Update"
                variant="contained"
                className={styles.createBtn}
                type="file"
                loading={updateEmployeeLoading}
                disabled={updateEmployeeLoading}
                loaderClass={styles.buttonLoader}
              />
            </Grid>
          </Grid>
          {/* profile image start from here */}
          <Grid className={styles.profileImgContainer}>
            <Grid>
              <Typography className={styles.profileImgHeading} variant="h2">
                Profile Image
              </Typography>
            </Grid>
            <Grid className={styles.profileImgWrapper}>
              <Grid className={styles.profileImgGrid}>
                <img
                  src={editEmpRecord?.profileImage?.url || avatar}
                  alt="profile"
                  className={styles.profileImg}
                />
              </Grid>
              <Grid container className={styles.uploadProfileBtnContainer}>
                <Grid className={styles.loaderGrid}>
                  {loaderForProfileImg && (
                    <SubCircularLoader
                      size={20}
                      className={styles.subCircularLoader}
                    />
                  )}
                </Grid>
                <label htmlFor="upload-button">
                  <Button
                    component="span"
                    variant="outlined"
                    className={styles.uploadProfileBtn}
                    disabled={loaderForProfileImg}
                  >
                    Upload a Picture
                  </Button>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-button"
                  onChange={handleImageChange}
                  disabled={loaderForProfileImg}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* profile image ends from here */}

          {/* first layout start from here */}
          <Grid
            container
            mt={3}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>First Name</Typography>
              <CustomTextField
                error={Boolean(errors.firstName)}
                errorCondition={
                  errors.firstName && (
                    <Typography className={styles.errorMsg}>
                      {errors.firstName.message}
                    </Typography>
                  )
                }
                control={control}
                name="firstName"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="First Name"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Last Name</Typography>
              <CustomTextField
                error={Boolean(errors.lastName)}
                errorCondition={
                  errors.lastName && (
                    <Typography className={styles.errorMsg}>
                      {errors.lastName.message}
                    </Typography>
                  )
                }
                control={control}
                name="lastName"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="Last Name"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Personal Email
              </Typography>
              <CustomTextField
                error={Boolean(errors.personalEmail)}
                errorCondition={
                  errors.personalEmail && (
                    <Typography className={styles.errorMsg}>
                      {errors.personalEmail.message}
                    </Typography>
                  )
                }
                control={control}
                name="personalEmail"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="employee@gmail.com"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Mobile Number
              </Typography>
              <PhoneInput
                control={control}
                name="phone"
                error={Boolean(errors?.phone)}
                containerClass={styles.containerPhn}
                inputClass={`${styles.inputPhn} ${
                  Boolean(errors?.phone) ? styles.errorBorder : ""
                }`}
                placeholder="+91 8219941918"
                helperText={errors?.phone?.message}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>DOB</Typography>
              <CustomDatePicker
                error={Boolean(errors.dob)}
                errorCondition={
                  errors.dob && (
                    <Typography className={styles.errorMsg}>
                      {errors.dob.message}
                    </Typography>
                  )
                }
                control={control}
                name="dob"
                className={styles.datefieldContainer}
                selectedDate={editEmpRecord?.dob}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Blood Group</Typography>
              <CustomSelect
                // error={Boolean(errors.bloodGroup)}
                // errorCondition={
                //   errors.bloodGroup && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.bloodGroup.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="bloodGroup"
                selectData={perInfoData.bloodGroup}
                placeHolder="Ex: O + ve"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Mother’s Name
              </Typography>
              <CustomTextField
                // error={Boolean(errors.motherName)}
                // errorCondition={
                //   errors.motherName && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.motherName.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="motherName"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="Mother’s Name"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Father’s Name
              </Typography>
              <CustomTextField
                // error={Boolean(errors.fatherName)}
                // errorCondition={
                //   errors.fatherName && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.fatherName.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="fatherName"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="Father's Name"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Marital Status
              </Typography>
              <CustomSelect
                // error={Boolean(errors.maritalStatus)}
                // errorCondition={
                //   errors.maritalStatus && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.maritalStatus.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="maritalStatus"
                selectData={perInfoData.martialStatus}
                placeHolder="Marital Status"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Date of Marriage
              </Typography>
              <CustomDatePicker
                // error={Boolean(errors.dateOfMarriage)}
                // errorCondition={
                //   errors.dateOfMarriage && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.dateOfMarriage.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="dateOfMarriage"
                className={styles.datefieldContainer}
                selectedDate={editEmpRecord?.dateOfMarriage}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Nationality</Typography>

              <CustomSelect
                error={Boolean(errors.nationality)}
                errorCondition={
                  errors.nationality && (
                    <Typography className={styles.errorMsg}>
                      {errors.nationality.message}
                    </Typography>
                  )
                }
                control={control}
                name="nationality"
                selectData={perInfoData.nationality}
                placeHolder="Select Nationality"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Languages Known
              </Typography>
              <CustomMultiSelect
                // error={Boolean(errors.languageKnown)}
                // errorCondition={
                //   errors.languageKnown && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.languageKnown.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="languageKnown"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="Select language"
                defaultValue={defaultLanguages}
                selectData={perInfoData.languages}
              />
              {/* <CustomTextField
                // error={Boolean(errors.languageKnown)}
                // errorCondition={
                //   errors.languageKnown && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.languageKnown.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="languageKnown"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="English"
              /> */}
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Gender</Typography>

              <CustomSelect
                error={Boolean(errors.gender)}
                errorCondition={
                  errors.gender && (
                    <Typography className={styles.errorMsg}>
                      {errors.gender.message}
                    </Typography>
                  )
                }
                control={control}
                name="gender"
                selectData={perInfoData.gender}
                placeHolder="Select Gender"
                selectFieldCss={styles.selectField}
              />
            </Grid>
          </Grid>
          {/* first layout end here..... */}
          <Grid
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            className={styles.dividerContainer}
          >
            <Divider className={styles.divider} />
          </Grid>
          {/* Second alyout start here */}
          <Grid
            container
            mt={3}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid item md={12} lg={6} sm={12} xs={12} xl={6}>
              <Grid
                md={12}
                lg={12}
                sm={12}
                xs={12}
                xl={12}
                className={styles.formGrid}
              >
                <Typography className={styles.formLabel}>
                  Current Address
                </Typography>
                <Controller
                  control={control}
                  name="currentAddress"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      multiline
                      rows={3}
                      size="small"
                      fullWidth
                      {...field}
                      type="text"
                      error={error !== undefined}
                      InputProps={{
                        className: styles.textareaContainer,
                      }}
                      placeholder="Current Address"
                    />
                  )}
                />
                {/* {errors.currentAddress && (
                  <Typography className={styles.errorMsg}>
                    {errors.currentAddress.message}
                  </Typography>
                )} */}
              </Grid>
              <Grid container md={12} lg={12} sm={12} xs={12} xl={12}>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
                >
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Typography className={styles.formLabel}>
                      Zipcode
                    </Typography>
                    <CustomTextField
                      // error={Boolean(errors.zipCode)}
                      // errorCondition={
                      //   errors.zipCode && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.zipCode.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="zipCode"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="Zip code"
                      onChange={onZipcodeChange}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Grid sx={{ display: "flex" }} gap={1}>
                      <Typography className={styles.formLabel}>
                        State
                      </Typography>
                      {loaderForCurtAdrs && (
                        <SubCircularLoader
                          size={13}
                          className={styles.subCircularLoader}
                        />
                      )}
                    </Grid>
                    <CustomTextField
                      // error={Boolean(errors.state)}
                      // errorCondition={
                      //   errors.state && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.state.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="state"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="State"
                    />
                    {/* <CustomSelect
                      // error={Boolean(errors.state)}
                      // errorCondition={
                      //   errors.state && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.state.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="state"
                      selectData={perInfoData.states}
                      placeHolder="State"
                      selectFieldCss={styles.selectField}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container md={12} lg={12} sm={12} xs={12} xl={12}>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
                >
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Grid sx={{ display: "flex" }} gap={1}>
                      <Typography className={styles.formLabel}>City</Typography>
                      {loaderForCurtAdrs && (
                        <SubCircularLoader
                          size={13}
                          className={styles.subCircularLoader}
                        />
                      )}
                    </Grid>
                    <CustomTextField
                      // error={Boolean(errors.city)}
                      // errorCondition={
                      //   errors.city && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.city.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="city"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="Select city"
                    />
                    {/* <CustomSelect
                      // error={Boolean(errors.city)}
                      // errorCondition={
                      //   errors.city && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.city.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="city"
                      selectData={perInfoData.city}
                      placeHolder="Select city"
                      selectFieldCss={styles.selectField}
                    /> */}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Grid sx={{ display: "flex" }} gap={1}>
                      <Typography className={styles.formLabel}>
                        Country
                      </Typography>
                      {loaderForCurtAdrs && (
                        <SubCircularLoader
                          size={13}
                          className={styles.subCircularLoader}
                        />
                      )}
                    </Grid>
                    <CustomTextField
                      // error={Boolean(errors.country)}
                      // errorCondition={
                      //   errors.country && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.country.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="country"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="Country"
                    />
                    {/* <CustomSelect
                      // error={Boolean(errors.country)}
                      // errorCondition={
                      //   errors.country && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.country.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="country"
                      selectData={perInfoData.nationality}
                      placeHolder="Country"
                      selectFieldCss={styles.selectField}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* FOR PERMANENT ADDRESS */}
            <Grid item md={12} lg={6} sm={12} xs={12} xl={6}>
              <Grid
                item
                container
                md={12}
                lg={12}
                sm={12}
                xs={12}
                xl={12}
                className={styles.formGrid}
              >
                <Grid container className={styles.checkboxContainer}>
                  <Typography className={styles.formLabel}>
                    Permanent Address
                  </Typography>
                  <Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Same as current address"
                      className={styles.checkboxGrid}
                    />
                  </Typography>
                </Grid>
                <Controller
                  control={control}
                  name="permanentAddress"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      multiline
                      rows={3}
                      size="small"
                      fullWidth
                      {...field}
                      type="text"
                      error={error !== undefined}
                      InputProps={{
                        className: styles.textareaContainer,
                      }}
                      placeholder="Permanent Address"
                    />
                  )}
                />
                {/* <Typography className={styles.errorMsg}>
                  {errors.permanentAddress.message}
                </Typography> */}
              </Grid>
              <Grid container md={12} lg={12} sm={12} xs={12} xl={12}>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
                >
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Typography className={styles.formLabel}>
                      Zipcode
                    </Typography>
                    <CustomTextField
                      // error={Boolean(errors.zipCode2)}
                      // errorCondition={
                      //   errors.zipCode2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.zipCode2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="zipCode2"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="Zip code"
                      onChange={onZipcode2Change}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Grid sx={{ display: "flex" }} gap={1}>
                      <Typography className={styles.formLabel}>
                        State
                      </Typography>
                      {loaderForPerntAdrs && (
                        <SubCircularLoader
                          size={13}
                          className={styles.subCircularLoader}
                        />
                      )}
                    </Grid>
                    <CustomTextField
                      // error={Boolean(errors.state2)}
                      // errorCondition={
                      //   errors.state2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.state2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="state2"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="State"
                    />
                    {/* <CustomSelect
                      // error={Boolean(errors.state2)}
                      // errorCondition={
                      //   errors.state2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.state2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="state2"
                      selectData={perInfoData.states}
                      placeHolder="State"
                      selectFieldCss={styles.selectField}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container md={12} lg={12} sm={12} xs={12} xl={12}>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
                >
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Grid sx={{ display: "flex" }} gap={1}>
                      <Typography className={styles.formLabel}>City</Typography>
                      {loaderForPerntAdrs && (
                        <SubCircularLoader
                          size={13}
                          className={styles.subCircularLoader}
                        />
                      )}
                    </Grid>
                    <CustomTextField
                      // error={Boolean(errors.city2)}
                      // errorCondition={
                      //   errors.city2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.city2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="city2"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="Select city"
                    />
                    {/* <CustomSelect
                      // error={Boolean(errors.city2)}
                      // errorCondition={
                      //   errors.city2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.city2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="city2"
                      selectData={perInfoData.city}
                      placeHolder="Select city"
                      selectFieldCss={styles.selectField}
                    /> */}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    className={styles.formGrid}
                  >
                    <Grid sx={{ display: "flex" }} gap={1}>
                      <Typography className={styles.formLabel}>
                        Country
                      </Typography>
                      {loaderForPerntAdrs && (
                        <SubCircularLoader
                          size={13}
                          className={styles.subCircularLoader}
                        />
                      )}
                    </Grid>
                    <CustomTextField
                      // error={Boolean(errors.country2)}
                      // errorCondition={
                      //   errors.country2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.country2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="country2"
                      fullWidth={true}
                      className={styles.fieldContainer}
                      placeholder="Country"
                    />
                    {/* <CustomSelect
                      // error={Boolean(errors.country2)}
                      // errorCondition={
                      //   errors.country2 && (
                      //     <Typography className={styles.errorMsg}>
                      //       {errors.country2.message}
                      //     </Typography>
                      //   )
                      // }
                      control={control}
                      name="country2"
                      selectData={perInfoData.nationality}
                      placeHolder="Country"
                      selectFieldCss={styles.selectField}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*Emergency contact start here */}
          <Grid
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            className={styles.dividerContainer}
          >
            <Divider className={styles.divider} />
          </Grid>
          <Grid container mt={3} className={styles.headerGrid}>
            <Grid>
              <SettingCustomHeader
                header="Emergency Contact Details"
                paragraph=" Here are all the employees at your company"
              />
            </Grid>
            <Grid>
              <CustomButtons
                name="Add Contact"
                variant="outlined"
                icon={<AddIcon />}
                className={styles.createBtn}
                // type="submit"
                loading={updateEmployeeLoading}
                disabled={updateEmployeeLoading}
                loaderClass={styles.buttonLoader}
                onClick={addContactModalOpen}
              />
            </Grid>
          </Grid>
          <Grid container md={12} mt={3} mb={3}>
            <CustomTable
              tableHeader={tableStyle.tableHeader}
              data={editEmpRecord?.emergencyContactDetails || []}
              hidePagination={true}
              columns={[
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      {" "}
                      FIrst Name{" "}
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.firstName || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      {" "}
                      Last Name
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.lastName || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      {" "}
                      Email
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.email || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      {" "}
                      Contact Number
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.phone || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      {" "}
                      Relation
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.relation || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: "",
                  Cell: ({ item, index }: any) => {
                    return (
                      <MenuItems
                        options={["Edit", "Delete"]}
                        onViewDetails={(e: any) => handleMenuClick(item, e)}
                        // itemStatus={item.status}
                      />
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
        {/* <Grid container className={styles.editBtnContainer}>
          <CustomButtons
            name="Update"
            variant="contained"
            className={styles.createBtn}
            type="submit"
          />
        </Grid> */}
      </Grid>
      {openModal && (
        <EmergencyContactModal
          onClose={handleModalClose}
          open={openModal}
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
          selectedItem={selectedItem}
        />
      )}
    </Grid>
  );
};

export default PersonalInformation;
