import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ButtonsProps } from "src/CustomDatatypes/datatypes";
import CircularProgress from "@mui/material/CircularProgress";

export default function CustomButtons({
  name,
  className,
  onClick,
  variant,
  icon,
  type,
  disabled,
  loading,
  disableElevation,
  hidden,
  loaderClass,
  href,
  buttonWidth,
  fullWidth,
  disabledColor,
  textColor,
}: ButtonsProps) {
  return (
    <Stack spacing={2} direction="row">
      {!hidden &&
        (loading ? (
          <div>
            <div>
              <Button
                variant={variant}
                onClick={onClick}
                className={className}
                type={type}
                disabled={disabled}
                disableElevation={disableElevation}
                style={{ width: buttonWidth }}
                fullWidth={fullWidth}
              >
                {icon && icon}
                {name && name}
              </Button>
            </div>
            <div className={loaderClass}>
              <CircularProgress size={30} />
            </div>
          </div>
        ) : (
          <Button
            variant={variant}
            onClick={onClick}
            className={className}
            href={href}
            type={type}
            disabled={disabled}
            disableElevation={disableElevation}
            style={{
              width: buttonWidth,
              backgroundColor: disabled ? disabledColor : "",
              color: textColor,
            }}
            fullWidth={fullWidth}
          >
            {icon && icon}
            {name && name}
          </Button>
        ))}
    </Stack>
  );
}
