import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";

import {
  CustomTableProps1,
} from "src/CustomDatatypes/datatypes";
import CheckBoxes from "src/components/CustomCheckbox/Checkbox";
import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import style from "src/Styles/table.module.css";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function getProperty(propertyName: string, object: any) {
  if (!propertyName) return;
  var parts = propertyName.split("."),
    length = parts.length,
    i,
    property = object;

  for (i = 0; i < length; i++) {
    property = property && property[parts[i]];
  }

  return property;
}

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string | any;
  rowCount: number;
  columns: any;
  enableCheckBox?: boolean;
  draggable?: boolean;
  tableHeader?: any;
  stickyHeader?: boolean;
  checkboxClassName?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    enableCheckBox,
    tableHeader,
    checkboxClassName,
    draggable, // sorting shouldn't work for dragable table
  } = props;
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* {draggable && <TableCell padding="checkbox"></TableCell>} */}
        {enableCheckBox && (
          <TableCell padding="checkbox" className={checkboxClassName}>
            <CheckBoxes
              checkboxProps={{
                indeterminate: numSelected > 0 && numSelected < rowCount,
              }}
              defaultChecked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns?.map((headCell: any) => {
          const { Header, headerClassName, tooltipTitle, accessor } = headCell;
          let headerValue = Header;
          if (Header && typeof Header === "function") {
            headerValue = Header(headCell);
          }
          return (
            <TableCell
              key={columns[accessor]}
              sortDirection={
                orderBy && orderBy === columns[accessor] ? order : false
              }
              // className={tableHeader}
              className={`${tableHeader} `}
              style={{ position: "relative" }}
            >
              {tooltipTitle ? (
                <Tooltip title={tooltipTitle}>
                  {accessor && !draggable ? (
                    <TableSortLabel
                      active={orderBy && orderBy === columns[accessor]}
                      direction={
                        orderBy && orderBy === columns[accessor] ? order : "asc"
                      }
                      onClick={createSortHandler(accessor)}
                      className={headerClassName}
                    >
                      {headerValue}
                      {orderBy && orderBy === columns[accessor] ? (
                        <Grid component="span">
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Grid>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <Grid className={headerClassName}>{headerValue}</Grid>
                  )}
                </Tooltip>
              ) : (
                <>
                  {!draggable && accessor ? (
                    <TableSortLabel
                      active={orderBy && orderBy === columns[accessor]}
                      direction={
                        orderBy && orderBy === columns[accessor] ? order : "asc"
                      }
                      onClick={createSortHandler(accessor)}
                      className={headerClassName}
                    >
                      {headerValue}
                      {orderBy && orderBy === columns[accessor] ? (
                        <Grid component="span">
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Grid>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <Grid className={headerClassName}>{headerValue}</Grid>
                  )}
                </>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/**
 *
 * @param CustomTable
 *
 * @returns Multi purpose Table component
 *
 * columns >>           should contain column structure of the table & header name
 * data >>              data which need to be shown
 * enableCheckBox >>    enables table with checkboxes
 * loading >>           loader for table
 * tableTitle >>        title for table
 * onReorder >>         updates table order on drag
 * draggable >>         to enable drag & drop table row function
 * hidePagination >>    for hiding pagination footer, table rows will be as long as data
 *
 */
export default function CustomTable1({
  columns,
  data,
  enableCheckBox,
  loading,
  tableTitle,
  onReorder,
  draggable = false,
  hidePagination = true,
  tableCellFirstColStyle,
  tableBorder = {},
  tableHeader,
  tableContainerClass,
  checkboxClassName,
  stickyHeader,
  className,
  handleBulkApproveRejectClick,
  offset,
  setOffset,
  limit,
  setLimit,
  count
}: CustomTableProps1) {
  const [order, setOrder] = React.useState<any>("asc");
  const [orderBy, setOrderBy] = React.useState<any>();
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [tableRows, setTableRows] = React.useState(data);

  const [draggedIndex, setDraggedIndex] = React.useState(-1);

  const handleDragStart = (index: any) => {
    setDraggedIndex(index);
  };
  React.useEffect(() => {
    setTableRows(data);
    setSelected([]);
  }, [data]);

  const handleDragOver = (index: any) => {
    if (draggedIndex === -1 || index === draggedIndex) {
      //   debugger;
      return;
    }
    // debugger;
    const items = Array.from(tableRows);
    const [draggedItem] = items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);

    setTableRows(items);
    onReorder && onReorder(items);
    setDraggedIndex(index);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tableRows.map((n: any) => n.id);
      setSelected(newSelected);
      if (handleBulkApproveRejectClick) {
        handleBulkApproveRejectClick(newSelected);
      }
      return;
    }
    if (handleBulkApproveRejectClick) {
      handleBulkApproveRejectClick([]);
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (!handleBulkApproveRejectClick) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const handleCheckboxClick = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (handleBulkApproveRejectClick) {
      handleBulkApproveRejectClick(newSelected);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value))
    setOffset(1);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  if (loading) {
    return (
      <Paper className={style.table_paper__loading}>
        <CircularProgress size={50} />
      </Paper>
    );
  } else
    return (
      <Paper
        className={`${tableContainerClass} ${style.table__paper}`}
        elevation={0}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        {tableTitle && (
          <Typography variant="h5" className={style.table__title}>
            Users
          </Typography>
        )}

        <TableContainer className={style.border}>
          <Table
            // sx={tableBorder}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRows?.length}
              columns={columns}
              enableCheckBox={enableCheckBox}
              draggable={draggable}
              tableHeader={tableHeader}
              checkboxClassName={checkboxClassName}
            // totalCount={totalCount}
            />
            {data.length > 0 ? (
              <TableBody>
                {data?.map((item: any, id: any) => {
                  const isItemSelected = isSelected(item.id);
                  const labelId = `enhanced-table-checkbox-${id}`;
                  let rowIndex = id;
                  return (
                    <React.Fragment key={id}>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, item.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={item.id}
                        selected={isItemSelected}
                        style={{ position: "relative" }}
                      >
                        {draggable && (
                          <TableCell
                            padding="checkbox"
                            draggable={draggable}
                            onDragStart={() => handleDragStart(rowIndex)}
                            onDragOver={() => handleDragOver(rowIndex)}
                            className={style.table__move_row}
                          >
                            <DragIndicatorIcon />
                          </TableCell>
                        )}
                        {enableCheckBox && (
                          <TableCell padding="checkbox">
                            <CheckBoxes
                              value={isItemSelected}
                              onChange={(event: any) =>
                                handleCheckboxClick(event, item.id)
                              }
                              checkboxProps={{
                                inputProps: {
                                  "aria-labelledby": labelId,
                                },
                              }}
                            />
                          </TableCell>
                        )}
                        {columns.map((column, index) => {
                          let { accessor, Cell, className } = column;

                          let cellValue = getProperty(String(accessor), item);

                          if (Cell && typeof Cell === "function") {
                            cellValue = Cell({ item, index: rowIndex });
                          }

                          return (
                            <>
                              <TableCell className={className}>
                                {cellValue}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} className={style.errorText}>
                    <Typography className={style.errorTexttypo}>
                      Data not found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {!hidePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          />
        )}
      </Paper>
    );
}
