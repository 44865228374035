import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "src/Styles/HrDocsSliders.module.css";
import Styles from "src/Styles/EducationalDetails.module.css";
import CustomTable from "src/components/TableComponent/CustomTable";
import MenuItems from "src/components/MenuItems/MenuItems";
import LeaveViewDetailsModel from "./LeaveViewDetailsModel";
import { useQuery, useMutation } from "@apollo/client";
import type { RootState } from "src/store/store";
import { approveLeave, rejectLeave } from "src/store/LeaveSlice";
import { useAppSelector, useAppDispatch } from "src/store/hooks";
import moment from "moment";
import {
  GET_EMPLOYEE_LEAVES,
  BULK_APPROVE_REJECT_LEAVE,
  DELETE_LEAVE,
} from "src/graphql/employeeLeaves";
import { Toast } from "../ToastMessage";
import leaveStyles from "src/Styles/TimeOff.module.css";
import CustomButtons from "../CustomButton/Button";

const Leave = ({ selectedStatus }: any) => {
  const userId = localStorage.getItem("userId");

  const { isApproved } = useAppSelector((state: RootState) => state.LeaveSlice);
  const { isRejected } = useAppSelector((state: RootState) => state.LeaveSlice);

  const dispatch = useAppDispatch();

  const [deleteEmployeeLeaves] = useMutation(DELETE_LEAVE);

  const [selectedRequestStatus, setSelectedRequestStatus] =
    useState<string>(selectedStatus);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedStatusMap, setUpdatedStatusMap] = useState<{
    [id: string]: string;
  }>({});
  const [loaderForBulkApprove, setLoaderForBulkApprove] =
    useState<boolean>(false);
  const [loaderForBulkReject, setLoaderForBulkReject] =
    useState<boolean>(false);

  useEffect(() => {
    setSelectedRequestStatus(selectedStatus);
    if (selectedStatus !== "PENDING") {
      setAllChekboxClick(false);
      setBulkAprroveRejectIds([]);
    }
  }, [selectedStatus]);

  useEffect(() => { }, [isApproved, isRejected]);

  const [mopen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null>(null);
  const [allChekboxClick, setAllChekboxClick] = useState<boolean>(false);
  const [bulkAprroveRejectIds, setBulkAprroveRejectIds] = useState<any>([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDetails = async (item: any, event: any) => {
    if (event.target.textContent === "View") {
      setSelectedItem(item);
      dispatch(rejectLeave(item?.status));
      dispatch(approveLeave(item?.status));
      handleClickOpen();
    } else if (event.target.textContent === "Delete") {
      try {
        const response = await deleteEmployeeLeaves({
          variables: { deleteEmployeeLeavesId: item.id, deletedBy: "RM" },
        });
        if (response.data) {
          Toast("success", "Leave cancelled Successfully");
          refetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE_LEAVES, {
    query: GET_EMPLOYEE_LEAVES,
    variables: {
      approverId: userId,
      filterStatus: selectedRequestStatus,
      offset,
      limit,
    },
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch]);
  const [bulkApproveRejectLeave] = useMutation(BULK_APPROVE_REJECT_LEAVE);

  const handleBulkApproveRejectClick = (values: any) => {
    if (values.length && selectedRequestStatus === "PENDING") {
      setAllChekboxClick(true);
      setBulkAprroveRejectIds(values);
    } else {
      setAllChekboxClick(false);
      setBulkAprroveRejectIds([]);
    }
  };

  const handleBulkApprove = async () => {
    try {
      setLoaderForBulkApprove(true);

      let currentDate = moment();

      const allIds = bulkAprroveRejectIds.map((id: string) => ({
        id: id,
        input: {
          status: "APPROVED",
          approvedDate: currentDate.format("YYYY-MM-DD"),
        },
      }));

      const response = await bulkApproveRejectLeave({
        variables: {
          updates: allIds,
        },
      });

      if (response.data) {
        Toast("success", "Leave approved Successfully");
        setAllChekboxClick(false);
        setBulkAprroveRejectIds([]);
        refetch();
        setLoaderForBulkApprove(false);
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  const handleBulkReject = async () => {
    try {
      setLoaderForBulkReject(true);

      let currentDate = moment();
      const allIds = bulkAprroveRejectIds.map((id: string) => ({
        id: id,
        input: {
          status: "REJECTED",
          approvedDate: currentDate.format("YYYY-MM-DD"),
        },
      }));

      const response = await bulkApproveRejectLeave({
        variables: {
          updates: allIds,
        },
      });

      if (response.data) {
        Toast("success", "Leave rejected Successfully");
        setAllChekboxClick(false);
        setBulkAprroveRejectIds([]);
        refetch();
        setLoaderForBulkReject(false);
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid item container md={12} className={Styles.table}>
        {allChekboxClick && (
          <Grid className={leaveStyles.bulkButtoncontainer}>
            <CustomButtons
              name="Bulk Approve"
              className={leaveStyles.bulkApproveButton}
              variant="outlined"
              onClick={handleBulkApprove}
              loading={loaderForBulkApprove}
              disabled={loaderForBulkApprove}
              loaderClass={leaveStyles.buttonLoader}
            />
            <CustomButtons
              name="Bulk Reject"
              className={leaveStyles.bulkRejectButton}
              variant="outlined"
              onClick={handleBulkReject}
              loading={loaderForBulkReject}
              disabled={loaderForBulkReject}
              loaderClass={leaveStyles.buttonLoader}
            />
          </Grid>
        )}
        <CustomTable
          data={data?.getEmployeeLeaves?.employeeLeavesInfo || []}
          hidePagination={false}
          tableCellFirstColStyle={Styles.tableCellFirstColStyles}
          tableHeader={Styles.tableHeader}
          enableCheckBox={
            data?.getEmployeeLeaves?.employeeLeavesInfo?.length
              ? selectedRequestStatus === "PENDING"
              : false
          }
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          count={(data && data?.getEmployeeLeaves?.totalCount) || 0}
          handleBulkApproveRejectClick={handleBulkApproveRejectClick}
          checkboxClassName={styles.checkboxCss}
          columns={[
            {
              Header: <Grid className={Styles.tableHead}>User Name</Grid>,
              accessor: "reason",
              Cell: ({ item, index }: any) => {
                return <Grid className={Styles.cell}>{item.username}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Approver</Grid>,
              accessor: "acceptedBy",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid className={Styles.cell}>{item.approver.name}</Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}> Request Type </Grid>,
              accessor: "requestType",
              Cell: ({ item, index }: any) => {
                return <Grid className={Styles.cell}>{item.requestType}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Applied Date</Grid>,
              accessor: "appliedFrom",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(item.appliedFrom).format(
                  "DD-MM-YYYY"
                );
                return <Grid className={Styles.cell}>{formattedDate}</Grid>;
              },
            },

            {
              Header: <Grid className={Styles.tableHead}>Raised On</Grid>,
              accessor: "createdOn",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(item.createdOn).format(
                  "DD-MM-YYYY"
                );
                return <Grid className={Styles.cell}>{formattedDate}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Status</Grid>,
              accessor: "status",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid
                    className={Styles.cell}
                    sx={{
                      color:
                        item.status === "REJECTED"
                          ? "red"
                          : item.status === "DELETED"
                            ? "darkred"
                            : item.status === "APPROVED"
                              ? "green"
                              : "#FF7A00",
                      // borderRadius: "15px",
                      // fontSize: "15px",
                    }}
                  >
                    {item.status}
                  </Grid>
                );
              },
            },
            {
              Header: "",
              Cell: ({ item }: any) => (
                <Grid item className={styles.moreVertIcon}>
                  <MenuItems
                    options={
                      item.status === "APPROVED" ? ["View", "Delete"] : ["View"]
                    }
                    onViewDetails={(e: any) => handleViewDetails(item, e)}
                  />
                </Grid>
              ),
            },
          ]}
        />
        {mopen && (
          <LeaveViewDetailsModel
            onClick={handleClickOpen}
            open={mopen}
            onClose={handleClose}
            selectedItem={selectedItem}
            setOpen={setOpen}
            refetch={refetch}
          />
        )}
      </Grid>
    </>
  );
};

export default Leave;
