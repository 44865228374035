import React, { useState } from "react";
import styles from "src/Styles/addEmployee.module.css";
import { Grid, Typography, Divider } from "@mui/material";
import CustomSelect from "src/components/CustomSelect/customSelectValidations";
import { Controller, useForm } from "react-hook-form";
import workInformationData from "./WorkInformation/WorkInformationData.json";
import { useQuery } from "@apollo/client";
import { GET_JOBTITLE } from "src/graphql/jobTitle";
import { GET_DEPARTMENTS } from "src/graphql/departments";
import { GET_ALL_USERS } from "src/graphql/user";
import { GET_WORKPLACES } from "src/graphql/workplace";
import { GET_ENTITIES } from "src/graphql/entity";
import CustomSearchSelect from "src/components/CustomSearchSelect";
import CustomButtons from "../CustomButton/Button";
import { useNavigate } from "react-router-dom";
import perInfoData from "./PersonalInfoData/PersonalInfoData.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneInput from "../PhoneInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { createEmployeeSchema } from "src/utils/validations";
import CustomTextField from "../CustomTextField";
import CustomDatePicker from "../customDatePicker";
import { Toast } from "../ToastMessage";
import { useMutation } from "@apollo/client";
import { ADD_EMPLOYEE } from "src/graphql/user";

const CreateEmployee = ({ setState }: any) => {
  const navigate = useNavigate();

  const { data } = useQuery(GET_JOBTITLE, {
    variables: {
      limit: 0,
      offset: 0
    }
  });

  const designationData = data?.getJobTitles?.jobTitleInfo?.map((item: any) => ({
    name: item?.jobTitle,
    id: item?.id,
    type: item?.id,
  }));

  const { data: deptData } = useQuery(GET_DEPARTMENTS, {
    variables: {
      limit: 0,
      offset: 0
    }
  });
  const departmentDropdown = deptData?.getDepartments?.departmentInfo?.map((item: any) => ({
    name: item?.departmentName,
    id: item?.id,
    type: item?.id,
  }));

  const { data: reportingMangdata } = useQuery(GET_ALL_USERS, {
    variables: {
      input: {
        role: ["SUPER_ADMIN", "ADMIN", "EMPLOYER", "HR", "EMPLOYEE"],
      },
    },
  });
  const reportingManagerData = reportingMangdata?.getAllUsers?.map(
    (item: any) => ({
      name: item?.displayName,
      id: item?.id,
      type: item?.id,
    })
  );

  const { data: workplaceData } = useQuery(GET_WORKPLACES, {
    variables: {
      limit: 0,
      offset: 0
    }
  });
  const workplaceDropdown = workplaceData?.getWorkplaces?.workplaceInfo?.map((item: any) => ({
    name: item?.workplaceName,
    id: item?.id,
    type: item?.id,
  }));

  const { data: entityData } = useQuery(GET_ENTITIES, {
    variables: {
      limit: 0,
      offset: 0
    }
  });
  const entityDropdown = entityData?.getEntities?.workplaceInfo?.map((item: any) => ({
    name: item?.companyName,
    id: item?.id,
    type: item?.id,
  }));

  const { data: usersData } = useQuery(GET_ALL_USERS, {
    variables: {
      input: {
        role: ["HR"],
      },
    },
  });

  const usersDropdown = usersData?.getAllUsers?.map((item: any) => ({
    name: item?.displayName,
    id: item?.id,
    type: item?.displayName,
  }));

  // eslint-disable-next-line
  const [addEmployee, { data: addEmpData, loading: addEmpLoading }] =
    useMutation(ADD_EMPLOYEE);

  const [hideProbationEndDate, setHideProbationEndDate] = useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(createEmployeeSchema),
  });

  const onChangeOfProbationTerm = (e: any) => {
    if (e.target.value === "N/A") {
      setValue("probationEndDate", null);
      setHideProbationEndDate(true);
    } else {
      setHideProbationEndDate(false);
    }
  };

  const handleCreateEmployee = async (data: any) => {
    const userData = {
      employeeCode: data.employeeCode,
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.fullName,
      personalEmail: data.personalEmail,
      phone: data.phone,
      gender: data.gender,
      dob: data.dob,
      joiningDate: data.joiningDate,
      reportingManager: [
        {
          userId: data.reportingManager,
          sequenceOrder: "0",
        },
      ],
      departmentId: data.department,
      role: data.role,
      employeeType: data.employmentType,
      status: data.employmentStatus,
      probationTerm: data.probationTerm,
      probationStatus: data.probationTerm !== "N/A" ? true : false,
      probationEndDate: data.probationEndDate,
      noticePeriod: data.noticePeriod,
      designationId: data.designation,
      email: data.workEmail,
      entityId: data.companyName,
      workPlaceId: data.location,
      hrManager: data.hrManager,
      shift: data.shift,
      sourceOfVerification: data.sourceOfVerification,
      nationality: data.nationality,
    };

    try {
      const createResponse: any = await addEmployee({
        variables: { input: userData },
      });
      if (createResponse?.data?.addEmployee?.id) {
        Toast("success", "Employee created Successfully");
        navigate(
          `/admin/hrms/employee/edit/${createResponse?.data?.addEmployee?.id}`
        );
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  const handleBack = () => {
    navigate("/admin/hrms/employees/manage-employees");
  };

  return (
    <Grid
      className={styles.createEmployeeContainer}
      container
      md={12}
      sm={12}
      lg={12}
      xl={12}
      xs={12}
    >
      <Grid className={styles.createEmployeeWraper}>
        <form onSubmit={handleSubmit(handleCreateEmployee)}>
          <Grid className={styles.headerContainer}>
            <ArrowBackIcon onClick={handleBack} className={styles.backBtn} />
            <CustomButtons
              name="Create Employee"
              variant="contained"
              className={styles.createBtn}
              type="submit"
              loading={addEmpLoading}
              disabled={addEmpLoading}
              loaderClass={styles.buttonLoader}
            />
          </Grid>
          <Grid container>
            <Grid container md={12} sm={12} lg={12} xl={12} xs={12}>
              {/*  for the first grid layout */}
              <Grid container mt={1}>
                <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                  <Typography className={styles.subHeading}>
                    Basic Info
                  </Typography>
                </Grid>
                <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                  <Typography className={styles.subText}>
                    Here are all the basic info at your company
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                mt={3}
                mb={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
              >
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Employee Code
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.employeeCode)}
                    errorCondition={
                      errors.employeeCode && (
                        <Typography className={styles.errorMsg}>
                          {errors.employeeCode.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="employeeCode"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="AK012000"
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  justifyContent={"space-evenly"}
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    First Name
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.firstName)}
                    errorCondition={
                      errors.firstName && (
                        <Typography className={styles.errorMsg}>
                          {errors.firstName.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="firstName"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Last Name
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.lastName)}
                    errorCondition={
                      errors.lastName && (
                        <Typography className={styles.errorMsg}>
                          {errors.lastName.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="lastName"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Last Name"
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Full Name
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.fullName)}
                    errorCondition={
                      errors.fullName && (
                        <Typography className={styles.errorMsg}>
                          {errors.fullName.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="fullName"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="John"
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Personal Email
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.personalEmail)}
                    errorCondition={
                      errors.personalEmail && (
                        <Typography className={styles.errorMsg}>
                          {errors.personalEmail.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="personalEmail"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="employee@gmail.com"
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Mobile Number
                  </Typography>
                  <PhoneInput
                    control={control}
                    name="phone"
                    error={Boolean(errors?.phone)}
                    containerClass={styles.containerPhn}
                    inputClass={`${styles.inputPhn} ${Boolean(errors?.phone) ? styles.errorBorder : ""
                      }`}
                    placeholder="+91 8219941918"
                    helperText={errors?.phone?.message}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Gender</Typography>

                  <CustomSelect
                    error={Boolean(errors.gender)}
                    errorCondition={
                      errors.gender && (
                        <Typography className={styles.errorMsg}>
                          {errors.gender.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="gender"
                    selectData={perInfoData.gender}
                    placeHolder="Select Gender"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>DOB</Typography>
                  <CustomDatePicker
                    error={Boolean(errors.dob)}
                    errorCondition={
                      errors.dob && (
                        <Typography className={styles.errorMsg}>
                          {errors.dob.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="dob"
                    className={styles.datefieldContainer}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Joining Date
                  </Typography>
                  <CustomDatePicker
                    error={Boolean(errors.joiningDate)}
                    errorCondition={
                      errors.joiningDate && (
                        <Typography className={styles.errorMsg}>
                          {errors.joiningDate.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="joiningDate"
                    className={styles.datefieldContainer}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Nationality
                  </Typography>

                  <CustomSelect
                    error={Boolean(errors.nationality)}
                    errorCondition={
                      errors.nationality && (
                        <Typography className={styles.errorMsg}>
                          {errors.nationality.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="nationality"
                    selectData={perInfoData.nationality}
                    placeHolder="Select Nationality"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
              </Grid>
              {/* divider */}
              <Grid
                item
                md={12}
                lg={12}
                sm={12}
                xs={12}
                xl={12}
                className={styles.dividerContainer}
              >
                <Divider className={styles.divider} />
              </Grid>
              {/*  for the second grid layout */}
              <Grid container mt={3}>
                <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                  <Typography className={styles.subHeading}>
                    Company Relations
                  </Typography>
                </Grid>
                <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                  <Typography className={styles.subText}>
                    Here are all the Company Relations
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                mt={3}
                mb={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
              >
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Reporting Manger
                  </Typography>

                  <Controller
                    control={control}
                    name="reportingManager"
                    render={({ field }) => (
                      <CustomSearchSelect
                        label="Reporting Manager"
                        options={reportingManagerData || []}
                        field={field}
                        error={Boolean(errors.reportingManager)}
                        errorCondition={
                          errors.reportingManager && (
                            <Typography className={styles.errorMsg}>
                              {errors.reportingManager.message}
                            </Typography>
                          )
                        }
                        autocompleteCss={styles.autocompleteCss}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Department
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.department)}
                    errorCondition={
                      errors.department && (
                        <Typography className={styles.errorMsg}>
                          {errors.department.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="department"
                    placeHolder="Select-Dept."
                    selectData={departmentDropdown || []}
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Role</Typography>
                  <CustomSelect
                    error={Boolean(errors.role)}
                    errorCondition={
                      errors.role && (
                        <Typography className={styles.errorMsg}>
                          {errors.role.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="role"
                    selectData={workInformationData.role}
                    placeHolder="Role"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Employment Type
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.employmentType)}
                    errorCondition={
                      errors.employmentType && (
                        <Typography className={styles.errorMsg}>
                          {errors.employmentType.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="employmentType"
                    selectData={workInformationData.employeeTypes}
                    placeHolder="Select-Type"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Employment Status
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.employmentStatus)}
                    errorCondition={
                      errors.employmentStatus && (
                        <Typography className={styles.errorMsg}>
                          {errors.employmentStatus.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="employmentStatus"
                    selectData={workInformationData.employeeStatus}
                    placeHolder="Select Status"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Probation Term
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.probationTerm)}
                    errorCondition={
                      errors.probationTerm && (
                        <Typography className={styles.errorMsg}>
                          {errors.probationTerm.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="probationTerm"
                    selectData={workInformationData.probationTerm}
                    placeHolder="Select Probation"
                    selectFieldCss={styles.selectField}
                    onChange={onChangeOfProbationTerm}
                  />
                </Grid>
                {hideProbationEndDate ? (
                  ""
                ) : (
                  <Grid
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    item
                    className={styles.formGrid}
                  >
                    <Typography className={styles.formLabel}>
                      Probation End Date
                    </Typography>
                    <CustomDatePicker
                      error={Boolean(errors.probationEndDate)}
                      errorCondition={
                        errors.probationEndDate && (
                          <Typography className={styles.errorMsg}>
                            {errors.probationEndDate.message}
                          </Typography>
                        )
                      }
                      control={control}
                      name="probationEndDate"
                      className={styles.datefieldContainer}
                    />
                  </Grid>
                )}
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Notice Period
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.noticePeriod)}
                    errorCondition={
                      errors.noticePeriod && (
                        <Typography className={styles.errorMsg}>
                          {errors.noticePeriod.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="noticePeriod"
                    selectData={workInformationData.NoticePeriod}
                    placeHolder="Select Notice period"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Designation
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.designation)}
                    errorCondition={
                      errors.designation && (
                        <Typography className={styles.errorMsg}>
                          {errors.designation.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="designation"
                    // selectData={workInformationData.Designation}
                    selectData={designationData || []}
                    placeHolder="Select Designation"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Work Email
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.workEmail)}
                    errorCondition={
                      errors.workEmail && (
                        <Typography className={styles.errorMsg}>
                          {errors.workEmail.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="workEmail"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="example@email.com"
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  justifyContent={"space-evenly"}
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Company Name
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.companyName)}
                    errorCondition={
                      errors.companyName && (
                        <Typography className={styles.errorMsg}>
                          {errors.companyName.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="companyName"
                    selectData={entityDropdown || []}
                    placeHolder="Company Name"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Work Place
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.location)}
                    errorCondition={
                      errors.location && (
                        <Typography className={styles.errorMsg}>
                          {errors.location.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="location"
                    selectData={workplaceDropdown || []}
                    placeHolder="Work Place"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    HR Manager
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.hrManager)}
                    errorCondition={
                      errors.hrManager && (
                        <Typography className={styles.errorMsg}>
                          {errors.hrManager.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="hrManager"
                    selectData={usersDropdown || []}
                    placeHolder="HR Manager"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Shift</Typography>
                  <CustomSelect
                    error={Boolean(errors.shift)}
                    errorCondition={
                      errors.shift && (
                        <Typography className={styles.errorMsg}>
                          {errors.shift.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="shift"
                    selectData={workInformationData.shifts}
                    placeHolder="Shift"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Source of Verification
                  </Typography>
                  <CustomSelect
                    error={Boolean(errors.sourceOfVerification)}
                    errorCondition={
                      errors.sourceOfVerification && (
                        <Typography className={styles.errorMsg}>
                          {errors.sourceOfVerification.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="sourceOfVerification"
                    selectData={workInformationData.sourceOfVerification}
                    placeHolder="Verification"
                    selectFieldCss={styles.selectField}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid gap={2} className={styles.btnContainer}>
          <CustomButtons
            name="Create"
            variant="contained"
            className={styles.createBtn}
            onClick={handleCreateEmployee}
          />
          <CustomButtons
            name="Cancel"
            variant="contained"
            className={styles.cancelBtn}
            onClick={handleBack}
          />
        </Grid> */}
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateEmployee;
