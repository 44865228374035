import gql from "graphql-tag";

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      displayName
      role
      userAllowances {
        allowanceId
        allowancesAvailable
      }
    }
  }
`;

export const GET_ALL_USERS_BY_PORTAL = gql`
  query GetUsersByPortal($input: UsersFilterInput) {
    getUsersByPortal(input: $input) {
      totalCount
      users{
      id
      email
      firstName
      lastName
      employeeType
      displayName
      role
      departmentId
      designationId
      status
      employeeCode
      reportingManagerName
      jobTitleName
      deptName
      userAllowances {
        allowanceId
        allowancesAvailable
      }
    }
    }
  }
`;

export const CREATE_USER_ALLOWANCES = gql`
  mutation Mutation($input: CreateUserAllowancesInput!) {
    createUserAllowances(input: $input) {
      userAllowances {
        allowanceId
        allowancesAvailable
      }
    }
  }
`;
