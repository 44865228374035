import React, { useState } from "react";
import CustomTab from "src/components/CustomTab";
import { Grid, Stack, Typography } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import styles from "src/Styles/informationTab.module.css";
import MenuItemsButton from "../MenuItemsButton";
import ApplyNewWorkFromHome from "./ApplyNewWorkFromHome";
import ApplyLeaveModel from "../ApplyLeaveModel/ApplyLeaveModel";
import Regularize from "../Regularize";
import { useDispatch, useSelector } from "react-redux";
import { taskTabValue } from "src/store/TaskSlice";
import { useNavigate } from "react-router-dom";

function TasksComponent({ children }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedValue, setSelectedValue] = useState("PENDING");

  const statusOptions = [
    {
      id: "PENDING",
      text: "pending",
    },
    {
      id: "APPROVED",
      text: "Approved",
    },
    {
      id: "REJECTED",
      text: "Rejected",
    },
    {
      id: "DELETED",
      text: "Deleted",
    },
    {
      id: "ALL",
      text: "All Requests",
    },
  ];
  const tabOption = useSelector((state: any) => state?.TaskSlice.label);

  const handleModalOpen = (option: string) => {
    setSelectedOption(option);

    setModalOpen(true);

    if (option === "Leave") {
      navigate("/hrms/tasks/leave");
      dispatch(taskTabValue({ value: 0, label: "Leave" }));
    } else if (option === "Regularization") {
      navigate("/hrms/tasks/regularization");
      dispatch(taskTabValue({ value: 1, label: "Regularization" }));
    } else if (option === "Work From Home") {
      navigate("/hrms/tasks/work-from-home");
      dispatch(taskTabValue({ value: 2, label: "Work From Home" }));
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const renderModal = () => {
    if (selectedOption === "Leave") {
      return <ApplyLeaveModel open={modalOpen} onClose={handleModalClose} />;
    } else if (selectedOption === "Regularization") {
      return (
        <Regularize
          open={modalOpen}
          onClose={handleModalClose}
          selectedDateInfo={selectedDate}
        />
      );
    } else if (selectedOption === "Work From Home") {
      return (
        <ApplyNewWorkFromHome open={modalOpen} onClose={handleModalClose} />
      );
    }
    return null;
  };

  const handleStatusChange = (event: any) => {
    const selectedStatus = event.target.value;
    setSelectedValue(selectedStatus);
  };

  const data = [
    {
      id: 0,
      label: (
        <Stack direction="row">
          <Typography className={styles.tabs_typography}>Leave</Typography>
        </Stack>
      ),
      // component: <Leave selectedStatus={selectedValue} />,

      href: "/hrms/tasks/leave",
    },
    {
      id: 1,
      label: (
        <Stack direction="row">
          <Typography className={styles.tabs_typography}>
            Regularization
          </Typography>
        </Stack>
      ),
      // component: <Regulisation />,
      href: "/hrms/tasks/regularization",
    },
    {
      id: 2,
      label: (
        <Stack direction="row" alignSelf="start">
          <Typography className={styles.tabs_typography}>
            Work From Home
          </Typography>
        </Stack>
      ),
      // component: <WorkFromHome />,
      href: "/hrms/tasks/work-from-home",
    },
  ];
  return (
    <>
      <Grid container className={styles.mainContainer}>
        <Grid container className={styles.subContainer}>
          <Grid
            item
            container
            className={styles.subGrid}
            md={7}
            sm={12}
            lg={8}
            xs={12}
          >
            <Typography className={styles.leaveText}>{tabOption}</Typography>
          </Grid>
          <Grid
            item
            container
            md={5}
            sm={12}
            lg={4}
            xs={12}
            className={styles.inputsRight}
          >
            <Grid
              item
              container
              xs={12}
              lg={6}
              sm={6}
              md={6}
              className={styles.selectDropdownParent}
            >
              <FormControl fullWidth>
                <Select
                  value={selectedValue}
                  onChange={handleStatusChange}
                  className={styles.select}
                  MenuProps={{ classes: { paper: styles.menuPaper } }}
                >
                  {statusOptions.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      className={styles.menuItem}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sm={6}
              lg={6}
              className={styles.filterListOutlinedIcon}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <MenuItemsButton
                options={["Leave", "Regularization", "Work From Home"]}
                onViewDetails={handleModalOpen}
                buttonSize="large"
                className={styles.menuButtonText}
                paperStyle={styles.menuPaper}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.customTabHeight}>
        <CustomTab
          myTabData={data}
          orientation="horizontal"
          moreSideIcon={true}
          component={children}
          selectedStatus={selectedValue === "ALL" ? "" : selectedValue}
        />
        {/* {children} */}
      </Grid>
      {modalOpen && renderModal()}
    </>
  );
}

export default TasksComponent;
