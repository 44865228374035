import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { Button, MenuItem, Typography } from "@mui/material";
import styles from "src/Styles/HrDocsSliders.module.css";
import Styles from "src/Styles/informationTab.module.css";

const MenuItemsButton = ({
  options,
  onViewDetails,
  buttonSize,
  className,
  paperStyle,
}: any) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpen = (event: any) => {
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };
  const handleViewClick = (option: string) => {
    if (onViewDetails) {
      onViewDetails(option);
    }
    handlePopoverClose();
  };

  return (
    <>
      <Button
        variant="contained"
        size={buttonSize}
        sx={{ textTransform: "initial" }}
        className={className}
        onClick={handleClickOpen}
      >
        Apply New
      </Button>
      <Popover
        open={popoverOpen}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: paperStyle,
        }}
      >
        <Typography className={styles.optionsData}>
          {options.map((option: any) => (
            <MenuItem
              sx={{ padding: "15px" }}
              className={Styles.menuItem}
              key={option}
              onClick={() => handleViewClick(option)}
              selected={option === "Pyxis"}
            >
              {option}
            </MenuItem>
          ))}
        </Typography>
      </Popover>
    </>
  );
};

export default MenuItemsButton;
