import React from "react";
import { useMutation } from "@apollo/client";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Card } from "@mui/material";
import styles from "src/Styles/AddjobTitle.module.css";
import Divider from "@mui/material/Divider";
import ModalBox from "src/components/ReusableModal/modal";
import { useForm } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";
import { DELETE_BY_USER_ID, GET_ALL_USERS } from "src/graphql/user";
import { Toast } from "../ToastMessage";

const ConfirmationTab = ({ onClick, open, onClose, setOpen, userID }: any) => {
  const [DeleteUserByID] = useMutation(DELETE_BY_USER_ID, {
    refetchQueries: [
      GET_ALL_USERS,
      "getAllUsers", // Query name
    ],
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleOnSubmit = async (evt: any) => {
    const response = await DeleteUserByID({
      variables: { userId: userID },
    });
    onClose();
    if (response) {
      Toast("error", "Employee deleted successfully");
    }
  };

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        className={styles.add_dept_modal}
        content={
          <Card>
            <Grid
              container
              className={styles.topDiv}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            ></Grid>
            <Divider />
            <Grid container className={styles.bottomDiv}>
              <form className={styles.textField}>
                <Grid xs={12}></Grid>
                <Grid xs={12}>
                  <Typography className={styles.inputHeadingDelete}>
                    Are you sure you want to delete the Employee ?
                  </Typography>
                </Grid>
                <Grid xs={12}></Grid>
                <Grid xs={12} className={styles.buttonsDiv}>
                  <CustomButtons
                    name="YES"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      handleSubmit(handleOnSubmit)();
                    }}
                    className={styles.deleteButton}
                  />
                  <CustomButtons
                    name="NO"
                    type="submit"
                    variant="contained"
                    onClick={onClose}
                    className={styles.deleteButtonNo}
                  />
                </Grid>
              </form>
            </Grid>
          </Card>
        }
      />
    </>
  );
};

export default ConfirmationTab;
