import React, { useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_EMPLOYEE_BY_QUERY_TYPE } from "src/graphql/user";
import CustomTextField from "src/components/CustomTextField";
import PhoneInput from "src/components/PhoneInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmergencyContactSchema } from "src/utils/validations";
import CustomButtons from "src/components/CustomButton/Button";
import { Toast } from "src/components/ToastMessage";
import ModalBox from "src/components/ReusableModal/modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styles from "../../Styles/EmergencyContact.module.css";

const EmergencyContactModal = ({
  editEmpRecord,
  editEmpRefetch,
  onClick,
  open,
  onClose,
  selectedItem,
}: any) => {
  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeData, loading: updateEmployeeLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  useEffect(() => {
    if (selectedItem) {
      const FormatedEditEmployeeData = {
        firstName: selectedItem?.firstName || "",
        lastName: selectedItem?.lastName || "",
        email: selectedItem?.email || "",
        phone: selectedItem?.phone || "",
        relation: selectedItem?.relation || "",
      };
      reset(FormatedEditEmployeeData);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  const {
    control,
    // eslint-disable-next-line
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ resolver: yupResolver(EmergencyContactSchema) });

  const handleUpdateEmergencyContact = async (data: any) => {
    try {
      if (!selectedItem) {
        const addUserData = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          relation: data.relation,
        };
        const updateResponse: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "addEmergencyContact",
              emergencyContactDetails: addUserData,
            },
          },
        });

        if (updateResponse) {
          editEmpRefetch();
          Toast("success", "Emergency Contact Details created Successfully");
          onClose();
        }
      } else {
        const editUserData = {
          id: selectedItem?.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          relation: data.relation,
        };
        const updateResponse: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "editEmergencyContact",
              emergencyContactDetails: editUserData,
            },
          },
        });

        if (updateResponse) {
          editEmpRefetch();
          Toast("success", "Emergency Contact Details updated Successfully");
          onClose();
        }
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };
  const handleCancelClick = () => {
    onClose();
  };
  return (
    <>
      <ModalBox
        // onClick={onClick}
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        height={600}
        content={
          <Grid
            className={styles.maincontainer}
            component="form"
            onSubmit={handleSubmit(handleUpdateEmergencyContact)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <Grid className={styles.headerContainer}>
              <Grid>
                <Typography className={styles.headerText}>
                  {selectedItem ? "Edit" : "Add"} Emergency Contact Details
                </Typography>
              </Grid>
              <Grid>
                <CloseOutlinedIcon
                  onClick={onClose}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid className={styles.mainWrapper}>
              <Grid
                container
                mt={3}
                mb={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
              >
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    First Name
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.firstName)}
                    errorCondition={
                      errors.firstName && (
                        <Typography className={styles.errorMsg}>
                          {errors.firstName.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="firstName"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Last Name
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.lastName)}
                    errorCondition={
                      errors.lastName && (
                        <Typography className={styles.errorMsg}>
                          {errors.lastName.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="lastName"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Last Name"
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Email</Typography>
                  <CustomTextField
                    error={Boolean(errors.email)}
                    errorCondition={
                      errors.email && (
                        <Typography className={styles.errorMsg}>
                          {errors.email.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="email"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="abc@gmail.com"
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Contact Number
                  </Typography>
                  <PhoneInput
                    control={control}
                    name="phone"
                    error={Boolean(errors?.phone)}
                    containerClass={styles.containerPhn}
                    inputClass={`${styles.inputPhn} ${
                      Boolean(errors?.phone) ? styles.errorBorder : ""
                    }`}
                    placeholder="+91 8219941918"
                    helperText={errors?.phone?.message}
                    errstyle={styles.errorMsg}
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Relation</Typography>
                  <CustomTextField
                    error={Boolean(errors.relation)}
                    errorCondition={
                      errors.relation && (
                        <Typography className={styles.errorMsg}>
                          {errors.relation.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="relation"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Relation"
                  />
                </Grid>
              </Grid>
              <Grid className={styles.btnContainer}>
                <CustomButtons
                  name="Cancel"
                  variant="contained"
                  className={styles.cancelBtn}
                  onClick={handleCancelClick}
                />
                <CustomButtons
                  name={selectedItem ? "Edit" : "Add"}
                  variant="contained"
                  className={styles.createBtn}
                  type="submit"
                  loading={updateEmployeeLoading}
                  disabled={updateEmployeeLoading}
                  loaderClass={styles.buttonLoader}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default EmergencyContactModal;
