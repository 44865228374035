import React, { useEffect, useState } from "react";
import Styles from "src/Styles/addEmployee.module.css";
import {
  CircularProgress,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomButtons from "src/components/CustomButton/Button";
import InputAdornment from "@mui/material/InputAdornment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useForm } from "react-hook-form";
import CustomTextField from "src/components/CustomTextField";
import { Toast } from "src/components/ToastMessage";
import {
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_BY_QUERY_TYPE,
} from "src/graphql/user";
import { useMutation } from "@apollo/client";
import tableStyle from "../../../Styles/commonTable.module.css";
import moment from "moment";
import CustomTable from "src/components/TableComponent";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import MenuItems from "src/components/MenuItems/MenuItems";
import AddAppraisalModal from "src/components/AddAppraisalsModal";
import AddDocument from "src/components/AddDocumentModal";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import {
  CREATE_DOCUMENT_ADD,
  GET_DOCUMENT_ADD,
} from "src/graphql/documentType";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDocSchema } from "src/utils/validations";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageModal from "src/components/ImageModal";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CompensationDetails = ({
  editEmpRecord,
  editEmpRefetch,
  employeeID,
}: any) => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const [createDocumentAdd] = useMutation(CREATE_DOCUMENT_ADD, {
    refetchQueries: [
      GET_DOCUMENT_ADD,
      "getDocumentAdd", // Query name
    ],
  });

  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeRes, loading: updateEmployeeResLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  useEffect(() => {
    const FormatedEditEmployeeData = {
      yearlyCtc: editEmpRecord?.compensationDetails?.yearlyCTC || "",
      monthlyCtc: editEmpRecord?.compensationDetails?.MonthlyCTC || "",
      varPay: editEmpRecord?.compensationDetails?.variablePay || "",
      VariablePayTerm: editEmpRecord?.compensationDetails?.variableTerm || "",
    };
    reset(FormatedEditEmployeeData);
    // eslint-disable-next-line
  }, [editEmpRecord]);

  const [openModal, setOpenModal] = useState(false);
  const [openDocModal, setOpenDocModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [uploadDocLoader, setUploadDocLoader] = useState<any>(false);
  const [appraisalDocId, setAppraisalDocId] = useState<any>("");
  const [isImage, setIsImage] = React.useState<any>({});
  const [imgOpen, setImgOpen] = React.useState(false);
  const [imgData, setImgData] = React.useState<any>({});
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [documentName, setDocumentName] = React.useState<string | null>(null);
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({});

  const handleUpdateCompensationInfo = async (data: any) => {
    try {
      const userData = {
        queryType: "compensationDetails",
        compensationDetails: {
          // you can send multiple fields here
          yearlyCTC: data.yearlyCtc,
          MonthlyCTC: data.monthlyCtc,
          variablePay: data.varPay,
          variableTerm: data.VariablePayTerm,
        },
      };

      const updateResponse: any = await updateEmployeeByQueryType({
        variables: {
          updateEmployeeByQueryTypeId: editEmpRecord?.id,
          input: userData,
        },
      });

      if (updateResponse) {
        editEmpRefetch();
        Toast("success", "Compensation Details updated Successfully");
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  const addExperienceModalOpen = () => {
    setSelectedItem(null);
    setOpenModal(!openModal);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleMenuClick = async (item: any, event: any) => {
    if (event.target.textContent === "Edit") {
      setSelectedItem(item);
      setOpenModal(!openModal);
    } else if (event.target.textContent === "Delete") {
      try {
        const response: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "deleteAppraisal",
              appraisalDetails: { id: item.id },
            },
          },
        });
        if (response.data) {
          Toast("success", "Appraisal details deleted Successfully");
          editEmpRefetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  const handleAddDocClick = (item: any) => {
    setAppraisalDocId(item?.id);
    docReset({});
    setOpenDocModal(true);
  };

  // React.useEffect(() => {
  //   if (editFlag) {
  //     const defaultValues = {
  //       documentType: editData.documentType,
  //       description: editData.description,
  //     };
  //     docReset(defaultValues);
  //   }
  //   // eslint-disable-next-line
  // }, [editData, editFlag]);

  const {
    control: docControl,
    handleSubmit: handleDocFormSubmit,
    formState: { errors: docErrors },
    reset: docReset,
    getValues: getValuess,
    setValue: docSetValue,
    trigger: docTrigger,
  } = useForm({
    resolver: yupResolver(addDocSchema),
  });

  const handleDocSubmit = async (data: any) => {
    try {
      setUploadDocLoader(true);

      const createData = {
        documentType: data.documentType,
        description: data.description,
        documentName: data.documentName,
        createdByEmployerUserId: userInfo?.id,
        employeeID: employeeID,
      };

      const response = await createDocumentAdd({
        variables: { input: createData },
      });
      if (response?.data) {
        const responseID = response?.data?.createDocumentAdd?.id;

        const queryType = {
          type: "appraisalDoc",
          appraisalId: appraisalDocId,
          employeeId: employeeID,
          documentName: response?.data?.createDocumentAdd?.documentName || "",
        };

        selectedFile.append("queryType", JSON.stringify(queryType));

        selectedFile.append("_id", responseID);
        // eslint-disable-next-line
        const responseUpload = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/upload`,
          selectedFile
        );
        Toast("success", "Document created Successfully");
        editEmpRefetch();
        setUploadDocLoader(false);
        setOpenDocModal(false);
      }
    } catch (error: any) {
      console.error("Error creating document Type:", error);
      Toast("error", error?.message);
    }
  };

  const handleSelectedFile = async (evt: any) => {
    try {
      const file = evt.target.files[0];
      docSetValue("file", file);
      docTrigger("file");

      const newFormData = new FormData();
      newFormData.append("file", evt.target.files[0]);
      newFormData.append("hostname", "localhost");
      setSelectedFile(newFormData);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = imgData;

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  }, [imgData]);

  const handleViewClick = async (item: any) => {
    const isImage =
      item?.file?.includes("png") ||
      item?.file?.includes("jpeg") ||
      item?.file?.includes("jpg") ||
      item?.file?.includes("webp") ||
      item.file?.includes("svg");
    setIsImage(isImage);
    setLoadingImg(true);
    setDocumentName(item.documentName);
    setImgOpen(true);
    setImageDimensions({ width: 0, height: 0 });
    const urlData = {
      id: item?.id,
      hostname: "localhost",
      key: item?.assetKey,
    };
    const response = axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/getAssetById`,
      urlData
    );
    setImgData((await response).data);
    setLoadingImg(false);
  };

  const handleimgClose = () => {
    setImgOpen(false);
    setImgData({});
  };

  return (
    <Grid
      container
      md={12}
      sm={12}
      lg={12}
      xl={12}
      xs={12}
      className={Styles.createEmployeeContainer}
    >
      <Grid
        container
        component="form"
        className={Styles.infoWraper}
        onSubmit={handleSubmit(handleUpdateCompensationInfo)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Grid container>
          <Grid container mb={3} className={Styles.headerGrid}>
            <Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={Styles.subHeading}>
                  Compensation Details
                </Typography>
              </Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={Styles.subText}>
                  Here are all the employees at your company.
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <CustomButtons
                name="Update"
                variant="contained"
                className={Styles.createBtn}
                type="submit"
                loading={updateEmployeeResLoading}
                disabled={updateEmployeeResLoading}
                loaderClass={Styles.buttonLoader}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid item sm={6} md={4} xs={12} lg={3} className={Styles.formGrid}>
              <Typography className={Styles.formLabel}>Yearly CTC</Typography>
              <CustomTextField
                // error={Boolean(errors.yearlyCtc)}
                // errorCondition={
                //   errors.yearlyCtc && (
                //     <Typography className={Styles.errorMsg}>
                //       {errors.yearlyCtc.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="yearlyCtc"
                fullWidth={true}
                className={Styles.fieldContainer}
                startAdornment={
                  <InputAdornment position="start">
                    <Grid className={Styles.rupeeBox}>
                      <CurrencyRupeeIcon className={Styles.rupeeIcon} />
                    </Grid>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item sm={6} md={4} xs={12} lg={3} className={Styles.formGrid}>
              <Typography className={Styles.formLabel}>Monthly CTC</Typography>
              <CustomTextField
                // error={Boolean(errors.monthlyCtc)}
                // errorCondition={
                //   errors.monthlyCtc && (
                //     <Typography className={Styles.errorMsg}>
                //       {errors.monthlyCtc.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="monthlyCtc"
                fullWidth={true}
                className={Styles.fieldContainer}
                startAdornment={
                  <InputAdornment position="start">
                    <Grid className={Styles.rupeeBox}>
                      <CurrencyRupeeIcon className={Styles.rupeeIcon} />
                    </Grid>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item sm={6} md={4} xs={12} lg={3} className={Styles.formGrid}>
              <Typography className={Styles.formLabel}>Variable Pay</Typography>
              <CustomTextField
                // error={Boolean(errors.varPay)}
                // errorCondition={
                //   errors.varPay && (
                //     <Typography className={Styles.errorMsg}>
                //       {errors.varPay.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="varPay"
                fullWidth={true}
                className={Styles.fieldContainer}
                startAdornment={
                  <InputAdornment position="start">
                    <Grid className={Styles.rupeeBox}>
                      <CurrencyRupeeIcon className={Styles.rupeeIcon} />
                    </Grid>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item sm={6} md={4} xs={12} lg={3} className={Styles.formGrid}>
              <Typography className={Styles.formLabel}>
                Variable Pay Term
              </Typography>
              <CustomTextField
                error={Boolean(errors.VariablePayTerm)}
                // errorCondition={
                //   errors.VariablePayTerm && (
                //     <Typography className={Styles.errorMsg}>
                //       {errors.VariablePayTerm.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="VariablePayTerm"
                fullWidth={true}
                className={Styles.fieldContainer}
                placeholder="Variable Pay Term (month/year)"
              />
              {/* <CustomSelect
                  error={Boolean(errors.noticePeriod)}
                  errorCondition={
                    errors.noticePeriod && (
                      <Typography color="error">
                        Please Select a value
                      </Typography>
                    )
                  }
                  control={control}
                  name="variablePayTerm"
                  selectData={CompensationDetailData.variablePayTerm}
                  placeHolder="00"
                /> */}
            </Grid>
          </Grid>
          <Grid container className={Styles.headerGrid}>
            <Grid>
              <SettingCustomHeader
                header="Appraisals Details"
                paragraph=" Here are all the employees at your company"
              />
            </Grid>
            <Grid>
              <CustomButtons
                name="Add Appraisal"
                variant="outlined"
                icon={<AddIcon />}
                className={Styles.createBtn}
                onClick={addExperienceModalOpen}
              />
            </Grid>
          </Grid>
          <Grid container md={12} mt={3} mb={3}>
            <CustomTable
              tableHeader={tableStyle.tableHeader}
              data={editEmpRecord?.compensationDetails?.appraisalDetails || []}
              hidePagination={true}
              columns={[
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={100}>
                      Date
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {(item.appraisalDate &&
                          moment(item.appraisalDate).format("MM-DD-YYYY")) ||
                          "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={100}>
                      CTC
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {(item.CTC && `₹${item.CTC}L`) || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={130}>
                      Revised CTC
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {(item.revisedCTC && `₹${item.revisedCTC}L`) || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={130}>
                      Promoted From
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.promotedFrom || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={130}>
                      Promoted To
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell}>
                        {item.promotedTo || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid
                      className={tableStyle.tableHead}
                      minWidth={130}
                      sx={{
                        justifyContent: "center",
                        display:
                          editEmpRecord?.compensationDetails?.appraisalDetails
                            ?.length === 0
                            ? "none"
                            : "flex",
                      }}
                    >
                      Appraisal Doc
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <>
                        <Grid
                          className={tableStyle.cell}
                          sx={{
                            justifyContent: "center",
                            display:
                              editEmpRecord?.compensationDetails
                                ?.appraisalDetails?.length === 0
                                ? "none"
                                : "flex",
                          }}
                        >
                          {item?.assetKey ? (
                            <Tooltip
                              title="View documents"
                              onClick={() => handleViewClick(item)}
                              sx={{ cursor: "pointer" }}
                            >
                              <VisibilityIcon />
                            </Tooltip>
                          ) : (
                            <Typography
                              className={Styles.addDocText}
                              onClick={() => handleAddDocClick(item)}
                            >
                              {" "}
                              Add Document
                            </Typography>
                          )}
                        </Grid>
                      </>
                    );
                  },
                },
                {
                  Header: "",
                  Cell: ({ item, index }: any) => {
                    return (
                      <MenuItems
                        options={["Edit", "Delete"]}
                        onViewDetails={(e: any) => handleMenuClick(item, e)}
                        itemStatus={item.status}
                      />
                    );
                  },
                },
              ]}
            />
          </Grid>
          {/* <Grid sm={12} md={12} container className={Styles.textUpload}>
          testing server
            <Typography className={Styles.contentTypography1}>
              Upload Compensation   
            </Typography>
            <Dropzone />
          </Grid>
          <Grid sm={12} md={12} className={Styles.devider}>
            <Divider />
          </Grid>
          <Grid container className={Styles.Appraisals}>
            <Grid sm={8} md={8}>
              <SettingCustomHeader
                header=" Appraisals Details"
                paragraph=" Here are all the employees at your company, including
                  terminated ones."
              />
            </Grid>
            <Grid
              sm={4}
              md={4}
              container
              justifyContent="center"
              alignItems="center"
            >
              <CustomButtons
                icon={<AddIcon />}
                name="Add Appraisal"
                className={Styles.addContactBtn}
                variant="contained"
              />
            </Grid>
          </Grid>
          <Grid item sm={12} md={12}>
            <CustomTable
              data={fieldsData}
              hidePagination={true}
              columns={[
                {
                  Header: <Grid className={Styles.paperContent}> Date </Grid>,
                  Cell: ({ item, index }: any) => {
                    return <Grid className={Styles.cell}>12/06/2023</Grid>;
                  },
                },
                {
                  Header: <Grid className={Styles.paperContent}> CTC </Grid>,
                  Cell: ({ item, index }: any) => {
                    return <Grid className={Styles.cell}>₹12.5L</Grid>;
                  },
                },
                {
                  Header: (
                    <Grid className={Styles.paperContent}> Revised CTC </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return <Grid className={Styles.cell}>₹18.5L</Grid>;
                  },
                },
                {
                  Header: (
                    <Grid className={Styles.paperContent}> Promoted From </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={Styles.cell}>
                        Senior Software Engineer
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={Styles.paperContent}> Promoted To </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return <Grid className={Styles.cell}>Team Lead</Grid>;
                  },
                },
                {
                  Header: (
                    <Grid className={Styles.paperContent}>Appraisal Doc </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return <Grid className={Styles.cell}></Grid>;
                  },
                },
                {
                  Header: "",
                  Cell: ({ item, index }: any) => {
                    return <MoreVertIcon className={Styles.moreIcon} />;
                  },
                },
              ]}
            />
          </Grid> */}
        </Grid>
        {/* <Grid container className={Styles.editBtnContainer}>
          <CustomButtons
            name="Update"
            variant="contained"
            className={Styles.createBtn}
            type="submit"
          />
        </Grid> */}
      </Grid>
      {openModal && (
        <AddAppraisalModal
          onClose={handleModalClose}
          open={openModal}
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
          selectedItem={selectedItem}
        />
      )}
      {openDocModal && (
        <AddDocument
          onClose={() => {
            setOpenDocModal(!openDocModal);
          }}
          open={openDocModal}
          handleDocSubmit={handleDocSubmit}
          handleSelectedFile={handleSelectedFile}
          handleDocFormSubmit={handleDocFormSubmit}
          docControl={docControl}
          docErrors={docErrors}
          uploadDocLoader={uploadDocLoader}
        />
      )}

      {imgOpen && (
        <ImageModal
          open={imgOpen}
          onClose={handleimgClose}
          title={documentName || "Document Details"}
          loading={loadingImg}
          isImage={isImage}
          imgData={imgData}
          documentName={documentName}
          imageDimensions={imageDimensions}
        />
      )}
    </Grid>
  );
};

export default CompensationDetails;
