import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "src/Styles/HrDocsSliders.module.css";
import Styles from "src/Styles/EducationalDetails.module.css";
import CustomTable from "src/components/TableComponent/CustomTable";
import MenuItems from "src/components/MenuItems/MenuItems";
import { useMutation, useQuery } from "@apollo/client";
import type { RootState } from "src/store/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector, useAppDispatch } from "src/store/hooks";
import RegularizationDetailsModel from "./RegularizationDetails";
import moment from "moment";
import {
  DELETE_REGULARIZE,
  GET_REGULARISATION_BY_ID,
} from "src/graphql/regularization";
import { Toast } from "../ToastMessage";

const Regularisation = ({ selectedStatus }: any) => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  const [offset, setOffset] = useState(1)
  const [limit, setLimit] = useState(10)

  const { data, loading, error, refetch } = useQuery(GET_REGULARISATION_BY_ID, {
    variables: {
      employeeId: userInfo?.id,
      filterStatus: selectedStatus,
      offset,
      limit
    },
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch])
  const [deleteRegularizeRequest] = useMutation(DELETE_REGULARIZE);

  const [mopen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDetails = async (item: any, event: any) => {
    if (event.target.textContent === "View") {
      setOpen(true);
      setSelectedItem(item);
    } else if (event.target.textContent === "Delete") {
      try {
        const response = await deleteRegularizeRequest({
          variables: {
            deleteRegularizeRequestId: item.id,
            deletedBy: "EMPLOYEE",
          },
        });
        if (response.data) {
          Toast("success", "Regularization cancelled Successfully");
          refetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid item container md={12} className={Styles.table}>
        <CustomTable
          data={data?.getRegularizeListById?.regularisationInfo || []}
          hidePagination={false}
          tableCellFirstColStyle={Styles.tableCellFirstColStyles}
          tableHeader={Styles.tableHeader}
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          count={data?.getRegularizeListById?.totalCount || 0}
          // enableCheckBox={true}
          checkboxClassName={styles.checkboxCss}
          columns={[
            {
              Header: <Grid className={Styles.tableHead}>Approver</Grid>,
              accessor: "acceptedBy",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid className={Styles.cell}>{item.approver.name}</Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}> Type </Grid>,
              accessor: "requestType",
              Cell: ({ item, index }: any) => {
                return <Grid className={Styles.cell}>{item.requestType}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Applied Date</Grid>,
              accessor: "appliedFrom",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(
                  item.requestedDates[0].requestDate
                ).format("DD-MM-YYYY");
                return <Grid className={Styles.cell}>{formattedDate}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Total Hours</Grid>,
              accessor: "appliedFrom",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid className={Styles.cell}>
                    {item.requestedDates[0].totalHours}
                  </Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Raised On</Grid>,
              accessor: "appliedTo",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(item.createdOn).format(
                  "DD-MM-YYYY"
                );
                return <Grid className={Styles.cell}>{formattedDate}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Status</Grid>,
              accessor: "status",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid
                    className={Styles.cell}
                    sx={{
                      color:
                        item.regularizeStatus === "REJECTED"
                          ? "red"
                          : item.regularizeStatus === "DELETED"
                            ? "darkred"
                            : item.regularizeStatus === "APPROVED"
                              ? "green"
                              : "orange",
                      borderRadius: "15px",
                      fontSize: "15px",
                    }}
                  >
                    {item.regularizeStatus}
                  </Grid>
                );
              },
            },
            {
              Header: "",
              Cell: ({ item }: any) => (
                <Grid item className={styles.moreVertIcon}>
                  <MenuItems
                    options={
                      item.regularizeStatus === "PENDING" ||
                        item.regularizeStatus === "APPROVED"
                        ? ["View", "Delete"]
                        : ["View"]
                    }
                    onViewDetails={(e: any) => handleViewDetails(item, e)}
                  />
                </Grid>
              ),
            },
          ]}
        />
        {mopen && (
          <RegularizationDetailsModel
            onClick={handleClickOpen}
            open={mopen}
            onClose={handleClose}
            selectedItem={selectedItem}
          />
        )}
      </Grid>
    </>
  );
};

export default Regularisation;
