import CustomTab from "src/components/CustomTab";
import { Grid, Stack, Typography } from "@mui/material";
import Styles from "src/Styles/informationTab.module.css";

const InformationTab = ({ children }: any) => {
  const data = [
    {
      id: 0,
      label: (
        <Stack direction="row">
          <Typography className={Styles.tabs_typography}>
            Manage Employees
          </Typography>
        </Stack>
      ),
      // component: <Employees condition={false} />,
      href: "/admin/hrms/employees/manage-employees",
    },
    {
      id: 1,
      label: (
        <Stack direction="row">
          <Typography className={Styles.tabs_typography}>Directory</Typography>
        </Stack>
      ),
      // component: (
      //   <>
      //     <Directory />
      //   </>
      // ),
      href: "/admin/hrms/employees/directory",
    },
    {
      id: 2,
      label: (
        <Stack direction="row" alignSelf="start">
          <Typography className={Styles.tabs_typography}>Org Chart</Typography>
        </Stack>
      ),
      href: "/admin/hrms/employees/org",
    },
  ];

  return (
    <Grid md={12}>
      <CustomTab
        myTabData={data}
        orientation="horizontal"
        moreSideIcon={true}
        component={children}
      />
    </Grid>
  );
};

export default InformationTab;
