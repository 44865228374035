export function formatNoData(data: any, config?: any) {
  if (data) {
    // eslint-disable-next-line
    if (data == "other") {
      return "—";
    } else {
      return data;
    }
  } else {
    if (config?.placeholder) {
      return `${config?.placeholder}`;
    }
    return "—";
  }
}
