import React from "react";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Card,
  TextareaAutosize,
  Box,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import styles from "src/Styles/AddjobTitle.module.css";
import Divider from "@mui/material/Divider";
import ModalBox from "src/components/ReusableModal/modal";
import { useForm } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_USERS_BY_PORTAL, UPDATE_EMPLOYEE } from "src/graphql/user";
import { Toast } from "../ToastMessage";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import CustomSelect from "../CustomSelect/customSelectValidations";
import { GET_ALL_USERS } from "src/graphql/user";
import workInformationData from "../../components/AddEmployee/WorkInformation/WorkInformationData.json";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const CompanyUsersModal = ({
  onClick,
  open,
  onClose,
  setOpen,
  editFlag,
  editData,
}: any) => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const accessRules = [
    {
      type: "selectAll",
      label: "Select All",
    },
    {
      type: "employees",
      revoked: "false",
      adminRevokedStatus: "false",
      active: true,
    },
    {
      type: "calendar",
      revoked: "false",
      adminRevokedStatus: "false",
      active: true,
    },
    {
      type: "timeOff",
      revoked: "false",
      adminRevokedStatus: "false",
      active: true,
    },
    {
      type: "documents",
      revoked: "false",
      adminRevokedStatus: "false",
      active: true,
    },
    {
      type: "settings",
      revoked: "false",
      adminRevokedStatus: "false",
      active: true,
    },
  ];

  const [accessPermission, setAccessPermission] = React.useState<any[]>(
    editData?.accessRules || []
  );

  const schema = yup.object().shape({
    accessPermission: yup
      .array()
      .of(yup.object().required("Please select at least one access permission"))
      .min(1),
  });

  const { data: reportingMangdata } = useQuery(GET_ALL_USERS, {
    variables: {
      input: {
        role: ["SUPER_ADMIN", "ADMIN", "EMPLOYER", "HR", "EMPLOYEE"],
      },
    },
  });

  const reportingManagerData = reportingMangdata?.getAllUsers?.map(
    (item: any) => ({
      name: item?.displayName,
      id: item?.id,
      type: item?.id,
    })
  );

  const [UpdateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [
      GET_ALL_USERS_BY_PORTAL,
      "GetUsersByPortal", // Query name
    ],
  });

  React.useEffect(() => {
    if (!open) {
      // Reset the state when the modal is closed
      setAccessPermission([]);
    }
  }, [open]);
  

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    // reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleCheckboxChange = (obj: any) => {
    if (obj.type === "selectAll") {
      // Handle "Select All" checkbox
      const selectAllChecked =
        accessPermission.length === accessRules.length - 1;

      const updatedAccessPermission = selectAllChecked
        ? [] // Uncheck all checkboxes if "Select All" is checked
        : accessRules.slice(1); // Check all checkboxes if "Select All" is unchecked

      setAccessPermission(updatedAccessPermission);
    } else {
      // Handle other checkboxes
      const isChecked = accessPermission.some(
        (selectedObj) => selectedObj.type === obj.type
      );

      const updatedAccessPermission = isChecked
        ? accessPermission.filter((item) => item.type !== obj.type)
        : [...accessPermission, obj];

      setAccessPermission(updatedAccessPermission);
    }
  };
  const handleOnSubmit = async (data: any) => {
    if (editFlag) {
      try {
        const createData = {
          accessRules: accessPermission.map(({ type, active, revoked, default: defaultValue }) => ({
            type,
            active,
            revoked,
            default: defaultValue,
          })),
        };
        const response = await UpdateEmployee({
          variables: { updateEmployeeId: editData.id, input: createData },
        });
        if (response.data) {
          Toast("success", "Company users updated Successfully");
          setOpen(false);
        }
      } catch (error: any) {
        console.error("Error creating company users:", error);
        Toast("error", error?.message);
      }
    }
  };

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        content={
          <Card className={styles.modalContent}>
            <Box className={styles.headerContainer}>
              <Grid>
                <Typography className={styles.headerText}>
                  {editData.displayName}
                </Typography>
                <Typography className={styles.headerTextSub}>
                  {!editFlag
                    ? "Create new Company User to know his Access permission and his role"
                    : "Edit new Company User to know his Access permission and his role"}
                </Typography>
              </Grid>
              <Grid mb={2}>
                <CloseIcon onClick={onClose} className={styles.closeIcon} />
              </Grid>
            </Box>
            <Divider />
            <Grid container className={styles.bottomDiv}>
              <form
                onSubmit={handleSubmit(handleOnSubmit)}
                className={styles.textField}
              >
                <Grid xs={12}>
                  <Typography className={styles.inputHeading}>
                    Access Permissions
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  {/* <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Access
                    </InputLabel>
                    <Controller
                      name="accessPermission"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={accessPermission}
                          onChange={(e: any) =>
                            setAccessPermission(e.target.value)
                          }
                          renderValue={(selected: any) =>
                            selected.map((obj: any) => obj.type).join(", ")
                          }
                        >
                          {accessRules.map((obj: any) => (
                            <MenuItem key={obj.type} value={obj}>
                              <Checkbox
                                checked={accessPermission.some(
                                  (selectedObj: any) =>
                                    selectedObj.type === obj.type
                                )}
                              />
                              <ListItemText primary={obj.type} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <Typography variant="body2" color="error">
                      {errors.accessPermission?.message}
                    </Typography>
                  </FormControl> */}
                  <FormGroup row>
                    {accessRules.map((obj: any) => (
                      <FormControlLabel
                        key={obj.type}
                        control={
                          <Checkbox
                            checked={
                              obj.type === "selectAll"
                                ? accessPermission.length ===
                                  accessRules.length - 1
                                : accessPermission.some(
                                    (selectedObj) =>
                                      selectedObj.type === obj.type
                                  )
                            }
                            onChange={() => handleCheckboxChange(obj)}
                          />
                        }
                        label={obj.label || obj.type}
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid xs={12} className={styles.buttonsDiv}>
                  <CustomButtons
                    name={!editFlag ? "Add Company User" : "Edit Company User"}
                    type="submit"
                    variant="contained"
                    className={styles.buttons}
                  />
                </Grid>
              </form>
            </Grid>
          </Card>
        }
      />
    </>
  );
};

export default CompanyUsersModal;
