import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

// eslint-disable-next-line
interface GoogleAuth {
  email: Function;
}

const AuthResponse = ({ email, width }: any) => {
  const handleLoginSuccess = (credentialResponse: any) => {
    const decoded: any = jwtDecode(credentialResponse.credential);
    const userEmail: any = decoded?.email;
    email(userEmail);
  };

  return (
    <div style={{ width }}>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={() => {
            console.log("Login Failed");
          }}
          width="100%"
          auto_select={false}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default AuthResponse;
