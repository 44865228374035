// Import necessary hooks and functions
import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Styles from 'src/Styles/EducationalDetails.module.css';
import CustomTable from 'src/components/TableComponent/CustomTable';
import ManageAllowancesModal from 'src/components/ManageAllowances/ManageAllowancesModal';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS_BY_PORTAL } from '../../graphql/manageAllowances';

// Define the component
const ManageAllowances = () => {
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  // Query to fetch users data
  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS_BY_PORTAL, {
    variables: {
      input:
      {
        offset,
        limit
      }
    }
  });
  useEffect(() => {
    refetch()
  }, [refetch, offset, limit])
  // states declaration
  const [userss, setUserss] = useState(null);
  const [userId, setUserId] = useState(0);
  const [fieldData, setFieldData] = useState(0);
  const [userName, setUserName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  // React hook to execute side effects after component renders
  // useEffect(() => {
  //   const userData = data?.getUsersByPortal?.users || [];
  //   setUserss(userData);
  // }, [data]);

  // Function to open the modal and set necessary states
  const openModal = (employee: any) => {
    setUserId(employee.id);
    setFieldData(employee.userAllowances);
    setUserName(employee?.displayName);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  if (loading) {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'flex';
    }
  } else {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'none';
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        {data?.getUsersByPortal.users && (
          <CustomTable
            data={data?.getUsersByPortal.users || []}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={(data && data?.getUsersByPortal.totalCount) || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Display Name</Grid>,
                accessor: 'displayName',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.displayName}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Role</Grid>,
                accessor: 'role',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.role}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Edit</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <EditIcon onClick={() => openModal(item)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {modalOpen && (
          <ManageAllowancesModal
            open={modalOpen}
            onClose={closeModal}
            userId={userId}
            balance={fieldData}
            userName={userName}
          />
        )}
      </Grid>
    </>
  );
};

export default ManageAllowances;
