import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "src/Styles/ManageEmployees.module.css";
import TuneIcon from "@mui/icons-material/Tune";
import EmployeesTableSettings from "src/components/EmployeesTableSettings";
import ViewProfile from "src/components/ViewProfile/ViewProfile";
import FilterComp from "src/components/Filter/FilterComp";
import CustomTable from "src/components/TableComponent/CustomTable";
import CustomButtons from "src/components/CustomButton/Button";
import SearchBox from "src/components/Search/index";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@apollo/client";
import { GET_USER_BY_ID, GET_ALL_USERS_BY_PORTAL } from "src/graphql/user";
import ConfirmationTab from "../Confirmation";
import EmployeeDetailsModel from "../EmployeeDetailsModel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import tableStyle from "../../Styles/commonTable.module.css";
import { debounce } from "src/utils/debounce";

const Employees = ({ condition }: any) => {
  const navigate = useNavigate();

  const [allSearch, setAllSearch] = useState("");
  const [searchbyField, setSearchByField] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userID, setUserID] = useState("");
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  // eslint-disable-next-line
  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS_BY_PORTAL, {
    variables: {
      input:
        Object.keys(searchbyField).length !== 0
          ? {
            designationId: searchbyField?.designation,
            departmentId: searchbyField?.department,
            status: searchbyField?.employeeStatus,
            workPlaceId: searchbyField?.workPlace,
            offset,
            limit
          }
          : {
            searchString: allSearch,
            offset,
            limit
          },
    },
  });

  const {
    data: userDataById,
    // loading: userDataByIdLoading,
    // error: userDataByIdError,
  } = useQuery(GET_USER_BY_ID, {
    variables: { userId: userID },
    skip: !userID,
  });

  const [openSettings, setOpenSettings] = useState(false);
  const toggleSettings = () => setOpenSettings(!openSettings);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editEmployeeData, setEditEmployeeData] = React.useState({});

  // Debounce the search function
  const debouncedSearch = debounce((searchValue: string) => {
    setAllSearch(searchValue);
    // refetch();
  }, 500);

  const requestSearch = async (evt: any) => {
    setSearchByField("");

    const searchValue = evt.target.value;

    debouncedSearch(searchValue);
  };
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch]);

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleClickOpen = () => {
    navigate("/admin/hrms/employee/create");
  };

  const handleClickViewDetails = (id: any) => {
    setUserID(id);
    setIsModalOpen(true);
  };

  const handleDeleteUserOpen = (id: any) => {
    setUserID(id);
    setDeleteOpen(true);
  };

  const handleDeleteUserClose = () => {
    setDeleteOpen(false);
  };

  const handleEditClick = (item: any) => {
    setEditEmployeeData(item);

    navigate(`/admin/hrms/employee/edit/${item?.id}`);
  };

  const handleViewProfileClose = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      Header: (
        <Grid item className={tableStyle.tableHead}>
          Employee Name
        </Grid>
      ),
      Cell: ({ item, index }: any) => {
        return (
          <Grid container display="flex" alignItems="center">
            <Grid item>
              {item.profileImage.url ? (
                <img
                  src={item.profileImage.url || ""}
                  alt="profile"
                  className={styles.avatar}
                />
              ) : (
                <Avatar className={styles.noAvatar}>
                  {item.displayName?.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </Grid>
            <Grid item className={tableStyle.cell}>
              {item.displayName || "—"}
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: (
        <Grid item className={tableStyle.tableHead}>
          Job Title
        </Grid>
      ),
      Cell: ({ item, index }: any) => {
        return (
          <Grid item className={tableStyle.cell}>
            {item.jobTitleName || "—"}
          </Grid>
        );
      },
    },
    {
      Header: (
        <Grid item className={tableStyle.tableHead}>
          Department
        </Grid>
      ),
      Cell: ({ item, index }: any) => {
        return (
          <Grid item className={tableStyle.cell}>
            {item.deptName || "—"}
          </Grid>
        );
      },
    },
    {
      Header: (
        <Grid item className={tableStyle.tableHead}>
          Reporting Manager
        </Grid>
      ),
      Cell: ({ item, index }: any) => {
        return (
          <Grid item className={tableStyle.cell}>
            {item.reportingManagerName || "—"}
          </Grid>
        );
      },
    },
    {
      Header: (
        <Grid item className={tableStyle.tableHead}>
          Status
        </Grid>
      ),
      Cell: ({ item, index }: any) => {
        const status = item.status;

        if (status === "ACTIVE") {
          return <CustomButtons name="Active" className={styles.ActBtn} />;
        } else {
          return (
            <CustomButtons name="Inactive" className={styles.InActiveBtn} />
          );
        }
      },
    },
    {
      Header: (
        <Grid item className={tableStyle.tableHead}>
          Delete
        </Grid>
      ),
      Cell: ({ item, index }: any) => {
        return (
          <IconButton disabled={item.id === userInfo?.id}>
            <DeleteOutlineIcon
              className={styles.deleteButton}
              onClick={() => handleDeleteUserOpen(item.id)}
            />
          </IconButton>
        );
      },
    },
    {
      Header: <></>,
      Cell: ({ item, index }: any) => {
        return (
          <ViewProfile
            onClick={() => handleClickViewDetails(item.id)}
            onEditClick={() => handleEditClick(item)}
          />
        );
      },
    },
  ];

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Grid container className={styles.Parent_Grid}>
      <Grid
        container
        item
        md={12}
        lg={12}
        sm={12}
        xs={12}
        className={styles.Header_Text}
      >
        <Grid
          item
          lg={8}
          md={8}
          sm={6}
          xs={12}
          className={styles.upperGridHeading}
        >
          <SettingCustomHeader
            header="Employees"
            paragraph="Here are all the employees at your company, including terminated ones."
            className={styles.settingCustomHeader}
          />
        </Grid>
        <Grid
          container
          item
          lg={4}
          md={4}
          sm={6}
          xs={12}
          className={styles.upperGrid_padding}
        >
          <CustomButtons
            icon={<AddIcon />}
            name="Add Employe"
            className={styles.add_button}
            onClick={handleClickOpen}
            variant="contained"
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={styles.Table}
        xs={12}
        md={12}
        sm={12}
        lg={12}
        xl={12}
      >
        <Grid
          item
          className={styles.SearchContainer}
          xs={12}
          md={12}
          sm={12}
          lg={12}
          xl={12}
        >
          <Grid
            item
            xs={8}
            md={6}
            sm={6}
            lg={3.5}
            xl={3.5}
            container
            justifyContent={"flex-start"}
          >
            <SearchBox
              data={[]}
              customStyle={styles.searchBox}
              handleChange={requestSearch}
              borderRadius="30px"
              placeholder="Search"
              size="small"
            />
          </Grid>
          <Grid
            item
            xs={4}
            md={6}
            sm={6}
            lg={8.5}
            xl={8.5}
            container
            justifyContent={"flex-end"}
          >
            <TuneIcon onClick={toggleFilter} className={styles.FilterIcon} />
          </Grid>
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12} xl={12}>
          <CustomTable
            data={(data && data?.getUsersByPortal.users) || []}
            tableContainerClass={styles.Table_Content}
            tableHeader={tableStyle.tableHeader}
            draggable={false}
            hidePagination={false}
            columns={columns}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={(data && data?.getUsersByPortal.totalCount) || 0}
          />

        </Grid>
      </Grid>
      <EmployeesTableSettings
        openSettings={openSettings}
        toggleSettings={toggleSettings}
      />
      <FilterComp
        openFilter={openFilter}
        toggleFilter={toggleFilter}
        setSearchByField={setSearchByField}
      />

      {deleteOpen && (
        <ConfirmationTab
          onClose={handleDeleteUserClose}
          open={deleteOpen}
          userID={userID}
        />
      )}
      {isModalOpen && (
        <EmployeeDetailsModel
          onClose={handleViewProfileClose}
          employeeDetails={userDataById?.getUserById}
          open={isModalOpen}
        />
      )}
    </Grid>
  );
};

export default Employees;
