import React from "react";
// import excelImg from "../../../../public/MicrosoftExcel.png";
// import d1 from "./ExcelData1.json";
// import d2 from "./ExcelData2.json";
// import Styles from "src/Styles/ExcelImg.module.css";
const ExcelSheet = () => {
  // const data = {
  //   Data1: d1,
  //   Data2: d2,
  // };
  return (
    <div>
      {/* <ExcelDownloder
        data={data}
        filename={"book"}
        type={Type.Button} // or type={'button'}
      > */}
      {/* <Image
          src={excelImg}
          height={20}
          width={20}
          alt="xlsx"
          className={Styles.excelImg}
        /> */}
      {/* </ExcelDownloder> */}
    </div>
  );
};

export default ExcelSheet;
