// Import necessary hooks and functions
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Card, TextField, Button, CircularProgress, Divider } from '@mui/material';
import styles from 'src/Styles/ManageAllowancesModal.module.css';
import ModalBox from 'src/components/ReusableModal/modal';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_USERS } from '../../graphql/manageAllowances';
import { GET_ALLOWANCES } from '../../graphql/allowances';
import { CREATE_USER_ALLOWANCES } from '../../graphql/manageAllowances';
import { Toast } from '../ToastMessage/index';
import SubCircularLoader from '../ActivityIndicator/SubIndicator';
import CloseIcon from '@mui/icons-material/Close';

// Define the component
const ManageAllowancesModal = ({ open, onClose, content, userId, balance, userName }: any) => {
  // Query to fetch allowances data
  const { data, loading: allowancesLoading } = useQuery(GET_ALLOWANCES, { variables: { limit: 0, offset: 0 } });
  // Mutation to update user allowances
  const [updateUserAllowances] = useMutation(CREATE_USER_ALLOWANCES, {
    refetchQueries: [GET_ALL_USERS, 'GetAllUsers'],
  });

  // Extract policy items from the fetched data or initialize as empty array
  const policyItems = data?.getAllowances?.allowanceInfo || [];

  // State to manage backdrop visibility
  const [showBackdrop, setShowBackdrop] = useState(false);
  // State to manage updated allowances
  const [updatedAllowances, setUpdatedAllowances] = useState<any[]>(balance || []);

  // Function to handle allowance change
  const handleAllowanceChange = (allowanceId: string, allowancesAvailable: string) => {
    const updatedAllowancesCopy = [...updatedAllowances];
    const index = updatedAllowancesCopy.findIndex((item) => item?.allowanceId === allowanceId);

    if (index !== -1) {
      updatedAllowancesCopy[index] = {
        ...updatedAllowancesCopy[index],
        allowancesAvailable,
      };
    } else {
      updatedAllowancesCopy.push({
        allowanceId,
        allowancesAvailable,
      });
    }
    setUpdatedAllowances(updatedAllowancesCopy);
  };

  // Function to validate the form
  const validateForm = () => {
    return true;
  };

  // Function to handle save action
  const handleSave = () => {
    if (validateForm()) {
      setShowBackdrop(true);
      const userAllowances = updatedAllowances.map((item) => ({
        allowanceId: item.allowanceId,
        allowancesAvailable: parseFloat(item.allowancesAvailable || 0),
      }));

      updateUserAllowances({
        variables: {
          input: {
            id: userId,
            userAllowances,
          },
        },
      }).then((data1) => {
        Toast('success', 'Updated successfully!');

        setTimeout(() => {
          setShowBackdrop(false);
          onClose();
        }, 1000);
      });
    } else {
      Toast('error', 'All fields are required');
    }
  };

  return (
    <>
      <ModalBox
        open={open}
        onClose={onClose}
        content={
          <Card className={styles.card}>
            {allowancesLoading ? (
              <SubCircularLoader size={30} className={styles.subCircularLoader} />
            ) : (
              <Grid container>
                <Grid md={9} lg={9} sm={10} xs={10} xl={9} container>
                  <Typography className={styles.headerText}>Edit {userName} Allowances</Typography>
                </Grid>

                <Grid md={3} lg={3} sm={2} xs={2} xl={3} className={styles.close_button} container>
                  <CloseIcon onClick={onClose} className={styles.closeIcon} />
                </Grid>
                <Divider className={styles.divider} />
                <Grid container spacing={1} md={12} className={styles.containerFields}>
                  {policyItems.map((policy: any) => {
                    const allowances = updatedAllowances?.find((item: any) => item?.allowanceId === policy?.id);
                    return (
                      <Grid md={6} lg={6} sm={6} xs={12} xl={6} className={styles.inputs} key={policy.id}>
                        <Typography className={styles.inputHeading}>{policy?.policyName}</Typography>
                        <TextField
                          id="outlined-basic"
                          placeholder={policy.policyName}
                          variant="outlined"
                          fullWidth
                          value={allowances?.allowancesAvailable}
                          onChange={(e) => handleAllowanceChange(policy.id, e.target.value)}
                          className={styles.common_width}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid md={12} lg={12} sm={12} xs={12} xl={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={showBackdrop}
                    onClick={handleSave}
                    className={styles.saveBtn}
                  >
                    {showBackdrop ? <CircularProgress color="inherit" /> : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Card>
        }
      />
    </>
  );
};

export default ManageAllowancesModal;
