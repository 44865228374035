import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "src/Styles/HrDocsSliders.module.css";
import Styles from "src/Styles/EducationalDetails.module.css";
import CustomTable from "src/components/TableComponent/CustomTable";
import MenuItems from "src/components/MenuItems/MenuItems";
import { useQuery, useMutation } from "@apollo/client";
import { approveLeave, rejectLeave } from "src/store/LeaveSlice";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector, useAppDispatch } from "src/store/hooks";
import EmployeeLeaveViewDetails from "src/components/EmployeeSideLeaves/LeaveViewDetails";
import moment from "moment";
import { GET_EMPLOYEE_LEAVES, DELETE_LEAVE } from "src/graphql/employeeLeaves";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { formatNoData } from "src/utils/formatNoData";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CircularIndeterminate from "../ActivityIndicator/Indicator";
import { Toast } from "../ToastMessage";

const Leave = ({ selectedStatus }: { selectedStatus: string }) => {
  const userId = localStorage.getItem("userId");

  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const [updatedStatusMap, setUpdatedStatusMap] = useState<{
    [id: string]: string;
  }>({});

  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE_LEAVES, {
    query: GET_EMPLOYEE_LEAVES,
    variables: {
      employeeId: userId,
      filterStatus: selectedStatus,
      offset,
      limit,
    },
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch]);
  const [deleteEmployeeLeaves] = useMutation(DELETE_LEAVE);

  const handleStatusChange = (itemId: string, status: string) => {
    dispatch(rejectLeave(status));
    dispatch(approveLeave(status));
    setUpdatedStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [itemId]: status,
    }));
  };

  const [mopen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDetails = async (item: any, event: any) => {
    if (event.target.textContent === "View") {
      setSelectedItem(item);

      dispatch(rejectLeave(item?.status));
      dispatch(approveLeave(item?.status));
      handleClickOpen();
    } else if (event.target.textContent === "Delete") {
      try {
        const response = await deleteEmployeeLeaves({
          variables: { deleteEmployeeLeavesId: item.id, deletedBy: "EMPLOYEE" },
        });
        if (response.data) {
          Toast("success", "Leave cancelled Successfully");
          refetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid item container md={12} className={Styles.table}>
        <CustomTable
          data={data?.getEmployeeLeaves.employeeLeavesInfo || []}
          hidePagination={false}
          tableCellFirstColStyle={Styles.tableCellFirstColStyles}
          tableHeader={Styles.tableHeader}
          // enableCheckBox={true}
          checkboxClassName={styles.checkboxCss}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          count={(data && data?.getEmployeeLeaves?.totalCount) || 0}
          columns={[
            {
              Header: <Grid className={Styles.tableHead}>Approver</Grid>,
              accessor: "acceptedBy",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid key={item.id} className={Styles.cell}>
                    {/* {formatNoData(item?.acceptedBy)} */}
                    {item.approver.name}
                  </Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}> Type </Grid>,
              accessor: "requestType",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid key={item.id} className={Styles.cell}>
                    {item.requestType}
                  </Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Applied Date</Grid>,
              accessor: "appliedFrom",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(item.appliedFrom).format(
                  "DD-MM-YYYY"
                );
                return (
                  <Grid key={item.id} className={Styles.cell}>
                    {formattedDate}
                  </Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Raised On</Grid>,
              accessor: "createdOn",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(item.createdOn).format(
                  "DD-MM-YYYY"
                );
                return (
                  <Grid key={item.id} className={Styles.cell}>
                    {formattedDate}
                  </Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Status</Grid>,
              accessor: "status",
              Cell: ({ item, index }: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const status = updatedStatusMap[item.id] || item.status;

                return (
                  <Grid
                    key={item.id}
                    className={Styles.cell}
                    sx={{
                      color:
                        item.status === "REJECTED"
                          ? "red"
                          : item.status === "DELETED"
                            ? "darkred"
                            : item.status === "APPROVED"
                              ? "green"
                              : "orange",
                      borderRadius: "15px",
                      fontSize: "15px",
                    }}
                  >
                    {item.status}
                  </Grid>
                );
              },
            },
            {
              Header: "",
              Cell: ({ item }: any) => (
                <Grid key={item.id} item className={styles.moreVertIcon}>
                  <MenuItems
                    options={
                      item.status === "PENDING" || item.status === "APPROVED"
                        ? ["View", "Delete"]
                        : ["View"]
                    }
                    onViewDetails={(e: any) => handleViewDetails(item, e)}
                    itemStatus={item.status}
                  />
                </Grid>
              ),
            },
          ]}
        />
        {mopen && (
          <EmployeeLeaveViewDetails
            onClick={handleClickOpen}
            open={mopen}
            onClose={handleClose}
            selectedItem={selectedItem}
            onStatusChange={handleStatusChange}
          />
        )}
      </Grid>
    </>
  );
};

export default Leave;
