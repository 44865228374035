import gql from 'graphql-tag';

export const GET_CALENDER_DATA = gql`
  query getCalendarData($input: CalendarInput) {
    getCalendarData(input: $input) {
      userId
      entityId
      startDate
      endDate
      monthOfYear
      calendarData {
        title
        date
        holidayName
        isAbsent
        isHoliday
        isWeekend
        attendance {
          id
          userId
          loginTime
          logoutTime
          status
          timeZone
          shiftId
          location {
            loginLocation {
              address
              city
              state
            }
            logoutLocation {
              address
              city
              state
            }
          }
          attendanceDate
          totalTime
          allowanceName
          leaveType
          wfhType
          regularizeAppliedForHalfDay
        }
      }
    }
  }
`;
