import React from "react";
import DatePicker from "react-multi-date-picker";

const CustomDateRangePicker = ({
  field,
  minDate,
  containerClassName,
  isEditable,
  format,
  placeholder,
}: any) => {
  return (
    <DatePicker
      value={field.value}
      onChange={(value) => field.onChange(value)}
      range
      format={format}
      placeholder={placeholder}
      editable={isEditable}
      containerClassName={containerClassName}
      minDate={minDate}
    />
  );
};

export default CustomDateRangePicker;
