import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  Button,
  TextField,
  Divider,
  Paper,
  Popper,
} from "@mui/material";
import {
  DialogProps,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import BasicDatePicker from "src/components/DatePicker/index";
import Styles from "src/Styles/Regularize.module.css";
import CustomButtons from "../CustomButton/Button";
import {
  CREATE_REGULARISATION,
  GET_REGULARISATION_BY_ID,
} from "src/graphql/regularization";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Toast } from "../ToastMessage";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector, useAppDispatch } from "src/store/hooks";
import type { RootState } from "src/store/store";
import dayjs from "dayjs";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
interface RegularizeProps {
  open: boolean;
  onClose: () => void;
  selectedDateInfo: any;
}

const Regularize: React.FC<RegularizeProps> = ({
  open,
  onClose,
  selectedDateInfo,
}) => {
  const [createRegularization, { loading: createRegularizeLoading }] =
    useMutation(CREATE_REGULARISATION, {
      refetchQueries: [
        GET_REGULARISATION_BY_ID, // DocumentNode object parsed with gql
        "getRegularizeListById", // Query name
      ],
    });

  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [gridRows, setGridRows] = useState<Array<React.ReactNode>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isGridLineVisible, setIsGridLineVisible] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [dateEntries, setDateEntries] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState<Array<React.ReactNode>>([]);

  const handleAddDateClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsCalendarOpen((prev) => !prev);
  };

  const handleDateCalendarClose = () => {
    setIsCalendarOpen(false);
  };
  useEffect(() => {
    reset();
    setGridRows([]);
    setDateEntries({});
    // eslint-disable-next-line
  }, [open]);

  const generateValidationSchema = (selectedDates: any) => {
    const schemaObject: any = {};
    selectedDates.forEach((date: any) => {
      schemaObject[`inTime-${date}`] = Yup.string().required(
        "In time is required."
      );
      schemaObject[`outTime-${date}`] = Yup.string().required(
        "Out time is required."
      );
      schemaObject[`reason-${date}`] = Yup.string().required(
        "Reason is required."
      );
    });

    return Yup.object().shape(schemaObject);
  };

  const ApplyRegularizeSchema = generateValidationSchema(selectedDate);

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    reset,
    watch,
    setValue,
    clearErrors,
  }: any = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(ApplyRegularizeSchema),
  });

  // eslint-disable-line
  useEffect(() => {
    if (selectedDateInfo?.date) {
      let mFromat: any = moment(selectedDateInfo?.date).format("YYYY-MM-DD");
      const momentINTime: any = moment(selectedDateInfo?.loginTime);
      const momentOUTTime: any = moment(selectedDateInfo?.logoutTime);
      handleDateChange(mFromat);
      setValue(`inTime-${mFromat}`, momentINTime);
      setValue(`outTime-${mFromat}`, momentOUTTime);
      const defaultTotalHr = calculateTotalHours(momentINTime, momentOUTTime);
      setValue(`totalHours-${mFromat}`, defaultTotalHr);
      setValue(`reason-${mFromat}`, "");
      setDateEntries({
        [mFromat]: {
          [`inTime-${mFromat}`]: momentINTime,
          [`outTime-${mFromat}`]: momentOUTTime,
          [`totalHours-${mFromat}`]: defaultTotalHr || "00:00:00",
        },
      });
    }
    // eslint-disable-next-line
  }, [selectedDateInfo?.date]);

  const calculateTotalHours = (inTime: string, outTime: string) => {
    const format = "HH:mm:ss";
    const inTimeMoment = moment(inTime);
    const outTimeMoment = moment(outTime);

    const duration = moment.duration(outTimeMoment.diff(inTimeMoment));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const totalHoursFormatted = moment
      .utc()
      .hours(hours)
      .minutes(minutes)
      .seconds(seconds)
      .format(format);

    return totalHoursFormatted;
  };

  const handleFieldChange = async (fieldName: string, value: any) => {
    let updatedValue = value;

    if (fieldName.includes("inTime") || fieldName.includes("outTime")) {
      updatedValue = moment(value?.$d);
    }

    setValue(fieldName, updatedValue);
    const dateKey = fieldName.split("-").slice(1).join("-");
    const newEntry = { [fieldName]: updatedValue };

    setDateEntries((prevData: any) => ({
      ...prevData,
      [dateKey]: { ...(prevData[dateKey] || {}), ...newEntry },
    }));

    const inTime = watch(`inTime-${dateKey}`);
    const outTime = watch(`outTime-${dateKey}`);

    if (inTime && outTime) {
      const momentInTime: any = moment(inTime);
      const momentOutTime: any = moment(outTime);
      const totalHours = await calculateTotalHours(momentInTime, momentOutTime);
      setValue(`totalHours-${dateKey}`, totalHours);
      setDateEntries((prevData: any) => ({
        ...prevData,
        [dateKey]: {
          ...(prevData[dateKey] || {}),
          [`totalHours-${dateKey}`]: totalHours,
        },
      }));
    } else {
      setValue(`totalHours-${dateKey}`, "");
      setDateEntries((prevData: any) => ({
        ...prevData,
        [dateKey]: {
          ...(prevData[dateKey] || {}),
          [`totalHours-${dateKey}`]: "",
        },
      }));
    }
  };

  const handleCloseGridLine = (dateKey: any) => {
    setDateEntries((prevData: any) => {
      const newData = { ...prevData };
      delete newData[dateKey];
      return newData;
    });
    setGridRows((prevRows) =>
      prevRows.filter(
        (row: any) => moment(row.key).format("YYYY-MM-DD") !== dateKey
      )
    );
    setSelectedDate((prevDate) =>
      prevDate.filter((d: any) => moment(d).format("YYYY-MM-DD") !== dateKey)
    );
    setValue(`inTime-${dateKey}`, undefined);
    setValue(`outTime-${dateKey}`, undefined);
    setValue(`totalHours-${dateKey}`, "");
    setValue(`reason-${dateKey}`, "");

    clearErrors(`inTime-${dateKey}`);
    clearErrors(`outTime-${dateKey}`);
    clearErrors(`totalHours-${dateKey}`);
    clearErrors(`reason-${dateKey}`);
  };

  const handleDateChange = (date: Date | null) => {
    const currentDate: any = moment();
    const addedDate: any = moment(date).format("YYYY-MM-DD");
    const isEntryExists = gridRows.some(
      (row: any) => moment(row.key).format("YYYY-MM-DD") === addedDate
    );

    handleFieldChange(
      `inTime-${addedDate}`,
      dayjs(`${addedDate} ${"09:30:00 AM"}`)
    );

    handleFieldChange(
      `outTime-${addedDate}`,
      dayjs(`${addedDate} ${"06:30:00 PM"}`)
    );

    const isNotPastDate = date && addedDate <= currentDate.format("YYYY-MM-DD");

    if (!isEntryExists && isNotPastDate) {
      const newRow = (
        <Grid key={addedDate} container className={Styles.addDateMainContainer}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            {gridRows?.length !== 0 && (
              <Grid className={Styles.dividerContainer}>
                <Divider />
              </Grid>
            )}
          </Grid>
          <Grid container xs={11} sm={11} md={11} lg={11} spacing={2} item>
            <Grid item className={Styles.dateGrid} xs={12} md={2} sm={6} lg={2}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.datecontainer}
              >
                <Typography className={Styles.dateContainerHeadingText}>
                  Date
                </Typography>
              </Grid>
              <Grid
                item
                container
                className={Styles.addDateRow}
                xs={12}
                sm={12}
                lg={12}
              >
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <Typography>
                      {moment(date).format("ddd, D MMM ")}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item className={Styles.dateGrid} xs={12} md={2} sm={6} lg={2}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.dateGrid}
              >
                <Typography className={Styles.dateContainerHeadingText}>
                  In Time*
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.dateIntimeContainer}
              >
                <Controller
                  name={`inTime-${addedDate}`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Typography className={Styles.dateIntimeText}>
                        <TimePicker
                          ampm={true}
                          {...field}
                          className={Styles.dateIntimeInput}
                          value={
                            watch(`inTime-${addedDate}`) &&
                            dayjs(watch(`inTime-${addedDate}`))
                          }
                          onChange={(value) =>
                            handleFieldChange(`inTime-${addedDate}`, value)
                          }
                        />
                      </Typography>
                      {fieldState?.error && (
                        <Typography color="error" className={Styles.errorText}>
                          {fieldState?.error?.message}
                        </Typography>
                      )}
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item className={Styles.dateGrid} xs={12} md={2} sm={6} lg={2}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.dateGrid}
              >
                <Typography className={Styles.dateContainerHeadingText}>
                  Out Time*
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.dateIntimeContainer}
              >
                <Controller
                  name={`outTime-${addedDate}`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={true}
                        {...field}
                        className={Styles.dateIntimeInput}
                        value={
                          watch(`outTime-${addedDate}`) &&
                          dayjs(watch(`outTime-${addedDate}`))
                        }
                        onChange={(value) =>
                          handleFieldChange(`outTime-${addedDate}`, value)
                        }
                      />
                      {fieldState?.error && (
                        <Typography color="error" className={Styles.errorText}>
                          {fieldState?.error?.message}
                        </Typography>
                      )}
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sm={6} lg={2} className={Styles.dateGrid}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.dateGrid}
              >
                <Typography className={Styles.dateContainerHeadingText}>
                  Total Hours
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} lg={12}>
                <Controller
                  name="totalHours"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="00:00:00"
                      variant="outlined"
                      fullWidth
                      disabled
                      value={watch(`totalHours-${addedDate}`)}
                      onChange={(value) =>
                        handleFieldChange(`totalHours-${addedDate}`, value)
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} className={Styles.dateGrid}>
              <Grid
                container
                xs={12}
                sm={12}
                lg={12}
                className={Styles.dateGrid}
              >
                <Typography className={Styles.dateContainerHeadingText}>
                  Reason*
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                className={Styles.reasondateContainer}
              >
                <Controller
                  name={`reason-${addedDate}`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <TextField
                        {...field}
                        placeholder="Write a Reason"
                        variant="outlined"
                        fullWidth
                        onChange={(e) =>
                          handleFieldChange(
                            `reason-${addedDate}`,
                            e.target.value
                          )
                        }
                      />
                      {fieldState?.error && (
                        <Typography color="error" className={Styles.errorText}>
                          {fieldState?.error ? fieldState?.error?.message : ""}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sm={1}
            md={1}
            xs={1}
            lg={1}
            className={Styles.DateContainerCloseIcon}
          >
            <Grid
              item
              md={1}
              sm={1}
              lg={1}
              xs={12}
              className={Styles.DateCloseIcon}
            >
              <CloseIcon onClick={() => handleCloseGridLine(addedDate)} />
            </Grid>
          </Grid>
        </Grid>
      );

      // reset();
      setSelectedDate((prevDate) => [...prevDate, addedDate]);
      setGridRows((prevRows) => [...prevRows, newRow]);
    }

    handleDateCalendarClose();
  };

  const handleOnSubmit = async (evt: any) => {
    const requestedDates = Object.keys(dateEntries).map((dateKey) => {
      const inTime =
        dateEntries[dateKey][`inTime-${dateKey}`]?.format("HH:mm:ss");
      const outTime =
        dateEntries[dateKey][`outTime-${dateKey}`]?.format("HH:mm:ss");
      const FromatedinTime = moment(`${dateKey} ${inTime}`);
      const FromatedoutTime = moment(`${dateKey} ${outTime}`);

      return {
        requestDate: dateKey,
        regularizeToTime: FromatedoutTime,
        regularizeReason: dateEntries[dateKey][`reason-${dateKey}`],
        regularizeFromTime: FromatedinTime,
        totalHours: dateEntries[dateKey][`totalHours-${dateKey}`],
      };
    });
    try {
      const input = {
        employeeId: userInfo.id,
        requestedDates: requestedDates,
        regularizeStatus: "PENDING",
        reportingManagerId: "",
        requestType: "Regularization",
      };
      const response = await createRegularization({
        variables: { input },
      });
      if (response.data) {
        Toast("success", "Regularization applied Successfully");
        onClose();
      }
    } catch (error: any) {
      console.error("Something went wrong", error);
      Toast("error", error?.message);
    }
  };

  const maxDate = dayjs();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{ className: Styles.props }}
        className={Styles.mainContainer}
      >
        <DialogTitle id="scroll-dialog-title">
          <Grid container alignItems="center" className={Styles.parent}>
            <Grid item container md={12} lg={12} sm={12} xs={12}>
              <Grid item md={9} lg={9} sm={5} xs={12}>
                <Typography variant="h6" className={Styles.Regularize}>
                  Regularize Attendance
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                lg={2}
                sm={4}
                xs={8}
                className={Styles.parentGridAddSectionDate}
              >
                <Typography className={Styles.AddDate}>
                  <Button
                    onClick={handleAddDateClick}
                    className={Styles.AddDateClick}
                  >
                    <AddIcon className={Styles.AddIcon} />
                    Add Date
                  </Button>
                </Typography>
              </Grid>
              <Grid
                item
                md={1}
                lg={1}
                sm={3}
                xs={2}
                className={Styles.closeButtonDiv}
              >
                <CloseIcon onClick={onClose} className={Styles.closeButton} />
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={Styles.overFlowY}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {gridRows.map((row) => row)}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            lg={12}
            sm={12}
            md={12}
            xs={12}
            // marginTop={2}
            // marginBottom={2}
            // spacing={2}
            className={Styles.buttonContainer}
          >
            <Grid item className={Styles.buttonGrid}>
              <CustomButtons
                variant="contained"
                onClick={onClose}
                className={Styles.cancelButton}
                name="Cancel"
              ></CustomButtons>
            </Grid>
            <Grid item className={Styles.buttonGrid}>
              <CustomButtons
                variant="contained"
                className={Styles.submitbButton}
                name="Submit"
                loading={createRegularizeLoading}
                disabled={createRegularizeLoading}
                loaderClass={Styles.buttonLoader}
                onClick={handleSubmit(handleOnSubmit)}
              ></CustomButtons>
            </Grid>
          </Grid>
        </DialogActions>
        <Popper
          open={isCalendarOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Paper className="PAPER">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BasicDatePicker
                open={isCalendarOpen}
                onClose={handleDateCalendarClose}
                // selectedDate={selectedDateInfo?.date}
                onChange={handleDateChange}
                className={Styles.datePicker}
                maxDate={maxDate}
              />
            </LocalizationProvider>
          </Paper>
        </Popper>
      </Dialog>
    </>
  );
};

export default Regularize;
