import CustomButtons from 'src/components/CustomButton/Button';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddDepartment from '../AddDepartments';
import SettingCustomHeader from 'src/components/SettingHeader';
import styles from 'src/Styles/Workplace.module.css';
import AddIcon from '@mui/icons-material/Add';
import { useQuery, useMutation } from '@apollo/client';
import { GET_DEPARTMENTS, DELETE_DEPARTMENT } from '../../graphql/departments';
import { Toast } from '../ToastMessage';
import CustomTable from '../TableComponent/CustomTable';
import Styles from 'src/Styles/EducationalDetails.module.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Departments = () => {
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)
  const { data, loading, error, refetch } = useQuery(GET_DEPARTMENTS, {
    variables: {
      offset, limit
    }

  });
  useEffect(() => {
    refetch()
  }, [refetch, offset, limit])
  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT, {
    refetchQueries: [
      GET_DEPARTMENTS, // DocumentNode object parsed with gql
      'getDepartments', // Query name
    ],
  });

  const [mopen, setOpen] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState(false);
  const [editData, setEditData] = React.useState({});

  const handleClickOpen = () => {
    setEditFlag(false);
    setOpen(true);
  };

  const onEdit = (data: any) => {
    setEditData(data);
    setEditFlag(true);
    setOpen(true);
  };

  const onDelete = async (id: any) => {
    const response = await deleteDepartment({
      variables: { departmentId: id },
    });
    if (response.data) {
      Toast('success', 'Department deleted Successfully');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'flex';
    }
  } else {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'none';
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid container item md={6} sm={6} xs={12}>
            <SettingCustomHeader
              header="Departments"
              paragraph="Add your office locations located around the world."
              className={styles.headerFooterCss}
            />
          </Grid>

          <Grid item container md={6} sm={6} xs={12} justifyContent={'flex-end'} className={styles.upperGrid_padding}>
            <CustomButtons
              icon={<AddIcon className={styles.customButton} />}
              name="Add Department"
              className={styles.add_button}
              onClick={handleClickOpen}
              variant="contained"
            />
          </Grid>
        </Grid>

        {data?.getDepartments && (
          <CustomTable
            data={data?.getDepartments?.departmentInfo || []}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={data?.getDepartments?.totalCount || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Department Name</Grid>,
                accessor: 'departmentName',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.departmentName}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Employee Count</Grid>,
                accessor: 'employeeCount',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.employeeCount}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Edit</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <EditIcon onClick={() => onEdit(item)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Delete</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <DeleteForeverIcon onClick={() => onDelete(item.id)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {mopen && (
          <AddDepartment onClose={handleClose} open={mopen} setOpen={setOpen} editFlag={editFlag} editData={editData} />
        )}
      </Grid>
    </>
  );
};

export default Departments;
