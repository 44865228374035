import React from "react";

export default function AuthLayout({ children }: any) {
  return (
    <div>
      {/* <MainContainer> */}
      {/* <Header /> */}
      <main className="main-content">{children}</main>
      {/* </MainContainer> */}
    </div>
  );
}
