import { Grid, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingCustomHeader from "src/components/SettingHeader";
import styles from "src/Styles/Workplace.module.css";
import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTable from "../TableComponent/CustomTable";
import Styles from "src/Styles/EducationalDetails.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GET_DOCUMENT_ADD } from "../../graphql/documentType";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";

const HrDocuments = () => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  // eslint-disable-next-line
  const { id } = useParams();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENT_ADD, {
    variables: { employeeID: userInfo.id, offset, limit },
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch])
  // eslint-disable-next-line
  const navigate = useNavigate();

  const [imgOpen, setImgOpen] = React.useState(false);
  const [imgData, setImgData] = React.useState<any>({});
  // eslint-disable-next-line
  const [isImage, setIsImage] = React.useState<any>({});
  const [documentName, setDocumentName] = React.useState();
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const img = new Image();
    img.src = imgData;

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  }, [imgData]);

  // const sortedDocuments = data?.getDocumentAdd?.sort((a: any, b: any) => {
  //   const dateA = new Date(a.modifiedOn).getTime();
  //   const dateB = new Date(b.modifiedOn).getTime();
  //   return dateB - dateA;
  // });

  const handleimgClose = () => {
    setImgOpen(false);
    setImgData({});
  };

  const handleViewClick = async (item: any) => {
    const isImage =
      item.file.includes("png") ||
      item.file.includes("jpeg") ||
      item.file.includes("jpg") ||
      item?.file?.includes("webp") ||
      item.file.includes("svg");
    setIsImage(isImage);
    setLoadingImg(true);
    setDocumentName(item.documentName);
    setImgOpen(true);
    setImageDimensions({ width: 0, height: 0 });
    const urlData = {
      id: item?.id,
      hostname: "localhost",
      key: item?.key,
    };
    const response = axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/getAssetById`,
      urlData
    );
    setImgData((await response).data);
    setLoadingImg(false);

  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }

  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
        //   justifyContent={"space-between"}
        //   alignItems={"center"}
        >
          <Grid
            container
            item
            md={6}
            sm={6}
            xs={12}
            className={styles.idProofsContainer}
          >
            <SettingCustomHeader header="DOCUMENTS" />
          </Grid>
        </Grid>

        {data?.getDocumentAdd && (
          <CustomTable
            data={data?.getDocumentAdd?.documentAddInfo || []}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={data?.getDocumentAdd?.totalCount || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Document Name</Grid>,
                accessor: "documentName",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.documentName}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Document Type</Grid>,
                accessor: "documentType",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.documentType}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Description</Grid>,
                accessor: "description",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.description}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Created By</Grid>,
                accessor: "createdBy",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.createdByName}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Date</Grid>,
                accessor: "uploadedDate",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>
                    {moment(item.modifiedOn).format("MMMM D, YYYY h:mm A")}
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Action</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <Tooltip
                      title="View documents"
                      onClick={() => handleViewClick(item)}
                      sx={{ cursor: "pointer" }}
                    >
                      <VisibilityIcon />
                    </Tooltip>
                  </Grid>
                ),
              },
            ]}
          />
        )}
      </Grid>

      {imgOpen && (
        <Modal
          onClose={handleimgClose}
          open={imgOpen}
          aria-labelledby="image-modal"
          aria-describedby="image-modal-description"
        >
          <Grid
            width={isImage ? imageDimensions.width : "100%"}
            className={styles.modalContainer}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={styles.modalContentContainer}
            >
              <Grid container className={styles.headerContainer}>
                <Grid item>
                  <Typography className={styles.headerText}>
                    {documentName || "Document Details"}
                  </Typography>
                </Grid>
                <Grid item>
                  <CloseOutlinedIcon
                    onClick={handleimgClose}
                    className={styles.closeIcon}
                  />
                </Grid>
              </Grid>
              {loadingImg ? (
                <Grid item>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {isImage ? (
                    <img
                      src={imgData}
                      alt="Modal"
                      className={styles.imgContainer}
                    />
                  ) : (
                    <iframe
                      src={imgData}
                      title="doc"
                      width="100%"
                      height="700px"
                      scrolling="no"
                      frameBorder={0}
                    ></iframe>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default HrDocuments;
