// AddTaskModalBox.js

import React from "react";
import ModalBox from "../../ReusableModal/modal";
import {
  Divider,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Styles from "src/Styles/AddTaskModalBox.module.css";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "src/components/TextField/InputField";
import { useForm } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";
import NewCategoryModalBox from "src/components/DashboardCards/ModalBox/NewCategorModalBox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import Calendar from "@mui/icons-material/Event";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const AddTaskModalBox = ({ openTaskModal, toggleTaskModal }: any) => {
  const handleCancel = () => {
    toggleTaskModal();
  };

  const handleSubmit = () => {
    // Handle submit logic here
  };

  const [openCategoryModal, setOpenCategoryModal] = React.useState(false);

  const toggleCategoryModal = () => {
    setOpenCategoryModal(!openCategoryModal);
  };

  const WorkInformation = ({ control }: any) => {
    return (
      <ModalBox
        open={openTaskModal}
        onClose={toggleTaskModal}
        content={
          <Grid container className={Styles.Modal_Content}>
            <Grid container item className={Styles.ModalBox}>
              <Grid item md={11}>
                <Typography className={Styles.New_Task}>New Task</Typography>
              </Grid>
              <Grid item md={1} className={Styles.close_Btn_Grid}>
                <IconButton
                  className={Styles.Close}
                  sx={{ fontSize: 30 }}
                  onClick={toggleTaskModal}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container className={Styles.Body_Grid}>
              <Grid item md={12} className={Styles.Divider}>
                <Divider />
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <TextField
                    size="medium"
                    label="Title"
                    variant="outlined"
                    className={Styles.Title_Input}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.Upload_File}>
                  <InputField type="file" size="medium" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.DroaDown_1}>
                  <TextField
                    size="medium"
                    label="Assigne Name*"
                    variant="outlined"
                    className={Styles.Title_Input}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.DroaDown_1}>
                  <FormControl fullWidth>
                    {/* <FormControl> */}
                    <InputLabel id="demo-simple-select-label">
                      Category *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Leave Type"
                    >
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="clent-feedback">
                        Client Feedback
                      </MenuItem>
                      <MenuItem value="hign-priority">High Priority</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="tracex">Tracex</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.New_catg_btn}>
                  <CustomButtons
                    name="+ New Category"
                    onClick={toggleCategoryModal}
                    className={Styles.New_Ctg_Btn}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.Date_Range_Picker}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      className={Styles.dateInput}
                      slots={{ field: SingleInputDateRangeField }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: <Calendar />,
                          },
                        },
                      }}
                      calendars={1}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container className={Styles.Time_Range_Picker}>
                <Grid item md={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker ampm={false} />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker ampm={false} />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={12} className={Styles.Remarks_Inputs}>
                  <TextField
                    fullWidth
                    multiline
                    label="Remarks *"
                    rows={4}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={Styles.Footer}>
              <Grid item md={12} className={Styles.Divider2}>
                <Divider />
              </Grid>
              <Grid item md={2}>
                <CustomButtons
                  variant="text"
                  name="Cancel"
                  onClick={handleCancel}
                  className={Styles.Cancel_Btn}
                />
              </Grid>
              <Grid item md={2}>
                <CustomButtons
                  variant="contained"
                  name="Submit"
                  onClick={handleSubmit}
                  className={Styles.Submit_Btn}
                />
              </Grid>
            </Grid>
            {openCategoryModal && (
              <NewCategoryModalBox
                open={openCategoryModal}
                onClose={toggleCategoryModal}
              />
            )}
          </Grid>
        }
      />
    );
  };

  const methods = useForm();

  return <WorkInformation control={methods.control} />;
};

export default AddTaskModalBox;
