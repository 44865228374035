import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import SettingCustomHeader from "src/components/SettingHeader";
import styles from "src/Styles/Workplace.module.css";
import { useQuery } from "@apollo/client";
import CustomTable from "../TableComponent/CustomTable";
import Styles from "src/Styles/EducationalDetails.module.css";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { useNavigate } from "react-router-dom";
import { GET_ALL_USERS_BY_PORTAL } from "src/graphql/user";
import SearchBox from "../Search";
import { debounce } from "src/utils/debounce";

const DocumentOrg = () => {
  // eslint-disable-next-line
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  // eslint-disable-next-line
  const [mopen, setOpen] = useState(false);
  const [userss, setUserss] = useState(null);
  const [allSearch, setAllSearch] = useState("");
  const [searchbyField, setSearchByField] = useState<any>({});
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS_BY_PORTAL, {
    variables: {
      input:
        Object.keys(searchbyField).length !== 0
          ? {
            displayName: searchbyField?.displayName,
            email: searchbyField?.email,
            role: searchbyField?.role,
            offset,
            limit
          }
          : {
            searchString: allSearch,
            offset,
            limit
          },
    },
  });

  const navigate = useNavigate();

  const handleClickOpen = (employeeID: any) => {
    navigate(`/admin/hrms/admin-documents/view-documents/${employeeID}`);
  };
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch]);

  useEffect(() => {
    const userData = data?.getUsersByPortal?.users || [];
    setUserss(userData);
  }, [data]);

  // Debounce the search function
  const debouncedSearch = debounce((searchValue: string) => {
    setAllSearch(searchValue);
    // refetch();
  }, 500);

  const requestSearch = async (evt: any) => {
    setSearchByField("");

    const searchValue = evt.target.value;

    debouncedSearch(searchValue);
  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid container item md={6} sm={6} xs={12}>
            <SettingCustomHeader
              header="Employee Documents Space"
              paragraph="Create employees personal documents
                that will be available to each employee from the &ldquo;My
                Documents&rdquo; section."
            />
          </Grid>

          {/* <Grid
            item
            container
            md={6}
            sm={6}
            xs={12}
            justifyContent={"flex-end"}
            className={styles.upperGrid_padding}
          ></Grid> */}
        </Grid>
        <Grid
          item
          xs={8}
          md={6}
          sm={6}
          lg={3.5}
          xl={3.5}
          container
          justifyContent={"flex-start"}
          className={styles.SearchContainer}
        >
          <SearchBox
            data={[]}
            customStyle={styles.searchBox}
            handleChange={requestSearch}
            borderRadius="30px"
            placeholder="Search"
            size="small"
          />
        </Grid>
        {userss && (
          <CustomTable
            data={(data && data?.getUsersByPortal.users) || []}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={(data && data?.getUsersByPortal.totalCount) || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Employee Name</Grid>,
                accessor: "jobTitle",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.displayName}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Email</Grid>,
                accessor: "employeeCount",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.email}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Role</Grid>,
                accessor: "employeeCount",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.role}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Action</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <Typography
                      onClick={() => {
                        handleClickOpen(item.id);
                      }}
                      // onClick ={handleClickOpen}
                      sx={{ cursor: "pointer", color: "#335aff" }}
                    >
                      View Documents
                    </Typography>
                  </Grid>
                ),
              },
            ]}
          />
        )}
      </Grid>
    </>
  );
};

export default DocumentOrg;
