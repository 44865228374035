import React, { useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_EMPLOYEE_BY_QUERY_TYPE } from "src/graphql/user";
import CustomTextField from "src/components/CustomTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { addQualificationSchema } from "src/utils/validations";
import CustomButtons from "src/components/CustomButton/Button";
import { Toast } from "src/components/ToastMessage";
import ModalBox from "src/components/ReusableModal/modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styles from "../../Styles/EmergencyContact.module.css";
import CustomDatePicker from "../customDatePicker";
import dayjs from "dayjs";

const AddQualificationModal = ({
  editEmpRecord,
  editEmpRefetch,
  onClick,
  open,
  onClose,
  selectedItem,
}: any) => {
  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeData, loading: updateEmployeeLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  useEffect(() => {
    if (selectedItem) {
      const FormatedEditEmployeeData = {
        degree: selectedItem?.degree || "",
        university: selectedItem?.university || "",
        fromDate: selectedItem?.fromDate || "",
        toDate: selectedItem?.toDate || "",
        location: selectedItem?.location || "",
      };
      reset(FormatedEditEmployeeData);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  const {
    control,
    // eslint-disable-next-line
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ resolver: yupResolver(addQualificationSchema) });

  const handleUpdateQualificationsDetails = async (data: any) => {
    try {
      if (!selectedItem) {
        const addUserData = {
          degree: data.degree,
          university: data.university,
          fromDate: data.fromDate,
          toDate: data.toDate,
          location: data.location,
        };
        const updateResponse: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "addQualification",
              qualificationDetails: addUserData,
            },
          },
        });
        if (updateResponse) {
          editEmpRefetch();
          Toast("success", "Qualification Details created Successfully");
          onClose();
        }
      } else {
        const editUserData = {
          id: selectedItem?.id,
          degree: data.degree,
          university: data.university,
          fromDate: data.fromDate,
          toDate: data.toDate,
          location: data.location,
        };
        const updateResponse: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "editQualification",
              qualificationDetails: editUserData,
            },
          },
        });
        if (updateResponse) {
          editEmpRefetch();
          Toast("success", "Qualification Details updated Successfully");
          onClose();
        }
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };
  const handleCancelClick = () => {
    onClose();
  };

  const maxDate = dayjs();

  return (
    <>
      <ModalBox
        // onClick={onClick}
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        height={600}
        content={
          <Grid
            className={styles.maincontainer}
            component="form"
            onSubmit={handleSubmit(handleUpdateQualificationsDetails)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <Grid className={styles.headerContainer}>
              <Grid>
                <Typography className={styles.headerText}>
                  {selectedItem ? "Edit" : "Add"} Qualifications Details
                </Typography>
              </Grid>
              <Grid>
                <CloseOutlinedIcon
                  onClick={onClose}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid className={styles.mainWrapper}>
              <Grid
                container
                mt={3}
                mb={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
              >
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Degree</Typography>
                  <CustomTextField
                    error={Boolean(errors.degree)}
                    errorCondition={
                      errors.degree && (
                        <Typography className={styles.errorMsg}>
                          {errors.degree.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="degree"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Degree"
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    University
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.university)}
                    errorCondition={
                      errors.university && (
                        <Typography className={styles.errorMsg}>
                          {errors.university.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="university"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="University"
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>From</Typography>
                  <CustomDatePicker
                    error={Boolean(errors.fromDate)}
                    errorCondition={
                      errors.fromDate && (
                        <Typography className={styles.errorMsg}>
                          {errors.fromDate.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="fromDate"
                    className={styles.datefieldContainer}
                    monthYearOnly={true}
                    selectedDate={selectedItem?.fromDate}
                    maxDate={maxDate}
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>To</Typography>
                  <CustomDatePicker
                    error={Boolean(errors.toDate)}
                    errorCondition={
                      errors.toDate && (
                        <Typography className={styles.errorMsg}>
                          {errors.toDate.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="toDate"
                    className={styles.datefieldContainer}
                    monthYearOnly={true}
                    selectedDate={selectedItem?.toDate}
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Location</Typography>
                  <CustomTextField
                    error={Boolean(errors.location)}
                    errorCondition={
                      errors.location && (
                        <Typography className={styles.errorMsg}>
                          {errors.location.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="location"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Location"
                  />
                </Grid>
              </Grid>
              <Grid className={styles.btnContainer}>
                <CustomButtons
                  name="Cancel"
                  variant="contained"
                  className={styles.cancelBtn}
                  onClick={handleCancelClick}
                />
                <CustomButtons
                  name={selectedItem ? "Edit" : "Add"}
                  variant="contained"
                  className={styles.createBtn}
                  type="submit"
                  loading={updateEmployeeLoading}
                  disabled={updateEmployeeLoading}
                  loaderClass={styles.buttonLoader}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default AddQualificationModal;
