import React, { useEffect, useState } from "react";
import styles from "src/Styles/addEmployee.module.css";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomButtons from "src/components/CustomButton/Button";
import { useForm } from "react-hook-form";
import CustomSelect from "src/components/CustomSelect/customSelectValidations";
import professionalDetail from "./ProfessionalData.json";
import { Toast } from "src/components/ToastMessage";
import { UPDATE_EMPLOYEE_BY_QUERY_TYPE } from "src/graphql/user";
import { useMutation } from "@apollo/client";
import tableStyle from "../../../Styles/commonTable.module.css";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "src/components/TableComponent";
import moment from "moment";
import MenuItems from "src/components/MenuItems/MenuItems";
import AddExperienceModal from "src/components/AddExperienceModal";
import perInfoData from "../PersonalInfoData/PersonalInfoData.json";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ProfessionalDetails = ({ editEmpRecord, editEmpRefetch }: any) => {
  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeRes, loading: updateEmployeeResLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  useEffect(() => {
    const FormatedEditEmployeeData = {
      yearOfExperience: editEmpRecord?.professionalExperience?.year || "",
      experienceMonths: editEmpRecord?.professionalExperience?.month || "",
    };
    reset(FormatedEditEmployeeData);
    // eslint-disable-next-line
  }, [editEmpRecord]);

  useEffect(() => {
    const empData =
      editEmpRecord?.professionalExperience?.skillsDetails?.map(
        (item: any) => ({
          skill: item?.skill,
          proficiency: item?.proficiency,
          yearsOfExperience: item?.yearsOfExperience,
          monthsOfExperience: item?.monthsOfExperience,
        })
      ) ?? [];

    setSkillsData(
      empData.length
        ? empData
        : [
            {
              skill: "",
              proficiency: "",
              yearsOfExperience: "",
              monthsOfExperience: "",
            },
          ]
    );

    const defaultSkillsData: any = {};

    empData?.forEach((item: any, index: number) => {
      defaultSkillsData[`skillsData[${index}].skill`] = item?.skill;
      defaultSkillsData[`skillsData[${index}].proficiency`] = item?.proficiency;
      defaultSkillsData[`skillsData[${index}].yearsOfExperience`] =
        item?.yearsOfExperience;
      defaultSkillsData[`skillsData[${index}].monthsOfExperience`] =
        item?.monthsOfExperience;
    });

    tableReset(defaultSkillsData);
  }, [editEmpRecord]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [skillsData, setSkillsData] = useState<any>([]);

  const cleanSkillsData = skillsData.filter((item: any) => item !== undefined);

  const skillsSchema = yup.object().shape({
    skillsData: yup.array().of(
      yup.object().shape({
        skill: yup.string().required("Skill is required"),
        proficiency: yup.string().required("Proficiency is required"),
        yearsOfExperience: yup
          .string()
          .required("Years of experience is required"),
        monthsOfExperience: yup
          .string()
          .required("Months of experience is required"),
      })
    ),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({});

  const {
    control: tableControl,
    reset: tableReset,
    handleSubmit: tableHandleSubmit,
    formState: { errors: tableErrors },
    getValues: tableGetValues,
    clearErrors: tableClearErrors,
    watch: tableWatch,
  }: any = useForm({
    shouldUnregister: true,
    resolver: yupResolver(skillsSchema),
  });

  const skillsFormValues = tableWatch();

  const handleUpdateProfessionalInfo = async (data: any) => {
    try {
      const userData: any = {
        queryType: "professionalExperience",
        professionalExperience: {
          // you can send multiple fields here
          year: data.yearOfExperience,
          month: data.experienceMonths,
        },
      };

      if (skillsFormValues?.skillsData?.length) {
        userData.professionalExperience.skillsDetails =
          skillsFormValues?.skillsData?.filter(
            (item: any) =>
              item !== null && item.skill !== null && item.skill !== ""
          );
      } else {
        userData.professionalExperience.skillsDetails = [];
      }

      const updateResponse: any = await updateEmployeeByQueryType({
        variables: {
          updateEmployeeByQueryTypeId: editEmpRecord?.id,
          input: userData,
        },
      });

      if (updateResponse) {
        editEmpRefetch();
        Toast("success", "Professional Experience updated Successfully");
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  const addExperienceModalOpen = () => {
    setSelectedItem(null);
    setOpenModal(!openModal);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleMenuClick = async (item: any, event: any) => {
    if (event.target.textContent === "Edit") {
      setSelectedItem(item);
      setOpenModal(!openModal);
    } else if (event.target.textContent === "Delete") {
      try {
        const response: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "deleteExperience",
              experienceDetails: { id: item.id },
            },
          },
        });
        if (response.data) {
          Toast("success", "Experience details deleted Successfully");
          editEmpRefetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  const addSkillRow = async (data: any) => {
    const filteredSkillsData = data?.skillsData?.filter((item: any) => item);

    setSkillsData((prevData: any) => [
      ...filteredSkillsData,
      {
        skill: "",
        proficiency: "",
        yearsOfExperience: "",
        monthsOfExperience: "",
      },
    ]);

    tableReset({
      skillsData: [
        ...tableGetValues().skillsData,
        {
          skill: "",
          proficiency: "",
          yearsOfExperience: "",
          monthsOfExperience: "",
        },
      ],
    });
  };

  const removeSkillRow = (item: any, index: number) => {
    setSkillsData((prevData: any) => {
      let updatedData = [...prevData];
      updatedData.splice(index, 1);

      tableReset({
        skillsData: tableGetValues().skillsData.filter(
          (item: any, i: any) => i !== index
        ),
      });
      return updatedData;
    });
  };

  return (
    <>
      <Grid
        container
        md={12}
        sm={12}
        lg={12}
        xl={12}
        xs={12}
        className={styles.createEmployeeContainer}
      >
        <Grid
          container
          className={styles.infoWraper}
          component="form"
          onSubmit={handleSubmit(handleUpdateProfessionalInfo)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <Grid container>
            <Grid container mb={3} className={styles.headerGrid}>
              <Grid>
                <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                  <Typography className={styles.subHeading}>
                    Professional Experience
                  </Typography>
                </Grid>
                <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                  <Typography className={styles.subText}>
                    Here are all the employees at your company.
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <CustomButtons
                  name="Update"
                  variant="contained"
                  className={styles.createBtn}
                  type="submit"
                  loading={updateEmployeeResLoading}
                  disabled={updateEmployeeResLoading}
                  loaderClass={styles.buttonLoader}
                />
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid container>
                <Typography variant="caption" className={styles.formLabel}>
                  Overall years of exp (As of joining date)
                </Typography>
                <Grid
                  container
                  mt={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
                >
                  <Grid
                    item
                    lg={2}
                    md={4}
                    sm={6}
                    xs={12}
                    className={styles.formGrid}
                  >
                    <Typography className={styles.formLabel}>Years</Typography>
                    <CustomSelect
                      error={Boolean(errors.noticePeriod)}
                      errorCondition={
                        errors.noticePeriod && (
                          <Typography color="error">
                            Please Select a value
                          </Typography>
                        )
                      }
                      control={control}
                      name="yearOfExperience"
                      selectData={professionalDetail.yearsOfExperience}
                      placeHolder="Years"
                      selectFieldCss={styles.selectField}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={4}
                    sm={6}
                    xs={12}
                    className={styles.formGrid}
                  >
                    <Typography className={styles.formLabel}>Months</Typography>
                    <CustomSelect
                      error={Boolean(errors.noticePeriod)}
                      errorCondition={
                        errors.noticePeriod && (
                          <Typography color="error">
                            Please Select a value
                          </Typography>
                        )
                      }
                      control={control}
                      name="experienceMonths"
                      selectData={professionalDetail.months}
                      placeHolder="Months"
                      selectFieldCss={styles.selectField}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={styles.headerGrid}>
              <Grid>
                <SettingCustomHeader
                  header="Experience"
                  paragraph=" Here are all the employees at your company"
                />
              </Grid>
              <Grid>
                <CustomButtons
                  name="Add Experience"
                  variant="outlined"
                  icon={<AddIcon />}
                  className={styles.createBtn}
                  onClick={addExperienceModalOpen}
                />
              </Grid>
            </Grid>
            <Grid container md={12} mt={3} mb={3}>
              <CustomTable
                tableHeader={tableStyle.tableHeader}
                tableContainerClass={styles.tableContainerExpDiv}
                data={
                  editEmpRecord?.professionalExperience?.experienceDetails || []
                }
                hidePagination={true}
                columns={[
                  {
                    Header: (
                      <Grid className={tableStyle.tableHead} minWidth={170}>
                        Employer Name
                      </Grid>
                    ),
                    Cell: ({ item, index }: any) => {
                      return (
                        <Grid className={tableStyle.cell}>
                          {item.employerName || "—"}
                        </Grid>
                      );
                    },
                  },
                  {
                    Header: (
                      <Grid className={tableStyle.tableHead} minWidth={170}>
                        Job Designation
                      </Grid>
                    ),
                    Cell: ({ item, index }: any) => {
                      return (
                        <Grid className={tableStyle.cell}>
                          {item.jobDesignation || "—"}
                        </Grid>
                      );
                    },
                  },
                  {
                    Header: (
                      <Grid className={tableStyle.tableHead} minWidth={170}>
                        Start Date
                      </Grid>
                    ),
                    Cell: ({ item, index }: any) => {
                      return (
                        <Grid className={tableStyle.cell}>
                          {(item.startDate &&
                            moment(item.startDate).format("MMM YYYY")) ||
                            "—"}
                        </Grid>
                      );
                    },
                  },
                  {
                    Header: (
                      <Grid className={tableStyle.tableHead} minWidth={170}>
                        End Date
                      </Grid>
                    ),
                    Cell: ({ item, index }: any) => {
                      return (
                        <Grid className={tableStyle.cell}>
                          {(item.endDate &&
                            moment(item.endDate).format("MMM YYYY")) ||
                            "—"}
                        </Grid>
                      );
                    },
                  },
                  {
                    Header: (
                      <Grid className={tableStyle.tableHead} minWidth={170}>
                        Location
                      </Grid>
                    ),
                    Cell: ({ item, index }: any) => {
                      return (
                        <Grid className={tableStyle.cell}>
                          {item.location || "—"}
                        </Grid>
                      );
                    },
                  },
                  {
                    Header: "",
                    Cell: ({ item, index }: any) => {
                      return (
                        <MenuItems
                          options={["Edit", "Delete"]}
                          onViewDetails={(e: any) => handleMenuClick(item, e)}
                          // itemStatus={item.status}
                        />
                      );
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
          {/* <Grid container className={styles.editBtnContainer}>
            <CustomButtons
              name="Update"
              variant="contained"
              className={styles.createBtn}
              type="submit"
            />
          </Grid> */}
        </Grid>
        <Grid container className={styles.infoWraper}>
          <Grid container className={styles.headerGrid}>
            <Grid>
              <SettingCustomHeader
                header="Skills"
                paragraph=" Here are all the employees at your company"
              />
            </Grid>
          </Grid>
          <Grid
            onSubmit={tableHandleSubmit(addSkillRow)}
            component="form"
            container
            md={12}
            mt={3}
            mb={3}
          >
            <CustomTable
              tableHeader={tableStyle.tableHeader}
              tableContainerClass={styles.tableContainerSkillDiv}
              data={cleanSkillsData || []}
              hidePagination={true}
              columns={[
                {
                  Header: <Grid className={tableStyle.tableHead}>Skill</Grid>,
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid className={tableStyle.cell} key={`skill-${index}`}>
                        <CustomSelect
                          error={Boolean(
                            tableErrors?.skillsData?.[index]?.skill
                          )}
                          errorCondition={
                            tableErrors?.skillsData?.[index]?.skill && (
                              <Typography className={styles.errorMsg}>
                                {
                                  tableErrors?.skillsData?.[index]?.skill
                                    ?.message
                                }
                              </Typography>
                            )
                          }
                          control={tableControl}
                          name={`skillsData[${index}].skill`}
                          selectData={professionalDetail.skills}
                          placeHolder="Skill"
                          selectFieldCss={styles.skillSelectField}
                        />
                      </Grid>
                    );
                  },
                  className: `${styles.skillTableCell}`,
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead}>Proficiency</Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid
                        className={tableStyle.cell}
                        key={`proficiency-${index}`}
                      >
                        <CustomSelect
                          error={Boolean(
                            tableErrors?.skillsData?.[index]?.proficiency
                          )}
                          errorCondition={
                            tableErrors?.skillsData?.[index]?.proficiency && (
                              <Typography className={styles.errorMsg}>
                                {
                                  tableErrors?.skillsData?.[index]?.proficiency
                                    ?.message
                                }
                              </Typography>
                            )
                          }
                          control={tableControl}
                          name={`skillsData[${index}].proficiency`}
                          selectData={professionalDetail.proficiency}
                          placeHolder="Proficiency"
                          selectFieldCss={styles.skillSelectField}
                        />
                      </Grid>
                    );
                  },
                  className: `${styles.skillTableCell}`,
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={110}>
                      Years of Exp
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid
                        className={tableStyle.cell}
                        key={`yearsOfExperience-${index}`}
                      >
                        <CustomSelect
                          error={Boolean(
                            tableErrors?.skillsData?.[index]?.yearsOfExperience
                          )}
                          errorCondition={
                            tableErrors?.skillsData?.[index]
                              ?.yearsOfExperience && (
                              <Typography className={styles.errorMsg}>
                                {
                                  tableErrors?.skillsData?.[index]
                                    ?.yearsOfExperience?.message
                                }
                              </Typography>
                            )
                          }
                          control={tableControl}
                          name={`skillsData[${index}].yearsOfExperience`}
                          selectData={professionalDetail.yearsOfExperience}
                          placeHolder="Years of experience"
                          selectFieldCss={styles.skillSelectField}
                        />
                      </Grid>
                    );
                  },
                  className: `${styles.skillTableCell}`,
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={120}>
                      Months of Exp
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid
                        className={tableStyle.cell}
                        key={`monthsOfExperience-${index}`}
                      >
                        <CustomSelect
                          error={Boolean(
                            tableErrors?.skillsData?.[index]?.monthsOfExperience
                          )}
                          errorCondition={
                            tableErrors?.skillsData?.[index]
                              ?.monthsOfExperience && (
                              <Typography className={styles.errorMsg}>
                                {
                                  tableErrors?.skillsData?.[index]
                                    ?.monthsOfExperience?.message
                                }
                              </Typography>
                            )
                          }
                          control={tableControl}
                          name={`skillsData[${index}].monthsOfExperience`}
                          selectData={professionalDetail.months}
                          placeHolder="Months of experience"
                          selectFieldCss={styles.skillSelectField}
                        />
                      </Grid>
                    );
                  },
                  className: `${styles.skillTableCell}`,
                },
                {
                  Header: "",
                  Cell: ({ item, index }: any) => {
                    const isLastRow = index === cleanSkillsData.length - 1;
                    return (
                      <Grid item md={12} className={styles.addCircleDiv}>
                        {editEmpRecord?.professionalExperience?.skillsDetails
                          .length === 0 && cleanSkillsData.length === 1 ? (
                          <AddCircle
                            className={styles.addCircle}
                            onClick={() => tableHandleSubmit(addSkillRow)()}
                          />
                        ) : isLastRow ? (
                          <>
                            <RemoveCircle
                              className={styles.addCircle}
                              onClick={() => removeSkillRow(item, index)}
                            />

                            <AddCircle
                              className={styles.addCircle}
                              onClick={() => tableHandleSubmit(addSkillRow)()}
                            />
                          </>
                        ) : (
                          <>
                            {item?.skill ? (
                              <RemoveCircle
                                className={styles.addCircle}
                                onClick={() => removeSkillRow(item, index)}
                              />
                            ) : (
                              <AddCircle
                                className={styles.addCircle}
                                onClick={() => tableHandleSubmit(addSkillRow)()}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
        {openModal && (
          <AddExperienceModal
            onClose={handleModalClose}
            open={openModal}
            editEmpRecord={editEmpRecord}
            editEmpRefetch={editEmpRefetch}
            selectedItem={selectedItem}
          />
        )}
      </Grid>
    </>
  );
};

export default ProfessionalDetails;
