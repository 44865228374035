import { configureStore } from "@reduxjs/toolkit";
import DemoSlice from "./DemoSlice";
import Orgslice from "src/store/OrganizationSlice";
// import TimerSlice from "src/store/TimerSlice/index";
import ClockInSlice from "src/store/ClockInSlice/index";
import LeaveSlice from "src/store/LeaveSlice/index";
import AddEmployeeSlice from "src/store/AddEmployeeSlice/index";
import UserSlice from "src/store/UserSlice/index";
import TaskSlice from "./TaskSlice";
export const store = configureStore({
  reducer: {
    demoSlice: DemoSlice,
    organizationSlice: Orgslice,
    // timerSlice: TimerSlice,
    ClockInSlice: ClockInSlice,
    LeaveSlice: LeaveSlice,
    AddEmployeeSlice: AddEmployeeSlice,
    userSlice: UserSlice,
    TaskSlice: TaskSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
