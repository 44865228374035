import "react-toastify/dist/ReactToastify.css";
import { Grid } from "@mui/material";
import styles from "src/Styles/DashboardPage.module.css";
import DashboardCards from "src/components/DashboardCards";

const Dashboard = () => {
  return (
    <Grid item xs={12} sm={12} md={12} className={styles.mainContainer}>
      <DashboardCards />
    </Grid>
  );
};

export default Dashboard;
