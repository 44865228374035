import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, TextareaAutosize, Box } from "@mui/material";
import styles from "src/Styles/addDoc.module.css";
import Divider from "@mui/material/Divider";
import ModalBox from "src/components/ReusableModal/modal";
import CustomButtons from "src/components/CustomButton/Button";
import { Controller } from "react-hook-form";

import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_DOCUMENT_ADD,
  GET_ALLDOCUMENT_TYPE,
  GET_DOCUMENT_ADD,
} from "../../graphql/documentType";
import CloseIcon from "@mui/icons-material/Close";
import CustomSelect from "../CustomSelect/customSelectValidations";
import InputField from "../TextField/InputField";
import CustomTextField from "../CustomTextField";
import CustomTextareaAutosize from "../CustomTextareaAutosize";

const AddDocument = ({
  onClick,
  open,
  onClose,
  handleDocSubmit,
  handleDocFormSubmit,
  handleSelectedFile,
  docControl,
  docErrors,
  uploadDocLoader,
}: any) => {
  const { data: documentTypeData } = useQuery(GET_ALLDOCUMENT_TYPE, {
    variables: {
      limit: 0,
      offset: 0
    }
  });
  const documentTypeDropdown = documentTypeData?.getAllDocumentType?.documentInfo?.map(
    (item: any) => ({
      name: item?.documentType,
      id: item?.id,
      type: item?.documentType,
    })
  );

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        content={
          <Grid className={styles.modalContent}>
            <Box className={styles.headerContainer}>
              <Grid>
                <Typography className={styles.headerText}>
                  Add Document
                </Typography>
                <Typography className={styles.headerTextSub}>
                  Create new Document Type by using different locations to
                  arrange employees geographically
                </Typography>
              </Grid>
              <Grid mb={2}>
                <CloseIcon onClick={onClose} className={styles.closeIcon} />
              </Grid>
            </Box>
            <Divider />
            <form onSubmit={handleDocFormSubmit(handleDocSubmit)}>
              <Grid container className={styles.mainWrapper}>
                <Grid
                  sm={12}
                  md={12}
                  xs={12}
                  lg={12}
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Document Name
                  </Typography>
                  <CustomTextField
                    error={Boolean(docErrors.documentName)}
                    errorCondition={
                      docErrors.documentName && (
                        <Typography className={styles.errorMsg}>
                          {docErrors.documentName.message}
                        </Typography>
                      )
                    }
                    control={docControl}
                    name="documentName"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Document Name"
                  />
                </Grid>
                <Grid
                  sm={12}
                  md={12}
                  xs={12}
                  lg={12}
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Document Type
                  </Typography>
                  <CustomSelect
                    error={Boolean(docErrors.documentType)}
                    errorCondition={
                      docErrors.documentType && (
                        <Typography className={styles.errorMsg}>
                          {docErrors.documentType.message}
                        </Typography>
                      )
                    }
                    control={docControl}
                    name="documentType"
                    selectData={documentTypeDropdown || []}
                    placeHolder="Document Type"
                    selectFieldCss={styles.selectField}
                    isTitleRequired={true}
                  />
                </Grid>
                <Grid
                  sm={12}
                  md={12}
                  xs={12}
                  lg={12}
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Description
                  </Typography>
                  <CustomTextareaAutosize
                    error={Boolean(docErrors.description)}
                    errorCondition={
                      docErrors.description && (
                        <Typography className={styles.errorMsg}>
                          {docErrors.description.message}
                        </Typography>
                      )
                    }
                    control={docControl}
                    name="description"
                    fullWidth={true}
                    className={styles.textArea}
                    placeholder="Description"
                  />
                </Grid>
                <Grid
                  sm={12}
                  md={12}
                  xs={12}
                  lg={12}
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel} pb={1}>
                    Upload File (
                    <span style={{ fontWeight: "normal" }}>
                      Jpeg, Jpg, Png, SVG, Pdf, Webp
                    </span>
                    )
                  </Typography>
                  <Controller
                    control={docControl}
                    name="file"
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputField
                          type="file"
                          size="medium"
                          // label="Upload Document"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleSelectedFile(event)}
                          className={styles.fileFieldContainer}
                          error={docErrors.file !== undefined}
                        //   helperText={
                        //     docErrors.file ? (
                        //       <Typography className={styles.errorMsg}>
                        //         {docErrors.file.message}
                        //       </Typography>
                        //     ) : (
                        //       ""
                        //     )
                        //   }
                        />
                      </>
                    )}
                  />
                  {docErrors.file && (
                    <Typography className={styles.errorMsg}>
                      {docErrors.file.message}
                    </Typography>
                  )}
                </Grid>
                <Grid container className={styles.buttonDiv}>
                  <CustomButtons
                    name={"Add Document"}
                    type="submit"
                    variant="contained"
                    className={styles.button}
                    loading={uploadDocLoader}
                    disabled={uploadDocLoader}
                    loaderClass={styles.buttonLoader}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
    </>
  );
};

export default AddDocument;
