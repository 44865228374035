import gql from "graphql-tag";

export const GET_WORK_FROM_HOME_BY_ID = gql`
  query Query($filterStatus: String, $employeeId: ID, $approverId: ID,$offset:Int,$limit:Int) {
    getWorkFromHomeById(
      filterStatus: $filterStatus
      employeeId: $employeeId
      approverId: $approverId
      offset:$offset
      limit:$limit
    ) {
      workFromHomeInfo{
      id
      employeeId
      allowanceId
      employeeCode
      designation
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      wfhType
      createdOn
      modifiedOn
      reportingManagerId
      noOfDaysApplied
      username
      requestType
      approver {
        profileImg
        name
        employeeCode
      }
      approvedDate  
      }
      totalCount
    }
  }
`;

export const CREATE_WORK_FROM_HOME_BY_ID = gql`
  mutation Mutation($input: CreateWorkFromHomeInput!) {
    createWorkFromHome(input: $input) {
      id
      employeeId
      allowanceId
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      wfhType
      createdOn
      modifiedOn
      reportingManagerId
      noOfDaysApplied
      username
      requestType
    }
  }
`;

export const UPDATE_WORK_FROM_HOME_BY_ID = gql`
  mutation Mutation(
    $updateWorkFromHomeId: ID!
    $input: CreateWorkFromHomeInput!
  ) {
    updateWorkFromHome(id: $updateWorkFromHomeId, input: $input) {
      id
      employeeId
      allowanceId
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      wfhType
      createdOn
      modifiedOn
      reportingManagerId
      noOfDaysApplied
      username
      requestType
      approver {
        profileImg
        name
        employeeCode
      }
      approvedDate
    }
  }
`;

export const BULK_APPROVE_REJECT_WFH = gql`
  mutation BulkApproveRejectWorkFromHome(
    $updates: [bulkApproveRejectWorkFromHomeInput!]!
  ) {
    bulkApproveRejectWorkFromHome(updates: $updates) {
      id
      employeeId
      allowanceId
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      wfhType
      createdOn
      modifiedOn
      reportingManagerId
      noOfDaysApplied
      username
      requestType
      approver {
        name
        employeeCode
        profileImg
      }
      approvedDate
      employeeCode
      designation
    }
  }
`;

export const DELETE_WFH = gql`
  mutation DeleteWorkFromHome($deleteWorkFromHomeId: ID!, $deletedBy: String!) {
    deleteWorkFromHome(id: $deleteWorkFromHomeId, deletedBy: $deletedBy) {
      id
    }
  }
`;
