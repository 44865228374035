import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const MuiCheckbox = ({ label, checked, onChange, color }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxChange = (event: { target: { checked: any } }) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} color={color} />}
      label={label}
    />
  );
};

export default MuiCheckbox;
