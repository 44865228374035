import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";

export default function CheckBoxes({
  className,
  onChange,
  defaultChecked,
  value,
  ...checkboxProps
}: any) {
  return (
    <Grid>
      <Checkbox
        inputProps={{ "aria-label": "Checkbox demo", ...checkboxProps }}
        defaultChecked={defaultChecked}
        onChange={onChange}
        className={className}
        checked={value}
      />
    </Grid>
  );
}
