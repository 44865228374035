import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { IconButton, MenuItem, Typography } from "@mui/material";
import styles from "src/Styles/HrDocsSliders.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const MenuItems = ({ options, onViewDetails }: any) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpen = (event: any) => {
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <IconButton>
        <MoreVertIcon onClick={handleClickOpen} />
      </IconButton>
      <Popover
        open={popoverOpen}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          className: styles.menuPaper,
        }}
      >
        <Typography className={styles.optionsData}>
          {options.map((option: any) => (
            <MenuItem
              sx={{ padding: "15px" }}
              key={option}
              onClick={(e) => {
                onViewDetails(e);
                handlePopoverClose();
              }}
              selected={option === "Pyxis"}
            >
              {option}
            </MenuItem>
          ))}
        </Typography>
      </Popover>
    </>
  );
};

export default MenuItems;
