import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import styles from "src/Styles/Filtercomp.module.css";
import Divider from "@mui/material/Divider";
import ModalBox from "../ReusableModal/modal";
import CustomButtons from "src/components/CustomButton/Button";
import { useQuery } from "@apollo/client";
import { GET_DEPARTMENTS } from "src/graphql/departments";
import { GET_JOBTITLE } from "src/graphql/jobTitle";
import { GET_WORKPLACES } from "src/graphql/workplace";
import { GET_ALL_USERS } from "src/graphql/user";
import MultipleSelectCheckmarks from "src/components/Search/selectDropdown";

function FilterComp({ openFilter, toggleFilter, setSearchByField }: any) {
  const {
    data: jobTitlesData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: designationLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: refetchDesignation,
  } = useQuery(GET_JOBTITLE, {
    variables: {
      offset: 0, limit: 0
    }
  });

  const {
    data: departmentData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: departmentLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: refetchDepartments,
  } = useQuery(GET_DEPARTMENTS, {
    variables: {
      offset: 0, limit: 0
    }
  });

  const {
    data: WorkplacesData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: WorkplacesLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: refetchWorkplaces,
  } = useQuery(GET_WORKPLACES, {
    variables: {
      offset: 0, limit: 0
    }
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: emplyeeStatusData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: emplyeeStatussLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: refetchEmplyeeStatus,
  } = useQuery(GET_ALL_USERS);

  // const [clearAllFields, setClearAllFields] = React.useState<boolean>(false);

  const [selectedValues1, setSelectedValues1] = React.useState<any[]>([]);
  const [selectedValues2, setSelectedValues2] = React.useState<any[]>([]);
  const [selectedValues3, setSelectedValues3] = React.useState<any[]>([]);
  const [selectedValues4, setSelectedValues4] = React.useState<any[]>([]);
  const [departmentValues, setdepartmentValues] = React.useState<any[]>([]);
  const [designationValues, setdesignationValues] = React.useState<any[]>([]);
  const [workplaceValues, setworkplaceValues] = React.useState<any[]>([]);

  // For Designation values
  useEffect(() => {
    const dropdown = jobTitlesData?.getJobTitles?.jobTitleInfo || [];

    const designationDatas = dropdown.map((user: any) => {
      return { id: user?.id, title: user?.jobTitle };
    });
    setdesignationValues(designationDatas);

    const dropdown2 = departmentData?.getDepartments?.departmentInfo || [];
    const departmentDatas = dropdown2.map((user: any) => {
      return { id: user.id, title: user.departmentName };
    });
    setdepartmentValues(departmentDatas);

    const dropdown3 = WorkplacesData?.getWorkplaces?.workplaceInfo || [];
    const workplaceDatas = dropdown3.map((user: any) => {
      return {
        id: user.id,
        title: user.workplaceName,
        name: user.workplaceName,
      };
    });
    setworkplaceValues(workplaceDatas);
  }, [jobTitlesData?.getJobTitles, departmentData?.getDepartments.departmentInfo, WorkplacesData?.getWorkplaces, departmentData?.getDepartments]);

  const emplyeeStatusValues = [
    { id: "ACTIVE", title: "ACTIVE" },
    { id: "INACTIVE", title: "INACTIVE" },
  ];

  const handleChange1 = (evt: any) => {
    // //check here
    let checkAll = evt.find((item: any) => item.id === "ALL");
    if (checkAll) {
      setSelectedValues1(designationValues);
    } else {
      //work here
      setSelectedValues1(evt);
    }
  };

  const handleChange2 = (evt: any) => {
    let checkAll = evt.find((item: any) => item.id === "ALL");

    if (checkAll) {
      setSelectedValues2(departmentValues);
    } else {
      //work here
      setSelectedValues2(evt);
    }
  };

  const handleChange3 = (evt: any) => {
    let checkAll = evt.find((item: any) => item.id === "ALL");

    if (checkAll) {
      setSelectedValues3(workplaceValues);
    } else {
      //work here
      setSelectedValues3(evt);
    }
  };

  const handleChange4 = (evt: any) => {
    //check here
    let checkAll = evt.find((item: any) => item.id === "ALL");

    if (checkAll) {
      setSelectedValues4(emplyeeStatusValues);
    } else {
      //work here
      setSelectedValues4(evt);
    }
  };
  const removeSelection1 = (id: any) => {
    let selected: any = selectedValues1?.filter(
      (value: any) => value.id !== id
    );
    setSelectedValues1(selected);
  };

  const removeSelection2 = (id: any) => {
    let selected: any = selectedValues1?.filter(
      (value: any) => value.id !== id
    );
    setSelectedValues1(selected);
  };

  const removeSelection3 = (id: any) => {
    let selected: any = selectedValues2?.filter(
      (value: any) => value.id !== id
    );
    setSelectedValues2(selected);
  };

  const removeSelection4 = (id: any) => {
    let selected: any = selectedValues3?.filter(
      (value: any) => value.id !== id
    );
    setSelectedValues3(selected);
  };

  const handleApply = (evt: any) => {
    const data1 = selectedValues1.map((i) => i.id);
    const data2 = selectedValues2.map((i) => i.id);
    const data3 = selectedValues3.map((i) => i.id);
    const data4 = selectedValues4.map((i) => i.id);
    setSearchByField({
      designation: data1,
      department: data2,
      workPlace: data3,
      employeeStatus: data4,
    });
    toggleFilter();
  };
  return (
    <>
      <ModalBox
        open={openFilter}
        onClose={toggleFilter}
        content={
          <Grid className={styles.modalContent} container>
            <Grid
              container
              item
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              className={styles.header}
            >
              <Grid display={"flex"} gap={1}>
                <Grid>
                  <TuneIcon className={styles.filterIcon} />
                </Grid>
                <Grid>
                  <Typography className={styles.filterText}>Filters</Typography>
                </Grid>
              </Grid>
              <Grid>
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={toggleFilter}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Divider className={styles.divider} />
            </Grid>
            <Grid p={3} container>
              <Grid
                container
                item
                xs={12}
                md={12}
                sm={12}
                lg={12}
                xl={12}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                className={styles.fieldContainer}
              >
                <Grid item container xs={12} md={6} sm={6} lg={6} xl={6} mb={3}>
                  <MultipleSelectCheckmarks
                    size="small"
                    handleChange={handleChange1}
                    data={designationValues}
                    removeSelection={removeSelection1}
                    label="Select Designation"
                    labelInput="Select Designation"
                    prevSelectedValues={selectedValues1}
                  />
                </Grid>
                <Grid item container xs={12} md={6} sm={6} lg={6} xl={6} mb={3}>
                  <MultipleSelectCheckmarks
                    size="small"
                    handleChange={handleChange2}
                    data={departmentValues}
                    removeSelection={removeSelection2}
                    label="Select Department"
                    labelInput="Select Department"
                    prevSelectedValues={selectedValues2}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6} xl={6} container mb={3}>
                  <MultipleSelectCheckmarks
                    size="small"
                    handleChange={handleChange3}
                    data={workplaceValues}
                    removeSelection={removeSelection3}
                    label="Select Office"
                    labelInput="Select Office"
                    prevSelectedValues={selectedValues3}
                  />
                </Grid>
                <Grid item container xs={12} md={6} sm={6} lg={6} xl={6} mb={3}>
                  <MultipleSelectCheckmarks
                    size="small"
                    handleChange={handleChange4}
                    data={emplyeeStatusValues}
                    removeSelection={removeSelection4}
                    label="Select Status"
                    labelInput="Select Status"
                    prevSelectedValues={selectedValues4}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={12}
                sm={12}
                lg={12}
                xl={12}
                className={styles.btnGrid}
              >
                <Grid item>
                  <CustomButtons
                    name="Cancel"
                    onClick={() => {
                      toggleFilter();
                      // setClearAllFields(true);
                    }}
                    className={styles.CancelBtn}
                  />
                </Grid>
                <Grid item>
                  <CustomButtons
                    name="Apply"
                    variant="contained"
                    onClick={handleApply}
                    className={styles.ApplyButton}
                    disabled={
                      selectedValues1.length === 0 &&
                      selectedValues2.length === 0 &&
                      selectedValues3.length === 0 &&
                      selectedValues4.length === 0
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}

export default FilterComp;
