import CustomTab from "src/components/CustomTab";
import { Grid, Stack, Typography } from "@mui/material";
import Styles from "src/Styles/informationTab.module.css";
import TimeOffLeave from "./TimeOffLeave";

const TimeOffTabs = () => {
  const data = [
    {
      id: 0,
      label: (
        <Stack>
          <Typography className={Styles.tabs_typography}>Leave</Typography>
        </Stack>
      ),
      component: <TimeOffLeave />,
    },
    // {
    //   id: 1,
    //   label: (
    //     <Stack>
    //       <Typography className={Styles.tabs_typography}>
    //         Holidays
    //       </Typography>
    //     </Stack>
    //   ),
    //   // component: <LegalEntites />,
    // },
    // {
    //   id: 2,
    //   label: (
    //     <Stack>
    //       <Typography className={Styles.tabs_typography}>
    //         Shift
    //       </Typography>
    //     </Stack>
    //   ),
    //   // component: <Departments />,
    // },
    // {
    //   id: 3,
    //   label: (
    //     <Stack>
    //       <Typography className={Styles.tabs_typography}>
    //         Short Leave
    //       </Typography>
    //     </Stack>
    //   ),
    //   // component: <JobTitle />,
    // }
  ];

  return (
    <Grid className={Styles.timeOffTabs}>
      <CustomTab myTabData={data} orientation={"horizontal"} />
    </Grid>
  );
};

export default TimeOffTabs;
