import React, { useState } from 'react';
import CustomButtons from 'src/components/CustomButton/Button';
import { Grid } from '@mui/material';
import AddLegalEntityModel from 'src/components/AddLegalEntityModel/index';
import styles from 'src/Styles/Workplace.module.css';
import AddIcon from '@mui/icons-material/Add';
import SettingCustomHeader from 'src/components/SettingHeader';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ENTITIES, DELETE_ENTITY_BY_ID } from 'src/graphql/entity';
import CircularProgress from '@mui/material/CircularProgress';
import Styles from 'src/Styles/EducationalDetails.module.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomTable from '../TableComponent/CustomTable';

const LegalEntites = () => {
  // Query to fetch entities data
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)
  const { data, loading, error } = useQuery(GET_ENTITIES, {
    variables: {
      offset, limit
    }
  });
  // Mutation to delete an entity
  const [deleteEntity] = useMutation(DELETE_ENTITY_BY_ID);

  const [open, setOpen] = React.useState(false); // State to manage modal open/close
  const [selectedData, setSelectedData] = React.useState(null); // State to store the selected data

  // Function to toggle the workspace modal
  const toggleWorkSpaceModal = (data: any) => {
    if (data) {
      setSelectedData(data);
    }
    setOpen(!open);
  };

  // Function to handle entity deletion
  const handleDeleteEntity = (id: any) => {
    deleteEntity({
      variables: {
        workplaceId: id,
      },
      refetchQueries: [{ query: GET_ENTITIES }], // Refetch the users data after the mutation
    });
  };

  if (data?.getEntities?.companyName === 0) {
    return <h2>No Data available</h2>;
  }
  if (loading) {
    return (
      <Grid sx={{ marginTop: '100px', marginLeft: '40%' }}>
        <CircularProgress color="inherit" />
      </Grid>
    );
  }
  let cardData = data?.getEntities?.workplaceInfo || [];
  if (loading) {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'flex';
    }
  } else {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'none';
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid container item md={6} sm={6} xs={12}>
            <SettingCustomHeader
              header="Legal Entities"
              paragraph="Add your office locations located around the world."
            />
          </Grid>

          <Grid item container md={6} sm={6} xs={12} justifyContent={'flex-end'} className={styles.upperGrid_padding}>
            <CustomButtons
              icon={<AddIcon className={styles.customButton} />}
              name="Legal Entities"
              className={styles.add_button}
              onClick={toggleWorkSpaceModal}
              variant="contained"
            />
          </Grid>
        </Grid>

        {cardData && (
          <CustomTable
            data={cardData}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={data?.getEntities?.totalCount || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Company Name</Grid>,
                accessor: 'companyName',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.companyName}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Employee Count</Grid>,
                accessor: 'employeeCount',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.employeeCount}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Edit</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <EditIcon onClick={() => toggleWorkSpaceModal(item)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Delete</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <DeleteForeverIcon onClick={() => handleDeleteEntity(item?.id)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {open && (
          <AddLegalEntityModel
            handleClose={toggleWorkSpaceModal}
            onClose={toggleWorkSpaceModal}
            open={open}
            selectedData={selectedData}
          />
        )}
      </Grid>
    </>
  );
};

export default LegalEntites;
