import React from "react";
import {
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModalBox from "../../ReusableModal/modal";
import Styles from "src/Styles/NewCategoryModalBox.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomButtons from "../../CustomButton/Button";

const NewCategoryModalBox = ({ open, onClose }: any) => {
  return (
    <ModalBox
      open={open}
      onClose={onClose}
      content={
        <Grid container className={Styles.Main_Container}>
          <Grid container item className={Styles.ModalBox}>
            <Grid item md={11}>
              <Typography className={Styles.New_Task}>New Categary</Typography>
            </Grid>
            <Grid item md={1}>
              <IconButton
                className={Styles.Close}
                sx={{ fontSize: 30 }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container>
              <Grid item md={12} className={Styles.Text_Field}>
                <TextField label="Name" variant="outlined" fullWidth />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <Grid item md={12}>
                  <Divider />
                </Grid>
                <Grid item md={12} className={Styles.Save_Btn_Container}>
                  <CustomButtons
                    name="Save"
                    variant="contained"
                    className={Styles.Save_Btn}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default NewCategoryModalBox;
