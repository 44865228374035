import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AddEmplyeeState {
  selectdEmployeeData: any;
  uploadFileData: any[];
}

const initialState: AddEmplyeeState = {
  selectdEmployeeData: {},
  uploadFileData: [],
};

export const AddEmployeeSlice = createSlice({
  name: "AddEmplyee",
  initialState: initialState,
  reducers: {
    addEmployeeData: (state, action: PayloadAction) => {
      state.selectdEmployeeData = action.payload;
    },
    uploadFileDataForAddEmployee: (state, action: PayloadAction<any[]>) => {
      const { docData, ...rest }: any = action.payload;
      const existingIndex = state.uploadFileData.findIndex(
        (item) => item.docData.id === docData.id
      );

      if (existingIndex !== -1) {
        state.uploadFileData[existingIndex] = { ...rest, docData };
      } else {
        state.uploadFileData.push(action.payload);
      }
    },
  },
});

export const { addEmployeeData, uploadFileDataForAddEmployee } =
  AddEmployeeSlice.actions;

export default AddEmployeeSlice.reducer;
