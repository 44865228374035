import gql from "graphql-tag";

export const GET_EMPLOYEE_LEAVES = gql`
  query GetEmployeeLeaves(
    $filterStatus: String
    $employeeId: ID
    $approverId: ID
    $offset:Int
    $limit:Int
  ) {
    getEmployeeLeaves(
      filterStatus: $filterStatus
      employeeId: $employeeId
      approverId: $approverId
      offset:$offset
      limit:$limit
    ) {
      employeeLeavesInfo{
      id
      employeeId
      allowanceId
      allowancesAvailable
      designation
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      leaveType
      createdOn
      modifiedOn
      noOfDaysApplied
      username
      employeeCode
      requestType
      requestReceivedBy {
        userId
      }
      approver {
        profileImg
        name
        employeeCode
      }
      approvedDate
    }
     totalCount
    }
  }
`;

export const CREATE_EMPLOYEE_LEAVES = gql`
  mutation CreateEmployeeLeaves($input: createEmployeeLeavesInput!) {
    createEmployeeLeaves(input: $input) {
      id
      employeeId
      allowanceId
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      leaveType
      createdOn
      modifiedOn
      noOfDaysApplied
      username
      requestType
      reportingManagerId
      approvedDate
    }
  }
`;

export const UPDATE_EMPLOYEE_LEAVES_MUTATION = gql`
  mutation Mutation(
    $updateEmployeeLeavesId: ID!
    $input: createEmployeeLeavesInput!
  ) {
    updateEmployeeLeaves(id: $updateEmployeeLeavesId, input: $input) {
      id
      employeeId
      allowanceId
      status
      isDeleted
      acceptedBy
      reason
      appliedFrom
      appliedTo
      leaveType
      createdOn
      modifiedOn
      noOfDaysApplied
      username
      requestType
      approvedDate
    }
  }
`;

export const BULK_APPROVE_REJECT_LEAVE = gql`
  mutation BulkApproveRejectLeave($updates: [BulkApproveRejectLeaveInput!]!) {
    bulkApproveRejectLeave(updates: $updates) {
      id
      status
      createdOn
      approvedDate
    }
  }
`;

export const DELETE_LEAVE = gql`
  mutation DeleteEmployeeLeaves(
    $deleteEmployeeLeavesId: ID!
    $deletedBy: String!
  ) {
    deleteEmployeeLeaves(id: $deleteEmployeeLeavesId, deletedBy: $deletedBy) {
      id
      status
    }
  }
`;
