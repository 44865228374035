import CustomButtons from 'src/components/CustomButton/Button';
import ModalBox from 'src/components/ReusableModal/modal';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React from 'react';
import styles from 'src/Styles/setting.module.css';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from '@apollo/client';

import { GET_DEPARTMENTS, CREATE_DEPARTMENT, UPDATE_DEPARTMENT } from '../../graphql/departments';
import { Toast } from '../ToastMessage';

const AddDepartment = ({ onClick, open, onClose, content, setOpen, editFlag, editData }: any) => {
  const [createDepartment] = useMutation(CREATE_DEPARTMENT, {
    refetchQueries: [
      GET_DEPARTMENTS, // DocumentNode object parsed with gql
      'getDepartments', // Query name
    ],
  });
  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT, {
    refetchQueries: [
      GET_DEPARTMENTS, // DocumentNode object parsed with gql
      'getDepartments', // Query name
    ],
  });

  const [frmdata, setFrmdata] = React.useState(null);

  const schema = yup.object().shape({
    code: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
  });

  React.useEffect(() => {
    if (editFlag) {
      const defaultValues = {
        code: editData.departmentCode,
        name: editData.departmentName,
        description: editData.description,
      };
      // eslint-disable-next-line
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [editData, editFlag]);

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (evt: any) => {
    setFrmdata(evt);

    if (editFlag) {
      try {
        const input = {
          departmentCode: evt.code,
          departmentName: evt.name,
          description: evt.description,
          isDeleted: null,
          status: null,
        };

        const response = await updateDepartment({
          variables: { departmentId: editData.id, input },
        });

        if (response.data) {
          Toast('success', 'Department updated Successfully');
          setOpen(false);
        }
      } catch (error: any) {
        console.error('Error creating Department:', error);
        Toast('error', error?.message);
      }
    } else {
      try {
        const input = {
          departmentCode: evt.code,
          departmentName: evt.name,
          description: evt.description,
          isDeleted: null,
          status: null,
        };

        const response = await createDepartment({
          variables: { input },
        });

        if (response.data) {
          Toast('success', 'Department added Successfully');
          setOpen(false);
        }
      } catch (error: any) {
        console.error('Error creating Department:', error);
        Toast('error', error?.message);
      }
    }
  };

  return (
    <ModalBox
      onClick={onClick}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
      content={
        <>
          <Grid className={styles.modalContent}>
            <Grid component="form" onSubmit={handleSubmit(handleOnSubmit)}>
              <Grid container item className={styles.add_dept_modal} lg={12} md={12} sm={12} xs={12} xl={12}>
                <Grid md={9} lg={9} sm={9} xs={9} xl={9}>
                  <Typography className={styles.header}>{!editFlag ? 'Add Department' : 'Edit Department'}</Typography>
                  <Typography className={styles.typography_color}>
                    {!editFlag
                      ? 'Create new Department for the present Employees.'
                      : 'Edit Department for the present Employees.'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  xl={3}
                  className={styles.closeIcon}
                  justifyContent={'flex-end'}
                >
                  <CloseIcon onClick={onClose} />
                </Grid>
              </Grid>

              <Divider sx={{ marginTop: '10px' }} />
              <Grid
                container
                spacing={1}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mt={1}
                className={styles.inputtexts}
              >
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Department Code</Typography>
                  <Controller
                    control={control}
                    name="code"
                    rules={{
                      required: true,
                      pattern: /^\d+$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="code"
                        error={error !== undefined}
                        helperText={error ? 'Please enter a Department Code' : ''}
                        placeholder="D001"
                        className={styles.departmentFiled}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={6}>
                  <Typography className={styles.inputHeading}>Department Name</Typography>
                  <Controller
                    control={control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="name"
                        error={error !== undefined}
                        helperText={error ? 'Please enter a Department Name' : ''}
                        placeholder="HR Operations"
                        className={styles.departmentFiled}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item container md={12} lg={12} sm={12} xs={12} className={styles.inputtexts}>
                <Grid item container md={12} lg={12} sm={12} xs={12}>
                  <Typography className={`${styles.InputHeading} ${styles.departmentDes}`}>Description</Typography>
                </Grid>
                <Grid item container md={12} lg={12} sm={12} xs={12} className={styles.descriptionCss}>
                  <Controller
                    control={control}
                    name="description"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        multiline
                        rows={4}
                        fullWidth={true}
                        {...field}
                        type="description"
                        error={error !== undefined}
                        placeholder="Department description"
                        helperText={error ? 'Please write the Description' : ''}
                        className={styles.departmentFiled}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid>
                <Grid container item md={12} lg={12} sm={12} xs={12}>
                  <Grid container item md={12} lg={12} sm={12} xs={12} className={styles.btnGrid} pb={1}>
                    <CustomButtons
                      name={!editFlag ? 'Add department' : 'Edit department'}
                      variant="contained"
                      className={styles.submit_btn}
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default AddDepartment;
