import React from "react";
import { Grid, Typography, Divider, TextField } from "@mui/material";
import CustomButtons from "src/components/CustomButton/Button";
import AddIcon from "@mui/icons-material/Add";
import styles from "src/Styles/AddTimeoffModel.module.css";
import { useForm, Controller } from "react-hook-form";
import { Toast } from "../ToastMessage";
import {
  CREATE_TIMEOFF_ALLOWANCE,
  GET_TIMEOFF_ALLOWANCE,
  UPDATE_TIMEOFF_ALLOWANCE,
} from "../../graphql/timeOff";
import { useMutation } from "@apollo/client";

function AddTimeOff({ setShow, setOpen, editFlag, editData }: any) {
  const [createAllowance] = useMutation(CREATE_TIMEOFF_ALLOWANCE, {
    refetchQueries: [
      GET_TIMEOFF_ALLOWANCE, // DocumentNode object parsed with gql
      "GetAllowances", // Query name
    ],
  });
  const [updateAllowance] = useMutation(UPDATE_TIMEOFF_ALLOWANCE, {
    refetchQueries: [
      GET_TIMEOFF_ALLOWANCE, // DocumentNode object parsed with gql
      "GetAllowances", // Query name
    ],
  });

  React.useEffect(() => {
    if (editFlag) {
      const defaultValues = {
        plicyName: editData.policyName,
        palicyDesc: editData.policyDescription,
      };
      reset(defaultValues);
    }
    //eslint-disable-next-line
  }, [editData, editFlag]);

  const { control, handleSubmit, reset } = useForm({
    reValidateMode: "onBlur",
  });

  const handleOnSubmit = async (evt: any) => {
    if (editFlag) {
      try {
        const input = {
          policyName: evt.plicyName,
          policyDescription: evt.palicyDesc,
        };
        const response = await updateAllowance({
          variables: { updateAllowanceId: editData.id, input },
        });
        if (response.data) {
          Toast("success", "Time Off updated Successfully");
          setOpen(false);
        }
      } catch (error: any) {
        console.error("Error creating Department:", error);
        Toast("error", error?.message);
      }
    } else {
      try {
        const input = {
          policyName: evt.plicyName,
          policyDescription: evt.palicyDesc,
        };

        const response = await createAllowance({
          variables: { input },
        });

        if (response.data) {
          Toast("success", "Time Off added Successfully");
          setOpen(false);
        }
      } catch (error: any) {
        console.error("Error creating Department:", error);
        Toast("error", error?.message);
      }
    }
  };

  return (
    <>
      <Grid container md={12}>
        <Divider className={styles.DiV_0} />
      </Grid>
      <Grid
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.Main_Grid}
        component="form"
      >
        <Grid item container>
          <Grid container md={12}>
            <Typography className={styles.text_1}>Policy Name</Typography>
          </Grid>
          <Grid md={12}>
            <Controller
              control={control}
              name="plicyName"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  size="small"
                  {...field}
                  type="plicyName"
                  error={error !== undefined}
                  helperText={error ? "Please enter a Policy Name" : ""}
                  placeholder="Policy Name"
                  className={styles.Input_1}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid container md={12}>
            <Typography className={styles.text}>Policy Description</Typography>
          </Grid>
          <Grid md={8} sm={12} className={styles.palicyDescription}>
            <Controller
              control={control}
              name="palicyDesc"
              // defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  className={styles.Input_2}
                  size="small"
                  {...field}
                  type="palicyDesc"
                  error={error !== undefined}
                  helperText={error ? "Please enter a Policy Description" : ""}
                  placeholder="Policy Description"
                  multiline
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          className={styles.marginBtn}
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <CustomButtons
            className={styles.Add_Btn}
            icon={<AddIcon />}
            name={!editFlag ? "Add Time Off" : "Edit Time Off"}
            // onClick={openModal}
            type="submit"
          />
        </Grid>
        {/* <Divider className={styles.Divider_2} />
        <Grid container className={styles.parentContainer}>
          <Grid className={styles.upperGrid_padding} container>
            <Grid item md={9} className={styles.Grid_Time}>
              <Typography className={styles.Time_off}>
                Time-off allowance
              </Typography>
              <Typography variant="caption" className={styles.typography_color}>
                Set the number of days off each employee will be entitled to anh
                how many days can be accumulated over the next cycle.
              </Typography>
            </Grid>
            <Grid className={styles.marginBtn}>
              <CustomButtons
                className={styles.Add_Btn}
                icon={<AddIcon />}
                name="Add Allowance"
                onClick={openModal}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.crad_Card} item md={11}>
          {AddTimeoffData?.map((item, index: number) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={3}
              className={styles.cardAlign}
            >
              <WorkplaceCard
                cardHeading={item.LeaveType}
                cardPara1={item.LeaveCount}
                employeeCount={item.count}
                Switch={item.Switch}
              />
            </Grid>
          ))}
        </Grid> */}
      </Grid>
    </>
  );
}

export default AddTimeOff;
