import gql from "graphql-tag";

export const GET_ALL_HOLIDAYS = gql`
  query GetAllHolidays($year: String) {
    getAllHolidays(year: $year) {
      id
      name
      date
      active
      entityId
      type
    }
  }
`;
