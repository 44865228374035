import CustomButtons from "src/components/CustomButton/Button";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import styles from "src/Styles/Workplace.module.css";
import { useQuery } from "@apollo/client";
import CustomTable from "../TableComponent/CustomTable";
import Styles from "src/Styles/EducationalDetails.module.css";
import EditIcon from "@mui/icons-material/Edit";
import AddDocumentType from "../AddDocumentType";
import { GET_ALLDOCUMENT_TYPE } from "../../graphql/documentType";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";

const DocumentType = () => {
  // eslint-disable-next-line
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  // const { data, loading, error } = useQuery(GET_DOCUMENT_TYPE, {
  //   variables: { userId: userInfo.id},
  // });
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)
  const { data, loading, error, refetch } = useQuery(GET_ALLDOCUMENT_TYPE, {
    variables: {
      offset, limit
    }
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch])
  const [mopen, setOpen] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState(false);
  const [editData, setEditData] = React.useState({});

  const handleClickOpen = () => {
    setEditFlag(false);
    setOpen(true);
  };

  const onEdit = (data: any) => {
    setEditData(data);
    setEditFlag(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid container item md={6} sm={6} xs={12}>
            <SettingCustomHeader
              header="Employee Documents Space"
              paragraph="Create employees personal documents
                that will be available to each employee from the &ldquo;My
                Documents&rdquo; section."
            />
          </Grid>

          <Grid
            item
            container
            md={6}
            sm={6}
            xs={12}
            justifyContent={"flex-end"}
            className={styles.upperGrid_padding}
          >
            <CustomButtons
              icon={<AddIcon className={styles.customButton} />}
              name="Add Document Type"
              className={styles.add_button}
              onClick={handleClickOpen}
              variant="contained"
            />
          </Grid>
        </Grid>

        {data?.getAllDocumentType && (
          <CustomTable
            data={data?.getAllDocumentType?.documentInfo}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={data?.getAllDocumentType?.totalCount || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Document Type</Grid>,
                accessor: "jobTitle",
                Cell: ({ item }: any) => (
                  <Grid key={item.id} className={Styles.cell}>
                    {item.documentType}
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Description</Grid>,
                accessor: "employeeCount",
                Cell: ({ item }: any) => (
                  <Grid key={item.id} className={Styles.cell}>
                    {item.description}
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Created By</Grid>,
                accessor: "employeeCount",
                Cell: ({ item }: any) => (
                  <Grid key={item.id} className={Styles.cell}>
                    {item.createdByName}
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Edit</Grid>,
                Cell: ({ item }: any) => (
                  <Grid key={item.id} item>
                    <EditIcon
                      onClick={() => onEdit(item)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {mopen && (
          <AddDocumentType
            onClose={handleClose}
            open={mopen}
            setOpen={setOpen}
            editFlag={editFlag}
            editData={editData}
          />
        )}
      </Grid>
    </>
  );
};

export default DocumentType;
