import React, { useState, useEffect } from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import ModalBox from "src/components/ReusableModal/modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "src/Styles/LeaveViewDetailsModel.module.css";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

const EmployeeLeaveViewDetails = ({
  open,
  onClose,
  selectedItem,
  onStatusChange,
}: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showBackdrop, setShowBackdrop] = useState(false);

  useEffect(() => {
    setShowBackdrop(false);
  }, []);

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      content={
        <>
          {selectedItem && (
            <Grid
              className={styles.mainContainer}
              md={12}
              sm={12}
              lg={12}
              xs={12}
            >
              <Grid
                item
                container
                md={12}
                sm={12}
                lg={12}
                xs={12}
                alignItems={"center"}
                className={styles.headerContainer}
              >
                <Grid
                  item
                  container
                  md={12}
                  sm={12}
                  lg={12}
                  xs={12}
                  className={styles.headerSubContainer}
                >
                  <Grid item className={styles.headerSection}>
                    <Typography className={styles.headerText}>Leave</Typography>
                    <Grid item className={styles.Statusheader}>
                      <Typography
                        className={styles.headerStatus}
                        sx={{
                          backgroundColor:
                            selectedItem.status === "APPROVED"
                              ? "green"
                              : selectedItem.status === "REJECTED"
                              ? "red"
                              : "orange",
                          borderRadius: "15px",
                          fontSize: "15px",
                          color: "white",
                          paddingBottom: "3px",
                        }}
                      >
                        {selectedItem.status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item mt={1}>
                    <CloseIcon onClick={onClose} className={styles.closeIcon} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container className={styles.raisedDiv}>
                <Grid className={styles.raised}>
                  <Typography className={styles.raisedText}>
                    Raised By : {selectedItem.username} |
                  </Typography>
                </Grid>
                <Grid className={styles.raised}>
                  <Typography className={styles.raisedOnText}>
                    Raised on :&nbsp;
                    {moment(selectedItem.createdOn).format("YYYY-MM-DD")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container className={styles.accordion}>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <Accordion className={styles.accordionText}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styles.accordionHeader}>
                        {selectedItem.username} ({selectedItem.employeeCode})
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item container className={styles.accordionDetails}>
                        <Grid item xs={2} sm={1} md={1} lg={1} mb={2}>
                          <Avatar className={styles.addIcon2}>
                            {selectedItem?.username?.charAt(0)}
                          </Avatar>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sm={9}
                          md={9}
                          lg={9}
                          className={styles.accordionDetailsGrid}
                        >
                          <Typography className={styles.designationText}>
                            {selectedItem.designation} | Arokee
                          </Typography>

                          <Typography className={styles.RepostsText}>
                            Reports to {selectedItem.approver.name} |
                            <Typography className={styles.locationDiv}>
                              <Typography>
                                <LocationOnSharpIcon
                                  className={styles.locationIocn}
                                />
                              </Typography>
                              <Typography>Bengaluru</Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item container>
                  <Grid item container md={12} className={styles.approver}>
                    <Typography className={styles.approverText}>
                      Approver:
                    </Typography>
                    <Typography className={styles.typo2}>
                      {selectedItem.approver.name} (
                      {selectedItem.approver.employeeCode})
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className={styles.appliedText}>
                  Applied Date
                </Typography>
                <Grid md={12} className={styles.appliedFormDiv}>
                  <Grid md={8}>
                    <Typography className={styles.typo}>
                      {moment(selectedItem.appliedFrom).format("YYYY-MM-DD")}
                    </Typography>
                  </Grid>
                  <Grid md={4} className={styles.leaveText}>
                    <Typography className={styles.typo}>
                      {selectedItem.leaveType}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className={styles.appliedText}>Details</Typography>
                <Grid md={12} className={styles.appliedFormDiv}>
                  <Grid md={8}>
                    <Typography className={styles.typo}>
                      Raised Date:
                    </Typography>
                    <Typography className={styles.typo}>
                      {selectedItem.approvedDate &&
                      selectedItem.status === "APPROVED"
                        ? "Approved Date:"
                        : selectedItem.status === "REJECTED"
                        ? "Rejected Date:"
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid md={4} className={styles.leaveText}>
                    <Typography className={styles.typo}>
                      {moment(selectedItem.createdOn).format("YYYY-MM-DD")}
                    </Typography>
                    <Typography className={styles.typo}>
                      {selectedItem.approvedDate &&
                        moment(selectedItem.approvedDate).format("YYYY-MM-DD")}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className={styles.appliedText}>Leaves</Typography>
                <Grid md={12} className={styles.appliedFormDiv}>
                  <Grid md={8}>
                    <Typography className={styles.leavestypo}>
                      Leave Type:{" "}
                    </Typography>
                    {selectedItem.allowancesAvailable !== null && (
                      <Typography className={styles.leavestypo}>
                        Leave Balance:{" "}
                      </Typography>
                    )}
                  </Grid>
                  <Grid md={4} className={styles.leaveText}>
                    <Typography className={styles.typo1}>
                      {selectedItem.requestType}
                    </Typography>
                    {selectedItem.allowancesAvailable !== null && (
                      <Typography className={styles.noOfDaysApplied}>
                        {selectedItem.allowancesAvailable}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Typography className={styles.appliedText}>Reason</Typography>
                <Grid md={12} className={styles.appliedFormDiv}>
                  <Typography className={styles.typo}>
                    {selectedItem.reason !== "" ? selectedItem.reason : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
};

export default EmployeeLeaveViewDetails;
