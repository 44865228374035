import { Grid } from "@mui/material";
import CardComponent from "src/components/Cards/index";
import { GET_ALL_USERS } from "src/graphql/user";
import { useQuery } from "@apollo/client";
import SettingCustomHeader from "src/components/SettingHeader";
import CircularProgress from "@mui/material/CircularProgress";
// eslint-disable-next-line
{
  /* Please create seperate css file for directory and header grid style same as manage employee*/
}

function Directory() {
  const { data, loading } = useQuery(GET_ALL_USERS);

  const directoryUsersData = data?.getAllUsers || [];
  const directoryLoading = loading;

  return (
    <>
      <Grid item md={12} lg={12} sm={12} xs={12} sx={{ margin: "20px 0px" }}>
        {/*Please create seperate css file for directory and header grid style same as manage employee */}
        <SettingCustomHeader
          header="Directory"
          paragraph="Here are all the employees at your company, including terminated ones."
        />
      </Grid>
      {directoryLoading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress size={50} />
        </Grid>
      ) : (
        <Grid container item>
          {directoryUsersData.map((directoryUsersData: { id: any }) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardComponent
                key={directoryUsersData.id}
                directoryUsersData={directoryUsersData}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default Directory;
