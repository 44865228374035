import React, { memo } from 'react';
import { Grid, Paper, Popover, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Styles from 'src/Styles/Calendar.module.css';
import moment from 'moment';
import { getFormattedTime } from 'src/utils/getFormattedTime';

const Regularize = React.lazy(() => import('src/components/Regularize'));
const ApplyLeaveModel = React.lazy(() => import('../ApplyLeaveModel/ApplyLeaveModel'));

const SelectedDayDetailsModal = memo(
  ({
    open,
    handleClose,
    position,
    selectedDateInfo,
    isregularize,
    isapplyleave,
    handleRegularizeClose,
    handleApplyleaveClose,
  }: any) => {
    return (
      <Popover
        open={open}
        onClose={handleClose}
        anchorReference={'anchorPosition'}
        anchorPosition={{ top: position.y, left: position.x }}
        disableScrollLock
      >
        <Paper elevation={8} className={`${Styles.Popoverpaper} ${Styles.popoverContent}`}>
          <Grid container alignItems={'center'} spacing={2} className={Styles.popoverParent}>
            <Grid item md={10} className={`${Styles.popoverChild} ${Styles.popoverText1}`}>
              <Typography>
                {selectedDateInfo?.date ? moment(selectedDateInfo?.date).format('dddd, MMMM Do YYYY') : ''}
              </Typography>
            </Grid>
            <Grid item md={2} container justifyContent={'flex-end'} className={Styles.popoverChild}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="body2" className={`${Styles.text2} ${Styles.popoverChild}`}>
            Shift 09:30 AM-06:30 PM
          </Typography>
          <Grid container className={`${Styles.parentGrid} ${Styles.Grid1}`}>
            <Typography variant="body2" className={`${Styles.childTypography} ${Styles.clockInTypography}`}>
              Clock In: {getFormattedTime(selectedDateInfo?.loginTime)}
            </Typography>
          </Grid>
          <Grid container marginBottom={2} className={`${Styles.parentGrid}`}>
            <Typography variant="body2" className={`${Styles.childTypography} ${Styles.clockOutTypography}`}>
              Clock Out: {getFormattedTime(selectedDateInfo?.logoutTime)}
            </Typography>
          </Grid>

          <Grid marginTop={4} container justifyContent="center" alignItems="center">
            <Grid item alignItems={'center'}>
              {isregularize && (
                <Regularize onClose={handleRegularizeClose} open={isregularize} selectedDateInfo={selectedDateInfo} />
              )}
              {isapplyleave && (
                <ApplyLeaveModel
                  onClose={handleApplyleaveClose}
                  open={isapplyleave}
                  selectedDateFromcalender={selectedDateInfo?.date}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    );
  }
);

export default SelectedDayDetailsModal;
