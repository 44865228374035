import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiCheckbox from "src/components/CheckBox/MuiCheckbox";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Styles from "src/Styles/settingsFilter.module.css";
import Divider from "@mui/material/Divider";
import ModalBox from "../ReusableModal/modal";
import CustomButtons from "src/components/CustomButton/Button";
import SearchBox from "src/components/Search/index";

function EmployeesTableSettings({ openSettings, toggleSettings }: any) {
  const [query, setQuery] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    employeeName: false,
    joiningDate1: false,
    joiningDate2: false,
    jobDescription: false,
    companyName: false,
    workEmail: false,
  });

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setQuery(event.target.value);
  };

  const submitSearch = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handleClick = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setQuery(e.target.value);
  };

  const handleSelectAll = () => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      employeeName: true,
      joiningDate1: true,
      joiningDate2: true,
      jobDescription: true,
      companyName: true,
      workEmail: true,
    }));
  };

  const handleClearAll = () => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      employeeName: false,
      joiningDate1: false,
      joiningDate2: false,
      jobDescription: false,
      companyName: false,
      workEmail: false,
    }));
  };

  return (
    <>
      <ModalBox
        open={openSettings}
        onClose={toggleSettings}
        content={
          <Grid container className={Styles.modalContent}>
            <Grid container item className={Styles.ModalBox}>
              <Grid item md={1}>
                <SettingsOutlinedIcon className={Styles.settingIcon} />
              </Grid>
              <Grid item md={3}>
                <Typography className={Styles.SettingText}>Settings</Typography>
              </Grid>
              <Grid item md={4}>
                <SearchBox
                  data={[]}
                  customStyle={Styles.searchBox}
                  handleChange={handleChange}
                  handleClick={handleClick}
                  submitSearch={submitSearch}
                  borderRadius="30px"
                  size="small"
                  placeholder="Search"
                />
              </Grid>
              <Grid item md={4}>
                <CloseIcon
                  className={Styles.CloseButton}
                  sx={{ fontSize: 30 }}
                  onClick={toggleSettings}
                />
              </Grid>
            </Grid>
            <Grid container item className={Styles.Container}>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid container className={Styles.SecondMainGrid}>
                <Grid item md={6}>
                  <Typography className={Styles.EmpInfo}>
                    EMPLOYEE INFORMATION
                  </Typography>
                </Grid>

                <Grid container item className={Styles.ThirdGrid}>
                  <Grid md={4} item className={Styles.CheckGrid}>
                    <MuiCheckbox
                      label={
                        <Typography className={Styles.checkText}>
                          Employee Name
                        </Typography>
                      }
                      className={Styles.CheckText}
                      checked={checkboxes.employeeName}
                      onChange={(event: any, checked: any) =>
                        setCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          employeeName: checked,
                        }))
                      }
                    />
                  </Grid>
                  <Grid md={4} item>
                    <MuiCheckbox
                      label={
                        <Typography className={Styles.checkText}>
                          Joining Date
                        </Typography>
                      }
                      className={Styles.CheckText}
                      checked={checkboxes.joiningDate1}
                      onChange={(event: any, checked: any) =>
                        setCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          joiningDate1: checked,
                        }))
                      }
                    />
                  </Grid>
                  <Grid md={4} item>
                    <MuiCheckbox
                      label={
                        <Typography className={Styles.checkText}>
                          Employment Type
                        </Typography>
                      }
                      className={Styles.CheckText}
                      checked={checkboxes.joiningDate2}
                      onChange={(event: any, checked: any) =>
                        setCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          joiningDate2: checked,
                        }))
                      }
                    />
                  </Grid>

                  <Grid xs={4} item className={Styles.CheckGrid}>
                    <MuiCheckbox
                      label={
                        <Typography className={Styles.checkText}>
                          Job Description
                        </Typography>
                      }
                      className={Styles.CheckText}
                      checked={checkboxes.jobDescription}
                      onChange={(event: any, checked: any) =>
                        setCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          jobDescription: checked,
                        }))
                      }
                    />
                  </Grid>
                  <Grid md={4} item>
                    <MuiCheckbox
                      label={
                        <Typography className={Styles.checkText}>
                          Company Name
                        </Typography>
                      }
                      className={Styles.CheckText}
                      checked={checkboxes.companyName}
                      onChange={(event: any, checked: any) =>
                        setCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          companyName: checked,
                        }))
                      }
                    />
                  </Grid>
                  <Grid md={4} item>
                    <MuiCheckbox
                      label={
                        <Typography className={Styles.checkText}>
                          Work Email
                        </Typography>
                      }
                      className={Styles.CheckText}
                      checked={checkboxes.workEmail}
                      onChange={(event: any, checked: any) =>
                        setCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          workEmail: checked,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid md={12} item className={Styles.Div}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  marginTop={2}
                  marginBottom={2}
                  sx={{ alignItems: "center" }}
                  className={Styles.Footer_Grid}
                >
                  <Grid md={6} item>
                    <Typography variant="subtitle2" className={Styles.Number}>
                      12/80
                    </Typography>
                  </Grid>
                  <Grid md={2} item>
                    <CustomButtons
                      variant="text"
                      name="Select All"
                      className={Styles.SelectAll}
                      onClick={handleSelectAll}
                    />
                  </Grid>
                  <Grid md={2} item>
                    <CustomButtons
                      variant="text"
                      name="Clear All"
                      className={Styles.ClearAll}
                      onClick={handleClearAll}
                    />
                  </Grid>
                  <Grid md={2} item>
                    <CustomButtons
                      variant="contained"
                      name="Apply"
                      className={Styles.ApplyButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}

export default EmployeesTableSettings;
