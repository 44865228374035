import gql from "graphql-tag";

export const GET_TIMEOFF_ALLOWANCE = gql`
  query GetAllowances($email: String,$offset:Int,$limit:Int) {
    getAllowances(email: $email,offset:$offset,limit:$limit) {
      allowanceInfo{
      id
      policyName
      policyDescription
      }
      totalCount
    }
  }
`;

export const CREATE_TIMEOFF_ALLOWANCE = gql`
  mutation CreateAllowance($input: createAllowanceInput!) {
    createAllowance(input: $input) {
      policyName
      policyDescription
    }
  }
`;

export const UPDATE_TIMEOFF_ALLOWANCE = gql`
  mutation UpdateAllowance(
    $updateAllowanceId: ID!
    $input: createAllowanceInput!
  ) {
    updateAllowance(id: $updateAllowanceId, input: $input) {
      policyName
      policyDescription
    }
  }
`;

export const DELETE_TIMEOFF_ALLOWANCE = gql`
  mutation DeleteAllowance($deleteAllowanceId: ID!) {
    deleteAllowance(id: $deleteAllowanceId) {
      id
    }
  }
`;
