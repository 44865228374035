import { Grid } from "@mui/material";

export default function TabInfoIcon({
  strokeColor,
  handleCircleClick,
  classes,
  icon,
}: any) {
  return (
    <svg width="100" height="100">
      <circle
        cx="50"
        cy="50"
        r="25"
        stroke={strokeColor}
        strokeWidth="2"
        fill="white"
        onClick={handleCircleClick}
        className={classes.root}
      />
      <foreignObject x="20" y="20" width="60" height="60">
        <Grid style={{ textAlign: "center", marginTop: 14 }}>{icon}</Grid>
      </foreignObject>
    </svg>
  );
}
