// eslint-disable-next-line
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "src/store/store";

interface organizationState {
  value: boolean;
}

const initialState: organizationState = {
  value: false,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    showOrganization: (state) => {
      state.value = !state.value;
    },
  },
});

export const { showOrganization } = organizationSlice.actions;

export const showOrg = (state: RootState) => state.organizationSlice;

export default organizationSlice.reducer;
