import React, { useState } from 'react';
import { Grid } from '@mui/material';
import CustomButtons from 'src/components/CustomButton/Button';
import AddIcon from '@mui/icons-material/Add';
import AddnewWorkplacesModal from 'src/components/AddnewWorkplacesModal';
import styles from 'src/Styles/Workplace.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import SettingCustomHeader from 'src/components/SettingHeader';
import { useQuery, useMutation } from '@apollo/client';
import { GET_WORKPLACES, DELETE_WORKPLACE_BY_ID } from 'src/graphql/workplace';
import CustomTable from '../TableComponent/CustomTable';
import Styles from 'src/Styles/EducationalDetails.module.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function Workplaces() {
  const [open, setOpen] = React.useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)
  const { data, loading, error } = useQuery(GET_WORKPLACES, {
    variables: { offset, limit }
  });
  const [deleteWorkplace] = useMutation(DELETE_WORKPLACE_BY_ID);
  const [selectedData, setSelectedData] = React.useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addData, setAddData] = React.useState(false);

  const toggleWorkSpaceModal = (data: any) => {
    if (data) {
      setSelectedData(data);
    }
    setOpen(!open);
  };

  if (data?.getWorkplaces?.workplaceName === 0) {
    return <h2>No Data available</h2>;
  }
  if (loading) {
    return (
      <Grid sx={{ marginTop: '100px', marginLeft: '40%' }}>
        <CircularProgress color="inherit" />
      </Grid>
    );
  }
  const handleDeleteWorkplace = (id: any) => {
    deleteWorkplace({
      variables: {
        id: id,
      },
      refetchQueries: [{ query: GET_WORKPLACES }], // Refetch the users data after the mutation
    });
  };

  let cardData = data?.getWorkplaces?.workplaceInfo || [];
  if (loading) {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'flex';
    }
  } else {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'none';
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid container item md={6} sm={6} xs={12}>
            <SettingCustomHeader header="Workplaces" paragraph="Add your office locations located around the world." />
          </Grid>

          <Grid item container md={6} sm={6} xs={12} justifyContent={'flex-end'} className={styles.upperGrid_padding}>
            <CustomButtons
              icon={<AddIcon className={styles.customButton} />}
              name="Add Workplace"
              className={styles.add_button}
              onClick={toggleWorkSpaceModal}
              variant="contained"
            />
          </Grid>
        </Grid>

        {cardData && (
          <CustomTable
            data={cardData}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={data?.getWorkplaces?.totalCount || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Workplace Name</Grid>,
                accessor: 'workplaceName',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.workplaceName}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Employee Count</Grid>,
                accessor: 'employeeCount',
                Cell: ({ item }: any) => <Grid className={Styles.cell}>{item.employeeCount}</Grid>,
              },
              {
                Header: <Grid className={Styles.tableHead}>Edit</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <EditIcon onClick={() => toggleWorkSpaceModal(item)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Delete</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <DeleteForeverIcon onClick={() => handleDeleteWorkplace(item?.id)} style={{ cursor: 'pointer' }} />
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {open && (
          <AddnewWorkplacesModal
            handleClose={toggleWorkSpaceModal}
            open={open}
            onClose={toggleWorkSpaceModal}
            Data={selectedData}
            addData={addData}
          />
        )}
      </Grid>
    </>
  );
}

export default Workplaces;
