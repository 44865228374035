import React from "react";
import {
  Checkbox,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedInput from "@mui/material/OutlinedInput";
import styles from "src/Styles/Filtercomp.module.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" />;

export default function CheckboxDropdown({
  handleChange,
  data,
  label,
  removeSelection,
  labelInput,
  prevSelectedValues,
}: any) {
  let defaultTitles: any = [];
  let checkAll = false;

  if (prevSelectedValues && prevSelectedValues.length > 0) {
    defaultTitles = prevSelectedValues.map((item: any) => item.title);
    if (defaultTitles.length === data.length) {
      checkAll = true;
    }
  }
  // eslint-disable-next-line
  const [dropdownData, setdropDownData] = React.useState(data);
  const [selectedValuesData, setSelectedValuesData] = React.useState<any>(
    checkAll ? [...defaultTitles, "ALL"] : defaultTitles
  );
  const [fieldData, setFieldData] = React.useState<any>(defaultTitles);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        marginTop: 10,
      },
    },
  };

  const truncateLabel = (label: string, maxLength: number) => {
    if (label.length <= maxLength) {
      return label;
    } else {
      return label.substring(0, maxLength) + "...";
    }
  };

  const handleAllCheckboxClick = (e: any) => {
    if (e.target.checked) {
      const allValuesExceptAll = dropdownData
        .filter((option: any) => option.title !== "ALL")
        .map((option: any) => option.title);

      setFieldData(allValuesExceptAll);
      setSelectedValuesData([...allValuesExceptAll, "ALL"]);

      handleChange([{ id: "ALL", title: "ALL" }]);
    } else {
      setFieldData([]);
      setSelectedValuesData([]);
      handleChange([]);
    }
  };

  const handleCheckboxClick = (option: any) => {
    setFieldData((prev: any) =>
      prev.includes(option)
        ? prev.filter((item: any) => item !== option)
        : [...prev, option]
    );
    setSelectedValuesData((prev: any) =>
      prev.includes(option)
        ? prev.filter((item: any) => item !== option)
        : [...prev, option]
    );

    const updatedValues = selectedValuesData.includes(option)
      ? selectedValuesData.filter((item: any) => item !== option)
      : [...selectedValuesData, option];

    const finalValue = updatedValues.filter((item: any) => item !== "ALL");

    if (finalValue.length === dropdownData.length) {
      setSelectedValuesData([...finalValue, "ALL"]);
    } else {
      setSelectedValuesData(finalValue);
    }
    const formatedValues = finalValue.map((title: any) => ({
      id: dropdownData.find((item: any) => item.title === title)?.id || title,
      title,
    }));
    handleChange(formatedValues);
  };

  const tooltipValues = selectedValuesData.filter(
    (item: any) => item !== "ALL"
  );

  return (
    <FormControl fullWidth>
      {/* <FormControl> */}
      <InputLabel id="checkbox-dropdown-label">{label}</InputLabel>
      <Select
        labelId="checkbox-dropdown-label"
        id="checkbox-dropdown"
        multiple
        MenuProps={MenuProps}
        value={fieldData}
        input={<OutlinedInput label={labelInput} title={tooltipValues} />}
        renderValue={(selected) =>
          (selected as string[]).map((value) => (
            <Chip
              key={value}
              label={value}
              sx={{
                margin: "0px 3px",
              }}
            />
          ))
        }
      >
        {dropdownData && dropdownData.length > 0 ? (
          <MenuItem className={styles.menuItems}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              className={styles.checkBox}
              onClick={handleAllCheckboxClick}
              checked={selectedValuesData.includes("ALL")}
            />
            {truncateLabel("ALL", 17)}
          </MenuItem>
        ) : (
          ""
        )}
        {dropdownData &&
          dropdownData.map((option: any) => (
            <MenuItem
              key={option.id}
              value={option.title}
              title={option.title}
              className={styles.menuItems}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={styles.checkBox}
                onClick={() => handleCheckboxClick(option.title)}
                checked={selectedValuesData.includes(option.title)}
              />
              {truncateLabel(option.title, 17)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
