import React, { useState, useEffect } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import Card from "./Card";
import "./style.css"
import { Container, Grid, Typography } from "@mui/material";
import styles from "src/Styles/OrgChart.module.css";
import { GET_ORG_USERS } from "src/graphql/user";
import { useQuery } from "@apollo/client";

interface Employee {
  name: string;
  jobTitleName: string;
  profileImage: any;
  child?: Employee[];
}


const OrganizationChart: React.FC = () => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const { data } = useQuery(GET_ORG_USERS);

  useEffect(() => {
    // Show root node by default
    if (data?.getOrgUsers && data.getOrgUsers.length > 0) {
      setExpandedNodes([data.getOrgUsers[0].name]);
    }
  }, [data]);

  const handleToggleExpand = (nodeName: string) => {
    if (expandedNodes.includes(nodeName)) {
      setExpandedNodes(expandedNodes.filter(node => node !== nodeName));
    } else {
      setExpandedNodes([...expandedNodes, nodeName]);
    }
  };

  const renderTree = (node: Employee): JSX.Element | null => {
    const isExpanded = expandedNodes.includes(node?.name);
    const hasChildren = node?.child && node?.child?.length > 0;

    return (
      <TreeNode
        key={node.name}
        label={
          <Card
            data={node}
            onToggleExpand={() => handleToggleExpand(node.name)}
            isExpanded={isExpanded}
          />
        }
        className={!isExpanded && hasChildren ? 'no-child' : 'child'}
      >
        {isExpanded && node.child?.map(child => renderTree(child))}
      </TreeNode>
    );
  };

  return (
    <Grid className="org-chart">
      <Grid className={styles.Head}>
        <Typography className={styles.text4}>Organization Chart</Typography>
        <Typography className={styles.text1}>
          Here are all the employees at your company, including terminated ones.
        </Typography>
        <div className="additional-class">
          <Tree
            label={null}
            lineWidth={"3px"}
            lineColor={"#9BCF53"}
            lineBorderRadius={"10px"}
          >
            {data?.getOrgUsers && data.getOrgUsers.length > 0 && renderTree(data.getOrgUsers[0])}
          </Tree>
        </div>
      </Grid>
    </Grid>
  );
};


export default OrganizationChart;


