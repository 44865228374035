import React, { useEffect, useState, Suspense } from 'react';
import { Divider, Grid, IconButton, StepIconProps, TextField, Typography, Container } from '@mui/material';
import CustomButtons from 'src/components/CustomButton/Button';
import AddIcon from '@mui/icons-material/Add';
import AddTimeOff from '../AddTimeOffModel/index';
import ModalBox from '../ReusableModal/modal';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery, useMutation } from '@apollo/client';
import { GET_TIMEOFF_ALLOWANCE, DELETE_TIMEOFF_ALLOWANCE } from '../../graphql/timeOff';
import { Toast } from '../ToastMessage';
import styles from 'src/Styles/Workplace.module.css';
import SettingCustomHeader from '../SettingHeader';
import Styles from 'src/Styles/EducationalDetails.module.css';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from '../TableComponent/CustomTable';
import CustomTable1 from '../TableComponent'
import MenuItems from '../MenuItems/MenuItems';
import SearchBox from '../Search';
import { styled } from '@mui/material/styles';
import createTimeOffStyles from 'src/Styles/createTimeOffLeaveModel.module.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { TimeOffStepperOneSchema } from 'src/utils/validations';
import { yupResolver } from '@hookform/resolvers/yup';

// Styled StepConnector for customizing the stepper connector
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {},
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {},
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
  },
}));

// Styled StepIcon for customizing the stepper icons
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
  border: '2px solid #eaeaf0',
  color: '#eaeaf0',
  zIndex: 1,
  width: 20,
  height: 20,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'blue',
    border: '1px solid blue',
  }),
  ...(ownerState.completed && {}),
}));

// Custom StepIcon component for the stepper
function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: string } = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

// Function to get the steps for the stepper
function getSteps() {
  return ['Basic Details', 'Leave Details', 'Leave Restrictions', 'Advanced Settings'];
}

const TimeOffLeave = () => {
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)
  const { data, loading, error, refetch } = useQuery(GET_TIMEOFF_ALLOWANCE, {
    variables: {
      offset, limit
    }
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch])
  const [deleteAllowance] = useMutation(DELETE_TIMEOFF_ALLOWANCE, {
    refetchQueries: [
      GET_TIMEOFF_ALLOWANCE, // DocumentNode object parsed with gql
      'GetAllowances', // Query name
    ],
  });
  const steps = getSteps();

  const methods = useForm({
    // reValidateMode: 'onBlur',
    resolver: yupResolver(TimeOffStepperOneSchema),
  });

  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(0);
  const [editFlag, setEditFlag] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [search1, setSearch1] = useState('');
  const [searchbyField, setSearchByField] = useState<any>({});
  const [open1, setOpen1] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [timeOffAllowances, setTimeOffAllowances] = useState<
    { id: number; policyName: string; policyDescription: string; newItem: boolean }[]
  >([]);
  const [buttonStatus, setButtonStatus] = useState<{ [key: string]: boolean }>({});

  // Function to open the modal
  const openModal = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  // Function to open the modal 1
  const openModal1 = (e: any) => {
    e.preventDefault();
    setOpen1(true);
  };
  // Function to handle close of modal 1
  const handleClose1 = () => {
    setOpen1(!open1);
  };
  const getStepContent = (step: any) => {
    const componentNames = [
      'LeaveDetailsStepperOne',
      'LeaveDetailsStepperTwo',
      'LeaveDetailsStepperThree',
      'LeaveDetailsStepperFour',
    ];

    const componentName = componentNames[step];

    if (componentName) {
      const LazyComponent = React.lazy(() => import(`./Stepper/${componentName}`));

      return (
        <Suspense fallback={null}>
          <LazyComponent step={step} />
        </Suspense>
      );
    }

    return 'unknown step';
  };
  // Function to handle view details or delete item
  const handleViewDetails = async (item: any, event: any) => {
    if (event.target.textContent === 'View') {
      setEditData(data);
      setShow(1);
      setEditFlag(true);
      setOpen(true);
    } else if (event.target.textContent === 'Delete') {
      const response = await deleteAllowance({
        variables: { deleteAllowanceId: item.id },
      });
      if (response.data) {
        Toast('success', 'Time Off deleted Successfully');
      }
    }
  };
  // UseEffect to update timeOffAllowances on data change
  useEffect(() => {
    if (data?.getAllowances && data?.getAllowances?.length > 0) {
      setTimeOffAllowances(data?.getAllowances);
    }
  }, [data]);

  // Function to add a new item to timeOffAllowances
  const handleAddLeaveRuleType = () => {
    // Assuming you have some default structure for a new item
    const newItem = {
      id: Math.random(), // Generate a unique ID
      policyName: '',
      policyDescription: '',
      newItem: true,
    };

    // Update the state by appending the new item
    setTimeOffAllowances((prevState) => [newItem, ...prevState]);
  };

  // Function to handle search
  const requestSearch = async (evt: any) => {
    setSearchByField('');
    setSearch1(evt.target.value);
    // refetch();
  };

  // Function to handle close of modal
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle button click and toggle status
  const handleButtonClick = (rowId: string) => {
    // Toggle the status of the row
    setButtonStatus((prevStatus) => ({
      ...prevStatus,
      [rowId]: !prevStatus[rowId], // Toggle the status for the given rowId
    }));
  };

  // Function to handle next step
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1); // Increment active step
  };

  // Function to handle previous step
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1); // Decrement active step
  };

  // Function to handle TimeOffLeave
  const handleTimeOffLeave = async (data: any) => {
    handleNext();
  };

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
    // resolver: yupResolver(schema),
  });

  let content = null;
  if (show === 1) {
    content = <AddTimeOff setShow={setShow} setOpen={setOpen} editFlag={editFlag} editData={editData} />;
  }
  // Show loading indicator if loading
  if (loading) {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'flex';
    }
  } else {
    const mainLoader = document.getElementById('mainloader');
    if (mainLoader) {
      mainLoader.style.display = 'none';
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <Grid item container md={12} className={Styles.timeOffTable}>
      <Grid
        container
        item
        className={styles.parentContainer}
        lg={12}
        md={12}
        sm={12}
        xl={12}
        xs={12}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid container item md={4} sm={12} xs={12}>
          <SettingCustomHeader header="Leave Rules" paragraph="Add new leave categories." />
        </Grid>
        <Grid
          item
          container
          md={8}
          sm={12}
          xs={12}
          justifyContent={'space-between'}
          className={styles.upperGrid_padding}
        >
          <SearchBox
            data={[]}
            customStyle={`${Styles.searchBox} ${styles.timeSearchBox}`}
            handleChange={requestSearch}
            borderRadius="10px"
            placeholder="Search"
            size="small"
          />
          <CustomButtons
            icon={<AddIcon className={styles.customButton} />}
            name="Add Leave Rule"
            className={styles.add_button}
            onClick={openModal}
            variant="contained"
          />
        </Grid>
      </Grid>
      {data?.getAllowances && (
        <CustomTable
          data={data?.getAllowances?.allowanceInfo}
          hidePagination={false}
          tableCellFirstColStyle={Styles.tableCellFirstColStyles}
          tableHeader={Styles.tableHeader}
          enableCheckBox={true}
          checkboxClassName={Styles.checkboxCss}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          count={data?.getAllowances?.totalCount || 0}
          columns={[
            {
              Header: <Grid className={Styles.tableHead}>Rule Name</Grid>,
              accessor: 'policyName',
              Cell: ({ item }: any) => <Grid className={Styles.cell}>Rule 1</Grid>,
            },
            {
              Header: <Grid className={Styles.tableHead}>Status</Grid>,
              accessor: 'policyDescription',
              Cell: ({ item }: any) => <CustomButtons name="Active" className={Styles.ActBtn} />,
            },
            {
              Header: <Grid className={Styles.tableHead}>Employee Count</Grid>,
              Cell: ({ item }: any) => <Grid className={Styles.cell}>10</Grid>,
            },
            {
              Header: '',
              Cell: ({ item }: any) => (
                <Grid item className={Styles.moreVertIcon}>
                  <MenuItems
                    options={item.regularizeStatus === 'APPROVED' ? ['View', 'Delete'] : ['View']}
                    onViewDetails={(e: any) => handleViewDetails(item, e)}
                  />
                </Grid>
              ),
            },
          ]}
        />
      )}
      <ModalBox
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        content={
          <Grid className={createTimeOffStyles.mainContainer} md={12} sm={12} lg={12} xs={12}>
            <Grid
              item
              container
              md={12}
              sm={12}
              lg={12}
              xs={12}
              alignItems={'center'}
              className={createTimeOffStyles.headerContainer}
            >
              <Grid item className={createTimeOffStyles.headerSubContainer}>
                <Grid item>
                  <Typography className={createTimeOffStyles.headerText}>Add a New Leave Rule</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                md={2}
                sm={2}
                lg={2}
                xs={3}
                justifyContent={'flex-end'}
                className={styles.timeOffCloseIcon}
              >
                <CloseIcon onClick={handleClose} />
              </Grid>
            </Grid>

            <Divider sx={{ marginTop: '10px' }} />
            <Grid className={createTimeOffStyles.parentGrid}>
              <Grid md={12} className={createTimeOffStyles.inputtexts} container>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Grid container alignItems="center" className={createTimeOffStyles.resRuleNameTail}>
                    <Grid item md={8} sm={7} lg={6} xs={6}>
                      <Typography className={createTimeOffStyles.inputHeading}>Rule Name</Typography>
                      <Controller
                        control={control}
                        name="rulename"
                        rules={{
                          required: true,
                          pattern: /^[\w\s\d\(\)\-,:;.]+$/i,
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className={createTimeOffStyles.common_width}
                            {...field}
                            type="secondaryAddress"
                            placeholder="Enter Rule Name"
                            error={error !== undefined}
                            // inputRef={cityInputRef}
                            helperText={error ? 'Please enter your Rule Name' : ''}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={5} lg={6} xs={6} alignSelf={'flex-end'}>
                      <CustomButtons
                        icon={<AddIcon />}
                        name="Create Leave Type"
                        className={createTimeOffStyles.add_button}
                        onClick={handleAddLeaveRuleType}
                        variant="contained"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {timeOffAllowances && (
              <CustomTable1
                data={timeOffAllowances}
                hidePagination={true}
                tableCellFirstColStyle={Styles.tableCellFirstColStyles}
                tableHeader={Styles.tableHeader}
                columns={[
                  {
                    Header: <Grid className={Styles.tableHead}>Leave Name</Grid>,
                    accessor: 'policyName',
                    Cell: ({ item }: any) => {
                      return (
                        <div>
                          {item.policyName != '' ? (
                            <Grid className={Styles.cell}>{item.policyName}</Grid>
                          ) : (
                            <Grid>
                              <Controller
                                control={control}
                                name="policyName"
                                rules={{
                                  required: true,
                                  pattern: /^[\w\s\d\(\)\-,:;.]+$/i,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    className={createTimeOffStyles.common_width}
                                    {...field}
                                    type="secondaryAddress"
                                    placeholder="Enter Policy Name"
                                    error={error !== undefined}
                                    // inputRef={cityInputRef}
                                    helperText={error ? 'Please enter your Rule Name' : ''}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    Header: <Grid className={Styles.tableHead}>Disburesment Cycle</Grid>,
                    accessor: 'policyDescription',
                    Cell: ({ item }: any) => <Grid className={Styles.cell}>Monthly</Grid>,
                  },
                  {
                    Header: <Grid className={Styles.tableHead}>Leave Count</Grid>,
                    accessor: 'policyDescription',
                    Cell: ({ item }: any) => <Grid className={Styles.cell}>5</Grid>,
                  },
                  {
                    Header: <Grid className={Styles.tableHead}>Status</Grid>,
                    accessor: 'policyDescription',
                    Cell: ({ item }: any) => (
                      <CustomButtons
                        name={buttonStatus[item.id] ? 'Active' : 'In Active'}
                        className={createTimeOffStyles.ActBtn}
                        onClick={() => handleButtonClick(item.id)}
                      />
                    ),
                  },
                  {
                    Header: <Grid className={Styles.tableHead}>Action</Grid>,
                    Cell: ({ item }: any) => (
                      <Grid item className={`${Styles.moreVertIcon} ${createTimeOffStyles.editIcon}`}>
                        {item?.newItem ? (
                          <CustomButtons
                            name={'Save'}
                            className={createTimeOffStyles.ActBtn}
                            onClick={() => handleButtonClick(item.id)}
                          />
                        ) : (
                          <EditIcon onClick={openModal1} />
                        )}
                      </Grid>
                    ),
                  },
                ]}
              />
            )}
            <Grid className={createTimeOffStyles.stepperFooter}>
              <CustomButtons
                className={createTimeOffStyles.submitFinalBtn}
                loaderClass={styles.buttonLoader}
                variant="contained"
                type="button"
                name={'Cancel'}
                onClick={handleClose}
              />
              <CustomButtons
                className={createTimeOffStyles.submitFinalBtn}
                loaderClass={styles.buttonLoader}
                variant="contained"
                type="submit"
                name={'Submit'}
              // onClick={methods.handleSubmit(handleTimeOffLeave)}
              />
            </Grid>
          </Grid>
        }
      />

      {open1 && (
        <ModalBox
          open={open1}
          onClose={handleClose1}
          fullScreen={true}
          className={createTimeOffStyles.hrDocsSlider1}
          content={
            <>
              <Grid item container className={createTimeOffStyles.mainHrDocsSlider1}>
                <Grid item container className={createTimeOffStyles.containerHeader}>
                  <Grid item>
                    <Typography className={createTimeOffStyles.idText}>Configure Leave</Typography>
                  </Grid>
                  <Grid item className={createTimeOffStyles.closeicon}>
                    <IconButton>
                      <CloseIcon onClick={handleClose1} className={createTimeOffStyles.hrDocsCloseIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: '10px', width: '100%' }} />
                <Grid md={12} lg={12} sm={12} xs={12} mt={4} className={createTimeOffStyles.stepperGrid}>
                  <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => {
                      type properties = {
                        optional?: any;
                        error?: any;
                      };
                      const labelProps: properties = {};
                      const stepProps = {};

                      if (isStepFalied() && activeStep == index) {
                        labelProps.error = true;
                      }

                      return (
                        <Step {...stepProps} key={label} className={createTimeOffStyles.stepperLabel}>
                          <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Container maxWidth="lg" className={createTimeOffStyles.stepperContainer}>
                    <FormProvider {...methods}>
                      <form
                      // onSubmit={methods.handleSubmit(handleCreateEmployee)}
                      >
                        {getStepContent(activeStep)}
                      </form>
                    </FormProvider>
                    <Grid sx={{ display: 'flex' }} className={createTimeOffStyles.stepperFooter}>
                      {activeStep !== 0 && (
                        <CustomButtons
                          className={createTimeOffStyles.submitFinalBtn}
                          loaderClass={styles.buttonLoader}
                          variant="contained"
                          type="button"
                          name={'Previous'}
                          onClick={handleBack}
                        />
                      )}
                      {activeStep !== steps.length - 1 && (
                        <CustomButtons
                          className={createTimeOffStyles.submitFinalBtn}
                          loaderClass={styles.buttonLoader}
                          variant="contained"
                          type="submit"
                          name={'Next'}
                          onClick={methods.handleSubmit(handleTimeOffLeave)}
                        />
                      )}
                      {activeStep !== 0 && (
                        <CustomButtons
                          className={createTimeOffStyles.submitFinalBtn}
                          loaderClass={styles.buttonLoader}
                          variant="contained"
                          type="button"
                          name={'Cancel'}
                          onClick={handleClose1}
                        />
                      )}

                      {activeStep === steps.length - 1 && (
                        <CustomButtons
                          className={createTimeOffStyles.submitFinalBtn}
                          loaderClass={styles.buttonLoader}
                          variant="contained"
                          type="submit"
                          name={'Submit'}
                          onClick={methods.handleSubmit(handleTimeOffLeave)}
                        />
                      )}
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </>
          }
        />
      )}
    </Grid>
  );
};
export default TimeOffLeave;
