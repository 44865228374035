import React from "react";
import { Stack, Avatar, Typography, Box } from "@mui/material";

interface Employee {
    profileImage: any;
    name: string;
    jobTitleName: string;
    child?: Employee[];
}
interface CardProps {
    data: Employee;
    onToggleExpand: () => void;
    isExpanded: boolean;
}

const Card: React.FC<CardProps> = ({ data, onToggleExpand, isExpanded }) => {
    return (
        <>
            <Box className="org_card" sx={{ borderLeft: data.child && data.child.length > 0 ? "1px solid #ccc" : "none" }}>
                <Stack alignItems="center" spacing={1}>
                    <Avatar
                        src={data?.profileImage?.url}
                        className="org_avatar"
                    />
                    <Stack alignItems="center">
                        <Typography className="org_title">{data.name}</Typography>
                        <Typography color="#475467" className="org_subTitle">
                            {data.jobTitleName}
                        </Typography>
                    </Stack>


                </Stack>
                {data.child && data.child.length > 0 && (
                    <button className="org_button" onClick={onToggleExpand}>{isExpanded ? "-" : "+"}</button>
                )}
            </Box>
        </>

    );
};

export default Card;
