import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import styles from "src/Styles/addEmployee.module.css";
import CustomSelect from "src/components/CustomSelect/customSelectValidations";
import { useForm } from "react-hook-form";
import BankDetailData from "./BankDetaildata.json";
import CustomButtons from "src/components/CustomButton/Button";
import CustomTextField from "src/components/CustomTextField";
import { Toast } from "src/components/ToastMessage";
import { UPDATE_EMPLOYEE } from "src/graphql/user";
import { useMutation } from "@apollo/client";

function BankDetails({ editEmpRecord, editEmpRefetch }: any) {
  const [
    updateEmployee,
    // eslint-disable-next-line
    { data: updateEmployeeData, loading: updateEmployeeLoading },
  ] = useMutation(UPDATE_EMPLOYEE);

  useEffect(() => {
    const FormatedEditEmployeeData = {
      bankName: editEmpRecord?.bankDetails?.bankName || "",
      accountNo: editEmpRecord?.bankDetails?.accountNumber || "",
      accountName: editEmpRecord?.bankDetails?.accountName || "",
      ifscCode: editEmpRecord?.bankDetails?.IFSCCode || "",
      bankLocation: editEmpRecord?.bankDetails?.location || "",
    };
    reset(FormatedEditEmployeeData);
    // eslint-disable-next-line
  }, [editEmpRecord]);

  const { control, reset, handleSubmit }: any = useForm({});

  const handleUpdateBankInfo = async (data: any) => {
    try {
      const userData = {
        bankDetails: {
          bankName: data.bankName,
          accountName: data.accountName,
          accountNumber: data.accountNo,
          IFSCCode: data.ifscCode,
          location: data.bankLocation,
        },
      };

      const updateResponse: any = await updateEmployee({
        variables: {
          updateEmployeeId: editEmpRecord?.id,
          input: userData,
        },
      });

      if (updateResponse) {
        editEmpRefetch();
        Toast("success", "Bank Details updated Successfully");
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  return (
    <Grid
      container
      md={12}
      sm={12}
      lg={12}
      xl={12}
      xs={12}
      className={styles.createEmployeeContainer}
    >
      <Grid
        container
        component="form"
        className={styles.infoWraper}
        onSubmit={handleSubmit(handleUpdateBankInfo)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Grid container>
          <Grid container mb={3} className={styles.headerGrid}>
            <Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subHeading}>
                  Bank Details
                </Typography>
              </Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subText}>
                  Here are all the employees at your company.
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <CustomButtons
                name="Update"
                variant="contained"
                className={styles.createBtn}
                type="submit"
                loading={updateEmployeeLoading}
                disabled={updateEmployeeLoading}
                loaderClass={styles.buttonLoader}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid item lg={4} md={4} sm={6} xs={12} className={styles.formGrid}>
              <Typography className={styles.formLabel}>Select Bank</Typography>
              <CustomSelect
                // error={Boolean(errors.bankName)}
                // errorCondition={
                //   errors.bankName && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.bankName.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="bankName"
                selectData={BankDetailData.selectBank}
                placeHolder="Select Bank"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={12} className={styles.formGrid}>
              <Typography className={styles.formLabel}>Account No</Typography>
              <CustomTextField
                // error={Boolean(errors.accountNo)}
                // errorCondition={
                //   errors.accountNo && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.accountNo.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="accountNo"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="0017562545"
              />
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={12} className={styles.formGrid}>
              <Typography className={styles.formLabel}>Account Name</Typography>
              <CustomTextField
                // error={Boolean(errors.accountName)}
                // errorCondition={
                //   errors.accountName && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.accountName.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="accountName"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="John Miles"
              />
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={12} className={styles.formGrid}>
              <Typography className={styles.formLabel}>IFSC Code</Typography>
              <CustomTextField
                // error={Boolean(errors.ifscCode)}
                // errorCondition={
                //   errors.ifscCode && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.ifscCode.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="ifscCode"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="HDFC0000003"
              />
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={12} className={styles.formGrid}>
              <Typography className={styles.formLabel}>Location</Typography>
              <CustomTextField
                // error={Boolean(errors.bankLocation)}
                // errorCondition={
                //   errors.bankLocation && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.bankLocation.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="bankLocation"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="Location"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container className={styles.editBtnContainer}>
          <CustomButtons
            name="Update"
            variant="contained"
            className={styles.createBtn}
            type="submit"
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default BankDetails;
