import gql from "graphql-tag";

export const ATTENDANCE_IN_OUT = gql`
  mutation AttendanceInOut($input: AttendanceInput) {
    attendanceInOut(input: $input) {
      status
      userId
      loginTime
      totalTime
      source
      shiftId
      logoutTime
      id
      attendanceDate
      timeZone
      isLoggedIn
    }
  }
`;

export const GET_ATTENDANCE = gql`
  query getAttendances($userId: ID) {
    getAttendances(userId: $userId) {
      userId
      loginTime
    }
  }
`;

export const GET_ATTENDANCE_BY_ID = gql`
  query getAttendanceById($userId: ID!, $attendanceDate: Date!) {
    getAttendanceById(userId: $userId, attendanceDate: $attendanceDate) {
      userId
      loginTime
      attendanceDate
      status
      loginTime
      logoutTime
      source
      isLoggedIn
      totalTime
    }
  }
`;
