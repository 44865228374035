import moment from "moment";

export const getFormattedTime = (value: any) => {
  if (!value) return "00:00";
  let time: any = moment(value);

  let formattedTime = `${
    time.hour() > 12
      ? String(time.hour() - 12).padStart(2, "0")
      : String(time.hour()).padStart(2, "0")
  }:${String(time.minutes()).padStart(2, "0")}`;
  formattedTime = formattedTime + (time.hour() >= 12 ? " PM" : " AM");

  return formattedTime;
};
