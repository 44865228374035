import React, { useState } from "react";
import { Card as MUICard, Grid, Divider, Avatar } from "@mui/material";
import styles from "src/Styles/Cards.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import avatar from "src/images/assets/avatar.png";
import EmployeeDetailsModel from "src/components/EmployeeDetailsModel/index";
import moment from "moment";
import { GET_USER_BY_ID } from "src/graphql/user";
import { useQuery } from "@apollo/client";

function CardComponent({ directoryUsersData }: any) {
  const [mopen, setOpen] = React.useState(false);
  const [userID, setUserID] = useState("");

  const { data: userDataById } = useQuery(GET_USER_BY_ID, {
    variables: { userId: userID },
    skip: !userID,
  });

  const handleClickOpen = (id: any) => {
    setUserID(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MUICard
        className={styles.cards}
        onClick={() => handleClickOpen(directoryUsersData.id)}
      >
        <Grid container xs={12}>
          <Grid container xs={12} className={styles.firstGrid}>
            <Grid xs={9}>
              <Grid>
                <button className={styles.button}>
                  {directoryUsersData.status
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (word: any) =>
                        word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(" ")}
                </button>
              </Grid>
            </Grid>
            <Grid container xs={3} className={styles.firstInternalGrid}>
              <MoreHorizIcon className={styles.Icon} />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            className={styles.secondGrid}
          >
            <Grid item>
              {/* <Avatar className={styles.avatar}>
                {directoryUsersData?.firstName?.charAt(0)}
              </Avatar> */}
              <Avatar alt="avatar" src={avatar} className={styles.avatar} />
            </Grid>
          </Grid>
          <Grid xs={12} className={styles.name}>
            {directoryUsersData?.displayName}
          </Grid>
          <Grid xs={12} className={styles.designation}>
            {directoryUsersData?.jobTitleName}
          </Grid>

          <Divider className={styles.divider} />
          <Grid className={styles.BackgroundGrid} xs={12}>
            <Grid container xs={12}></Grid>
            <Grid xs={12} className={styles.dptSection}>
              <Grid xs={6} className={styles.DepartmentTitle}>
                Department
              </Grid>
              <Grid xs={6} className={styles.JoinedDateTitle}>
                Joined Date
              </Grid>
            </Grid>

            <Grid xs={12} className={styles.dptName}>
              <Grid xs={6} className={styles.department}>
                {directoryUsersData?.departmentName}
              </Grid>
              <Grid xs={6} className={styles.JoinedDate}>
                {moment(directoryUsersData?.joiningDate).format("YYYY/MM/DD")}
              </Grid>
            </Grid>

            <Grid xs={12} className={styles.phno}>
              {directoryUsersData?.phone || "-"}
            </Grid>
            <Grid xs={12} className={styles.MailId}>
              {directoryUsersData?.email}
            </Grid>
          </Grid>
        </Grid>
      </MUICard>

      {mopen && (
        <EmployeeDetailsModel
          onClose={handleClose}
          open={mopen}
          employeeDetails={userDataById?.getUserById}
        />
      )}
    </>
  );
}

export default CardComponent;
