import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Card, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import styles from "src/Styles/AddjobTitle.module.css";
import Divider from "@mui/material/Divider";
import ModalBox from "src/components/ReusableModal/modal";
import { useForm } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import {
  CREATE_DOCUMENT_TYPE,
  GET_ALLDOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
} from "../../graphql/documentType";
import { Toast } from "../ToastMessage";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";

const AddDocumentType = ({
  onClick,
  open,
  onClose,
  setOpen,
  editFlag,
  editData,
}: any) => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const [createDocumentType] = useMutation(CREATE_DOCUMENT_TYPE, {
    refetchQueries: [
      GET_ALLDOCUMENT_TYPE,
      "getAllDocumentType", // Query name
    ],
  });
  const [updateDocumentType] = useMutation(UPDATE_DOCUMENT_TYPE, {
    refetchQueries: [
      GET_ALLDOCUMENT_TYPE,
      "getAllDocumentType", // Query name
    ],
  });

  const schema = yup.object().shape({
    documentType: yup.string().required(),
    description: yup.string().required(),
  });

  React.useEffect(() => {
    if (editFlag) {
      const defaultValues = {
        documentType: editData.documentType,
        description: editData.description,
      };
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [editData, editFlag]);

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (data: any) => {
    if (editFlag) {
      try {
        const createData = {
          documentType: data.documentType,
          description: data.description,
          createdBy: userInfo?.id,
        };
        const response = await updateDocumentType({
          variables: { updateDocumentTypeId: editData.id, input: createData },
        });
        if (response.data) {
          Toast("success", "Job Title updated Successfully");
          setOpen(false);
        }
      } catch (error: any) {
        console.error("Error creating Department:", error);
        Toast("error", error?.message);
      }
    } else {
      try {
        const createData = {
          documentType: data.documentType,
          description: data.description,
          createdBy: userInfo?.id,
        };

        const response = await createDocumentType({
          variables: { input: createData },
        });
        if (response.data) {
          Toast("success", "Document type created Successfully");
          setOpen(false);
        }
      } catch (error: any) {
        console.error("Error creating document Type:", error);
        Toast("error", error?.message);
      }
    }
  };

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        content={
          <Card className={styles.modalContent}>
            <Box className={styles.headerContainer}>
              <Grid>
                <Typography className={styles.headerText}>
                  {!editFlag ? "Add Document Type" : "Edit Document Type"}
                </Typography>
                <Typography className={styles.headerTextSub}>
                  {!editFlag
                    ? "Create new Document Type by using different locations to arrange employees geographically"
                    : "Edit  Document Type by using different locations to arrange employees geographically"}
                </Typography>
              </Grid>
              <Grid mb={2}>
                <CloseIcon onClick={onClose} className={styles.closeIcon} />
              </Grid>
            </Box>

            <Divider />
            <Grid container className={styles.bottomDiv}>
              <Grid xs={12}>
                <Typography className={styles.inputHeading}>
                  Document Type
                </Typography>
              </Grid>
              <form
                onSubmit={handleSubmit(handleOnSubmit)}
                className={styles.textField}
              >
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="documentType"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth={true}
                        {...field}
                        type="documentType"
                        placeholder="Aadhar card "
                        error={error !== undefined}
                        helperText={
                          error ? "please enter your document Type" : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <Typography className={styles.inputHeading}>
                    Document Description
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="description"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth={true}
                        {...field}
                        type="description"
                        placeholder="Aadhar card description"
                        error={error !== undefined}
                        helperText={
                          error ? "please enter your description" : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} className={styles.buttonsDiv}>
                  <CustomButtons
                    name={
                      !editFlag ? "Add Document Type" : "Edit Document Type"
                    }
                    type="submit"
                    variant="contained"
                    className={styles.buttons}
                  />
                </Grid>
              </form>
            </Grid>
          </Card>
        }
      />
    </>
  );
};

export default AddDocumentType;
