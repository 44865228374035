import gql from "graphql-tag";

export const GET_ALLOWANCES = gql`
  query GetAllowances($email: String,$offset:Int,$limit:Int)  {
    getAllowances(email: $email,offset:$offset,limit:$limit) {
      allowanceInfo{
      id
      policyName
      }
     totalCount
    }
  }
`;
