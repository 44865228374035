import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalBox from "src/components/ReusableModal/modal";
import styles from "src/Styles/ApplyLeaveModel.module.css";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_EMPLOYEE_LEAVES,
  GET_EMPLOYEE_LEAVES,
} from "src/graphql/employeeLeaves";
import { GET_TIMEOFF_ALLOWANCE } from "src/graphql/timeOff";
import moment from "moment";
import { Toast } from "../ToastMessage";
import { GET_USER_BY_ID } from "src/graphql/user";
import { useAppSelector } from "src/store/hooks";
import type { RootState } from "src/store/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { ApplyLeaveSchema } from "src/utils/validations";
import CustomDateRangePicker from "../CustomDateRangePicker";
import CustomButtons from "../CustomButton/Button";

const ApplyLeaveModel = ({
  onClick,
  open,
  onClose,
  content,
  selectedDateFromcalender,
}: any) => {
  const userId = localStorage.getItem("userId");

  const [selectedLeaveType, setSelectedLeaveType] = useState("FULL_DAY");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [values, setValues] = useState<any>("");
  const [selectedAllowanceData, setSelectedAllowanceData] = useState<any>({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  }: any = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(ApplyLeaveSchema),
  });

  useEffect(() => {
    setSubmitDisabled(!isValid);
  }, [isValid]);

  const { data: allowancePayload } = useQuery(GET_TIMEOFF_ALLOWANCE, {
    variables: {
      limit: 0, offset: 0
    }
  });

  const [createEmployeeLeaves, { loading: createLeaveLoading }] = useMutation(
    CREATE_EMPLOYEE_LEAVES,
    {
      refetchQueries: [
        {
          query: GET_EMPLOYEE_LEAVES,
          variables: {
            employeeId: userId,
            filterStatus: "PENDING",
          },
        },
      ],
    }
  );

  const {
    data: userById,
    // loading: userByIdLoading,
    // error: userByIdError,
    refetch: userByIdRefetch,
  } = useQuery(GET_USER_BY_ID, {
    variables: { userId: userInfo?.id },
  });

  useEffect(() => {
    setValues(moment(selectedDateFromcalender));
  }, [selectedDateFromcalender]);

  const handleOnSubmit = async (evt: any) => {
    let formattedDates = [];

    if (evt.leaveDate) {
      const timestamps = evt.leaveDate.map((dateObject: any) =>
        dateObject?.toDate().getTime()
      );
      formattedDates = timestamps.map((timestamp: any) =>
        moment(timestamp).format("YYYY-MM-DD")
      );
    }

    try {
      const input = {
        employeeId: userId,
        reason: evt.reason,
        appliedFrom: formattedDates[0] || null,
        appliedTo: formattedDates[1] || null,
        leaveType: selectedLeaveType,
        allowanceId: evt.leaveType,
        reportingManagerId: "",
      };

      const response = await createEmployeeLeaves({
        variables: { input },
      });
      if (response.data) {
        Toast("success", "Leave applied Successfully");
        handleClose();
      }

      userByIdRefetch();
    } catch (error: any) {
      console.error("Error creating Employee Leave:", error);
      Toast("error", error?.message);
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedLeaveType("FULL_DAY");
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleLeaveTypeChange = (event: any) => {
    const newValue = event.target.value;
    setSelectedLeaveType(newValue);
  };

  const handleLeaveBalenceCheck = (id: any) => {
    const userrAllowancesData: any =
      userById?.getUserById?.userAllowances || [];

    const selectedAllowance = userrAllowancesData.find(
      (allowance: any) => allowance?.allowanceId === id
    );
    setSelectedAllowanceData(selectedAllowance);
  };

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        content={
          <>
            <Grid
              container
              spacing={1}
              component="form"
              onSubmit={handleSubmit(handleOnSubmit)}
              className={styles.mainContainer}
            >
              <Grid item container className={styles.applyLeaveText}>
                <Grid item md={11}>
                  <Typography variant="subtitle1" className={styles.headerText}>
                    Apply Leave
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <CloseOutlinedIcon
                    onClick={onClose}
                    className={styles.closeIcon}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={12}
                sm={12}
                lg={12}
                className={styles.leaveTypeInput}
              >
                <Controller
                  control={control}
                  name="leaveType"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl fullWidth>
                        {/* <FormControl> */}
                        <InputLabel id="demo-simple-select-label">
                          Request Type *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Request Type *"
                          id="demo-simple-select"
                          {...field}
                          error={error !== undefined}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        >
                          {allowancePayload?.getAllowances?.allowanceInfo?.map((item: any) => {
                            const isDisabled =
                              userById?.getUserById?.userAllowances.find(
                                (allowance: any) =>
                                  allowance?.allowanceId === item?.id &&
                                  allowance?.allowancesAvailable <= 0
                              );

                            return (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                onClick={() => {
                                  field.onChange(item.id);
                                  handleLeaveBalenceCheck(item.id);
                                }}
                                disabled={isDisabled}
                              >
                                {item.policyName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
                {errors.leaveType && (
                  <Typography color="error" className={styles.errorText}>
                    {errors.leaveType.message}
                  </Typography>
                )}
                {selectedAllowanceData?.allowancesAvailable && (
                  <Grid sx={{ mt: 1, color: "orange" }}>
                    <Typography>
                      Balance: {selectedAllowanceData?.allowancesAvailable}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <>
                <Grid
                  container
                  item
                  className={styles.datePickerInput}
                  spacing={1}
                >
                  <Grid item md={8} lg={8} sm={8} xs={12}>
                    <Controller
                      control={control}
                      name="leaveDate"
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState: { error } }: any) => (
                        <CustomDateRangePicker
                          field={field}
                          containerClassName={styles.dateRangeCustomContainer}
                          isEditable={false}
                          format="YYYY-MM-DD"
                          placeholder="YYYY-MM-DD - YYYY-MM-DD"
                        />
                      )}
                    />
                    {errors.leaveDate && (
                      <Typography color="error" className={styles.errorText}>
                        {errors.leaveDate.message}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    md={4}
                    lg={4}
                    sm={4}
                    xs={12}
                    className={styles.dayInput}
                  >
                    <Controller
                      control={control}
                      name="leaveType"
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth>
                          {/* <FormControl> */}
                          <Select
                            value={selectedLeaveType}
                            onChange={handleLeaveTypeChange}
                          >
                            <MenuItem value="FULL_DAY">Full Day</MenuItem>
                            <MenuItem value="FIRST_HALF">First Half</MenuItem>
                            <MenuItem value="SECOND_HALF">Second Half</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </>

              <Grid
                container
                item
                md={12}
                sm={12}
                lg={12}
                className={styles.datePickerInput}
              >
                <Controller
                  control={control}
                  name="reason"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth={true}
                      size="small"
                      {...field}
                      multiline
                      label="Reason *"
                      rows={4}
                      variant="outlined"
                      error={error !== undefined}
                    />
                  )}
                />
                {errors.reason && (
                  <Typography color="error" className={styles.errorText}>
                    {errors.reason.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                className={styles.buttonContainer}
                lg={12}
                sm={12}
                md={12}
                xs={12}
              >
                <Grid item className={styles.buttonGrid}>
                  <Button onClick={onClose} className={styles.cancelButton}>
                    Cancel
                  </Button>
                </Grid>

                <Grid item className={styles.buttonGrid}>
                  <CustomButtons
                    name="Submit"
                    type="submit"
                    variant="contained"
                    className={`${styles.submitbButton} ${isSubmitDisabled ? styles.disabledButton : ""
                      }`}
                    loading={createLeaveLoading}
                    disabled={isSubmitDisabled || createLeaveLoading}
                    loaderClass={styles.buttonLoader}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
export default ApplyLeaveModel;
