import React, { useState } from "react";
import styles from "../../Styles/Login.module.css";
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import xperia from "../../images/assets/Arokee Logo Black .png";
import loginLogo from "../../images/assets/loginLogo.png";
import { Link } from "react-router-dom";
import AuthLayout from "src/components/layouts/AuthLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { LOGIN_USER, AUTH_RESPONSE } from "src/graphql/connection";
import GoogleAuth from "../../components/GoogleAuth";
import { useForm, Controller } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const schema = yup.object().shape({
    password: yup.string().required(),
    email: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });
  // eslint-disable-next-line
  const [loginUsr, { data, error, loading }] = useMutation(LOGIN_USER);
  const [
    GoogleAuthentication,
    {
      data: googleauthRes,
      error: googleauthResError,
      loading: googleauthResLoading,
    },
  ] = useMutation(AUTH_RESPONSE);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleAuth = async (evt: any) => {
    try {
      const response = await GoogleAuthentication({
        variables: {
          email: evt,
        },
      });
      // Access the accessToken from the response data
      const accessToken = response.data.googleAuthentication.accessToken;
      const id = response.data.googleAuthentication.id;
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userId", id);
        // Redirect to the hrms/dashboard page.
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSubmit = async (evt: any) => {
    try {
      const response = await loginUsr({
        variables: {
          email: evt.email,
          password: evt.password,
        },
      });
      // Access the accessToken from the response data
      const accessToken = response.data.loginUser.accessToken;
      const id = response.data.loginUser.id;
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("sidebarIndex", "employeeDashboard")
        localStorage.setItem("userId", id);
        // Redirect to the hrms/dashboard page.
        navigate("/");
      }
    } catch (err) {
      // Handle any errors here
      console.error("Error:", err);
    }
  };

  //if (loading) return <Typography>loading......</Typography>;
  return (
    <Grid container component="main" className={styles.mainPage}>
      <Grid md={6} lg={6} sm={6} xs={12} className={styles.leftGrid}>
        <img src={loginLogo} alt="loginLogo" className={styles.loginLogo} />
      </Grid>
      <Grid
        md={6}
        lg={6}
        sm={12}
        xs={12}
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.right_half}
      >
        <Grid container className={styles.rightHalfWrapper}>
          <Grid container>
            <Grid item md={9} sm={9} lg={9} xs={9}>
              <img
                src={xperia}
                alt="front_image"
                className={styles.xperia_image}
              />
            </Grid>

            <Grid item md={3} sm={3} lg={3} xs={3} />
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="h4" className={styles.WelcomeText}>
                Welcome to HRMS
              </Typography>
            </Grid>

            <Card className={styles.signInContainer}>
              <CardContent>
                <Typography className={styles.sigin_text}>
                  Sign in, to get started
                </Typography>
                <Divider />
                <Grid className={styles.cardBody}>
                  <Typography className={styles.emailText}>
                    Email address
                  </Typography>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        className={styles.textField}
                        {...field}
                        type="email"
                        placeholder="Enter Work Email"
                        label=""
                        error={error !== undefined}
                        helperText={error ? "Please enter a valid email" : ""}
                      />
                    )}
                  />
                  <Typography className={styles.emailText}>Password</Typography>
                  <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        className={styles.textField}
                        {...field}
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        label=""
                        error={error !== undefined}
                        helperText={
                          error ? "Please enter a valid password" : ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Typography>
                    <Link
                      to="/forgot-password"
                      className={styles.forgotPassword}
                    >
                      Forgot your Password?
                    </Link>
                  </Typography>

                  <CustomButtons
                    name="Log in"
                    variant="contained"
                    className={styles.loginbutton}
                    type="submit"
                  />
                  <Grid className={styles.dividerContainer}>
                    <Grid className={styles.divider}></Grid>
                    <span className={styles.or}>or</span>
                    <Grid className={styles.divider}></Grid>
                  </Grid>

                  {(error || googleauthResError) && (
                    <Typography className={styles.errText}>
                      {error?.message || googleauthResError?.message}
                    </Typography>
                  )}

                  <Grid
                    md={12}
                    sm={12}
                    lg={12}
                    xs={12}
                    className={styles.googleAuthbutton}
                  >
                    <GoogleAuth email={handleAuth} />
                  </Grid>
                </Grid>

                <Grid className={styles.termsBody}>
                  <Typography className={styles.termsLinks}>
                    <span className={styles.termsOfService}>
                      Terms of Service
                    </span>
                    <span className={styles.privacy}>Privacy Policy</span>
                  </Typography>
                  <Typography className={styles.details}>
                    For details of how we use personal data see our privacy and
                    data processing statement
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;

Login.getLayout = function getLayout(page: any) {
  return (
    <AuthLayout>
      <div>{page}</div>
    </AuthLayout>
  );
};
