import { Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ExcelExport from "src/components/ExcelExport";
import AttendanceSheet from "src/components/AttendanceSheet/AttendanceSheet";
import Styles from "src/Styles/Calendar.module.css";
import React from "react";

interface CustomToolbarProps {
  onNavigate: (direction: "PREV" | "NEXT") => void;
  label: string;
  events: any[]; // Update the type of events accordingly
  refetchMonth: (direction: "PREV" | "NEXT", month: string) => void;
  props: any;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  onNavigate,
  label,
  refetchMonth,
  ...props
}) => {
  const handlePrev = () => {
    if (typeof onNavigate === "function") {
      onNavigate("PREV");
      refetchMonth("PREV", label);
    }
  };

  const handleNext = () => {
    if (typeof onNavigate === "function") {
      onNavigate("NEXT");
      refetchMonth("NEXT", label);
    }
  };
  const [isattendance, setIsAttendance] = React.useState(false);

  const handleClickOpen = () => {
    setIsAttendance(true);
  };

  const handleClose = () => {
    setIsAttendance(false);
  };
  return (
    <Grid item container justifyContent={"space-between"}>
      <Grid item lg={6} md={6} xs={6} className={Styles.ToolBar}>
        <IconButton onClick={handlePrev}>
          <ArrowBackIosIcon className={Styles.Icon} />
        </IconButton>
        <IconButton onClick={handleNext}>
          <ArrowForwardIosIcon className={Styles.Icon} />
        </IconButton>
        <Typography className={Styles.label}>{label}</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent={"space-between"}
        textAlign={"right"}
        lg={6}
        md={6}
        xs={6}
      >
        <Grid item lg={6} md={6} xs={6}>
          <ExcelExport />
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <IconButton>
            <FullscreenIcon
              onClick={handleClickOpen}
              className={Styles.ScreenIcon}
            />
            {isattendance && (
              <AttendanceSheet
                onClose={handleClose}
                open={isattendance}
                label={label}
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomToolbar;
