import React from "react";
import { Controller } from "react-hook-form";
import { TextareaAutosize, Grid } from "@mui/material";

export default function CustomTextareaAutosize({
  error,
  errorCondition,
  control,
  name,
  className,
  placeholder,
  onChange,
}: any) {
  return (
    <Grid container>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextareaAutosize
            {...field}
            minRows={5}
            maxRows={7}
            className={className}
            style={{ borderColor: error ? "red" : "", padding: "12px" }}
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        )}
      />
      {errorCondition}
    </Grid>
  );
}
