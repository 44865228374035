import gql from "graphql-tag";

export const GET_ADMIN_CALENDAR_DATA = gql`
  query GetAdminCalendarData(
    $startDate: Date
    $endDate: Date
    $monthOfYear: String
    $offset: Int
    $limit: Int
    $searchString: String
  ) {
    getAdminCalendarData(
      startDate: $startDate
      endDate: $endDate
      monthOfYear: $monthOfYear
      offset: $offset
      limit: $limit
      searchString: $searchString
    ) {
      data {
        name
        department
        attendance {
          id
          userId
          loginTime
          logoutTime
          source
          status
          timeZone
          shiftId
          location {
            loginLocation {
              address
              city
              state
            }
            logoutLocation {
              address
              city
              state
            }
          }
          attendanceDate
          totalTime
          isLoggedIn
          isAbsent
          isHoliday
          isWeekend
          date
          allowanceName
          leaveType
          wfhType
          regularizeAppliedForHalfDay
        }
        userAllowances {
          policyName
          allowancesAvailable
        }
        userAllowancesCurrentBalance
        currentMonthLeaveCount
      }
      lastOffset
      nextOffset
      currentPage
      pageSize
      totalRecords
    }
  }
`;
