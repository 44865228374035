import React from "react";
import CustomTab from "../CustomTab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import WorkInformation from "./WorkInformation/WorkInformation";
import PersonalInformation from "./PersonalInfoData/PersonalInformation";
import CompensationDetails from "./CompensationDetail/CompensationDetails";
import BankDetails from "./Bankdetails/BankDetails";
import EducationDetails from "./EducationaDetails/EducationDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import EmployeeDocuments from "./EmployeeDocuments";
import styles from "../../Styles/addEmployee.module.css";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import TabInfoIcon from "./TabInfoIcon";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useQuery } from "@apollo/client";
import { GET_USER_BY_ID } from "src/graphql/user";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

interface TabData {
  id: number;
  label: JSX.Element;
  component: any;
  href?: string;
}
const useStyles = makeStyles((theme: any) => ({
  root: {
    // Define your styles for pseudo-classes here
    "&:hover": {
      // Apply styles for hover pseudo-class
      backgroundColor: "red",
    },
    "&:active": {
      // Apply styles for active pseudo-class
      backgroundColor: "green",
    },
  },
  icon: {
    [theme.breakpoints.down(700)]: {
      fontSize: "20px",
    },
  },
}));

const EditEmployee = () => {
  const { id } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [strokeColor, setStrokeColor] = React.useState("grey");

  const handleCircleClick = () => {
    setStrokeColor(""); // Update the stroke color to green on click
  };

  const classes = useStyles();
  const {
    data: editEmpData,
    // eslint-disable-next-line
    loading: editEmpLoading,
    refetch: editEmpRefetch,
  } = useQuery(GET_USER_BY_ID, {
    variables: { userId: id },
  });

  const editEmpRecord = editEmpData?.getUserById;

  const data: TabData[] = [
    {
      id: 0,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<CorporateFareOutlinedIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <WorkInformation
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
        />
      ),
    },
    {
      id: 1,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<PersonOutlineIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <PersonalInformation
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
        />
      ),
    },
    {
      id: 2,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<CurrencyRupeeOutlinedIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <CompensationDetails
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
          employeeID={id}
        />
      ),
    },
    {
      id: 3,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<AssuredWorkloadOutlinedIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <BankDetails
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
        />
      ),
    },
    {
      id: 4,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<SchoolOutlinedIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <EducationDetails
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
        />
      ),
    },
    {
      id: 5,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<BusinessCenterOutlinedIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <ProfessionalDetails
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
        />
      ),
    },
    {
      id: 6,
      label: (
        <TabInfoIcon
          strokeColor={strokeColor}
          handleCircleClick={handleCircleClick}
          classes={classes}
          icon={<DescriptionOutlinedIcon style={{ fontSize: "30px" }} />}
        />
      ),
      component: (
        <EmployeeDocuments
        // editEmpRecord={editEmpRecord}
        // editEmpRefetch={editEmpRefetch}
        />
      ),
    },
  ];

  return (
    <Grid className={styles.editEmpMainDiv}>
      <CustomTab
        myTabData={data}
        orientation={isMobile ? "horizontal" : "vertical"}
        moreSideIcon={false}
        customBtnStyle={styles.customTabStyle}
        tabPanelStyle={styles.tabPanelStyle}
      />
    </Grid>
  );
};

export default EditEmployee;
