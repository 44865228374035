import * as Yup from "yup";

let emailRegex =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ApplyLeaveSchema = Yup.object().shape({
  leaveType: Yup.string().required("Request type field is required."),
  leaveDate: Yup.array().min(2, "Date range is required."),
  reason: Yup.string().required("Reason field is required."),
});

export const ApplyWorkFromHomeSchema = Yup.object().shape({
  wfhDate: Yup.string().required("Date  field is required."),
  wfhType: Yup.string().required("Request type field is required."),
  reason: Yup.string().required("Reason field is required."),
});

// export const AddNewDocuments = Yup.object().shape({
//   documentName: Yup.string().required("This Feild is required"),
//   remarks: Yup.string().required("This Feild is required"),
// });

export const AddNewDocuments = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      documentName: Yup.string().required("Document name is required"),
      remarks: Yup.string().required("Remarks are required"),
      fileName: Yup.string().required("File upload is required"),
    })
  ),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter the email")
    .matches(emailRegex, { message: "Please enter a valid email" }),
});

export const ResetPasswordSchema = Yup.object().shape({
  newpassword: Yup.string().required("Please enter a new password"),
  confirmPassword: Yup.string()
    .required("Please confirm your new password")
    .oneOf([Yup.ref("newpassword"), ""], "Passwords must match"),
});

export const UploadLogoSchema = Yup.object().shape({
  documentName: Yup.string().required("Please enter a valid documentName"),
  file: Yup.string().required("Please upload a valid file"),
});

export const AddEntitySchema = Yup.object().shape({
  selectOptionLanguage: Yup.string().required(),
  selectOptionIndustry: Yup.string().required(),
  name: Yup.string().required(),
  website: Yup.string().required(),
  description: Yup.string().required(),
  email: Yup.string().required(),
  mobileNumber: Yup.string().required(),
  documentName: Yup.string().required("Please enter a valid documentName"),
  file: Yup.string().required("Please upload a valid file"),
});

export const createEmployeeSchema = Yup.object().shape({
  employeeCode: Yup.string()
    .required("Please enter the Employee Code")
    .min(3, "Employee Code must be at least 3 characters"),
  firstName: Yup.string().required("Please enter the First Name"),
  lastName: Yup.string().required("Please enter the Last Name"),
  fullName: Yup.string()
    .required("Please enter the Full Name")
    .min(3, "Full Name must be at least 3 character"),
  personalEmail: Yup.string()
    .required("Please enter the Personal Email")
    .matches(emailRegex, { message: "Please enter a valid email" }),
  phone: Yup.string().required("Please enter the Mobile Number"),
  // .matches(/[0-9]/g, "Must be only digits")
  // .min(9, "Mobile Number must be at least 9 digits"),
  gender: Yup.string().required("Please enter the Gender"),
  dob: Yup.string().required("Please enter the DOB"),
  joiningDate: Yup.string().required("Please enter the Joining Date"),
  nationality: Yup.string().required("Please enter the Nationality"),
  reportingManager: Yup.string().required("Please enter the Reporting Manger"),
  department: Yup.string().required("Please enter the Department"),
  role: Yup.string().required("Please enter the Role"),
  employmentType: Yup.string().required("Please enter the Employment Type"),
  employmentStatus: Yup.string().required("Please enter the Employment Status"),
  probationTerm: Yup.string().required("Please enter the Probation Term"),
  probationEndDate: Yup.string().when("probationTerm", {
    is: (value: any) => value === "N/A",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) =>
      Yup.string().required("Please enter the Probation End Date"),
  }),
  noticePeriod: Yup.string().required("Please enter the Notice Period"),
  designation: Yup.string().required("Please enter the Designation"),
  workEmail: Yup.string()
    .required("Please enter the Work Email")
    .matches(emailRegex, { message: "Please enter a valid email" }),
  companyName: Yup.string().required("Please enter the Company Name"),
  location: Yup.string().required("Please enter the Work Place"),
  hrManager: Yup.string().required("Please enter the HR Manager"),
  shift: Yup.string().required("Please enter the Shift"),
  sourceOfVerification: Yup.string().required(
    "Please enter the Source of Verification"
  ),
});

export const workInfoSchema = Yup.object().shape({
  employeeCode: Yup.string()
    .required("Please enter the Employee Code")
    .min(3, "Employee Code must be at least 3 characters"),
  employmentType: Yup.string().required("Please enter the Employment Type"),
  employmentStatus: Yup.string().required(
    "Please enter the Employment  Status"
  ),
  fullName: Yup.string()
    .required("Please enter the Full Name")
    .min(3, "Full Name must be at least 3 character"),
  joiningDate: Yup.string().required("Please enter the Joining Date"),
  probationTerm: Yup.string().required("Please enter the Probation Term"),
  probationEndDate: Yup.string().when("probationTerm", {
    is: (value: any) => value === "N/A",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) =>
      Yup.string().required("Please enter the Probation End Date"),
  }),
  noticePeriod: Yup.string().required("Please enter the Notice Period"),
  designation: Yup.string().required("Please enter the Designation"),
  department: Yup.string().required("Please enter the Department"),
  reportingManager: Yup.string().required("Please enter the Reporting Manger"),
  workEmail: Yup.string()
    .required("Please enter the Work Email")
    .matches(emailRegex, { message: "Please enter a valid email" }),
  companyName: Yup.string().required("Please enter the Company Name"),
  location: Yup.string().required("Please enter the Work Place"),
  shift: Yup.string().required("Please enter the Shift"),
  // grade: Yup.string().required("Please enter the Grade"),
  sourceOfVerification: Yup.string().required(
    "Please enter the Source of Verification"
  ),
  hrManager: Yup.string().required("Please enter the HR Manager"),
  // hiredThrough: Yup.string().required("Please enter the Hired Through"),
  role: Yup.string().required("Please enter the Role"),
});

export const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter the First Name"),
  lastName: Yup.string().required("Please enter the Last Name"),
  personalEmail: Yup.string()
    .required("Please enter the Personal Email")
    .matches(emailRegex, { message: "Please enter a valid email" }),
  phone: Yup.string().required("Please enter the Mobile Number"),
  // .matches(/[0-9]/g, "Must be only digits")
  // .min(9, "Mobile Number must be at least 9 digits"),
  dob: Yup.string().required("Please enter the DOB"),
  // bloodGroup: Yup.string().required("Please enter the Blood Group"),
  // motherName: Yup.string().required("Please enter the Mother’s Name"),
  // fatherName: Yup.string().required("Please enter the Father’s Name"),
  // maritalStatus: Yup.string().required("Please enter the Marital Status"),
  // dateOfMarriage: Yup.string().required("Please enter the Date of Marriage"),
  nationality: Yup.string().required("Please enter the Nationality"),
  // languageKnown: Yup.string().required("Please enter the Languages Known"),
  gender: Yup.string().required("Please enter the Gender"),
  // currentAddress: Yup.string().required("Please enter the Current Address"),
  // zipCode: Yup.string().required("Please enter the Zipcode"),
  // state: Yup.string().required("Please enter the State"),
  // city: Yup.string().required("Please enter the City"),
  // country: Yup.string().required("Please enter the Country"),
  // permanentAddress: Yup.string().required("Please enter the Permanent Address"),
  // zipCode2: Yup.string().required("Please enter the Zipcode"),
  // state2: Yup.string().required("Please enter the State"),
  // city2: Yup.string().required("Please enter the City"),
  // country2: Yup.string().required("Please enter the Country"),
});

export const EmergencyContactSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter the First Name"),
  lastName: Yup.string().required("Please enter the Last Name"),
  email: Yup.string()
    .required("Please enter the Personal Email")
    .matches(emailRegex, { message: "Please enter a valid email" }),
  phone: Yup.string().required("Please enter the Contact Number"),
  // .matches(/[0-9]/g, "Must be only digits")
  // .min(9, "Mobile Number must be at least 9 digits"),
  relation: Yup.string().required("Please enter the Relation"),
});

export const addQualificationSchema = Yup.object().shape({
  degree: Yup.string().required("Please enter the Degree"),
  university: Yup.string().required("Please enter the University"),
  fromDate: Yup.date().required("Please enter the From Date"),
  toDate: Yup.date()
    .required("Please enter the To Date")
    .test(
      "toDate",
      "Passing year must be greater than the starting year",
      function (value) {
        const fromDate = this.resolve(Yup.ref("fromDate"));
        return fromDate ? value > fromDate : true;
      }
    ),
  location: Yup.string().required("Please enter the Location"),
});
export const TimeOffStepperOneSchema = Yup.object().shape({
  leaveName: Yup.string().required("Please enter the Leave Name"),
  shortForm: Yup.string().required("Please enter the Short Form"),
});

export const addExperienceSchema = Yup.object().shape({
  employerName: Yup.string().required("Please enter the Degree"),
  jobDesignation: Yup.string().required("Please enter the University"),
  startDate: Yup.date().required("Please enter the Start Date"),
  endDate: Yup.date()
    .required("Please enter the End Date")
    .test(
      "endDate",
      "End Date must be greater than the Start Date",
      function (value) {
        const startDate = this.resolve(Yup.ref("startDate"));
        return startDate ? value > startDate : true;
      }
    ),
  location: Yup.string().required("Please enter the Location"),
});

export const addAppraisalSchema = Yup.object().shape({
  appraisalDate: Yup.string().required("Please enter the Date"),
  ctc: Yup.number()
    .transform((value, originalValue) =>
      isNaN(originalValue) ? undefined : parseFloat(originalValue)
    )
    .nullable()
    .typeError("Please enter the CTC")
    .positive("Please enter a positive number for CTC")
    .required("Please enter a valid number for CTC"),
  revisedCTC: Yup.number()
    .transform((value, originalValue) =>
      isNaN(originalValue) ? undefined : parseFloat(originalValue)
    )
    .nullable()
    .typeError("Please enter the Revised CTC")
    .positive("Please enter a positive number for CTC")
    .moreThan(Yup.ref("ctc"), "Revised CTC must be greater than CTC")
    .required("Please enter a valid number for Revised CTC"),
  promotedFrom: Yup.string().required("Please enter the Promoted From"),
  promotedTo: Yup.string().required("Please enter the Promoted To"),
});

export const addDocSchema = Yup.object().shape({
  documentName: Yup.string().required("Please enter the Document Name"),
  documentType: Yup.string().required("Please enter the Document Type"),
  description: Yup.string()
    .max(60, "Description must be at most 60 characters")
    .required("Please enter the Description"),
  file: Yup.mixed()
    .required("Please upload a document")
    .test(
      "file-type",
      "Invalid file format. Please upload only JPEG, JPG, PNG, SVG, PDF, or WEBP files.",
      validateFileExtension
    ),
});

function validateFileExtension(value: any) {
  const allowedExtensions = ["jpeg", "jpg", "png", "svg", "pdf", "webp"];
  const extension = value?.name?.split(".").pop().toLowerCase();

  return allowedExtensions.includes(extension);
}
