import OtpInputComponent from "react-otp-input";
import { OtpInputTypes } from "src/CustomDatatypes/datatypes";
import Grid from "@mui/material/Grid";

const OtpInput = ({ value, onChange, className }: OtpInputTypes) => {
  const handleOtp = (value: any) => {
    onChange(value);
  };
  return (
    <>
      <Grid>
        <OtpInputComponent
          onChange={handleOtp}
          numInputs={6}
          value={value}
          //@ts-ignore
          separator={"  "}
          renderInput={(props) => <input {...props} />}
          inputStyle={className}
        />
      </Grid>
    </>
  );
};

export default OtpInput;
