import CustomButtons from "src/components/CustomButton/Button";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import styles from "src/Styles/companyUsers.module.css";
import { useQuery } from "@apollo/client";
import CustomTable from "../TableComponent/CustomTable";
import Styles from "src/Styles/EducationalDetails.module.css";
import EditIcon from "@mui/icons-material/Edit";
import AddDocumentType from "../AddDocumentType";
import { GET_ALLDOCUMENT_TYPE } from "../../graphql/documentType";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { GET_ALL_USERS_BY_PORTAL } from "src/graphql/user";
import { DeleteOutline } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CompanyUsersModal from "./CompanyUsersModal";
import CustomSelect from "../CustomSelect/customSelectValidations";
import { useForm } from "react-hook-form";
import EduDetaildata from "../../components/AddEmployee/EducationaDetails/EducationDetaildata.json";
import SelectLabels from "../CustomSelect";

const accessRules = [
  {
    type: "employees",
    revoked: "false",
    adminRevokedStatus: "false",
    active: true,
  },
  {
    type: "calendar",
    revoked: "false",
    adminRevokedStatus: "false",
    active: true,
  },
  {
    type: "timeOff",
    revoked: "false",
    adminRevokedStatus: "false",
    active: true,
  },
  {
    type: "documents",
    revoked: "false",
    adminRevokedStatus: "false",
    active: true,
  },
  {
    type: "settings",
    revoked: "false",
    adminRevokedStatus: "false",
    active: true,
  },
];

const CompanyUsers = () => {
  type UserType = {
    displayName: string;
    role: string;
    isActive: Boolean;
    isDeleted: Boolean;
    accessRules: Array<{
      type: string;
      revoked: string;
      adminRevokedStatus: string;
      active: boolean;
    }>;
  };

  // eslint-disable-next-line
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  const [selectedRoles, setSelectedRoles] = useState(["ADMIN", "SUPER_ADMIN", "HR"])
  const [mopen, setOpen] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [selectedValue, setSelectedValue] = React.useState("Managers");
  const loggedInuser = userInfo.id;
  const isCurrentUser = (userId: string) => userId === loggedInuser;
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10)

  const { data, loading, error } = useQuery(GET_ALL_USERS_BY_PORTAL, {
    variables: {
      input: {
        offset,
        limit,
        role: selectedRoles,
      }
    }
  });

  const onEdit = (data: any) => {
    setEditData(data);
    setEditFlag(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUsersChange = (event: any) => {
    console.log("event", event.target.value);
    const selectedUsers = event.target.value;
    if (selectedUsers === "Managers") {
      setSelectedRoles(["ADMIN", "SUPER_ADMIN", "HR"])
    } else {
      setSelectedRoles([])
    }
    setSelectedValue(selectedUsers);
  };

  const UsersOptions = [
    {
      id: "MANAGERS",
      name: "Managers",
    },
    {
      id: "ALLEMPLOYEES",
      name: "All Employees",
    },
  ];

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          className={styles.parentContainer}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid container item md={6} sm={6} xs={12}>
            <SettingCustomHeader
              header="Company Users"
              paragraph="Create company users to know their Access Permissons "
            />
          </Grid>

          <Grid
            item
            container
            md={6}
            sm={6}
            xs={12}
            justifyContent={"flex-end"}
            className={styles.upperGrid_padding}
          >
            {UsersOptions?.length > 0 && (
              <SelectLabels
                value={selectedValue}
                onChange={handleUsersChange}
                selectData={UsersOptions}
                className={styles.select}
              />
            )}
          </Grid>

          <Grid
            item
            container
            md={6}
            sm={6}
            xs={12}
            justifyContent={"flex-end"}
            className={styles.upperGrid_padding}
          >
          </Grid>
        </Grid>

        {data?.getUsersByPortal?.users && (
          <CustomTable
            data={data?.getUsersByPortal?.users || []}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={(data && data?.getUsersByPortal.totalCount) || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>User Name</Grid>,
                accessor: "displayName",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.displayName}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Role</Grid>,
                accessor: "role",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>
                    {item.role
                      .toLowerCase()
                      .split("_")
                      .map(
                        (word: any) =>
                          word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </Grid>
                ),
              },
              {
                Header: (
                  <Grid className={Styles.tableHead}>Access Permission</Grid>
                ),
                accessor: "accessRules",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>
                    {accessRules.map((rule: any) => {
                      const userRule = item.accessRules.find(
                        (ur: any) => ur.type === rule.type
                      );
                      const isActive = userRule && userRule.active;

                      return (
                        <div
                          key={rule.type}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "10px",
                          }}
                        >
                          <CheckCircleIcon
                            style={{
                              marginRight: "5px",
                              color: isActive ? "#4CAF50" : "lightgrey",
                            }}
                          />
                          <span>{rule.type}</span>
                        </div>
                      );
                    })}
                  </Grid>
                ),
              },

              {
                Header: <Grid className={Styles.tableHead}>Action</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    {isCurrentUser(item.id) ? (
                      <EditIcon style={{ color: "lightgrey" }} />
                    ) : (
                      <EditIcon
                        onClick={() => onEdit(item)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {mopen && (
          <CompanyUsersModal
            onClose={handleClose}
            open={mopen}
            setOpen={setOpen}
            editFlag={editFlag}
            editData={editData}
          />
        )}
      </Grid>
    </>
  );
};

export default CompanyUsers;
