import CustomTab from "src/components/CustomTab";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import styles from "src/Styles/informationTab.module.css";
import { useDispatch, useSelector } from "react-redux";
const TimeOffHorizontalTab = ({ children }: any) => {
  const [selectedValue, setSelectedValue] = useState("PENDING");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  const tabOption = useSelector((state: any) => state?.TaskSlice.label);

  const handleStatusChange = (event: any) => {
    const selectedStatus = event.target.value;
    setSelectedValue(selectedStatus);
  };
  const data = [
    {
      id: 0,
      label: (
        <Stack direction="row">
          <Typography className={styles.tabs_typography}>Leave</Typography>
        </Stack>
      ),
      // component: <Leave selectedStatus={selectedValue} />,

      href: "/admin/hrms/timeOff/leave",
    },
    {
      id: 1,
      label: (
        <Stack direction="row">
          <Typography className={styles.tabs_typography}>
            Regularization
          </Typography>
        </Stack>
      ),
      // component: "Regularisation",
      href: "/admin/hrms/timeOff/regularization",
    },
    {
      id: 2,
      label: (
        <Stack direction="row" alignSelf="start">
          <Typography className={styles.tabs_typography}>
            Work From Home
          </Typography>
        </Stack>
      ),
      // component: "Work From Home",
      href: "/admin/hrms/timeOff/work-from-home",
    },
  ];

  return (
    <>
      <Grid container className={styles.mainContainer}>
        <Grid container className={styles.subContainer}>
          <Grid item className={styles.subGrid} md={6} sm={6} xs={12} lg={6}>
            <Typography className={styles.leaveText}>{tabOption}</Typography>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            xs={12}
            className={styles.inputsRight}
          >
            <Grid
              md={6}
              lg={5}
              xl={4}
              sm={12}
              xs={12}
              className={styles.searchInput}
            >
              <FormControl fullWidth>
                {/* <FormControl> */}
                <Select
                  value={selectedValue}
                  onChange={handleStatusChange}
                  className={styles.select}
                  MenuProps={{ classes: { paper: styles.menuPaper } }}
                >
                  <MenuItem value="PENDING" className={styles.menuItem}>
                    Pending
                  </MenuItem>
                  <MenuItem value="APPROVED" className={styles.menuItem}>
                    Approved
                  </MenuItem>
                  <MenuItem value="REJECTED" className={styles.menuItem}>
                    Rejected
                  </MenuItem>
                  <MenuItem value="DELETED" className={styles.menuItem}>
                    Deleted
                  </MenuItem>
                  <MenuItem value="ALL" className={styles.menuItem}>
                    All Requests
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.customTabHeight}>
        <CustomTab
          myTabData={data}
          orientation="horizontal"
          moreSideIcon={true}
          component={children}
          selectedStatus={selectedValue === "ALL" ? "" : selectedValue}
        />
      </Grid>
    </>
  );
};

export default TimeOffHorizontalTab;
