import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider, InputAdornment } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_EMPLOYEE_BY_QUERY_TYPE } from "src/graphql/user";
import CustomTextField from "src/components/CustomTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { addAppraisalSchema } from "src/utils/validations";
import CustomButtons from "src/components/CustomButton/Button";
import { Toast } from "src/components/ToastMessage";
import ModalBox from "src/components/ReusableModal/modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styles from "../../Styles/EmergencyContact.module.css";
import CustomDatePicker from "../customDatePicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import dayjs from "dayjs";

const AddAppraisalModal = ({
  editEmpRecord,
  editEmpRefetch,
  onClick,
  open,
  onClose,
  selectedItem,
}: any) => {
  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeData, loading: updateEmployeeLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  useEffect(() => {
    if (selectedItem) {
      const FormatedEditEmployeeData = {
        appraisalDate: selectedItem?.appraisalDate || "",
        ctc: selectedItem?.CTC || "",
        revisedCTC: selectedItem?.revisedCTC || "",
        promotedFrom: selectedItem?.promotedFrom || "",
        promotedTo: selectedItem?.promotedTo || "",
      };
      reset(FormatedEditEmployeeData);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ resolver: yupResolver(addAppraisalSchema) });

  const handleUpdateAppraisalDetails = async (data: any) => {
    try {
      if (!selectedItem) {
        const addUserData = {
          appraisalDate: data.appraisalDate,
          CTC: data.ctc,
          revisedCTC: data.revisedCTC,
          promotedFrom: data.promotedFrom,
          promotedTo: data.promotedTo,
        };
        const updateResponse: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "addAppraisal",
              appraisalDetails: addUserData,
            },
          },
        });
        if (updateResponse) {
          editEmpRefetch();
          Toast("success", "Appraisal Details created Successfully");
          onClose();
        }
      } else {
        const editUserData = {
          id: selectedItem?.id,
          appraisalDate: data.appraisalDate,
          CTC: data.ctc,
          revisedCTC: data.revisedCTC,
          promotedFrom: data.promotedFrom,
          promotedTo: data.promotedTo,
        };
        const updateResponse: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "editAppraisal",
              appraisalDetails: editUserData,
            },
          },
        });
        if (updateResponse) {
          editEmpRefetch();
          Toast("success", "Appraisal Details updated Successfully");
          onClose();
        }
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };
  const handleCancelClick = () => {
    onClose();
  };

  const maxDate = dayjs();

  return (
    <>
      <ModalBox
        // onClick={onClick}
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        height={600}
        content={
          <Grid
            className={styles.maincontainer}
            component="form"
            onSubmit={handleSubmit(handleUpdateAppraisalDetails)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <Grid className={styles.headerContainer}>
              <Grid>
                <Typography className={styles.headerText}>
                  {selectedItem ? "Edit" : "Add"} Appraisal Details
                </Typography>
              </Grid>
              <Grid>
                <CloseOutlinedIcon
                  onClick={onClose}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid className={styles.mainWrapper}>
              <Grid
                container
                mt={3}
                mb={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
              >
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>CTC</Typography>
                  <CustomTextField
                    error={Boolean(errors.ctc)}
                    errorCondition={
                      errors.ctc && (
                        <Typography className={styles.errorMsg}>
                          {errors.ctc.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="ctc"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="CTC"
                    startAdornment={
                      <InputAdornment position="start">
                        <Grid className={styles.rupeeBox}>
                          <CurrencyRupeeIcon className={styles.rupeeIcon} />
                        </Grid>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Grid className={styles.endAdornmentGrid}>
                          <Typography variant="h6">Lakhs</Typography>
                        </Grid>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Revised CTC
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.revisedCTC)}
                    errorCondition={
                      errors.revisedCTC && (
                        <Typography className={styles.errorMsg}>
                          {errors.revisedCTC.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="revisedCTC"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Revised CTC"
                    startAdornment={
                      <InputAdornment position="start">
                        <Grid className={styles.rupeeBox}>
                          <CurrencyRupeeIcon className={styles.rupeeIcon} />
                        </Grid>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Grid className={styles.endAdornmentGrid}>
                          <Typography variant="h6">Lakhs</Typography>
                        </Grid>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>Date</Typography>
                  <CustomDatePicker
                    error={Boolean(errors.appraisalDate)}
                    errorCondition={
                      errors.appraisalDate && (
                        <Typography className={styles.errorMsg}>
                          {errors.appraisalDate.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="appraisalDate"
                    className={styles.datefieldContainer}
                    selectedDate={selectedItem?.appraisalDate}
                    // monthYearOnly={true}
                    // maxDate={maxDate}
                  />
                </Grid>

                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Promoted From
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.promotedFrom)}
                    errorCondition={
                      errors.promotedFrom && (
                        <Typography className={styles.errorMsg}>
                          {errors.promotedFrom.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="promotedFrom"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Promoted From"
                  />
                </Grid>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  className={styles.formGrid}
                >
                  <Typography className={styles.formLabel}>
                    Promoted To
                  </Typography>
                  <CustomTextField
                    error={Boolean(errors.promotedTo)}
                    errorCondition={
                      errors.promotedTo && (
                        <Typography className={styles.errorMsg}>
                          {errors.promotedTo.message}
                        </Typography>
                      )
                    }
                    control={control}
                    name="promotedTo"
                    fullWidth={true}
                    className={styles.fieldContainer}
                    placeholder="Promoted To"
                  />
                </Grid>
              </Grid>
              <Grid className={styles.btnContainer}>
                <CustomButtons
                  name="Cancel"
                  variant="contained"
                  className={styles.cancelBtn}
                  onClick={handleCancelClick}
                />
                <CustomButtons
                  name={selectedItem ? "Edit" : "Add"}
                  variant="contained"
                  className={styles.createBtn}
                  type="submit"
                  loading={updateEmployeeLoading}
                  disabled={updateEmployeeLoading}
                  loaderClass={styles.buttonLoader}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default AddAppraisalModal;
