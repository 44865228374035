
import { Grid } from "@mui/material";
import style from "src/Styles/404.module.css";
import CustomButtons from "src/components/CustomButton/Button";

const NotFound = () => {

  return (
    <Grid className={style.notFound}>
      <Grid >
        <img
          src="https://ark-hrms.s3.ap-south-1.amazonaws.com/ac0c7a29-4696-446c-8317-397bec1258f2.png"
          alt="images"
          className={style.imag}
        />
      </Grid>
      <Grid className={style.backToBtn}>
        <CustomButtons
          variant="contained"
          className={style.backToBtnLink}
          href="/"
          name="Back to Homepage"
        />
      </Grid>
    </Grid>
  );
};

export default NotFound;
