import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "src/store/store";

interface taskTabStateValue {
  value: number | null;
  label: string | null;
}

const initialState: taskTabStateValue = {
  value: null,
  label: null,
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    taskTabValue: (
      state,
      action: PayloadAction<{ value: number; label: string }>
    ) => {
      state.value = action.payload.value;
      state.label = action.payload.label;
    },
  },
});

export const { taskTabValue } = taskSlice.actions;

export const newTaskTabValue = (state: RootState) => state.TaskSlice;

export default taskSlice.reducer;
