import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AdminCalenderTable from "../AdminCalenderTable";
import styles from "../../Styles/AdminCalendar.module.css";
import { createTheme } from "@mui/material/styles";
import moment from "moment";
import { GET_ADMIN_CALENDAR_DATA } from "src/graphql/adminCalendar";
import { useQuery } from "@apollo/client";
import DatePickerCSV from "../DatePickerCSV";
import IconButton from "@mui/material/IconButton";
import { generateExcel, downloadExcel } from "../../utils/generateExcel";
import CustomButtons from "../CustomButton/Button";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchBox from "src/components/Search/index";
import { debounce } from "src/utils/debounce";
import PlaceIcon from "@mui/icons-material/Place";
import { isTotalTimeGreaterThan } from "src/utils/isTotalTimeGreaterThan ";

const AdminCalender = () => {
  const today = new Date();
  const [columnsData, setColumnsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [calendarDate, setCalendarDate] = React.useState<Date | null>(today);
  const [currentPage, setCurrentPage] = React.useState<Number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<Number>(10);
  const [loaderForExcelbtn, setLoaderForExcelbtn] =
    React.useState<boolean>(false);
  const [allSearch, setAllSearch] = useState("");

  const maxDate = dayjs().endOf("month");

  let currentDate = moment(calendarDate);

  let monthYear = currentDate.format("MMM YYYY");
  // eslint-disable-next-line
  const { data, loading, error, refetch } = useQuery(GET_ADMIN_CALENDAR_DATA, {
    variables: {
      monthOfYear: monthYear,
      offset: currentPage,
      limit: rowsPerPage,
      searchString: allSearch,
    },
  });
  // eslint-disable-next-line
  let filterEmployeesData: any[];

  const adminCalendarData = data?.getAdminCalendarData?.data || [];

  const handleDownloadExcel = async () => {
    setLoaderForExcelbtn(true);
    const { data: newData } = await refetch({
      monthOfYear: monthYear,
      offset: currentPage,
      limit: data?.getAdminCalendarData?.totalRecords,
    });
    let finalExcelExportData;
    if (filterEmployeesData && filterEmployeesData?.length > 0) {
      finalExcelExportData = [...filterEmployeesData];
    } else {
      finalExcelExportData = newData?.getAdminCalendarData?.data;
    }
    let excelData: any = [];
    finalExcelExportData?.forEach((user: any) => {
      const userEntry: any = {
        name: user.name,
        department: user.department,
      };

      user.attendance.forEach((attendanceData: any) => {
        let formattedLogoutTime;
        if (
          moment(attendanceData.logoutTime, moment.ISO_8601, true).isValid()
        ) {
          formattedLogoutTime = moment(attendanceData.logoutTime).format(
            "HH:mm"
          );
        } else {
          formattedLogoutTime = attendanceData.logoutTime;
        }

        let formattedLoginTime;
        if (moment(attendanceData.loginTime, moment.ISO_8601, true).isValid()) {
          formattedLoginTime = moment(attendanceData.loginTime).format("HH:mm");
        } else {
          formattedLoginTime = attendanceData.loginTime;
        }

        userEntry[attendanceData.date] =
          attendanceData.isWeekend && attendanceData.isAbsent
            ? "Weekend"
            : attendanceData.isHoliday && attendanceData.isAbsent
            ? "Holiday"
            : attendanceData.isWeekend &&
              (attendanceData.status === "FULL_DAY" ||
                attendanceData.status === "HALF_DAY")
            ? `Weekend ${attendanceData.status} (${formattedLoginTime} - ${formattedLogoutTime})` ||
              "-"
            : attendanceData.isHoliday &&
              (attendanceData.status === "FULL_DAY" ||
                attendanceData.status === "HALF_DAY")
            ? `Holiday ${attendanceData.status} (${formattedLoginTime} - ${formattedLogoutTime})` ||
              "-"
            : attendanceData.status === "FULL_DAY"
            ? `${attendanceData.status} (${formattedLoginTime} - ${formattedLogoutTime})` ||
              "-"
            : attendanceData.status === "HALF_DAY"
            ? `${attendanceData.status} (${formattedLoginTime} - ${formattedLogoutTime})` ||
              "-"
            : attendanceData.status === "LEAVE_APPROVED"
            ? attendanceData.leaveType === "FIRST_HALF"
              ? `${attendanceData.leaveType}: ${attendanceData.status} (${
                  attendanceData.allowanceName
                }), SECOND_HALF: ${
                  attendanceData.loginTime &&
                  attendanceData.logoutTime &&
                  attendanceData.totalTime &&
                  isTotalTimeGreaterThan(attendanceData?.totalTime, 240)
                    ? attendanceData?.regularizeAppliedForHalfDay === "APPROVED"
                      ? "Regularization"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "PENDING"
                      ? "REG: (Pending)"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "REJECTED"
                      ? "Absent"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "DELETED"
                      ? "Absent"
                      : "Present"
                    : attendanceData?.regularizeAppliedForHalfDay === "PENDING"
                    ? "REG: (Pending)"
                    : "Absent"
                }`
              : attendanceData.leaveType === "SECOND_HALF"
              ? `${attendanceData.leaveType}: ${attendanceData.status} (${
                  attendanceData.allowanceName
                }), FIRST_HALF: ${
                  attendanceData.loginTime &&
                  attendanceData.logoutTime &&
                  attendanceData.totalTime &&
                  isTotalTimeGreaterThan(attendanceData.totalTime, 240)
                    ? attendanceData.regularizeAppliedForHalfDay === "APPROVED"
                      ? "Regularization"
                      : attendanceData.regularizeAppliedForHalfDay === "PENDING"
                      ? "REG: (Pending)"
                      : attendanceData.regularizeAppliedForHalfDay ===
                        "REJECTED"
                      ? "Absent"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "DELETED"
                      ? "Absent"
                      : "Present"
                    : attendanceData.regularizeAppliedForHalfDay === "PENDING"
                    ? "REG: (Pending)"
                    : "Absent"
                }`
              : `${attendanceData.status} (${attendanceData.allowanceName}) - ${attendanceData.leaveType}` ||
                "-"
            : attendanceData.status === "LEAVE_PENDING"
            ? attendanceData.leaveType === "FIRST_HALF"
              ? `${attendanceData.leaveType}: ${attendanceData.status} (${
                  attendanceData.allowanceName
                }), SECOND_HALF: ${
                  attendanceData.loginTime &&
                  attendanceData.logoutTime &&
                  attendanceData.totalTime &&
                  isTotalTimeGreaterThan(attendanceData?.totalTime, 240)
                    ? attendanceData?.regularizeAppliedForHalfDay === "APPROVED"
                      ? "Regularization"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "PENDING"
                      ? "REG: (Pending)"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "REJECTED"
                      ? "Absent"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "DELETED"
                      ? "Absent"
                      : "Present"
                    : attendanceData?.regularizeAppliedForHalfDay === "PENDING"
                    ? "REG: (Pending)"
                    : "Absent"
                }`
              : attendanceData.leaveType === "SECOND_HALF"
              ? `${attendanceData.leaveType}: ${attendanceData.status} (${
                  attendanceData.allowanceName
                }), FIRST_HALF: ${
                  attendanceData.loginTime &&
                  attendanceData.logoutTime &&
                  attendanceData.totalTime &&
                  isTotalTimeGreaterThan(attendanceData.totalTime, 240)
                    ? attendanceData.regularizeAppliedForHalfDay === "APPROVED"
                      ? "Regularization"
                      : attendanceData.regularizeAppliedForHalfDay === "PENDING"
                      ? "REG: (Pending)"
                      : attendanceData.regularizeAppliedForHalfDay ===
                        "REJECTED"
                      ? "Absent"
                      : attendanceData?.regularizeAppliedForHalfDay ===
                        "DELETED"
                      ? "Absent"
                      : "Present"
                    : attendanceData.regularizeAppliedForHalfDay === "PENDING"
                    ? "REG: (Pending)"
                    : "Absent"
                }`
              : `${attendanceData.status} (${attendanceData.allowanceName}) - ${attendanceData.leaveType}` ||
                "-"
            : attendanceData.status === "REGULARIZATION_APPROVED"
            ? `${attendanceData.status} (${formattedLoginTime} - ${formattedLogoutTime})` ||
              "-"
            : attendanceData.status === "REGULARIZATION_PENDING"
            ? `${attendanceData.status} (${formattedLoginTime} - ${formattedLogoutTime})` ||
              "-"
            : `${attendanceData.status || "-"} - ${formattedLoginTime || "-"}`;
      });
      if (user?.userAllowances?.length > 0) {
        user?.userAllowances?.forEach((allowance: any) => {
          if (allowance && allowance.policyName != null) {
            userEntry[allowance?.policyName] = allowance?.allowancesAvailable
              ? Number(allowance?.allowancesAvailable)
              : 0;
          }
        });
      }
      let currentMonthLeave = user?.currentMonthLeaveCount
        ? user?.currentMonthLeaveCount
        : 0;
      userEntry["Applied Leaves"] = currentMonthLeave;
      let currentBalance = user?.userAllowancesCurrentBalance
        ? user?.userAllowancesCurrentBalance
        : 0;
      userEntry["Current Balance"] = currentBalance;

      excelData.push(userEntry);
    });

    const dataBlob = generateExcel(excelData);
    downloadExcel(
      dataBlob,
      `Attendance_sheet_${moment(currentDate).format("YYYY-MM-DD")}`
    );
    setLoaderForExcelbtn(false);
  };
  // eslint-disable-next-line
  const getAllDateOfMonth = () => {
    const startDate = moment(calendarDate)
      .startOf("month")
      .format("YYYY-MM-DD");

    const dates: any[] = [];

    new Array(moment(startDate, "YYYY-MM-DD").daysInMonth())
      .fill(null)
      .map(async (_x, i) => {
        const date: any = moment(moment(startDate, "YYYY-MM-DD"))
          .startOf("month")
          .add(i, "days")
          .format("YYYY-MM-DD");
        dates.push(date);
      });

    return dates;
  };

  // Debounce the search function
  const debouncedSearch = debounce((searchValue: string) => {
    setAllSearch(searchValue);
  }, 500);

  const requestSearch = async (evt: any) => {
    const searchValue = evt.target.value;
    debouncedSearch(searchValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const dates: any[] =
      // eslint-disable-next-line
      getAllDateOfMonth();
    if (adminCalendarData.length > 0) {
      const tableData = adminCalendarData.map((user: any) => {
        return [
          user?.name,
          user?.department,
          ...user?.attendance?.map((attendance: any) => attendance.loginTime),
        ];
      });

      const dynamicColumns: any = [
        {
          name: "name",
          label: "Employee Name",
          options: {
            customBodyRender: (
              _value: any,
              _tableMeta: any,
              _updateValue: any
            ) => {
              const userName = adminCalendarData[_tableMeta.rowIndex].name;
              return (
                <span className={styles.avtardiv}>
                  <span>
                    <img
                      src="https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2264922221.jpg"
                      alt=""
                      height="50px"
                      width="50px"
                    />
                  </span>
                  {userName}
                </span>
              );
            },
            setCellHeaderProps: () => ({
              className: styles.stickyEmpNameHeader,
            }),
            setCellProps: () => ({
              className: styles.stickyEmpNameData,
            }),
          },
        },
        {
          name: "department",
          label: "Department",
          options: {
            setCellHeaderProps: () => ({
              className: styles.stickyDeptHeader,
            }),
            setCellProps: () => ({
              className: styles.stickyDepData,
            }),
          },
        },
        ...dates.map((date) => ({
          name: date,
          label: `${moment(date).format("D MMM")}`,
          options: {
            customBodyRender: (
              _value: any,
              tableMeta: { rowIndex: any; columnIndex: number },
              _updateValue: any
            ) => {
              const userIndex = tableMeta.rowIndex;
              const dateIndex = tableMeta.columnIndex - 2; // Account for the "Name" and "Department" columns
              const attendance =
                adminCalendarData[userIndex].attendance[dateIndex];
              let content;

              const shiftTiming =
                attendance?.loginTime !== null &&
                attendance?.logoutTime !== null &&
                `${moment(attendance.loginTime).format("hh:mm A")} - ${moment(
                  attendance.logoutTime
                ).format("hh:mm A")}`;

              const loginAddress = attendance?.location?.loginLocation?.address;
              const logoutAddress =
                attendance?.location?.logoutLocation?.address;

              const locationData = loginAddress ? (
                <div>
                  <div>
                    <p className={styles.addressDiv}>
                      <span>Login</span>{" "}
                      <span>
                        <PlaceIcon fontSize="small" />
                      </span>
                    </p>
                    <p>{loginAddress || "-"}</p>
                  </div>
                  <div>
                    <p className={styles.addressDiv}>
                      <span>Logout</span>
                      <span>
                        <PlaceIcon fontSize="small" />
                      </span>
                    </p>
                    <p>{logoutAddress || "-"}</p>
                  </div>
                </div>
              ) : (
                ""
              );

              if (attendance?.isWeekend && attendance?.isAbsent) {
                content = "Week Off";
              } else if (attendance?.isAbsent) {
                content = (
                  <div>
                    <div
                      className={styles.AbsentText}
                      title={shiftTiming ? shiftTiming : undefined}
                    >
                      Absent
                    </div>
                  </div>
                );
              } else if (attendance?.isHoliday && attendance?.isAbsent) {
                content = "Holiday";
              } else if (attendance.status === "LEAVE_APPROVED") {
                let leaveTitle: any = null;
                if (attendance?.leaveType === "FULL_DAY") {
                  leaveTitle = (
                    <div>
                      Leave (Full Day)<div>{attendance?.allowanceName}</div>
                    </div>
                  );
                } else if (attendance?.leaveType === "FIRST_HALF") {
                  leaveTitle = (
                    <div>
                      Leave (First Half)<div>{attendance?.allowanceName}</div>
                      <div>
                        Second Half:{" "}
                        {attendance?.loginTime &&
                        attendance?.logoutTime &&
                        attendance?.totalTime &&
                        isTotalTimeGreaterThan(attendance?.totalTime, 240)
                          ? attendance?.regularizeAppliedForHalfDay ===
                            "APPROVED"
                            ? "Regularization"
                            : attendance?.regularizeAppliedForHalfDay ===
                              "PENDING"
                            ? "REG: (Pending)"
                            : attendance?.regularizeAppliedForHalfDay ===
                              "REJECTED"
                            ? "Absent"
                            : attendance?.regularizeAppliedForHalfDay ===
                              "DELETED"
                            ? "Absent"
                            : "Present"
                          : attendance?.regularizeAppliedForHalfDay ===
                            "PENDING"
                          ? "REG: (Pending)"
                          : "Absent"}
                      </div>
                    </div>
                  );
                } else if (attendance?.leaveType === "SECOND_HALF") {
                  leaveTitle = (
                    <div>
                      Leave (Second Half)<div>{attendance?.allowanceName}</div>
                      <div>
                        First Half:{" "}
                        {attendance?.loginTime &&
                        attendance?.logoutTime &&
                        attendance?.totalTime &&
                        isTotalTimeGreaterThan(attendance?.totalTime, 240)
                          ? attendance?.regularizeAppliedForHalfDay ===
                            "APPROVED"
                            ? "Regularization"
                            : attendance?.regularizeAppliedForHalfDay ===
                              "PENDING"
                            ? "REG: (Pending)"
                            : attendance?.regularizeAppliedForHalfDay ===
                              "REJECTED"
                            ? "Absent"
                            : attendance?.regularizeAppliedForHalfDay ===
                              "DELETED"
                            ? "Absent"
                            : "Present"
                          : attendance?.regularizeAppliedForHalfDay ===
                            "PENDING"
                          ? "REG: (Pending)"
                          : "Absent"}
                      </div>
                    </div>
                  );
                }

                content = (
                  <div>
                    <Tooltip title={leaveTitle || ""}>
                      <span>Leave</span>
                    </Tooltip>
                  </div>
                );
              } else if (attendance.status === "REGULARIZATION_PENDING") {
                content = "REG Pending";
              } else {
                let duration;
                if (
                  attendance.logoutTime !== null &&
                  attendance.loginTime !== null
                ) {
                  duration = moment.duration(
                    moment(attendance.logoutTime).diff(
                      moment(attendance.loginTime)
                    )
                  );
                }
                // if (attendance.status === 'HALF_DAY') {
                //   content = 'Half Day';
                // } else
                // Get duration in hours and minutes
                const hours = duration && duration.hours();
                const minutes = duration && duration.minutes();
                let textColorClass;
                if (hours !== undefined && minutes !== undefined) {
                  if (hours < 9 || (hours < 9 && minutes < 60)) {
                    textColorClass = styles.orangeColor;
                  } else if (hours === 0 && minutes === 0) {
                    textColorClass = styles.redColor;
                  } else {
                    textColorClass = styles.greenColor;
                  }
                }

                if (attendance?.isWeekend && !attendance?.isAbsent) {
                  if (attendance?.totalTime !== "-") {
                    content = (
                      <div style={{ alignItems: "center" }}>
                        {attendance?.isWeekend ? "Week Off" : ""}
                        {
                          <p
                            className={`${styles.totalTime} ${textColorClass}`}
                            title={shiftTiming ? shiftTiming : undefined}
                          >
                            {attendance?.totalTime}{" "}
                            {attendance?.totalTime != undefined &&
                            attendance?.totalTime != "-"
                              ? "Hrs"
                              : ""}
                          </p>
                        }
                      </div>
                    );
                  } else {
                    content = "Week Off";
                  }
                } else if (attendance?.isHoliday && !attendance?.isAbsent) {
                  if (attendance?.totalTime !== "-") {
                    content = (
                      <div style={{ alignItems: "center" }}>
                        {attendance?.isHoliday ? "Holiday" : ""}
                        {
                          <p
                            className={`${styles.totalTime} ${textColorClass}`}
                            title={shiftTiming ? shiftTiming : undefined}
                          >
                            {attendance?.totalTime}{" "}
                            {attendance?.totalTime != undefined &&
                            attendance?.totalTime != "-"
                              ? "Hrs"
                              : ""}
                          </p>
                        }
                      </div>
                    );
                  } else {
                    content = "Holiday";
                  }
                } else {
                  content = (
                    <div>
                      <span
                        className={`${styles.totalTime} ${textColorClass}`}
                        title={shiftTiming ? shiftTiming : undefined}
                      >
                        {attendance?.totalTime}{" "}
                        {attendance?.totalTime != undefined &&
                        attendance?.totalTime != "-"
                          ? "Hrs"
                          : ""}
                      </span>
                    </div>
                  );
                }
              }

              return (
                <div>
                  {content}
                  {locationData && (
                    <div>
                      <Tooltip title={locationData || ""}>
                        <span className={styles.cityText}>
                          {attendance?.location?.loginLocation?.city}
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            },
            setCellHeaderProps: () => ({
              style: {
                minWidth: 100,
              },
            }),
            setCellProps: () => ({
              style: {
                minWidth: 100,
              },
            }),
          },
        })),
      ];
      setFilteredData(tableData);
      setColumnsData(dynamicColumns);
    }
    // eslint-disable-next-line
  }, [data, adminCalendarData]);

  const selectedMonth = (date: Date | null) => {
    const extractedDate: any = moment(date).format("MMM-YYYY");
    setCalendarDate(extractedDate);
  };

  const CustomToolbar = () => {
    return (
      <React.Fragment>
        <Grid container item className={styles.toolbarSection}>
          <Grid item>
            <Typography className={styles.calenderClockText}>
              Clock-In & Clock-Out Report
            </Typography>
          </Grid>
          <Grid item sx={{ gap: 5 }} className={styles.toolbarRightSection}>
            <Grid className={styles.toolbarCalanderGrid}>
              <DatePickerCSV
                selectedDate={calendarDate}
                onChange={selectedMonth}
                maxDate={maxDate}
                className={styles.datePicker}
              />
            </Grid>
            <Grid className={styles.toolbarButtonGrid} item>
              <CustomButtons
                name="Download Report"
                variant="contained"
                className={styles.csLinkButton}
                onClick={handleDownloadExcel}
                loading={loaderForExcelbtn}
                disabled={loaderForExcelbtn}
                loaderClass={styles.loaderClass}
                icon={<DownloadIcon />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles.searchGrid}>
          <Grid
            item
            container
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent={"flex-start"}
          >
            <SearchBox
              data={[]}
              customStyle={styles.searchBox}
              handleChange={requestSearch}
              borderRadius="30px"
              placeholder="Search"
              size="small"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const handleSelectedRows = (allRowsSelected: any) => {
    const selectedIndicesSet = new Set(
      allRowsSelected.map((item: any) => item.dataIndex)
    );

    // Filter adminCalendarData based on whether their index is in selectedIndicesSet
    const filterSelectedData = adminCalendarData.filter(
      (_item: any, index: unknown) => selectedIndicesSet.has(index)
    );
    filterEmployeesData = filterSelectedData;
  };
  const options: any = {
    responsive: "scrollMaxHeight",
    fixedHeader: false,
    filter: false,
    print: false,
    fixedSelectColumn: true,
    download: false,
    viewColumns: false,
    sort: false,
    search: false,
    pagination: true,
    selectableRows: "multiple",
    selectableRowsHeader: true,
    customToolbar: CustomToolbar,
    rowsPerPageOptions: [10, 50, 100],
    onChangePage(currentPage: any) {
      setCurrentPage(currentPage + 1);
      setRowsPerPage(rowsPerPage);
    },
    onChangeRowsPerPage(rowsPerPage: any) {
      setCurrentPage(currentPage);
      setRowsPerPage(rowsPerPage);
    },
    onRowSelectionChange(
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) {
      handleSelectedRows(allRowsSelected);
    },
    customToolbarSelect: CustomToolbar,
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: any,
      onChangePage: any
    ) => {
      return (
        <Grid container gap={3} className={styles.paginationContainer}>
          <Grid item>
            <Typography className={styles.paginationText}>
              Rows per page:{" "}
              <FormControl variant="standard" className={styles.paginationForm}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={rowsPerPage}
                  onChange={(e: any) => {
                    changeRowsPerPage(parseInt(e.target.value));
                  }}
                  label="Age"
                  className={styles.paginationDropdown}
                >
                  {options.rowsPerPageOptions.map((option: number) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={styles.paginationText}>
              {data?.getAdminCalendarData?.totalRecords !== undefined ? (
                <>
                  {page * rowsPerPage + 1}-
                  {Math.min(
                    (page + 1) * rowsPerPage,
                    data?.getAdminCalendarData?.totalRecords
                  )}{" "}
                  of {data?.getAdminCalendarData?.totalRecords}
                </>
              ) : (
                "Loading..."
              )}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => onChangePage(page - 1)}
              disabled={page === 0}
            >
              <KeyboardArrowLeftIcon
                className={
                  page === 0 ? styles.arrowDisabled : styles.arrowBackIosIcon
                }
              />
            </IconButton>
            <IconButton
              onClick={() => onChangePage(page + 1)}
              disabled={
                page >=
                  Math.ceil(
                    data?.getAdminCalendarData?.totalRecords / rowsPerPage
                  ) -
                    1 ||
                (allSearch !== "" &&
                  data?.getAdminCalendarData?.data?.length <
                    data?.getAdminCalendarData?.pageSize)
              }
            >
              <KeyboardArrowRightIcon
                className={
                  page >=
                    Math.ceil(
                      data?.getAdminCalendarData?.totalRecords / rowsPerPage
                    ) -
                      1 ||
                  (allSearch !== "" &&
                    data?.getAdminCalendarData?.data?.length <
                      data?.getAdminCalendarData?.pageSize)
                    ? styles.arrowDisabled
                    : styles.arrowBackIosIcon
                }
              />
            </IconButton>
          </Grid>
        </Grid>
      );
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {},
          },
        },
        MUIDataTable: {
          styleOverrides: {
            root: {
              "tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight": {
                borderRadius: "8px",
              },
              "tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar-track":
                {
                  backgroundColor: "#f1f1f1",
                },
              "tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar-thumb":
                {
                  backgroundColor: "#888",
                },
              "tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar-button":
                {
                  backgroundColor: "#ccc",
                },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              "&:first-child": {
                backgroundColor: "white",
                position: "sticky",
                left: " -6px",
                zIndex: "101",
                minWidth: "30px",
              },
              "&:nth-child(3)": {
                borderRight: "1px solid lightgray",
              },
            },
            head: {
              "&:first-child": {
                backgroundColor: "#e5f2ff", // Set your desired color here
                borderTop: "1px solid #e5f2ff;",
                position: "sticky",
                left: " -6px",
                zIndex: "101",
                minWidth: "30px",
              },
              backgroundColor: "#e5f2ff", // Set your desired color here
              borderTop: "1px solid #e5f2ff;",
              fontSize: "16px",
              color: "#000000",
              fontWeight: "600",
              fontFamily: "Poppins, sans-serif",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              borderBottom: "1px solid lightgray",
              paddingTop: "10px",
              paddingBottom: "10px",
              ".tss-1fz5efq-MUIDataTableToolbar-left": {
                display: "none",
              },
              "@media (max-width: 500px)": {
                padding: "10px 10px",
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              border: "solid 1px #e8e8e8",
              borderRadius: "7px",
              ".tss-1dqj55d-MUIDataTableToolbarSelect-root": {
                padding: "10px 24px",
                minHeight: "64px",
                boxShadow: "none",
                border: "0px",
                borderBottom: "1px solid lightgray",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                flexWrap: "wrap",
              },
              ".tss-g5rez6-MUIDataTableToolbarSelect-title": {
                display: "none",
              },
              ".MuiTextField-root": {
                float: "right",
              },
              ".tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar":
                {
                  borderRadius: "8px",
                  width: "6px",
                  height: "6px",
                },
              ".tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar-track":
                {
                  boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.04)",
                  borderRadius: "8px",
                },
              ".tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar-thumb":
                {
                  backgroundColor: "#1976d2",
                  borderRadius: "8px",
                },
              ".tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight::-webkit-scrollbar-button":
                {
                  display: "none",
                },
              "@media (max-width: 500px)": {
                ".tss-1dqj55d-MUIDataTableToolbarSelect-root": {
                  padding: "10px 10px",
                },
              },
            },
          },
        },
      },
    });

  if ((columnsData.length === 0 && filteredData.length === 0) || loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  return (
    <Grid container className={styles.mainContainer}>
      <Grid container className={styles.subContainer}>
        {/* <Typography className={styles.headingText}>
          Employee Calendar
        </Typography> */}
        <AdminCalenderTable
          data={filteredData}
          columns={columnsData}
          options={options}
          title={""}
          getMuiTheme={getMuiTheme}
        />
      </Grid>
    </Grid>
  );
};

export default AdminCalender;
