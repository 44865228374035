import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SelectDropdownProps } from "src/CustomDatatypes/datatypes";
import Style from "src/Styles/customSelect.module.css";
import { Grid } from "@mui/material";

export default function SelectLabels({
  label,
  selectData,
  isErr,
  type,
  onChange,
  value,
  className,
  placeHolder,
}: SelectDropdownProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [age, setAge] = React.useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <Grid>
      <FormControl fullWidth>
        <InputLabel id="demo-select-small">{label}</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          label={label}
          value={value}
          type={type}
          onChange={onChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          error={isErr}
          className={className}
        >
          {/* <MenuItem value="" className={Style.menuItem}>
            <span className={Style.placeholder}>{placeHolder}</span>
          </MenuItem> */}
          {selectData.map((item: any) => {
            return (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
        {isErr && (
          <FormHelperText className={Style.helperTextStyle}>
            Please select a value
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}
