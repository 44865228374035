import { gql } from "graphql-tag";

export const GET_ENTITIES = gql`
  query GetEntities($offset:Int,$limit:Int) {
    getEntities(offset:$offset,limit:$limit) {
      workplaceInfo{
      id
      companyLogo
      companyLogoKey
      companyName
      companyBgImg
      language
      industry
      contactNumber
      companyEmail
      overview
      status
      isDeleted
      employeeCount
      website
       }
       totalCount
    }
  }
`;

export const GET_ENTITY_BY_ID = gql`
  query getEntityById($id: ID!) {
    getEntityById(workplaceId: $workplaceId) {
      workplaceName
    }
  }
`;

export const DELETE_ENTITY_BY_ID = gql`
  mutation DeleteEntity($workplaceId: ID!) {
    deleteEntity(workplaceId: $workplaceId) {
      message
    }
  }
`;

export const CREATE_ENTITY = gql`
  mutation CreateEntity($input: CreateEntityInput!) {
    createEntity(input: $input) {
      id
      companyName
      companyLogo
      companyLogoKey
      companyBgImg
      language
      industry
      contactNumber
      companyEmail
      overview
      website
      status
      isDeleted
    }
  }
`;

export const UPDATE_ENTITY = gql`
  mutation UpdateEntity($input: CreateEntityInput!, $workplaceId: ID!) {
    updateEntity(input: $input, workplaceId: $workplaceId) {
      id
      companyName
      companyLogo
      companyLogoKey
      companyBgImg
      language
      industry
      contactNumber
      companyEmail
      overview
      website
      status
      isDeleted
    }
  }
`;
