import React, { useState, Children } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Grid, Paper, Typography } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-calendar/dist/Calendar.css";
import Styles from "src/Styles/Calendar.module.css";
import CustomToolbar from "./customtoolbar";
import { GET_CALENDER_DATA } from "src/graphql/calendar";
import { useQuery } from "@apollo/client";
import SelectedDayDetailsModal from "./SelectedDayDetailsModal";
import { getFormattedTime } from "src/utils/getFormattedTime";
import MobileCalendar from "react-calendar";
import MobileCustomToolBar from "./mobileCustomToolbar";
import { isTotalTimeGreaterThan } from "src/utils/isTotalTimeGreaterThan ";

const localizer = momentLocalizer(moment);
const CalendarComponent: React.FC = () => {
  const [isregularize, setIsRegularize] = useState(false);
  const [isapplyleave, setIsApplyLeave] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDateInfo, setSelectedDateInfo] = useState<any>();
  const [monthView, setMonthView] = useState<string | number | Date>(
    moment(new Date()).format("MMMM YYYY")
  );

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [date, setDate] = useState(new Date());
  const userId = localStorage.getItem("userId");
  const { data, loading, error, refetch } = useQuery(GET_CALENDER_DATA, {
    variables: {
      input: {
        userId: userId,
        monthOfYear: monthView,
      },
    },
  });

  const handleSlotSelected = (slotInfo: any) => {
    const { start, box } = slotInfo;
    setPosition({ x: box?.x || 0, y: box?.y || 0 });
    setOpen(true);
    const dateSelected = events?.find(
      ({ date }: any) => date === moment(start).format("YYYY-MM-DD").toString()
    );
    setSelectedDateInfo(
      dateSelected || { date: moment(start).format("YYYY-MM-DD") }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegularizeClose = () => {
    setIsRegularize(false);
    setOpen(false);
  };

  const handleRegularizeOpen = () => {
    setIsRegularize(true);
  };

  const handleApplyleaveClose = () => {
    setIsApplyLeave(false);
    setOpen(false);
  };

  const handleApplyleaveOpen = () => {
    setIsApplyLeave(true);
  };

  const refetchMonth = async (direction: any, month: any) => {
    let currentDate: any = moment(`01 ${month.toString()}`).format(
      "DD MMMM YYYY"
    );

    if (direction === "NEXT") {
      currentDate = moment(currentDate).add(1, "months").toDate();
    }
    if (direction === "PREV") {
      currentDate = moment(currentDate).subtract(1, "months").toDate();
    }

    setMonthView(moment(currentDate).format("MMMM YYYY").toString());
    await refetch({
      input: {
        userId: userId,
        monthOfYear: moment(currentDate).format("MMMM YYYY").toString(),
      },
    });
  };
  const events =
    data?.getCalendarData?.calendarData?.map(
      ({
        date,
        isAbsent,
        isHoliday,
        holidayName,
        isWeekend,
        attendance,
        title,
      }: any) => {
        if (isHoliday || isAbsent || isWeekend) {
          return {
            start: moment(date).toDate(),
            end: moment(date).toDate(),
            date,
            title: title
              ? title
              : isHoliday
              ? holidayName
              : isAbsent
              ? "Absent"
              : isWeekend
              ? "Weekend"
              : "",
            isAbsent,
            isHoliday,
            isWeekend,
            loginTime: attendance?.loginTime,
            logoutTime: attendance?.logoutTime,
            status: attendance?.status,
            totalTime: attendance?.totalTime,
            attendance,
          };
        }

        return {
          date,
          start: moment(date).toDate(),
          end: moment(date).toDate(),
          isAbsent,
          isHoliday,
          holidayName,
          isWeekend,
          loginTime: attendance?.loginTime,
          logoutTime: attendance?.logoutTime,
          status: attendance?.status,
          totalTime: attendance?.totalTime,
          attendance,
        };
      }
    ) || [];

  const customComponent = {
    toolbar: (props: any) => {
      return (
        <CustomToolbar events={events} {...props} refetchMonth={refetchMonth} />
      );
    },
    eventWrapper: (props: any) => {
      const eventType = props?.event;
      let text = <p></p>;
      if (
        // eventType?.loginTime &&
        !eventType?.isHoliday &&
        !eventType?.isWeekend
      ) {
        let logintime: any = "";
        let logoutTime: any = "";

        if (eventType?.loginTime) {
          logintime = getFormattedTime(eventType?.loginTime);
        }

        if (eventType?.loginTime && eventType?.logoutTime) {
          logoutTime = getFormattedTime(eventType?.logoutTime);
          logintime = getFormattedTime(eventType?.loginTime);
        }

        if (eventType?.status === "WFH_PENDING") {
          eventType.title = (
            <div>
              WFH:Pending(
              {eventType?.attendance?.wfhType === "FULL_DAY"
                ? "Full Day"
                : eventType?.attendance?.wfhType === "FIRST_HALF"
                ? "First Half "
                : eventType?.attendance?.wfhType === "SECOND_HALF"
                ? "Second Half "
                : ""}
              )
            </div>
          );
        }
        if (eventType?.status === "LEAVE_PENDING") {
          eventType.title = (
            <div>
              Leave: Pending (
              {eventType?.attendance?.leaveType === "FULL_DAY"
                ? "Full Day"
                : eventType?.attendance?.leaveType === "FIRST_HALF"
                ? "First Half "
                : eventType?.attendance?.leaveType === "SECOND_HALF"
                ? "Second Half "
                : ""}
              )<div>{eventType?.attendance?.allowanceName}</div>
            </div>
          );
        }

        text = (
          <p className={Styles.attendedDateCell}>
            {eventType.title ? null : (
              <strong>
                {["PRESENT", "FULL_DAY"].includes(eventType?.status) ? (
                  "Full Day"
                ) : eventType?.status === "HALF_DAY" ? (
                  "Half Day"
                ) : eventType?.status === "REGULARIZATION_PENDING" ? (
                  "REG: (Pending)"
                ) : eventType?.status === "REGULARIZATION_APPROVED" ? (
                  "Regularization"
                ) : eventType?.status === "WFH_PENDING" ? (
                  <div>
                    WFH: Pending (
                    {eventType?.attendance?.wfhType === "FULL_DAY"
                      ? "Full Day"
                      : eventType?.attendance?.wfhType === "FIRST_HALF"
                      ? "First Half "
                      : eventType?.attendance?.wfhType === "SECOND_HALF"
                      ? "Second Half "
                      : ""}
                    )
                  </div>
                ) : eventType?.status === "WFH_APPROVED" ? (
                  <div>
                    WFH (
                    {eventType?.attendance?.wfhType === "FULL_DAY"
                      ? "Full Day"
                      : eventType?.attendance?.wfhType === "FIRST_HALF"
                      ? "First Half "
                      : eventType?.attendance?.wfhType === "SECOND_HALF"
                      ? "Second Half "
                      : ""}
                    )
                  </div>
                ) : eventType?.status === "LEAVE_PENDING" ? (
                  <div>
                    Leave: Pending (
                    {eventType?.attendance?.leaveType === "FULL_DAY"
                      ? "Full Day"
                      : eventType?.attendance?.leaveType === "FIRST_HALF"
                      ? "First Half "
                      : eventType?.attendance?.leaveType === "SECOND_HALF"
                      ? "Second Half "
                      : ""}
                    )<div>{eventType?.attendance?.allowanceName}</div>
                  </div>
                ) : eventType?.status === "LEAVE_APPROVED" ? (
                  <div>
                    Leave (
                    {eventType?.attendance?.leaveType === "FULL_DAY"
                      ? "Full Day"
                      : eventType?.attendance?.leaveType === "FIRST_HALF"
                      ? "First Half "
                      : eventType?.attendance?.leaveType === "SECOND_HALF"
                      ? "Second Half "
                      : ""}
                    )<div>{eventType?.attendance?.allowanceName}</div>
                  </div>
                ) : (
                  ""
                )}
              </strong>
            )}
            <div>
              {logintime && `${logintime} - ${logoutTime ? logoutTime : ""}`}
            </div>
            <div className={Styles.halfDayLeaveType}>
              {eventType?.attendance?.leaveType === "FIRST_HALF"
                ? `Second Half: ${
                    logintime &&
                    logoutTime &&
                    eventType?.attendance?.totalTime &&
                    isTotalTimeGreaterThan(
                      eventType?.attendance?.totalTime,
                      240
                    )
                      ? eventType?.attendance?.regularizeAppliedForHalfDay ===
                        "APPROVED"
                        ? "Regularization"
                        : eventType?.attendance?.regularizeAppliedForHalfDay ===
                          "PENDING"
                        ? "REG: (Pending)"
                        : eventType?.attendance?.regularizeAppliedForHalfDay ===
                          "REJECTED"
                        ? "Absent"
                        : eventType?.attendance?.regularizeAppliedForHalfDay ===
                          "DELETED"
                        ? "Absent"
                        : "Present"
                      : eventType?.attendance?.regularizeAppliedForHalfDay ===
                        "PENDING"
                      ? "REG: (Pending)"
                      : "Absent"
                  } `
                : eventType?.attendance?.leaveType === "SECOND_HALF"
                ? `First Half:  ${
                    logintime &&
                    logoutTime &&
                    eventType?.attendance?.totalTime &&
                    isTotalTimeGreaterThan(
                      eventType?.attendance?.totalTime,
                      240
                    )
                      ? eventType?.attendance?.regularizeAppliedForHalfDay ===
                        "APPROVED"
                        ? "Regularization"
                        : eventType?.attendance?.regularizeAppliedForHalfDay ===
                          "PENDING"
                        ? "REG: (Pending)"
                        : eventType?.attendance?.regularizeAppliedForHalfDay ===
                          "REJECTED"
                        ? "Absent"
                        : eventType?.attendance?.regularizeAppliedForHalfDay ===
                          "DELETED"
                        ? "Absent"
                        : "Present"
                      : eventType?.attendance?.regularizeAppliedForHalfDay ===
                        "PENDING"
                      ? "REG: (Pending)"
                      : "Absent"
                  } `
                : ""}
            </div>
          </p>
        );
      }
      return (
        <div className={Styles.eventWrapper}>
          <Typography
            variant="caption"
            component="div"
            className={`${Styles.holidayName}`}
          >
            {eventType.title}
            {text}
          </Typography>
        </div> // : isAbsent
        // ? "A
      );
    },
    dateCellWrapper: ({ children, ...rest }: any) => {
      const getDateDetails = events?.find(
        (i: any) => i?.date === moment(rest?.value).format("YYYY-MM-DD")
      );

      let cellClassName = "";

      if (!getDateDetails) {
        cellClassName = "rbc-off-range-bg";
      } else {
        cellClassName = `${Styles.defaultCell}`;
      }

      // if (getDateDetails?.loginTime) {
      if (!getDateDetails?.logoutTime && getDateDetails?.loginTime) {
        cellClassName = `${Styles.absentCell}`;
      }

      if (getDateDetails?.status === "ABSENT") {
        cellClassName = `${Styles.absentCell}`;
      }

      if (getDateDetails?.isAbsent) {
        cellClassName = `${Styles.absentCell}`;
      }

      if (
        [
          "FULL_DAY",
          "PRESENT",
          "REGULARIZATION_APPROVED",
          "LEAVE_APPROVED",
        ].includes(getDateDetails?.status)
      ) {
        cellClassName = `${Styles.fullDayCell}`;
      }
      if (
        getDateDetails?.status === "WFH_APPROVED" &&
        getDateDetails?.loginTime &&
        getDateDetails?.logoutTime &&
        getDateDetails?.isAbsent === false
      ) {
        cellClassName = `${Styles.fullDayCell}`;
      }
      if (
        getDateDetails?.status === "WFH_APPROVED" &&
        !getDateDetails?.loginTime &&
        !getDateDetails?.logoutTime &&
        getDateDetails?.isAbsent === false
      ) {
        cellClassName = `${Styles.absentCell}`;
      }
      if (
        getDateDetails?.status === "HALF_DAY" ||
        getDateDetails?.status === "WFH_PENDING" ||
        getDateDetails?.status === "LEAVE_PENDING"
      ) {
        cellClassName = `${Styles.halfDayCell}`;
      }
      if (getDateDetails?.status === "REGULARIZATION_PENDING") {
        cellClassName = `${Styles.regPendingCell}`;
      }

      // }

      if (getDateDetails?.isWeekend) {
        cellClassName = `${Styles.weekoffCell}`;
      }

      if (getDateDetails?.isHoliday) {
        cellClassName = `${Styles.holidayCell}`;
      }

      if (
        moment(selectedDateInfo?.date).format("YYYY-MM-DD") ===
        moment(rest?.value).format("YYYY-MM-DD")
      ) {
        cellClassName = `${cellClassName} ${Styles.selectedDateCell}`;
      }

      return React.cloneElement(Children.only(children), {
        className: ` rbc-day-bg ${cellClassName}`,
      });
    },
  };
  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  // Function to handle click on a day in the calendar
  const handleClickDay = (slotInfo: any, event: any) => {
    // Get the bounding rectangle of the clicked day relative to the viewport
    const boundingRect = event.target.getBoundingClientRect();

    // Calculate the position of the clicked day relative to the document
    const positionX = boundingRect.left + window.scrollX;
    const positionY = boundingRect.top + window.scrollY;
    setPosition({ x: positionX || 0, y: positionY || 0 });
    setOpen(true);
    const dateSelected = events?.find(
      ({ date }: any) =>
        date === moment(slotInfo).format("YYYY-MM-DD").toString()
    );
    setSelectedDateInfo(
      dateSelected || { date: moment(slotInfo).format("YYYY-MM-DD") }
    );
  };

  // Function to check if a given date is a weekend
  const isWeekend = (date: any) => {
    const day = new Date(date).getDay(); // Extract day from the date object
    return day === 0 || day === 6; // Sunday (0) or Saturday (6)
  };

  // Function to determine the class name for each date cell in the calendar
  const tileClassName = ({ activeStartDate, date, view }: any) => {
    const getDateDetails = events?.find(
      (i: any) => i?.date === moment(date).format("YYYY-MM-DD")
    );
    let cellClassName = "";

    if (!getDateDetails) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.unSelectedDay}`;
    } else {
      cellClassName = `${Styles.mobileCalendar} ${Styles.defaultCell}`;
    }

    if (!getDateDetails?.logoutTime && getDateDetails?.loginTime) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.absentCell}`;
    }

    if (getDateDetails?.status === "ABSENT") {
      cellClassName = `${Styles.mobileCalendar} ${Styles.absentCell}`;
    }

    if (getDateDetails?.isAbsent) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.absentCell}`;
    }

    if (
      [
        "FULL_DAY",
        "PRESENT",
        "REGULARIZATION_APPROVED",
        "LEAVE_APPROVED",
      ].includes(getDateDetails?.status)
    ) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.fullDayCell}`;
    }
    if (
      getDateDetails?.status === "WFH_APPROVED" &&
      getDateDetails?.loginTime &&
      getDateDetails?.logoutTime &&
      getDateDetails?.isAbsent === false
    ) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.fullDayCell}`;
    }
    if (
      getDateDetails?.status === "WFH_APPROVED" &&
      !getDateDetails?.loginTime &&
      !getDateDetails?.logoutTime &&
      getDateDetails?.isAbsent === false
    ) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.absentCell}`;
    }
    if (
      getDateDetails?.status === "HALF_DAY" ||
      getDateDetails?.status === "WFH_PENDING" ||
      getDateDetails?.status === "LEAVE_PENDING"
    ) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.halfDayCell}`;
    }
    if (getDateDetails?.status === "REGULARIZATION_PENDING") {
      cellClassName = `${Styles.mobileCalendar} ${Styles.regPendingCell}`;
    }

    if (getDateDetails?.isWeekend) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.weekoffCell}`;
    }

    if (getDateDetails?.isHoliday) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.holidayCell}`;
    }

    if (
      moment(selectedDateInfo?.date).format("YYYY-MM-DD") ===
      moment(date).format("YYYY-MM-DD")
    ) {
      cellClassName = `${Styles.mobileCalendar} ${cellClassName} ${Styles.selectedDateCell}`;
    }
    if (isWeekend(date)) {
      cellClassName = `${Styles.mobileCalendar} ${Styles.mobileCalendarDayCell}`;
    }
    return cellClassName; // Return null for default styling
  };

  // Function to render custom content for each date cell in the calendar
  const tileContent = ({ date, view }: any) => {
    // Format the date to display it in the desired format
    const formattedDate = date.getDate(); // Example: Get the day of the month
    const ariaLabel = `${formattedDate} ${date.toLocaleString("en-US", {
      month: "long",
    })} ${date.getFullYear()}`;
    // Return the custom content for each date cell, including the date
    return (
      <div className={Styles.customMobileDate}>
        <span className="custom-date" aria-label={ariaLabel}>
          {formattedDate}
        </span>{" "}
      </div>
    );
  };

  // Function to handle click on the previous month navigation button
  const handlePrevClick = () => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    setDate(newDate);
    refetchMonth(
      "PREV",
      date.toLocaleString("default", { month: "long", year: "numeric" })
    );
  };

  // Function to handle click on the next month navigation button
  const handleNextClick = () => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    setDate(newDate);
    refetchMonth(
      "NEXT",
      date.toLocaleString("default", { month: "long", year: "numeric" })
    );
  };

  return (
    <Paper elevation={8} className={Styles.paper}>
      <Grid className={Styles.calendar}>
        <Calendar
          events={[...events]}
          localizer={localizer}
          timeslots={2}
          components={customComponent}
          selectable={true}
          toolbar={true}
          views={["month"]}
          className={Styles.CalendarInside}
          onSelectSlot={handleSlotSelected}
        />
      </Grid>
      {/* Mobile screen calendar start here */}
      <div className={Styles.mobileCalendarContainer}>
        <MobileCustomToolBar
          label={date.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
        />
        <MobileCalendar
          onChange={() => setDate(new Date())}
          value={date}
          onClickDay={(value, event) => handleClickDay(value, event)}
          tileClassName={tileClassName}
          tileContent={tileContent}
          showNavigation={false}
        />
      </div>
      {/* Mobile screen calendar ends here */}
      {open && (
        <SelectedDayDetailsModal
          open={open}
          handleClose={handleClose}
          position={position}
          selectedDateInfo={selectedDateInfo}
          handleRegularizeOpen={handleRegularizeOpen}
          handleApplyleaveOpen={handleApplyleaveOpen}
          isregularize={isregularize}
          isapplyleave={isapplyleave}
          handleRegularizeClose={handleRegularizeClose}
          handleApplyleaveClose={handleApplyleaveClose}
        />
      )}
    </Paper>
  );
};

export default CalendarComponent;
