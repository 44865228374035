import { Grid, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingCustomHeader from "src/components/SettingHeader";
import styles from "src/Styles/Workplace.module.css";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTable from "../TableComponent/CustomTable";
import Styles from "src/Styles/EducationalDetails.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  CREATE_DOCUMENT_ADD,
  GET_DOCUMENT_ADD,
} from "../../graphql/documentType";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddDocument from "../AddDocumentModal";
import axios from "axios";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDocSchema } from "src/utils/validations";
import { Toast } from "../ToastMessage";
import ImageModal from "../ImageModal";

const ViewDocuments = () => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  const { id } = useParams();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data, loading, error, refetch } = useQuery(GET_DOCUMENT_ADD, {
    variables: { employeeID: id, offset, limit },
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch])

  const navigate = useNavigate();

  const [mopen, setOpen] = React.useState(false);
  const [imgOpen, setImgOpen] = React.useState(false);
  const [imgData, setImgData] = React.useState<any>({});
  // eslint-disable-next-line
  const [isImage, setIsImage] = React.useState<any>(false);
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [documentName, setDocumentName] = React.useState<string | null>(null);
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [selectedFile, setSelectedFile] = React.useState<any>({});
  const [uploadDocLoader, setUploadDocLoader] = React.useState<any>(false);

  useEffect(() => {
    const img = new Image();
    img.src = imgData;

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  }, [imgData]);

  const [createDocumentAdd] = useMutation(CREATE_DOCUMENT_ADD, {
    refetchQueries: [
      GET_DOCUMENT_ADD,
      "getDocumentAdd", // Query name
    ],
  });

  const handleOpen = () => {
    setOpenDocModal(true);
    docReset({});
  };

  const handleBack = () => {
    navigate("/admin/hrms/admin-documents");
  };

  const handleimgClose = () => {
    setImgOpen(false);
    setImgData({});
  };

  const {
    control: docControl,
    handleSubmit: handleDocFormSubmit,
    formState: { errors: docErrors },
    reset: docReset,
    getValues: getValuess,
    setValue: docSetValue,
    trigger: docTrigger,
  } = useForm({
    resolver: yupResolver(addDocSchema),
  });

  const handleViewClick = async (item: any) => {
    const isImage =
      item?.file?.includes("png") ||
      item?.file?.includes("jpeg") ||
      item?.file?.includes("jpg") ||
      item?.file?.includes("webp") ||
      item?.file?.includes("svg");
    setIsImage(isImage);
    setLoadingImg(true);
    setDocumentName(item.documentName);
    setImgOpen(true);
    setImageDimensions({ width: 0, height: 0 });
    const urlData = {
      id: item?.id,
      hostname: "localhost",
      key: item?.key,
    };
    const response = axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/getAssetById`,
      urlData
    );
    setImgData((await response).data);
    setLoadingImg(false);
  };

  const handleDocSubmit = async (data: any) => {
    try {
      setUploadDocLoader(true);
      const createData = {
        documentType: data.documentType,
        description: data.description,
        documentName: data.documentName,
        createdByEmployerUserId: userInfo?.id,
        employeeID: id,
      };

      const response = await createDocumentAdd({
        variables: { input: createData },
      });

      if (response?.data) {
        const responseID = response?.data?.createDocumentAdd?.id;

        selectedFile.append("_id", responseID);
        // eslint-disable-next-line
        const responseUpload = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/upload`,
          selectedFile
        );
        refetch();
        Toast("success", "Document created Successfully");
        setUploadDocLoader(false);
        setOpenDocModal(false);
      }
    } catch (error: any) {
      console.error("Error creating document Type:", error);
      Toast("error", error?.message);
    }
  };

  const handleSelectedFile = async (evt: any) => {
    try {
      const allowedFormats = ["jpeg", "jpg", "png", "svg", "pdf", "webp"];
      const file = evt.target.files[0];
      docSetValue("file", file);
      docTrigger("file");
      if (!file) {
        return;
      }
      const fileFormat = file.name.split(".").pop().toLowerCase();
      if (!allowedFormats.includes(fileFormat)) {
        Toast(
          "error",
          "Invalid file format. Please upload only JPEG, JPG, PNG, SVG, PDF, or WEBP files."
        );
        return;
      }
      const newFormData = new FormData();
      newFormData.append("file", evt.target.files[0]);
      newFormData.append("hostname", "localhost");
      setSelectedFile(newFormData);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };
  // const sortedDocuments = data?.getDocumentAdd?.sort((a: any, b: any) => {
  //   const dateA = new Date(a.modifiedOn).getTime();
  //   const dateB = new Date(b.modifiedOn).getTime();
  //   return dateB - dateA;
  // });

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid item container md={12} className={Styles.table1}>
        <Grid
          container
          item
          md={12}
          sm={12}
          xs={12}
          lg={12}
          className={styles.idProofsContainer}
        >
          <div className={styles.headerContainer}>
            <ArrowBackIcon onClick={handleBack} className={styles.backBtn} />
            <SettingCustomHeader header="DOCUMENTS" />
          </div>

          <Grid container md={8} sm={8} xs={6} lg={9} justifyContent="flex-end">
            <Typography className={styles.addDocumentText} onClick={handleOpen}>
              + ADD DOCUMENTS
            </Typography>
          </Grid>
        </Grid>

        {data?.getDocumentAdd && (
          <CustomTable
            data={data?.getDocumentAdd?.documentAddInfo || []}
            hidePagination={false}
            tableCellFirstColStyle={Styles.tableCellFirstColStyles}
            tableHeader={Styles.tableHeader}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            count={data?.getDocumentAdd?.totalCount || 0}
            columns={[
              {
                Header: <Grid className={Styles.tableHead}>Document Name</Grid>,
                accessor: "documentName",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.documentName}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Document Type</Grid>,
                accessor: "docuemntType",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.documentType}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Description</Grid>,
                accessor: "description",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.description}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Created By</Grid>,
                accessor: "createdBy",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>{item.createdByName}</Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Date</Grid>,
                accessor: "uploadedDate",
                Cell: ({ item }: any) => (
                  <Grid className={Styles.cell}>
                    {moment(item.modifiedOn).format("MMMM D, YYYY h:mm A")}
                  </Grid>
                ),
              },
              {
                Header: <Grid className={Styles.tableHead}>Action</Grid>,
                Cell: ({ item }: any) => (
                  <Grid item>
                    <Tooltip
                      title="View documents"
                      onClick={() => handleViewClick(item)}
                      sx={{ cursor: "pointer" }}
                    >
                      <VisibilityIcon />
                    </Tooltip>
                  </Grid>
                ),
              },
            ]}
          />
        )}
      </Grid>

      {openDocModal && (
        <AddDocument
          onClose={() => {
            setOpenDocModal(!openDocModal);
          }}
          open={openDocModal}
          handleDocSubmit={handleDocSubmit}
          handleSelectedFile={handleSelectedFile}
          handleDocFormSubmit={handleDocFormSubmit}
          docControl={docControl}
          docErrors={docErrors}
          uploadDocLoader={uploadDocLoader}
        />
      )}

      {/* {imgOpen && (
        <ImageModal
          open={imgOpen}
          onClose={handleimgClose}
          title={documentName || "Document Details"}
          loading={loadingImg}
          isImage={isImage}
          imgData={imgData}
          documentName={documentName}
          imageDimensions={imageDimensions}
        />
      )} */}
      {imgOpen && (
        <Modal
          onClose={handleimgClose}
          open={imgOpen}
          aria-labelledby="image-modal"
          aria-describedby="image-modal-description"
        >
          <Grid
            width={isImage ? imageDimensions.width : "100%"}
            className={styles.modalContainer}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={styles.modalContentContainer}
            >
              <Grid container className={styles.headerContainer}>
                <Grid item>
                  <Typography className={styles.headerText}>
                    {documentName || "Document Details"}
                  </Typography>
                </Grid>
                <Grid item>
                  <CloseOutlinedIcon
                    onClick={handleimgClose}
                    className={styles.closeIcon}
                  />
                </Grid>
              </Grid>
              {loadingImg ? (
                <Grid item>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {isImage ? (
                    <img
                      src={imgData}
                      alt="Modal"
                      className={styles.imgContainer}
                    />
                  ) : (
                    <iframe
                      src={imgData}
                      title="doc"
                      width="100%"
                      height="700px"
                      scrolling="no"
                      frameBorder={0}
                    ></iframe>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default ViewDocuments;
