//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import "./App.css";
import TabsLanding from "./common/TabsLanding";
import Login from "./pages/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetails } from "src/store/UserSlice";
import { useAppDispatch } from "src/store/hooks";
import { useNavigate } from "react-router-dom";
import CircularIndeterminate from "./components/ActivityIndicator/Indicator";
import { GET_USER_BY_ID } from "src/graphql/user";
import { fetchToken, onMessageListener } from "./firebase";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import { UPDATE_FCM_TOKEN } from "src/graphql/user";

function App() {
  // eslint-disable-next-line
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getWindow = () => window;

  const accessToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");
  // eslint-disable-next-line
  const { data, loading, error, refetch } = useQuery(GET_USER_BY_ID, {
    variables: { userId: userId },
    skip: !userId,
  });

  useEffect(() => {
    if (accessToken) {
      if (data?.getUserById?.id) {
        dispatch(getUserDetails(data?.getUserById));
      }
    } else {
      navigate("/");
    }
    refetch();
    // eslint-disable-next-line
  }, [accessToken, data?.getUserById?.id]);

  const [updateFCMToken] = useMutation(UPDATE_FCM_TOKEN);

  useEffect(() => {
    // Fetch FCM token
    fetchToken((token) => {
      // Send token to backend
      updateFCMToken({
        variables: {
          input: {
            token: token,
            userID: userId,
          },
        },
      })
        .then()
        .catch((error) => console.error("Error updating FCM token:", error));
    });
    // eslint-disable-next-line
  }, []);

  const CustomNotification = (
    title: string,
    body: string,
    hrmsLink: string
  ) => {
    if (Notification.permission === "granted") {
      const notification = new Notification(title, { body });

      // Add a click event listener to handle navigation when the notification is clicked
      notification.addEventListener("click", () => {
        // navigate(hrmsLink);
        window.open(hrmsLink, "_blank");
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification(title, { body });

          // Add a click event listener to handle navigation when the notification is clicked
          notification.addEventListener("click", () => {
            // navigate(hrmsLink);
            window.open(hrmsLink, "_blank");
          });
        }
      });
    }
  };

  const token: any = localStorage.getItem("accessToken");
  // eslint-disable-next-line
  const [notification, setNotification] = useState({ title: "", body: "" });
  // eslint-disable-next-line
  const [isTokenFound, setTokenFound] = useState(false);
  // fetchToken(setTokenFound);

  // useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
          const hrmsLink = payload.data.hrmsLink;
          CustomNotification(
            payload.notification.title,
            payload.notification.body,
            hrmsLink
          );
      })
      .catch((err: any) => console.error(err));

  return (
    <div className="App">
      <div id="mainloader" style={{ display: "none" }}>
        <CircularIndeterminate />
      </div>
      {accessToken ? (
        <>
          {/* <HrmsHeader /> <TabsLanding /> */}
          <TabsLanding window={getWindow} />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              accessToken ? <Navigate to="/" /> : <Login />
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
