import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalBox from "src/components/ReusableModal/modal";
import styles from "src/Styles/ApplyLeaveModel.module.css";
import { useMutation } from "@apollo/client";
import {
  CREATE_WORK_FROM_HOME_BY_ID,
  GET_WORK_FROM_HOME_BY_ID,
} from "src/graphql/workFromHome";
import { useForm, Controller } from "react-hook-form";
import { useAppSelector } from "src/store/hooks";
import type { RootState } from "src/store/store";
import moment from "moment";
import { Toast } from "../ToastMessage";
import dayjs from "dayjs";
import { ApplyWorkFromHomeSchema } from "src/utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButtons from "../CustomButton/Button";
const ApplyNewWorkFromHome = ({
  onClick,
  open,
  onClose,
}: // content,
// clearform,
any) => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [createWorkFromHome, { loading: createWfhLoading }] = useMutation(
    CREATE_WORK_FROM_HOME_BY_ID,
    {
      refetchQueries: [
        GET_WORK_FROM_HOME_BY_ID, // DocumentNode object parsed with gql
        "getWorkFromHomeById", // Query name
      ],
    }
  );

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [open]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(ApplyWorkFromHomeSchema),
  });

  useEffect(() => {
    setSubmitDisabled(!isValid);
  }, [isValid]);

  const handleOnSubmit = async (evt: any) => {
    try {
      const input = {
        wfhType: evt.wfhType,
        status: "PENDING",
        requestType: "Work From Home",
        reportingManagerId: null,
        reason: evt.reason,
        noOfDaysApplied: null,
        isDeleted: null,
        appliedTo: moment(evt.wfhDate).format("YYYY-MM-DD"),
        employeeId: userInfo.id,
        allowanceId: null,
        appliedFrom: moment(evt.wfhDate).format("YYYY-MM-DD"),
        acceptedBy: null,
      };
      const response = await createWorkFromHome({
        variables: { input },
      });
      if (response.data) {
        Toast("success", "Work From Home applied Successfully");
        handleClose();
      }
    } catch (error: any) {
      console.error("Something went wrong", error);
      Toast("error", error?.message);
    }
  };

  const [dataInstances, setDataInstances] = useState([
    {
      day: "full-day",
      startDate: null,
      endDate: null,
    },
  ]);

  const handleRemoveDate = (index: any) => () => {
    setDataInstances((prevDataInstances) => {
      if (prevDataInstances.length === 1) {
        return prevDataInstances;
      }

      const newDataInstances = prevDataInstances.filter((_, i) => i !== index);
      return newDataInstances;
    });
  };

  const minDate = dayjs();

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        content={
          <>
            <Grid
              container
              spacing={1}
              className={styles.mainContainer}
              component="form"
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <Grid md={12} item container className={styles.applywfhText}>
                <Grid item md={11} sm={10} xs={8}>
                  <Typography variant="subtitle1" className={styles.headerText}>
                    Apply Work From Home
                  </Typography>
                </Grid>
                <Grid item md={1} sm={2} xs={2}>
                  <CloseOutlinedIcon
                    onClick={onClose}
                    className={styles.closeIcon}
                  />
                </Grid>
              </Grid>

              {dataInstances.map((data, index) => (
                <>
                  <Grid
                    md={12}
                    container
                    item
                    key={index}
                    className={styles.datePickerInput}
                    spacing={1}
                  >
                    <Grid
                      container
                      item
                      md={8}
                      lg={8}
                      sm={8}
                      xs={12}
                      className={styles.inputContainer}
                    >
                      <Controller
                        control={control}
                        name="wfhDate"
                        defaultValue=""
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState: { error } }: any) => {
                          return (
                            <TextField
                              label="Select Date"
                              type="date"
                              defaultValue=""
                              InputProps={{
                                inputProps: {
                                  min: minDate.toISOString().split("T")[0],
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth={true}
                              size="medium"
                              {...field}
                              variant="outlined"
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              error={error !== undefined}
                            />
                          );
                        }}
                      />
                      {errors.wfhDate && (
                        <Typography color="error" className={styles.errorText}>
                          {errors.wfhDate.message}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      md={4}
                      lg={4}
                      sm={4}
                      xs={12}
                      className={styles.dayInput}
                    >
                      <FormControl fullWidth>
                        {/* <FormControl> */}
                        <Controller
                          name="wfhType"
                          control={control}
                          defaultValue="FULL_DAY"
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Select {...field}>
                              <MenuItem value="FULL_DAY">Full Day</MenuItem>
                              <MenuItem value="FIRST_HALF">First Half</MenuItem>
                              <MenuItem value="SECOND_HALF">
                                Second Half
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {index !== 0 && (
                      <Grid item md={1} className={styles.addClose}>
                        <IconButton onClick={handleRemoveDate(index)}>
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </>
              ))}

              <Grid
                container
                item
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className={styles.reasonInputContainer}
              >
                <Controller
                  control={control}
                  name="reason"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth={true}
                      size="small"
                      {...field}
                      multiline
                      label="Reason *"
                      rows={4}
                      variant="outlined"
                      error={error !== undefined}
                    />
                  )}
                />
                {errors.reason && (
                  <Typography color="error" className={styles.errorText}>
                    {errors.reason.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                lg={12}
                sm={12}
                md={12}
                xs={12}
                className={styles.buttonContainer}
              >
                <Grid item className={styles.buttonGrid}>
                  <Button onClick={onClose} className={styles.cancelButton}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item className={styles.buttonGrid}>
                  <CustomButtons
                    name="Submit"
                    type="submit"
                    variant="contained"
                    loading={createWfhLoading}
                    disabled={isSubmitDisabled || createWfhLoading}
                    loaderClass={styles.buttonLoader}
                    className={styles.submitbButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
export default ApplyNewWorkFromHome;
