import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";

const AdminCalenderTable = ({
  data,
  columns,
  options,
  title,
  getMuiTheme,
}: any) => {
  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </div>
  );
};

export default AdminCalenderTable;
