import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import styles from "src/Styles/customValidation.module.css";

const CustomSelect = ({
  error,
  errorCondition,
  onSubmit,
  control,
  name,
  form,
  selectData,
  placeHolder,
  sx,
  className,
  selectFieldCss,
  onChange,
  isTitleRequired,
}: any) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        marginTop: 10,
      },
    },
  };

  return (
    <Grid>
      <Grid component={form} onSubmit={onSubmit}>
        <FormControl
          fullWidth={true}
          className={`${styles.formControl} ${selectFieldCss}`}
        >
          <InputLabel id="selectOption-label"></InputLabel>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="selectOption-label"
                label=""
                error={error}
                size="small"
                displayEmpty
                sx={sx}
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) {
                    onChange(e);
                  }
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="" className={styles.menuItem}>
                  <span className={styles.placeholder}>{placeHolder}</span>
                </MenuItem>
                {selectData.map((item: any) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.type}
                      className={styles.menuItemList}
                      title={isTitleRequired ? item.name : undefined}
                    >
                      <span className={`${className} ${styles.menuText}`}>
                        {item.name}
                      </span>
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />

          {errorCondition}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CustomSelect;
