import gql from "graphql-tag";

export const GET_DEPARTMENTS = gql`
  query getDepartments($offset:Int,$limit:Int) {
    getDepartments(offset:$offset,limit:$limit) {
      departmentInfo{
      id
      departmentCode
      departmentName
      description
      isDeleted
      parentDepartmentName
      status
      employeeCount
      }
      totalCount
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
      departmentCode
      departmentName
      description
      isDeleted
      parentDepartmentName
      status
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation Mutation($departmentId: ID!, $input: CreateDepartmentInput!) {
    updateDepartment(departmentId: $departmentId, input: $input) {
      departmentCode
      departmentName
      description
      id
      isDeleted
      parentDepartmentName
      status
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($departmentId: ID!) {
    deleteDepartment(departmentId: $departmentId) {
      id
    }
  }
`;
