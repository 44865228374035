import gql from "graphql-tag";

export const GET_DASHBOARD_INSIGHTS_BY_ID = gql`
  query DashboardInsightsById($dashboardInsightsByIdId: ID!) {
    dashboardInsightsById(id: $dashboardInsightsByIdId) {
      leaveInsights {
        policyName
        allowancesAvailable
      }

      currentBalance
      pendingRequestOfRM {
        type
        count
      }
      celebrations {
        _id
        date
        displayName
        type
      }
      pendingRequestOfEmployee {
        id
        appliedFrom
        requestType
        status
      }
    }
  }
`;
