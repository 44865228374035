import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";

function CircularIndeterminate() {
  return (
    <Grid
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 9999,
      }}
    >
      <CircularProgress size={50} />
    </Grid>
  );
}
export default CircularIndeterminate;
