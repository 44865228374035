import React, { useEffect, useState } from "react";
import styles from "src/Styles/addEmployee.module.css";
import { Grid, Typography, Divider } from "@mui/material";
import CustomSelect from "src/components/CustomSelect/customSelectValidations";
import { Controller, useForm } from "react-hook-form";
import workInformationData from "./WorkInformationData.json";
import { useMutation, useQuery } from "@apollo/client";
import { GET_JOBTITLE } from "src/graphql/jobTitle";
import { GET_DEPARTMENTS } from "src/graphql/departments";
import { GET_ALL_USERS, UPDATE_EMPLOYEE } from "src/graphql/user";
import { GET_WORKPLACES } from "src/graphql/workplace";
import { GET_ENTITIES } from "src/graphql/entity";
import CustomSearchSelect from "src/components/CustomSearchSelect";
import CustomTextField from "src/components/CustomTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { workInfoSchema } from "src/utils/validations";
import CustomButtons from "src/components/CustomButton/Button";
import CustomDatePicker from "src/components/customDatePicker";
import { Toast } from "src/components/ToastMessage";

const WorkInformation = ({ editEmpRecord, editEmpRefetch }: any) => {
  const { data } = useQuery(GET_JOBTITLE, {
    variables: {
      offset: 0, limit: 0
    }
  });
  const designationData = data?.getJobTitles?.jobTitleInfo.map((item: any) => ({
    name: item?.jobTitle,
    id: item?.id,
    type: item?.id,
  }));

  const { data: deptData } = useQuery(GET_DEPARTMENTS, {
    variables: {
      offset: 0, limit: 0
    }
  });
  const departmentDropdown = deptData?.getDepartments?.departmentInfo.map((item: any) => ({
    name: item?.departmentName,
    id: item?.id,
    type: item?.id,
  }));

  const { data: reportingMangdata } = useQuery(GET_ALL_USERS, {
    variables: {
      input: {
        role: ["SUPER_ADMIN", "ADMIN", "EMPLOYER", "HR", "EMPLOYEE"],
      },
    },
  });
  const reportingManagerData = reportingMangdata?.getAllUsers?.map(
    (item: any) => ({
      name: item?.displayName,
      id: item?.id,
      type: item?.id,
    })
  );

  const { data: workplaceData } = useQuery(GET_WORKPLACES, {
    variables: {
      offset: 0, limit: 0
    }
  });
  const workplaceDropdown = workplaceData?.getWorkplaces?.workplaceInfo.map((item: any) => ({
    name: item?.workplaceName,
    id: item?.id,
    type: item?.id,
  }));

  const { data: entityData } = useQuery(GET_ENTITIES, {
    variables: {
      offset: 0, limit: 0
    }
  });
  const entityDropdown = entityData?.getEntities?.workplaceInfo.map((item: any) => ({
    name: item?.companyName,
    id: item?.id,
    type: item?.id,
  }));

  const { data: usersData } = useQuery(GET_ALL_USERS, {
    variables: {
      input: {
        role: ["HR"],
      },
    },
  });

  const usersDropdown = usersData?.getAllUsers?.map((item: any) => ({
    name: item?.displayName,
    id: item?.id,
    type: item?.displayName,
  }));

  const [hideProbationEndDate, setHideProbationEndDate] = useState(false);

  const [
    updateEmployee,
    // eslint-disable-next-line
    { data: updateEmployeeData, loading: updateEmployeeLoading },
  ] = useMutation(UPDATE_EMPLOYEE);

  useEffect(() => {
    if (editEmpRecord?.probationTerm === "N/A") {
      setHideProbationEndDate(true);
    } else {
      setHideProbationEndDate(false);
    }
    const FormatedEditEmployeeData = {
      employeeCode: editEmpRecord?.employeeCode || "",
      employmentType: editEmpRecord?.employeeType || "",
      employmentStatus: editEmpRecord?.status || "",
      fullName: editEmpRecord?.displayName || "",
      joiningDate: editEmpRecord?.joiningDate || "",
      probationTerm: editEmpRecord?.probationTerm || "",
      probationEndDate: editEmpRecord?.probationEndDate || "",
      noticePeriod: editEmpRecord?.noticePeriod || "",
      designation: editEmpRecord?.designationId || "",
      department: editEmpRecord?.departmentId || "",
      reportingManager:
        (editEmpRecord?.reportingManager.length &&
          editEmpRecord?.reportingManager[0]?.userId) ||
        "",
      workEmail: editEmpRecord?.email || "",
      companyName: editEmpRecord?.entityId || "",
      location: editEmpRecord?.workPlaceId || "",
      shift: editEmpRecord?.shift || "",
      grade: editEmpRecord?.grade || "",
      sourceOfVerification: editEmpRecord?.sourceOfVerification || "",
      hrManager: editEmpRecord?.hrManager || "",
      hiredThrough: editEmpRecord?.hiredThrough || "",
      role: editEmpRecord?.role || "",
    };
    reset(FormatedEditEmployeeData);
    // eslint-disable-next-line
  }, [editEmpRecord]);

  const onChangeOfProbationTerm = (e: any) => {
    if (e.target.value === "N/A") {
      setValue("probationEndDate", null);
      setHideProbationEndDate(true);
    } else {
      setHideProbationEndDate(false);
    }
  };

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ resolver: yupResolver(workInfoSchema) });

  const handleUpdateWorkInfo = async (data: any) => {
    try {
      const userData = {
        employeeCode: data.employeeCode,
        employeeType: data.employmentType,
        status: data.employmentStatus,
        displayName: data.fullName,
        joiningDate: data.joiningDate,
        probationTerm: data.probationTerm,
        probationStatus: data.probationTerm !== "N/A" ? true : false,
        probationEndDate: data.probationEndDate,
        noticePeriod: data.noticePeriod,
        designationId: data.designation,
        departmentId: data.department,
        reportingManager: [
          {
            userId: data.reportingManager,
            sequenceOrder: "0",
          },
        ],
        email: data.workEmail,
        entityId: data.companyName,
        workPlaceId: data.location,
        shift: data.shift,
        grade: data.grade,
        sourceOfVerification: data.sourceOfVerification,
        hrManager: data.hrManager,
        hiredThrough: data.hiredThrough,
        role: data.role,
      };

      const updateResponse: any = await updateEmployee({
        variables: {
          updateEmployeeId: editEmpRecord?.id,
          input: userData,
        },
      });

      if (updateResponse) {
        editEmpRefetch();
        Toast("success", "Work Information updated Successfully");
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  return (
    <Grid
      className={styles.createEmployeeContainer}
      container
      md={12}
      sm={12}
      lg={12}
      xl={12}
      xs={12}
    >
      <Grid
        container
        className={styles.infoWraper}
        component="form"
        onSubmit={handleSubmit(handleUpdateWorkInfo)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Grid container>
          <Grid container mb={3} className={styles.headerGrid}>
            <Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subHeading}>
                  Work Information
                </Typography>
              </Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subText}>
                  Here are all the employees at your company.
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <CustomButtons
                name="Update"
                variant="contained"
                className={styles.createBtn}
                type="submit"
                loading={updateEmployeeLoading}
                disabled={updateEmployeeLoading}
                loaderClass={styles.buttonLoader}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Employee Code
              </Typography>
              <CustomTextField
                error={Boolean(errors.employeeCode)}
                errorCondition={
                  errors.employeeCode && (
                    <Typography className={styles.errorMsg}>
                      {errors.employeeCode.message}
                    </Typography>
                  )
                }
                control={control}
                name="employeeCode"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="AK012000"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Employment Type
              </Typography>
              <CustomSelect
                error={Boolean(errors.employmentType)}
                errorCondition={
                  errors.employmentType && (
                    <Typography className={styles.errorMsg}>
                      {errors.employmentType.message}
                    </Typography>
                  )
                }
                control={control}
                name="employmentType"
                selectData={workInformationData.employeeTypes}
                placeHolder="Select-Type"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Employment Status
              </Typography>

              <CustomSelect
                error={Boolean(errors.employmentStatus)}
                errorCondition={
                  errors.employmentStatus && (
                    <Typography className={styles.errorMsg}>
                      {errors.employmentStatus.message}
                    </Typography>
                  )
                }
                control={control}
                name="employmentStatus"
                selectData={workInformationData.employeeStatus}
                placeHolder="Select Status"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Full Name</Typography>
              <CustomTextField
                error={Boolean(errors.fullName)}
                errorCondition={
                  errors.fullName && (
                    <Typography className={styles.errorMsg}>
                      {errors.fullName.message}
                    </Typography>
                  )
                }
                control={control}
                name="fullName"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="John"
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Joining Date</Typography>
              <CustomDatePicker
                error={Boolean(errors.joiningDate)}
                errorCondition={
                  errors.joiningDate && (
                    <Typography className={styles.errorMsg}>
                      {errors.joiningDate.message}
                    </Typography>
                  )
                }
                control={control}
                name="joiningDate"
                className={styles.datefieldContainer}
                selectedDate={editEmpRecord?.joiningDate}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Probation Term
              </Typography>
              <CustomSelect
                error={Boolean(errors.probationTerm)}
                errorCondition={
                  errors.probationTerm && (
                    <Typography className={styles.errorMsg}>
                      {errors.probationTerm.message}
                    </Typography>
                  )
                }
                control={control}
                name="probationTerm"
                selectData={workInformationData.probationTerm}
                placeHolder="Select Probation"
                selectFieldCss={styles.selectField}
                onChange={onChangeOfProbationTerm}
              />
            </Grid>
            {hideProbationEndDate ? (
              ""
            ) : (
              <Grid
                lg={4}
                md={4}
                sm={6}
                xs={12}
                item
                className={styles.formGrid}
              >
                <Typography className={styles.formLabel}>
                  Probation End Date
                </Typography>
                <CustomDatePicker
                  error={Boolean(errors.probationEndDate)}
                  errorCondition={
                    errors.probationEndDate && (
                      <Typography className={styles.errorMsg}>
                        {errors.probationEndDate.message}
                      </Typography>
                    )
                  }
                  control={control}
                  name="probationEndDate"
                  className={styles.datefieldContainer}
                  selectedDate={editEmpRecord?.probationEndDate}
                />
              </Grid>
            )}
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Notice Period
              </Typography>
              <CustomSelect
                error={Boolean(errors.noticePeriod)}
                errorCondition={
                  errors.noticePeriod && (
                    <Typography className={styles.errorMsg}>
                      {errors.noticePeriod.message}
                    </Typography>
                  )
                }
                control={control}
                name="noticePeriod"
                selectData={workInformationData.NoticePeriod}
                placeHolder="Select Notice period"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Designation</Typography>
              <CustomSelect
                error={Boolean(errors.designation)}
                errorCondition={
                  errors.designation && (
                    <Typography className={styles.errorMsg}>
                      {errors.designation.message}
                    </Typography>
                  )
                }
                control={control}
                name="designation"
                // selectData={workInformationData.Designation}
                selectData={designationData || []}
                placeHolder="Select Designation"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Department</Typography>
              <CustomSelect
                error={Boolean(errors.department)}
                errorCondition={
                  errors.department && (
                    <Typography className={styles.errorMsg}>
                      {errors.department.message}
                    </Typography>
                  )
                }
                control={control}
                name="department"
                placeHolder="Select-Dept."
                selectData={departmentDropdown || []}
                // selectData={workInformationData.Departement}
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Reporting Manger
              </Typography>

              <Controller
                control={control}
                name="reportingManager"
                render={({ field }) => (
                  <CustomSearchSelect
                    label="Reporting Manager"
                    options={reportingManagerData || []}
                    field={field}
                    error={Boolean(errors.reportingManager)}
                    errorCondition={
                      errors.reportingManager && (
                        <Typography className={styles.errorMsg}>
                          {errors.reportingManager.message}
                        </Typography>
                      )
                    }
                    autocompleteCss={styles.autocompleteCss}
                  />
                )}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Work Email</Typography>
              <CustomTextField
                error={Boolean(errors.workEmail)}
                errorCondition={
                  errors.workEmail && (
                    <Typography className={styles.errorMsg}>
                      {errors.workEmail.message}
                    </Typography>
                  )
                }
                control={control}
                name="workEmail"
                fullWidth={true}
                className={styles.fieldContainer}
                placeholder="example@email.com"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            className={styles.dividerContainer}
          >
            <Divider className={styles.divider} />
          </Grid>
          {/*  for the second grid layout */}
          <Grid
            container
            mt={3}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Company Name</Typography>
              <CustomSelect
                error={Boolean(errors.companyName)}
                errorCondition={
                  errors.companyName && (
                    <Typography className={styles.errorMsg}>
                      {errors.companyName.message}
                    </Typography>
                  )
                }
                control={control}
                name="companyName"
                selectData={entityDropdown || []}
                placeHolder="Company Name"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Work Place</Typography>
              <CustomSelect
                error={Boolean(errors.location)}
                errorCondition={
                  errors.location && (
                    <Typography className={styles.errorMsg}>
                      {errors.location.message}
                    </Typography>
                  )
                }
                control={control}
                name="location"
                selectData={workplaceDropdown || []}
                placeHolder="Work Place"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Shift</Typography>
              <CustomSelect
                error={Boolean(errors.shift)}
                errorCondition={
                  errors.shift && (
                    <Typography className={styles.errorMsg}>
                      {errors.shift.message}
                    </Typography>
                  )
                }
                control={control}
                name="shift"
                selectData={workInformationData.shifts}
                placeHolder="Shift"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Grade</Typography>
              <CustomSelect
                // error={Boolean(errors.grade)}
                // errorCondition={
                //   errors.grade && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.grade.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="grade"
                selectData={workInformationData.grade}
                placeHolder="Select Grade"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Source of Verification
              </Typography>
              <CustomSelect
                error={Boolean(errors.sourceOfVerification)}
                errorCondition={
                  errors.sourceOfVerification && (
                    <Typography className={styles.errorMsg}>
                      {errors.sourceOfVerification.message}
                    </Typography>
                  )
                }
                control={control}
                name="sourceOfVerification"
                selectData={workInformationData.sourceOfVerification}
                placeHolder="Verification"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>HR Manager</Typography>
              <CustomSelect
                error={Boolean(errors.hrManager)}
                errorCondition={
                  errors.hrManager && (
                    <Typography className={styles.errorMsg}>
                      {errors.hrManager.message}
                    </Typography>
                  )
                }
                control={control}
                name="hrManager"
                selectData={usersDropdown || []}
                placeHolder="HR Manager"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Hired Through
              </Typography>
              <CustomSelect
                // error={Boolean(errors.hiredThrough)}
                // errorCondition={
                //   errors.hiredThrough && (
                //     <Typography className={styles.errorMsg}>
                //       {errors.hiredThrough.message}
                //     </Typography>
                //   )
                // }
                control={control}
                name="hiredThrough"
                selectData={workInformationData.hiredThrough}
                placeHolder="Hired Through"
                selectFieldCss={styles.selectField}
              />
            </Grid>
            <Grid lg={4} md={4} sm={6} xs={12} item className={styles.formGrid}>
              <Typography className={styles.formLabel}>Role</Typography>
              <CustomSelect
                error={Boolean(errors.role)}
                errorCondition={
                  errors.role && (
                    <Typography className={styles.errorMsg}>
                      {errors.role.message}
                    </Typography>
                  )
                }
                control={control}
                name="role"
                selectData={workInformationData.role}
                placeHolder="Role"
                selectFieldCss={styles.selectField}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container className={styles.editBtnContainer}>
          <CustomButtons
            name="Update"
            variant="contained"
            className={styles.createBtn}
            type="submit"
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default WorkInformation;
