import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LeaveRequest {
  employeeId: string;
  allowanceId: string;
  isApproved: string;
  isDeleted: boolean;
  acceptedBy: string;
  reason: string;
  appliedFrom: Date;
  appliedTo: Date;
  leaveType: string;
  createdOn: Date;
  modifiedOn: Date;
  noOfDaysApplied: string;
  username: string;
  requestType: string;
}

interface LeaveState {
  isApproved: string | null;
  isRejected: string | null;
  selectedItems: LeaveRequest | null;
}

const initialState: LeaveState = {
  isApproved: "PENDING",
  isRejected: "PENDING",
  selectedItems: null,
};

export const leaveSlice = createSlice({
  name: "leave",
  initialState: initialState,
  reducers: {
    approveLeave: (state, action: PayloadAction<string>) => {
      state.isApproved = action.payload;
    },
    rejectLeave: (state, action: PayloadAction<string>) => {
      state.isRejected = action.payload;
    },
    updatedItem: (state, action: PayloadAction<LeaveRequest | null>) => {
      state.selectedItems = action.payload;
    },
  },
});

export const { approveLeave, rejectLeave, updatedItem } = leaveSlice.actions;

export default leaveSlice.reducer;
