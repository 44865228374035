import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import styles from "src/Styles/addEmployee.module.css";
import CustomButtons from "src/components/CustomButton/Button";
import CustomSelect from "src/components/CustomSelect/customSelectValidations";
import { useForm } from "react-hook-form";
import EduDetaildata from "./EducationDetaildata.json";
import { Toast } from "src/components/ToastMessage";
import { UPDATE_EMPLOYEE_BY_QUERY_TYPE } from "src/graphql/user";
import { useMutation } from "@apollo/client";
import SettingCustomHeader from "src/components/SettingHeader";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "src/components/TableComponent";
import tableStyle from "../../../Styles/commonTable.module.css";
import MenuItems from "src/components/MenuItems/MenuItems";
import AddQualificationModal from "src/components/AddQualificationsModal";
import moment from "moment";

const EducationDetails = ({ editEmpRecord, editEmpRefetch }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [
    updateEmployeeByQueryType,
    // eslint-disable-next-line
    { data: updateEmployeeRes, loading: updateEmployeeResLoading },
  ] = useMutation(UPDATE_EMPLOYEE_BY_QUERY_TYPE);

  useEffect(() => {
    const FormatedEditEmployeeData = {
      highestDegree: editEmpRecord?.educationalDetails?.highestDegree || "",
    };
    reset(FormatedEditEmployeeData);
    // eslint-disable-next-line
  }, [editEmpRecord]);

  const {
    control,
    reset,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
  }: any = useForm({});

  const handleUpdateEducationInfo = async (data: any) => {
    try {
      const userData = {
        queryType: "educationalDetails",
        educationalDetails: {
          // you can send multiple fields here
          highestDegree: data.highestDegree,
        },
      };

      const updateResponse: any = await updateEmployeeByQueryType({
        variables: {
          updateEmployeeByQueryTypeId: editEmpRecord?.id,
          input: userData,
        },
      });

      if (updateResponse) {
        editEmpRefetch();
        Toast("success", "Education Details updated Successfully");
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  const addQualificationModalOpen = () => {
    setSelectedItem(null);
    setOpenModal(!openModal);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleMenuClick = async (item: any, event: any) => {
    if (event.target.textContent === "Edit") {
      setSelectedItem(item);
      setOpenModal(!openModal);
    } else if (event.target.textContent === "Delete") {
      try {
        const response: any = await updateEmployeeByQueryType({
          variables: {
            updateEmployeeByQueryTypeId: editEmpRecord?.id,
            input: {
              queryType: "deleteQualification",
              qualificationDetails: { id: item.id },
            },
          },
        });
        if (response.data) {
          Toast("success", "Qualification details deleted Successfully");
          editEmpRefetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  return (
    <Grid
      container
      md={12}
      sm={12}
      lg={12}
      xl={12}
      xs={12}
      className={styles.createEmployeeContainer}
    >
      <Grid
        container
        component="form"
        className={styles.infoWraper}
        onSubmit={handleSubmit(handleUpdateEducationInfo)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Grid container>
          <Grid container mb={3} className={styles.headerGrid}>
            <Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subHeading}>
                  Education Details
                </Typography>
              </Grid>
              <Grid md={12} sm={12} lg={12} xl={12} xs={12}>
                <Typography className={styles.subText}>
                  Here are all the employees at your company.
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <CustomButtons
                name="Update"
                variant="contained"
                className={styles.createBtn}
                type="submit"
                loading={updateEmployeeResLoading}
                disabled={updateEmployeeResLoading}
                loaderClass={styles.buttonLoader}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
          >
            <Grid item lg={4} md={4} sm={6} xs={12} className={styles.formGrid}>
              <Typography className={styles.formLabel}>
                Highest Degree
              </Typography>
              <CustomSelect
                // error={Boolean(errors.highestDegree)}
                // errorCondition={
                //   errors.highestDegree && (
                //     <Typography >{errors.highestDegree.message}</Typography>
                //   )
                // }
                control={control}
                name="highestDegree"
                selectData={EduDetaildata.educationalDetails}
                placeHolder="Please Select a value"
                selectFieldCss={styles.selectField}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.headerGrid}>
            <Grid>
              <SettingCustomHeader
                header="Qualifications"
                paragraph=" Here are all the employees at your company"
              />
            </Grid>
            <Grid>
              <CustomButtons
                name="Add Qualification"
                variant="outlined"
                icon={<AddIcon />}
                className={styles.createBtn}
                onClick={addQualificationModalOpen}
              />
            </Grid>
          </Grid>
          <Grid container md={12} mt={3} mb={3}>
            <CustomTable
              tableHeader={tableStyle.tableHeader}
              data={
                editEmpRecord?.educationalDetails?.qualificationDetails || []
              }
              hidePagination={true}
              columns={[
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      Degree{" "}
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid key={item.id} className={tableStyle.cell}>
                        {item.degree || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      University
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid key={item.id} className={tableStyle.cell}>
                        {item.university || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={100}>
                      From
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid key={item.id} className={tableStyle.cell}>
                        {(item.fromDate &&
                          moment(item.fromDate).format("MMM YYYY")) ||
                          "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={100}>
                      To
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid key={item.id} className={tableStyle.cell}>
                        {(item.toDate &&
                          moment(item.toDate).format("MMM YYYY")) ||
                          "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: (
                    <Grid className={tableStyle.tableHead} minWidth={150}>
                      Location
                    </Grid>
                  ),
                  Cell: ({ item, index }: any) => {
                    return (
                      <Grid key={item.id} className={tableStyle.cell}>
                        {item.location || "—"}
                      </Grid>
                    );
                  },
                },
                {
                  Header: "",
                  Cell: ({ item, index }: any) => {
                    return (
                      <MenuItems
                        options={["Edit", "Delete"]}
                        onViewDetails={(e: any) => handleMenuClick(item, e)}
                        // itemStatus={item.status}
                      />
                    );
                  },
                },
              ]}
            />
          </Grid>
          {/* <Grid item container xs={12} sm={12} md={12}>
        <Grid md={8} className={styles.quliText}>
          <Typography className={styles.Qual}>Qualifications</Typography>
          <Typography className={styles.HereAreAll}>
            Here are all the employees at your company, including terminated
            ones.
          </Typography>
        </Grid>
        <Grid md={4} className={styles.Qualification1}>
          <CustomButtons
            variant="outlined"
            name="+ Add Qualification"
            className={styles.AddBtn}
          />
        </Grid>
      </Grid>
      <Grid item container md={12} className={styles.table}>
        <CustomTable
          data={fieldsData}
          hidePagination={true}
          tableCellFirstColStyle={styles.tableCellFirstColstyles}
          tableHeader={styles.tableHeader}
          columns={[
            {
              Header: <Grid className={styles.paperContent}> Degree </Grid>,
              Cell: ({ item, index }: any) => {
                return <Grid className={styles.cell}>{item.Degree}</Grid>;
              },
            },
            {
              Header: <Grid className={styles.paperContent}> University </Grid>,
              Cell: ({ item, index }: any) => {
                return <Grid className={styles.cell}>{item.University}</Grid>;
              },
            },
            {
              Header: <Grid className={styles.paperContent}> From </Grid>,
              Cell: ({ item, index }: any) => {
                return <Grid className={styles.cell}>{item.From}</Grid>;
              },
            },
            {
              Header: <Grid className={styles.paperContent}> To </Grid>,
              Cell: ({ item, index }: any) => {
                return <Grid className={styles.cell}>{item.To}</Grid>;
              },
            },
            {
              Header: <Grid className={styles.paperContent}> Location </Grid>,
              Cell: ({ item, index }: any) => {
                return <Grid className={styles.cell}>{item.Location}</Grid>;
              },
            },
            {
              Header: "",
              Cell: ({ item, index }: any) => {
                return (
                  <div className={styles.icon}>{item ? item.icon : null}</div>
                );
              },
            },
          ]}
        />
      </Grid> */}
        </Grid>
        {/* <Grid container className={styles.editBtnContainer}>
          <CustomButtons
            name="Update"
            variant="contained"
            className={styles.createBtn}
            type="submit"
          />
        </Grid> */}
      </Grid>
      {openModal && (
        <AddQualificationModal
          onClose={handleModalClose}
          open={openModal}
          editEmpRecord={editEmpRecord}
          editEmpRefetch={editEmpRefetch}
          selectedItem={selectedItem}
        />
      )}
    </Grid>
  );
};

export default EducationDetails;
