import gql from "graphql-tag";

export const REGISTER_USER = gql`
  mutation registerUser(
    $hostname: String!
    $dbname: String
    $clientType: ClientType
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $displayName: String
    $role: String
    $companyUrl: String
    $entityId: ID
  ) {
    registerUser(
      hostname: $hostname
      dbname: $dbname
      clientType: $clientType
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      displayName: $displayName
      role: $role
      companyUrl: $companyUrl
      entityId: $entityId
    ) {
      firstName
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation AddEmployee($input: AddEmployeeInput!) {
    addEmployee(input: $input) {
      id
      email
      firstName
      lastName
      employeeType
      shift
      languagesKnown
      displayName
      role
      departmentId
      designationId
      workPlaceId
      address {
        addressDetails
        city
        state
        zipCode
        country
      }
      emailNotifications {
        dailyReport
        billingUpdates
      }
      profileImage {
        imageId
        name
        url
      }
      userAllowances {
        allowanceId
        allowancesAvailable
      }
      office
      status
      phone
      countryCode
      twoFactorAuthentication
      globalTwoFactorAuthentication
      subscriptionType
      createOn
      modifiedOn
      isActive
      isDeleted
      employeeCode
      accessRules {
        type
        active
        revoked
        adminRevokedStatus
        default
      }
      fields
      reportingManager {
        userId
        sequenceOrder
      }
      reportingManagerName
      compensationDetails {
        yearlyCTC
        MonthlyCTC
        variablePay
        variableTerm
      }
      permanentAddress {
        addressDetails
        city
        state
        country
        zipCode
      }
      bankDetails {
        bankName
        accountName
        accountNumber
        IFSCCode
        location
      }
      educationalDetails {
        highestDegree
      }
      professionalExperience {
        year
        month
      }
      jobTitleName
      deptName
      bloodGroup
      departmentName
      designationName
      gender
      joiningDate
      probationTerm
      probationEndDate
      noticePeriod
      entityId
      grade
      sourceOfVerification
      hrManager
      hiredThrough
      personalEmail
      dob
      maritalStatus
      fatherName
      motherName
      dateOfMarriage
      nationality
      probationStatus
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers($input: filterInput) {
    getAllUsers(input: $input) {
      id
      email
      firstName
      lastName
      employeeType
      shift
      languagesKnown
      displayName
      role
      departmentId
      designationId
      workPlaceId
      address {
        addressDetails
        city
        state
        zipCode
        country
      }
      emailNotifications {
        dailyReport
        billingUpdates
      }
      profileImage {
        imageId
        name
        url
      }
      userAllowances {
        allowanceId
        allowancesAvailable
      }
      office
      status
      phone
      countryCode
      twoFactorAuthentication
      globalTwoFactorAuthentication
      subscriptionType
      createOn
      modifiedOn
      isActive
      isDeleted
      employeeCode
      accessRules {
        type
        active
        revoked
        default
      }
      fields
      reportingManager {
        userId
        sequenceOrder
      }
      reportingManagerName
      compensationDetails {
        yearlyCTC
        MonthlyCTC
        variablePay
        variableTerm
      }
      permanentAddress {
        addressDetails
        city
        state
        country
        zipCode
      }
      bankDetails {
        bankName
        accountName
        accountNumber
        IFSCCode
        location
      }
      educationalDetails {
        highestDegree
      }
      professionalExperience {
        year
        month
      }
      jobTitleName
      deptName
      bloodGroup
      departmentName
      designationName
      gender
      joiningDate
      probationTerm
      probationEndDate
      noticePeriod
      entityId
      grade
      sourceOfVerification
      hrManager
      hiredThrough
      personalEmail
      dob
      maritalStatus
      fatherName
      motherName
      dateOfMarriage
    }
  }
`;


export const GET_ORG_USERS = gql`
  query Query {
  getOrgUsers
}
`;

export const GET_ALL_USERS_BY_PORTAL = gql`
  query GetUsersByPortal($input: UsersFilterInput) {
    getUsersByPortal(input: $input) {
 totalCount
    users {
         id
    email
    firstName
    lastName
    employeeType
    shift
    languagesKnown
    displayName
    role
    departmentId
    designationId
    workPlaceId
    address {
      addressDetails
      city
      state
      zipCode
      country
    }
    emailNotifications {
      dailyReport
      billingUpdates
    }
    profileImage {
      imageId
      name
      url
    }
    userAllowances {
      allowanceId
      allowancesAvailable
    }
    office
    status
    phone
    countryCode
    twoFactorAuthentication
    globalTwoFactorAuthentication
    subscriptionType
    createOn
    modifiedOn
    isActive
    isDeleted
    employeeCode
    accessRules {
      type
      active
      revoked
      adminRevokedStatus
      default
    }
    fields
    reportingManager {
      userId
      sequenceOrder
    }
    reportingManagerName
    compensationDetails {
      yearlyCTC
      MonthlyCTC
      variablePay
      variableTerm
    }
    permanentAddress {
      addressDetails
      city
      state
      country
      zipCode
    }
    bankDetails {
      bankName
      accountName
      accountNumber
      IFSCCode
      location
    }
    educationalDetails {
      highestDegree
      
    }

    jobTitleName
    deptName
    bloodGroup
    departmentName
    designationName
    gender
    joiningDate
    probationTerm
    probationEndDate
    noticePeriod
    entityId
    grade
    sourceOfVerification
    hrManager
    hiredThrough
    personalEmail
    dob
    maritalStatus
    fatherName
    motherName
    dateOfMarriage
    nationality
    probationStatus
    
    }
  }
  }
`;

export const DELETE_BY_USER_ID = gql`
  mutation DeleteUserByID($userId: ID!) {
    deleteUserByID(userId: $userId) {
      message
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($userId: String) {
    getUserById(userId: $userId) {
      id
      email
      firstName
      lastName
      employeeType
      shift
      languagesKnown
      displayName
      role
      departmentId
      designationId
      workPlaceId
      address {
        addressDetails
        city
        state
        zipCode
        country
      }
      emailNotifications {
        dailyReport
        billingUpdates
      }
      profileImage {
        imageId
        name
        url
      }
      userAllowances {
        allowanceId
        allowancesAvailable
      }
      office
      status
      phone
      countryCode
      twoFactorAuthentication
      globalTwoFactorAuthentication
      subscriptionType
      createOn
      modifiedOn
      isActive
      isDeleted
      employeeCode
      accessRules {
        type
        active
        revoked
        default
      }
      fields
      reportingManager {
        userId
        sequenceOrder
      }
      reportingManagerName
      compensationDetails {
        yearlyCTC
        MonthlyCTC
        variablePay
        variableTerm
        appraisalDetails {
          id
          appraisalDate
          CTC
          revisedCTC
          promotedFrom
          promotedTo
          file
          assetKey
          documentName
        }
      }
      permanentAddress {
        addressDetails
        city
        state
        country
        zipCode
      }
      bankDetails {
        bankName
        accountName
        accountNumber
        IFSCCode
        location
      }
      educationalDetails {
        highestDegree
        qualificationDetails {
          id
          degree
          university
          fromDate
          toDate
          location
        }
      }
      professionalExperience {
        year
        month
        experienceDetails {
          id
          employerName
          jobDesignation
          startDate
          endDate
          location
        }
        skillsDetails {
          skill
          proficiency
          yearsOfExperience
          monthsOfExperience
        }
      }
      jobTitleName
      deptName
      bloodGroup
      departmentName
      designationName
      gender
      joiningDate
      probationTerm
      probationEndDate
      noticePeriod
      entityId
      grade
      sourceOfVerification
      hrManager
      hiredThrough
      personalEmail
      dob
      maritalStatus
      fatherName
      motherName
      dateOfMarriage
      nationality
      probationStatus
      emergencyContactDetails {
        id
        firstName
        lastName
        email
        phone
        relation
      }
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($updateEmployeeId: ID!, $input: UpdateEmployeeInput) {
    updateEmployee(id: $updateEmployeeId, input: $input) {
      email
      firstName
      lastName
      employeeType
      shift
      languagesKnown
      displayName
      role
      departmentId
      designationId
      workPlaceId
      address {
        addressDetails
        city
        state
        zipCode
        country
      }
      emailNotifications {
        dailyReport
        billingUpdates
      }
      profileImage {
        imageId
        name
        url
      }
      userAllowances {
        allowanceId
        allowancesAvailable
      }
      office
      status
      phone
      countryCode
      twoFactorAuthentication
      globalTwoFactorAuthentication
      subscriptionType
      createOn
      modifiedOn
      isActive
      isDeleted
      employeeCode
      accessRules {
        type
        active
        revoked
        adminRevokedStatus
        default
      }
      fields
      reportingManager {
        userId
        sequenceOrder
      }
      reportingManagerName
      compensationDetails {
        yearlyCTC
        MonthlyCTC
        variablePay
        variableTerm
      }
      permanentAddress {
        addressDetails
        city
        state
        country
        zipCode
      }
      bankDetails {
        bankName
        accountName
        accountNumber
        IFSCCode
        location
      }
      educationalDetails {
        highestDegree
      }
      professionalExperience {
        year
        month
      }
      jobTitleName
      deptName
      bloodGroup
      departmentName
      designationName
      gender
      joiningDate
      probationTerm
      probationEndDate
      noticePeriod
      entityId
      grade
      sourceOfVerification
      hrManager
      hiredThrough
      personalEmail
      dob
      maritalStatus
      fatherName
      motherName
      dateOfMarriage
      nationality
      probationStatus
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation OtpVerification($input: verifyOtpInput) {
    otpVerification(input: $input) {
      success
      status
      email
      userId
      message
    }
  }
`;

export const UPDATE_FCM_TOKEN = gql`
  mutation UpdateFCMToken($input: NotificationInput) {
    updateFCMToken(input: $input) {
      userID
    }
  }
`;

export const GET_ADDRESS_BY_ZIPCODE = gql`
  query GetAddressByZipcode($zipCode: String) {
    getAddressByZipcode(zipCode: $zipCode) {
      state
      city
      country
    }
  }
`;

export const UPDATE_EMPLOYEE_BY_QUERY_TYPE = gql`
  mutation UpdateEmployeeByQueryType(
    $updateEmployeeByQueryTypeId: ID!
    $input: UpdateEmployeeQueryTypeInput
  ) {
    updateEmployeeByQueryType(id: $updateEmployeeByQueryTypeId, input: $input) {
      success
      message
    }
  }
`;
