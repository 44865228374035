import * as XLSX from "xlsx";

export function generateExcel(data: any) {
  const ws = XLSX.utils.json_to_sheet(data);

  const columnWidths = getColumnWidths(data);
  columnWidths.forEach((width: any, i: any) => {
    ws["!cols"] = ws["!cols"] || [];
    ws["!cols"][i] = { width };
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return dataBlob;
}

function getColumnWidths(data: any) {
  const columnWidths: any = [];

  data.forEach((row: any) => {
    Object.keys(row).forEach((key, columnIndex) => {
      const cellValue = row[key];
      const cellLength = cellValue ? String(cellValue).length : 0;
      if (
        !columnWidths[columnIndex] ||
        cellLength > columnWidths[columnIndex]
      ) {
        columnWidths[columnIndex] = cellLength;
      }
    });
  });
  const padding = 5;
  return columnWidths.map((width: any) => width + padding);
}

export function downloadExcel(dataBlob: any, fileName: any) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(dataBlob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
