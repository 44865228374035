import React from "react";
import {
  Grid,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FullScreenDialog from "src/components/SliderModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Styles from "src/Styles/AllTaskSlider.module.css";
import empty_table from "src/images/assets/empty_table (1).svg";

const AllTaskSlider = ({ onClick, open, onClose, content }: any) => {
  return (
    <>
      <FullScreenDialog
        onClick={onClick}
        open={open}
        onClose={onClose}
        direction="up"
        className={Styles.AllTaskSlider_Main}
        content={
          <>
            <Grid container className={Styles.Parent_Grid}>
              <Grid container className={Styles.Header_Grid}>
                <Grid item md={6}>
                  <Typography className={Styles.Header_Text}>
                    All Task
                  </Typography>
                </Grid>
                <Grid item md={2} className={Styles.Selecter_1}>
                  <FormControl fullWidth>
                    {/* <FormControl> */}
                    <InputLabel className={Styles.Assign_status}>
                      Assign Status
                    </InputLabel>
                    <Select
                      labelId="all-task"
                      id="demo-simple-select"
                      label="Task status"
                    >
                      <MenuItem value="clent-feedback">All</MenuItem>
                      <MenuItem value="hign-priority">Assigned to me</MenuItem>
                      <MenuItem value="low">Assigned by me</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2} className={Styles.Selecter_2}>
                  <FormControl fullWidth>
                    {/* <FormControl> */}
                    <InputLabel id="all-task">Task Status</InputLabel>
                    <Select
                      labelId="all-task"
                      id="demo-simple-select"
                      label="Task status"
                    >
                      <MenuItem value="clent-feedback">All</MenuItem>
                      <MenuItem value="hign-priority">Pending</MenuItem>
                      <MenuItem value="low">In Progress</MenuItem>
                      <MenuItem value="tracex">Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid container className={Styles.Close_btn_Grid}> */}
                <Grid item md={2} className={Styles.Close_Btn}>
                  <IconButton>
                    <CloseOutlinedIcon onClick={onClose} />
                  </IconButton>
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Grid container className={Styles.Content_Grid}>
                <Grid item md={12}>
                  <img
                    alt="pending-tasks"
                    src={empty_table}
                    width={300}
                    height={300}
                    className={Styles.Background_img}
                  />
                </Grid>
                <Grid item md={12} className={Styles.Footer_text}>
                  <Typography>No Data Found.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default AllTaskSlider;
