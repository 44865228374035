import { gql } from "graphql-tag";

export const GET_CONNECTIONS = gql`
  query GetConnections {
    getConnections {
      _id
      hostname
      dbname
      active
      createdOn
      modifiedOn
      loggedInCount
      lastLoggedIn
    }
  }
`;

export const GET_WORKPLACES = gql`
  query GetWorkplaces($offset:Int,$limit:Int) {
    getWorkplaces(offset:$offset,limit:$limit) {
      workplaceInfo{
      id
      workplaceName
      workplaceCode
      addressLine1
      addressLine2
      city
      zipCode
      state
      country
      mobileNumber
      timeZone
      status
      isDeleted
      employeeCount
        }
        totalCount
    }
  }
`;

export const GET_WORKPLACES_BY_ID = gql`
  query getWorkplaceById($workplaceId: String) {
    getWorkplaceById(workplaceId: $workplaceId) {
      workplaceName
    }
  }
`;

export const DELETE_WORKPLACE_BY_ID = gql`
  mutation deleteWorkspace($id: ID!) {
    deleteWorkspace(id: $id) {
      message
    }
  }
`;

export const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      addressLine1
      addressLine2
      city
      country
      id
      isDeleted
      mobileNumber
      state
      timeZone
      workplaceCode
      workplaceName
      zipCode
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($workplaceId: ID!, $input: CreateWorkspaceInput!) {
    updateWorkspace(workplaceId: $workplaceId, input: $input) {
      id
      workplaceName
      workplaceCode
      addressLine1
      addressLine2
      city
      zipCode
      state
      country
      mobileNumber
      timeZone
      status
      isDeleted
    }
  }
`;
