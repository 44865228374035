import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomMultiSelect({
  error,
  errorCondition,
  onSubmit,
  control,
  name,
  form,
  fullWidth,
  className,
  placeholder,
  onChange,
  defaultValue,
  selectData,
}: any) {
  const [fieldValue, setFieldValue] = useState<any>(defaultValue);

  useEffect(() => {
    setFieldValue(defaultValue);
  }, [defaultValue]);

  // Filter options to exclude the default value
  const filteredOptions: any = selectData.filter(
    (option: any) =>
      !fieldValue.find((selected: any) => selected.title === option.title)
  );
  return (
    <Grid component={form} onSubmit={onSubmit}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            multiple
            id="tags-outlined"
            filterSelectedOptions
            options={filteredOptions || []}
            value={fieldValue}
            getOptionLabel={(option: any) => option.title || ""}
            onChange={(e, value) => {
              field.onChange(value);
              setFieldValue(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={fullWidth}
                error={error}
                InputProps={{
                  ...params.InputProps,
                  className: className,
                }}
                placeholder={placeholder}
              />
            )}
            renderTags={(value, getTagProps) => {
              const displayedChips = value.slice(0, 2);
              const hiddenChipsCount = Math.max(0, value.length - 2);
              const hiddenChips = value.slice(2);

              return (
                <>
                  {displayedChips.map((option: any, index) => (
                    <Chip
                      label={
                        option.title.length > 10
                          ? option.title.slice(0, 10) + "..."
                          : option.title
                      }
                      {...getTagProps({ index })}
                      key={index}
                      title={option.title}
                    />
                  ))}
                  {hiddenChipsCount > 0 && (
                    <Chip
                      label={`+${hiddenChipsCount} more`}
                      style={{ opacity: 0.5, cursor: "pointer" }}
                      title={hiddenChips
                        .map((chip: any) => chip.title)
                        .join(", ")}
                    />
                  )}
                </>
              );
            }}
          />
        )}
      />
      {errorCondition}
    </Grid>
  );
}
