import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Card } from '@mui/material';
import TextField from '@mui/material/TextField';
import styles from 'src/Styles/AddjobTitle.module.css';
import Divider from '@mui/material/Divider';
import ModalBox from 'src/components/ReusableModal/modal';
import { useForm } from 'react-hook-form';
import CustomButtons from 'src/components/CustomButton/Button';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { GET_JOBTITLE, CREATE_JOBTITLE, UPDATE_JOBTITLE } from '../../graphql/jobTitle';
import { Toast } from '../ToastMessage';
import CloseIcon from '@mui/icons-material/Close';

const AddJobTitle = ({ onClick, open, onClose, setOpen, editFlag, editData }: any) => {
  // eslint-disable-next-line
  const [frmdata, setFrmdata] = React.useState(null);

  const [createJobTitle] = useMutation(CREATE_JOBTITLE, {
    refetchQueries: [
      GET_JOBTITLE, // DocumentNode object parsed with gql
      'getJobTitles', // Query name
    ],
  });
  const [updateJobTitle] = useMutation(UPDATE_JOBTITLE, {
    refetchQueries: [
      GET_JOBTITLE, // DocumentNode object parsed with gql
      'getJobTitles', // Query name
    ],
  });

  const schema = yup.object().shape({
    position: yup.string().required(),
  });

  React.useEffect(() => {
    if (editFlag) {
      const defaultValues = {
        position: editData.jobTitle,
        isDeleted: editData.isDeleted,
        status: editData.status,
      };
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [editData, editFlag]);

  const { control, handleSubmit, reset } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (evt: any) => {
    setFrmdata(evt);
    if (editFlag) {
      try {
        const input = {
          jobTitle: evt.position,
        };
        const response = await updateJobTitle({
          variables: { workplaceId: editData.id, input },
        });
        if (response.data) {
          Toast('success', 'Job Title updated Successfully');
          setOpen(false);
        }
      } catch (error: any) {
        console.error('Error creating Department:', error);
        Toast('error', error?.message);
      }
    } else {
      try {
        const input = {
          jobTitle: evt.position,
        };

        const response = await createJobTitle({
          variables: { input },
        });
        if (response.data) {
          Toast('success', 'Job Title created Successfully');
          setOpen(false);
        }
      } catch (error: any) {
        console.error('Error creating Department:', error);
        Toast('error', error?.message);
      }
    }
  };

  return (
    <>
      <ModalBox
        onClick={onClick}
        open={open}
        onClose={onClose}
        content={
          <div className={styles.modalContent}>
            <Grid container className={styles.topDiv}>
              <Grid xs={9} lg={9} xl={9} md={9} sm={9}>
                <Typography className={styles.header}>{!editFlag ? 'Add Job Title' : 'Edit Job Title'}</Typography>
                <Typography className={styles.typography_color}>
                  {!editFlag
                    ? 'Create a new job title to organize employees geographically across various locations.'
                    : 'Edit a job title to organize employees geographically across various locations.'}
                </Typography>
              </Grid>
              <Grid lg={3} md={3} sm={3} xs={3} xl={3} className={styles.closeicon} justifyContent={'flex-end'}>
                <CloseIcon onClick={onClose} />
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: '10px' }} />
            <Grid container className={styles.bottomDiv}>
              <Grid xs={12}>
                <Typography className={styles.inputHeading}>Job Title Name</Typography>
              </Grid>
              <form onSubmit={handleSubmit(handleOnSubmit)} className={styles.textField}>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="position"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth={true}
                        {...field}
                        type="position"
                        placeholder="Senior Software Engineer"
                        error={error !== undefined}
                        helperText={error ? 'Please Enter a Position' : ''}
                        className={styles.jobTitleFiled}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} lg={4} md={4} xl={4} sm={4} className={styles.buttonsDiv} pb={1}>
                  <CustomButtons
                    name={!editFlag ? 'Add a Job Title' : 'Edit a Job Title'}
                    type="submit"
                    variant="contained"
                    className={styles.buttons}
                  />
                </Grid>
              </form>
            </Grid>
          </div>
        }
      />
    </>
  );
};

export default AddJobTitle;
