import React, { useState } from "react";
import styles from "../../Styles/Login.module.css";
import {
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import xperia from "../../images/assets/Arokee Logo Black .png";
import loginLogo from "../../images/assets/loginLogo.png";
import Link from "@mui/material/Link";
import OtpInput from "src/components/Otpcomp/OtpInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { FORGOT_PASSWORD } from "src/graphql/user";
import { useMutation } from "@apollo/client";
import { ForgotPasswordSchema } from "src/utils/validations";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordDetails } from "src/store/UserSlice";
import { useForm, Controller } from "react-hook-form";
import CustomButtons from "src/components/CustomButton/Button";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [otpVerification, { data, error, loading }] =
    useMutation(FORGOT_PASSWORD);

  const [openOtpContent, setOpenOtpContent] = useState<Boolean>(false);
  const [userEmail, setUserEmail] = useState("");
  const [verfiyOtpRes, setVerifyOtpRes] = useState({ message: "", type: "" });
  const [otpInputValue, setOtpInputValue] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const handleOnEmailSubmit = async (evt: any) => {
    setUserEmail(evt?.email);
    try {
      const response = await otpVerification({
        variables: {
          input: {
            email: evt?.email,
            verificationType: "sendOtp",
            type: "email",
          },
        },
      });

      if (
        response?.data?.otpVerification?.status === "SENT" &&
        response?.data?.otpVerification?.email
      ) {
        setOpenOtpContent(true);
      }
    } catch (err) {
      // Handle any errors here
      console.error("Error:", err);
      setError("email", {
        message: "Invalid email",
      });
    }
  };

  const handleResendOtp = async (evt: any) => {
    try {
      const response = await otpVerification({
        variables: {
          input: {
            email: userEmail,
            verificationType: "sendOtp",
            type: "email",
          },
        },
      });

      if (
        response?.data?.otpVerification?.status === "SENT" &&
        response?.data?.otpVerification?.email
      ) {
        setVerifyOtpRes({
          message: response?.data?.otpVerification?.message,
          type: "success",
        });
        setOtpInputValue("");
      }
    } catch (err: any) {
      // Handle any errors here
      setVerifyOtpRes({ message: err?.message, type: "error" });
      console.error("Error:", err);
    }
  };

  const handleEnteredOtp = async (code: any) => {
    setOtpInputValue(code);
    if (code?.length === 6) {
      try {
        const response = await otpVerification({
          variables: {
            input: {
              email: userEmail,
              verificationType: "verifyOtp",
              type: "email",
              otp: parseInt(code),
            },
          },
        });

        if (
          response?.data?.otpVerification?.status === "VERIFIED" &&
          response?.data?.otpVerification?.email
        ) {
          dispatch(
            forgotPasswordDetails({
              email: response?.data?.otpVerification?.email,
              userId: response?.data?.otpVerification?.userId,
            })
          );
          // setVerifyRes(response?.data?.otpVerification?.message);
          navigate("/reset-password");
        }
      } catch (err: any) {
        // Handle any errors here
        setVerifyOtpRes({ message: err?.message, type: "error" });
        console.error("Error:", err);
      }
    }
  };
  return (
    <Grid container component="main" className={styles.mainPage}>
      <Grid item md={6} lg={6} sm={6} xs={12} className={styles.leftGrid}>
        <img src={loginLogo} alt="loginLogo" className={styles.loginLogo} />
      </Grid>
      {!openOtpContent ? (
        <Grid
          md={6}
          lg={6}
          sm={12}
          xs={12}
          component="form"
          onSubmit={handleSubmit(handleOnEmailSubmit)}
          className={styles.right_half}
        >
          <Grid container className={styles.rightHalfWrapper}>
            <Grid container>
              <Grid item md={9} sm={9} lg={9} xs={9}>
                <img
                  src={xperia}
                  alt="front_image"
                  className={styles.xperia_image}
                />
              </Grid>
              <Grid item md={3} sm={3} lg={3} xs={3} />
              <Grid item md={12} sm={12} lg={12} xs={12}>
                <Typography variant="h4" className={styles.WelcomeText}>
                  Forgot Password?
                </Typography>
              </Grid>

              {/* Replace the Grid container with a Card component */}
              <Card className={styles.signInContainer}>
                <CardContent>
                  <Typography className={styles.sigin_textforgotPwd}>
                    Enter the email address you used when you joined and we’ll
                    end you instructions to reset your password.
                  </Typography>
                  <Divider />
                  <Grid className={styles.cardBody}>
                    <Typography className={styles.emailText}>
                      Email address
                    </Typography>

                    <Controller
                      control={control}
                      name="email"
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className={styles.textField}
                          id="outlined-basic"
                          type="email"
                          placeholder="Enter Work Email"
                          label=""
                        />
                      )}
                    />
                    {errors.email && (
                      <Typography color="error" className={styles.errorMsg}>
                        {errors.email.message}
                      </Typography>
                    )}
                    <Grid className={styles.sendButtonContainer}>
                      <Typography className={styles.takeMeBack}>
                        Take me back to{" "}
                        <Link href="/" className={styles.loginText}>
                          Log in
                        </Link>
                      </Typography>
                      <CustomButtons
                        className={styles.sendButton}
                        name="Send"
                        type="submit"
                        disabled={!isValid}
                        disabledColor="#d9d9d9"
                        textColor="#fff"
                      />
                    </Grid>
                  </Grid>

                  <Grid className={styles.termsBodyforgotPwd}>
                    <Typography className={styles.termsLinks}>
                      <span className={styles.termsOfService}>
                        Terms of Service
                      </span>
                      <span className={styles.privacy}>Privacy Policy</span>
                    </Typography>
                    <Typography className={styles.details}>
                      For details of how we use personal data see our privacy
                      and data processing statement
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          md={6}
          lg={6}
          sm={12}
          xs={12}
          component="form"
          className={styles.right_half}
        >
          <Grid container className={styles.rightHalfWrapper}>
            <Grid container>
              <Grid item md={9} sm={9} lg={9} xs={9}>
                <img
                  src={xperia}
                  alt="front_image"
                  className={styles.xperia_image}
                />
              </Grid>
              <Grid item md={3} sm={3} lg={3} xs={3} />
              <Grid item md={12} sm={12} lg={12} xs={12}>
                <Typography variant="h4" className={styles.WelcomeText}>
                  Reset Password
                </Typography>
              </Grid>

              <Card className={styles.signInContainer}>
                <CardContent>
                  <Grid
                    container
                    className={styles.forgotPasswordTextContainer}
                  >
                    <Typography>
                      <span className={styles.sigin_textforgotPwd}>
                        OTP has been sent to
                      </span>
                      <span className={styles.sigin_textforgotPwduserMail}>
                        {userEmail}
                      </span>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid className={styles.cardBody}>
                    <Typography className={styles.emailTextEnterOtp}>
                      Enter OTP
                    </Typography>
                    <Grid className={styles.otpInputDiv}>
                      <OtpInput
                        value={otpInputValue}
                        onChange={handleEnteredOtp}
                        className={styles.otpInputBox}
                      />
                    </Grid>

                    <Grid>
                      {verfiyOtpRes?.type === "success" ? (
                        <Typography sx={{ color: "green" }}>
                          {verfiyOtpRes?.message}
                        </Typography>
                      ) : (
                        <Typography sx={{ color: "red" }}>
                          {verfiyOtpRes?.message}
                        </Typography>
                      )}
                    </Grid>
                    <Typography className={styles.haventRecievedOtp}>
                      Haven't received the OTP ?{" "}
                      <Link
                        className={styles.resendOtpText}
                        onClick={handleResendOtp}
                      >
                        Resend OTP
                      </Link>{" "}
                    </Typography>

                    <Typography className={styles.takeMeBack}>
                      Take me back to{" "}
                      <Link href="/" className={styles.loginText}>
                        Log in
                      </Link>
                    </Typography>
                  </Grid>

                  <Grid className={styles.termsBody}>
                    <Typography className={styles.termsLinks}>
                      <span className={styles.termsOfService}>
                        Terms of Service
                      </span>
                      <span className={styles.privacy}>Privacy Policy</span>
                    </Typography>
                    <Typography className={styles.details}>
                      For details of how we use personal data see our privacy
                      and data processing statement
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ForgotPassword;
