import React from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const CustomSearchSelect = ({
  label,
  options,
  field,
  error,
  errorCondition,
  autocompleteCss,
}: any) => {
  return (
    <Grid>
      <Autocomplete
        options={options}
        getOptionLabel={(option: any) => option.name}
        value={
          options?.find((option: any) => option?.id === field?.value) || null
        }
        onChange={(event, value) => field.onChange(value?.id || null)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={label}
            error={error}
          />
        )}
        className={autocompleteCss}
      />
      {error && errorCondition}
    </Grid>
  );
};

export default CustomSearchSelect;
