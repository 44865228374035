import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabsProps } from "src/CustomDatatypes/datatypes";
import styles from "src/Styles/verticalTab.module.css";
import { Grid } from "@mui/material";
import { taskTabValue } from "src/store/TaskSlice";
import { Link, useLocation } from "react-router-dom"; // Import Link for routing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useDispatch, useSelector } from "react-redux";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <>
      <Grid
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: "100%", height: "100%" }}
        className={className}
      >
        {value === index && <Grid>{children}</Grid>}
      </Grid>
    </>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function CustomTab({
  myTabData,
  orientation,
  component,
  selectedStatus,
  customBtnStyle,
  tabPanelStyle,
}: TabsProps) {
  const [value, setValue] = React.useState<number>(0);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const location = useLocation();

  React.useEffect(() => {
    const sidebarDataId = myTabData?.find(
      ({ href }: any) => href === location?.pathname
    );

    const activePageId = sidebarDataId
      ? sidebarDataId?.id
      : value > 0
      ? value
      : 0;

    setValue(activePageId);
    const values = extractLastValue(location);
    dispatch(taskTabValue({ value: 0, label: values }));
    // eslint-disable-next-line
  }, [location?.pathname]);
  const extractLastValue = (location: any) => {
    const parts = location.pathname.split("/");
    const lastPart = parts[parts.length - 1];
    const formattedString = lastPart.replace(/-/g, " ");
    const spacedString = formattedString.replace(/([A-Z])/g, " $1").trim();
    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
  };

  return (
    <Grid
      item
      md={12}
      sx={{
        display: orientation === "vertical" ? "flex" : "block",
      }}
      className={styles.customTabWrapper}
    >
      <Tabs
        orientation={orientation ? orientation : "horizontal"}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: orientation === "vertical" ? 1 : 0,
          borderColor: "divider",
          justifyContent: "space-between",
        }}
        className={customBtnStyle}
      >
        {myTabData.map((item: any) => {
          if (item?.href) {
            return (
              <Tab
                onClick={() =>
                  dispatch(
                    taskTabValue({
                      value: item?.id,
                      label: item?.label?.props.children?.props?.children,
                    })
                  )
                }
                key={item?.id}
                label={item?.label}
                component={Link}
                to={item.href}
                {...a11yProps(item?.id)}
                className={styles.verticalTab_main}
              />
            );
          } else {
            return (
              <Tab
                key={item?.id}
                label={item?.label}
                {...a11yProps(item?.id)}
                className={styles.verticalTab_main}
                // onClick={() => console.log("clicked tab")}
              />
            );
          }
        })}
        <Grid container>
          <Grid item md={11} />
          <Grid item md={1}></Grid>
        </Grid>
      </Tabs>

      {myTabData.map((item: any) => {
        return (
          <TabPanel
            value={value}
            index={item?.id}
            key={item?.id}
            className={tabPanelStyle}
          >
            {component ? (
              React.cloneElement(component, { selectedStatus: selectedStatus })
            ) : (
              <>{item?.component}</>
            )}
          </TabPanel>
        );
      })}
    </Grid>
  );
}
