import gql from "graphql-tag";

export const GETCONNECTION = gql`
  query Query {
    getConnections {
      _id
      hostname
      dbname
      active
      createdOn
      modifiedOn
      loggedInCount
      lastLoggedIn
    }
  }
`;
export const LOGIN_USER = gql`
  mutation LoginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      accessToken
      id
    }
  }
`;

//{
//   "Email":"elvin@yopmail.com",
//   "Password":"12345"
// }
export const CREATECONNECTION = gql`
  mutation Mutation(
    $dbname: String!
    $hostname: String!
    $clientType: ClientType
    $active: Boolean
  ) {
    createConnection(
      dbname: $dbname
      hostname: $hostname
      clientType: $clientType
      active: $active
    ) {
      hostname
      id
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation signupUser(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $displayName: String
  ) {
    signupUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      displayName: $displayName
    ) {
      firstName
    }
  }
`;

export const AUTH_RESPONSE = gql`
  mutation GoogleAuthentication($email: String) {
    googleAuthentication(email: $email) {
      accessToken
      id
      email
      firstName
      lastName
    }
  }
`;
