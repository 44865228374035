import React from 'react';
import { Dialog } from '@mui/material';

interface modalProps {
  open?: any;
  onClick?: any;
  onClose?: any;
  content?: any;
  className?: any;
  fullWidth?: any;
  maxWidth?: any;
  height?: any;
  width?: any;
  scroll?: any;
  fullScreen?: any;
}
function ModalBox({
  open,
  onClose,
  content,
  className,
  fullWidth,
  maxWidth,
  height,
  width,
  scroll,
  fullScreen,
}: modalProps) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={className}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: { height, width } }}
        scroll={scroll}
        fullScreen={fullScreen}
      >
        {content}
      </Dialog>
    </>
  );
}

export default ModalBox;
