import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line
import { PayloadAction } from "@reduxjs/toolkit";

export interface initialStateInterface {
  startTime: string | null;
  endTime: string | null;
}

let initialState: initialStateInterface;

if (typeof window !== "undefined") {
  initialState = {
    startTime: window.localStorage.getItem("startTime")
      ? window.localStorage.getItem("startTime")
      : null,
    endTime: window.localStorage.getItem("endTime")
      ? window.localStorage.getItem("endTime")
      : null,
  };
} else {
  initialState = {
    startTime: null,
    endTime: null,
  };
}

export const ClockInSlice = createSlice({
  name: "clockIn",
  initialState,
  reducers: {
    startTimeBtn: (state, action) => {
      state.startTime = action.payload;
    },
    endTimeBtn: (state, action) => {
      state.endTime = action.payload;
    },
  },
});

export const { startTimeBtn, endTimeBtn } = ClockInSlice.actions;
export default ClockInSlice.reducer;
