// import CalendarComponent from "src/components/Calendar";
import React from "react";
// import dynamic from "next/dynamic";
import CalendarComponent from "src/components/Calendar";

// const CalendarComponent = dynamic(
//   () => import("src/components/Calendar"),
//   {
//     ssr: false,
//   }
// );

const Calendar = () => {
  return (
    <>
      <CalendarComponent />
    </>
  );
};

export default Calendar;
