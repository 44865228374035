import * as React from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs"; // Import dayjs directly
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface Props {
  className?: string;
  label?: string;
  open?: boolean;
  onClose?: () => void;
  selectedDate?: Date | null;
  onChange?: (date: Date | null) => void;
  dateValue?: Dayjs | null;
  minDate?: Dayjs | undefined;
  maxDate?: Dayjs | undefined;
}

export default function BasicDatePicker({
  className,
  label,
  open,
  onClose,
  selectedDate,
  onChange,
  dateValue,
  minDate = undefined,
  maxDate = undefined,
}: Props) {
  const [value, setValue] = React.useState<Dayjs | null>(
    selectedDate ? dayjs(selectedDate) : null
  );

  const handleDateChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    if (onChange) {
      const dateFormat = dayjs(newValue, "DD/MM/YYYY");
      onChange(dateFormat ? dateFormat.toDate() : null);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={open}
          onClose={onClose}
          value={value}
          onChange={handleDateChange}
          sx={{ width: "100%" }}
          className={className}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </>
  );
}
