import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Styles from "src/Styles/EmployeDetailsModel.module.css";
import { Avatar, Typography, Divider } from "@mui/material";
import CustomTab from "src/components/CustomTab";
import FullScreenDialog from "src/components/SliderModal/index";
import { formatNoData } from "src/utils/formatNoData";
import avatar from "src/images/assets/avatar.png";
import styles from "src/Styles/Slider.module.css";
import moment from "moment";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import EmployeeChangePassword from "./EmployeeChangePassword";
import EmployeePersonalInformation from "./EmployeePersonalInformation";
import CloseIcon from "@mui/icons-material/Close";

const EmployeeDetailsModel = ({
  onClick,
  open,
  onClose,
  employeeDetails,
}: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  const [employee, setEmployee] = useState<any>(employeeDetails);
  const isActive = employee?.status === "ACTIVE";

  useEffect(() => {
    if (employeeDetails) {
      setEmployee(employeeDetails);
    }
  }, [employeeDetails]);

  const myTabData = [
    {
      id: 0,
      label: "Employee Details",
      component: <EmployeePersonalInformation employee={employee} />,
    },
    { id: 1, label: "Change Password", component: <EmployeeChangePassword /> },
  ];
  const employeeType = employee?.employeeType;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formattedEmployeeType = employeeType
    ? employeeType
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char: any) => char.toUpperCase())
    : null;
  return (
    <>
      <FullScreenDialog
        onClick={onClick}
        open={open}
        direction="left"
        onClose={onClose}
        className={styles.main_section}
        dialogClassName={styles.employeeDetailsDialog}
        PaperProps={{
          className: styles.profileMainWrapper,
        }}
        content={
          <Grid className={Styles.modalContent}>
            <Grid className={Styles.main_section}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item md={6} className={Styles.arrow}></Grid>
                <Grid item md={6} sm={8} xs={6} className={Styles.share}>
                  <CloseIcon className={Styles.closeIcon} onClick={onClose} />
                </Grid>
              </Grid>

              <Grid className={Styles.userProfileSection}>
                {/* left Container starts here*/}
                <Grid className={Styles.leftContainer}>
                  <Grid>
                    {employee?.profileImage?.url ? (
                      <img
                        className={Styles.profileImg}
                        alt="profile"
                        src={employee?.profileImage?.url || ""}
                      />
                    ) : (
                      <Avatar className={Styles.profileImg} alt="profile">
                        {employee?.firstName?.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                    {/* <Avatar
                      className={Styles.profileImg}
                      alt="avatar"
                      src={avatar}
                    ></Avatar> */}
                  </Grid>
                  <Grid>
                    <button
                      className={
                        isActive ? Styles.buttons : Styles.buttonsInactive
                      }
                    >
                      {employee?.status &&
                        formatNoData(
                          employee?.status.charAt(0).toUpperCase() +
                            employee?.status.slice(1).toLowerCase()
                        )}
                    </button>
                  </Grid>
                </Grid>
                {/* left Container ends here*/}
                {/* right Container starts here*/}
                <Grid className={Styles.rightContainer}>
                  <Grid className={Styles.rightTop} container>
                    <Grid lg={8} md={8} sm={8} xs={12}>
                      <Grid className={Styles.empId} gap={1}>
                        <Typography className={Styles.typographyHeading}>
                          Emp ID:
                        </Typography>
                        <Typography className={Styles.typographyData}>
                          {formatNoData(employee?.employeeCode)}
                        </Typography>
                      </Grid>
                      <Typography className={Styles.displayName}>
                        {" "}
                        {formatNoData(employee?.displayName)}
                      </Typography>
                      <Typography className={Styles.typographyHeading}>
                        {formatNoData(employee?.jobTitleName)}
                      </Typography>
                    </Grid>
                    <Grid
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      className={Styles.phoneContainer}
                    >
                      <Typography className={Styles.displayName}>
                        {formatNoData(employee?.phone)}
                      </Typography>
                      <Typography className={Styles.typographyHeading}>
                        {formatNoData(employee?.email)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={Styles.rightBottom}>
                    <Grid className={Styles.rightBottomDivsConatiner} container>
                      <Grid
                        className={Styles.rightBottomDivs}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Grid lg={6} md={5} sm={6} xs={6}>
                          <Typography className={Styles.typographyHeading}>
                            DOB
                          </Typography>
                        </Grid>
                        <Grid lg={10} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyData}>
                            {formatNoData(
                              employee?.dob
                                ? moment(employee?.dob).format("YYYY-MM-DD")
                                : null
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={Styles.rightBottomDivs}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Grid lg={6} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyHeading}>
                            Blood Group
                          </Typography>
                        </Grid>
                        <Grid lg={8} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyData}>
                            {formatNoData(employee?.bloodGroup)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={Styles.rightBottomDivs}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Grid lg={6} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyHeading}>
                            Department
                          </Typography>
                        </Grid>
                        <Grid lg={7} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyData}>
                            {formatNoData(employee?.departmentName)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={Styles.rightBottomDivs}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Grid lg={6} md={5} sm={6} xs={6}>
                          <Typography className={Styles.typographyHeading}>
                            Gender
                          </Typography>
                        </Grid>
                        <Grid lg={10} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyData}>
                            {formatNoData(
                              employee?.gender.charAt(0).toUpperCase() +
                                employee?.gender.slice(1).toLowerCase()
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={Styles.rightBottomDivs}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Grid lg={6} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyHeading}>
                            Joined Date
                          </Typography>
                        </Grid>
                        <Grid lg={8} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyData}>
                            {formatNoData(
                              employee?.joiningDate
                                ? moment(employee?.joiningDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : null
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={Styles.rightBottomDivs}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Grid lg={6} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyHeading}>
                            Reporting Manager
                          </Typography>
                        </Grid>
                        <Grid lg={7} md={6} sm={6} xs={6}>
                          <Typography className={Styles.typographyData}>
                            {formatNoData(employee?.reportingManagerName)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* right Container ends here*/}
              </Grid>

              <Grid container className={Styles.tab_containes}>
                <CustomTab myTabData={myTabData} />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default EmployeeDetailsModel;
