import gql from "graphql-tag";

export const CREATE_DOCUMENT_TYPE = gql`
  mutation CreateDocumentType($input: DocumentTypeInput!) {
    createDocumentType(input: $input) {
      id
      documentType
      description
      createdBy
      createdOn
      modifiedOn
    }
  }
`;

export const GET_DOCUMENT_TYPE = gql`
  query GetDocumentType($userId: ID) {
    getDocumentType(userId: $userId) {
      id
      documentType
      description
      createdBy
      createdOn
      modifiedOn
      createdByName
    }
  }
`;
export const GET_ALLDOCUMENT_TYPE = gql`
  query GetAllDocumentType($offset:Int,$limit:Int) {
    getAllDocumentType(offset:$offset,limit:$limit) {
      documentInfo{
      id
      documentType
      description
      createdBy
      createdOn
      modifiedOn
      createdByName
      }
      totalCount
    }
  }
`;

export const GET_DOCUMENT_ADD = gql`
  query GetDocumentAdd( $employeeID: ID,$offset:Int,$limit:Int) {
    getDocumentAdd( employeeID: $employeeID,offset:$offset,limit:$limit) {
      documentAddInfo{
      id
      documentType
      description
      documentName
      createdByEmployerUserId
      createdOn
      modifiedOn
      createdByName
      employeeID
      file
      key
      }
      totalCount
    }
  }
`;

export const UPDATE_DOCUMENT_TYPE = gql`
  mutation UpdateDocumentType(
    $updateDocumentTypeId: ID!
    $input: DocumentTypeInput
  ) {
    updateDocumentType(id: $updateDocumentTypeId, input: $input) {
      id
      documentType
      description
      createdBy
      createdOn
      modifiedOn
    }
  }
`;

export const CREATE_DOCUMENT_ADD = gql`
  mutation CreateDocumentAdd($input: DocumentAddInput!) {
    createDocumentAdd(input: $input) {
      id
      documentType
      description
      documentName
      createdByEmployerUserId
      createdOn
      modifiedOn
      createdByName
      employeeID
    }
  }
`;
