import React, { useState } from "react";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import ModalBox from "../../ReusableModal/modal";
import Styles from "src/Styles/AddQuickLinkModelBox.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomButtons from "../../CustomButton/Button";

const AddQuickLinkModalBox = ({
  openQuickLinkTaskModal,
  toggleQuickLinkTaskModal,
}: any) => {
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");

  const validateUrl = () => {
    if (url.trim() === "") {
      setUrlError("URL must include http/https");
      return false;
    }
    return true;
  };

  const handleSave = () => {
    const isUrlValid = validateUrl();

    if (isUrlValid) {
      // Perform save operation or API call
      toggleQuickLinkTaskModal();
    }
  };

  return (
    <>
      <ModalBox
        className={Styles.Main_Modal_Box}
        open={openQuickLinkTaskModal}
        onClose={toggleQuickLinkTaskModal}
        content={
          <Grid container className={Styles.Modal_Content}>
            <Grid container item className={Styles.ModalBox}>
              <Grid item md={11}>
                <Typography className={Styles.Quick_Link}>
                  Add Quick Link
                </Typography>
              </Grid>
              <Grid item md={1}>
                <IconButton
                  className={Styles.Close}
                  sx={{ fontSize: 30 }}
                  onClick={toggleQuickLinkTaskModal}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container className={Styles.Body_Grid}>
              <Grid container>
                <Grid item md={12}>
                  <TextField
                    size="medium"
                    label="Name*"
                    variant="outlined"
                    className={Styles.Title_Input}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.Url_Input}>
                  <TextField
                    label="URL*"
                    variant="outlined"
                    size="medium"
                    className={Styles.Title_Input}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    error={!!urlError}
                    helperText={urlError}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={Styles.Save_Grid}>
                  <CustomButtons
                    variant="contained"
                    name="Save"
                    onClick={handleSave}
                    className={Styles.save_Btn}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default AddQuickLinkModalBox;
