import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Calendar from "src/pages/hrms/calendar";
import Dashboard from "src/pages/hrms/dashboard";
import TasksComponent from "src/components/Tasks";
import HrDocs from "src/components/HrDocuments";
import InformationTab from "src/components/InformationTab";
import TimeOffHorizontalTab from "src/components/SideBarTabTimeOff";
import SettingsHorizontalTab from "src/components/Setting";
import Leave from "src/components/EmployeeSideLeaves/Leave";
import Regularisation from "src/components/EmployeeSideLeaves/Regulization";
import WorkFromHome from "src/components/EmployeeSideLeaves/WorkFromHome";
import Leave1 from "src/components/LeaveTypes/Leave";
import AdminCalendar from "src/components/AdminCalendar";
import DocumentsOrg from "src/components/Documents";
import AdminWorkFromHome from "src/components/AdminWorkFromHome";
import AdminRegularisation from "src/components/AdminRegularisation";
import NotFound from "src/pages/404";
import CreateEmployee from "src/components/AddEmployee/index";
import Employees from "src/components/ManageEmployees";
import Directory from "src/pages/hrms/Directory";
import EditEmployee from "src/components/AddEmployee/EditEmployee";
import ViewDocuments from "src/components/ViewDocuments";
import { RootState } from "src/store/store";
import { useAppSelector } from "src/store/hooks";
import OrganizationChart from "src/components/OrganizationChart";

function TabRoutes(selectedStatus: any,) {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );
  const accessRuleTypesArray = userInfo?.accessRules?.map((rule: { type: any; }) => rule.type);

  // Assuming userAccessRules contains the user's access rules
  const userAccessRules: string | string[] = accessRuleTypesArray;

  return (
    <>
      <Routes>
        {/* Employee Routes */}
        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/hrms/calendar" element={<Calendar />} />
        <Route path="/hrms/tasks/leave" element={<TasksComponent children={<Leave selectedStatus={selectedStatus} />} />} />
        <Route path="/hrms/tasks/regularization" element={<TasksComponent children={<Regularisation selectedStatus={selectedStatus} />} />} />
        <Route path="/hrms/tasks/work-from-home" element={<TasksComponent children={<WorkFromHome selectedStatus={selectedStatus} />} />} />
        <Route path="/hrms/documents" element={<HrDocs />} />

        {/* Admin Routes */}
        {userAccessRules?.includes('employees') && (
          <>
            <Route path="/admin/hrms/employees/manage-employees" element={<InformationTab children={<Employees condition={false} />} />} />
            <Route path="/admin/hrms/employee/create" element={<InformationTab children={<CreateEmployee />} />} />
            <Route path="/admin/hrms/employee/edit/:id" element={<InformationTab children={<EditEmployee />} />} />
            <Route path="/admin/hrms/employees/directory" element={<InformationTab children={<Directory />} />} />
            <Route path="/admin/hrms/employees/org" element={<InformationTab children={<OrganizationChart />} />} />
          </>
        )}
        {userAccessRules?.includes('calendar') && (
          <Route path="/admin/hrms/admin-calendar" element={<AdminCalendar />} />
        )}
        {userAccessRules?.includes('timeOff') && (
          <>
            <Route path="/admin/hrms/timeOff/leave" element={<TimeOffHorizontalTab children={<Leave1 selectedStatus={selectedStatus} />} />} />
            <Route path="/admin/hrms/timeOff/regularization" element={<TimeOffHorizontalTab children={<AdminRegularisation selectedStatus={selectedStatus} />} />} />
            <Route path="/admin/hrms/timeOff/work-from-home" element={<TimeOffHorizontalTab children={<AdminWorkFromHome selectedStatus={selectedStatus} />} />} />
          </>
        )}
        {userAccessRules?.includes('documents') && (
          <>
            <Route path="/admin/hrms/admin-documents" element={<DocumentsOrg />} />
            <Route path="/admin/hrms/admin-documents/view-documents/:id" element={<ViewDocuments />} />
          </>
        )}
        {userAccessRules?.includes('settings') && (
          <Route path="/admin/hrms/settings" element={<SettingsHorizontalTab />} />
        )}

        {/* 404 Route */}

      </Routes>
    </>
  );
}

export default TabRoutes;


