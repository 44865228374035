import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";

function SubCircularLoader({ size, className }: any) {
  return (
    <Grid className={className}>
      <CircularProgress size={size} />
    </Grid>
  );
}
export default SubCircularLoader;
