import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "src/Styles/HrDocsSliders.module.css";
import Styles from "src/Styles/EducationalDetails.module.css";
import CustomTable from "src/components/TableComponent/CustomTable";
import MenuItems from "src/components/MenuItems/MenuItems";
import { useQuery, useMutation } from "@apollo/client";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/hooks";
import AdminRegularisationDetailsModel from "./AdminRegularisationDetails";
import moment from "moment";
import {
  GET_REGULARISATION_BY_ID,
  BULK_APPROVE_REJECT_REGULARIZATION,
  DELETE_REGULARIZE,
} from "src/graphql/regularization";
import CustomButtons from "../CustomButton/Button";
import regularizeStyles from "src/Styles/TimeOff.module.css";
import { Toast } from "../ToastMessage";

const AdminRegularisation = ({ selectedStatus }: any) => {
  const { userInfo }: any = useAppSelector(
    (state: RootState) => state.userSlice
  );

  const [selectedRequestStatus, setSelectedRequestStatus] =
    useState<string>(selectedStatus);
  const [loaderForBulkApprove, setLoaderForBulkApprove] =
    useState<boolean>(false);
  const [loaderForBulkReject, setLoaderForBulkReject] =
    useState<boolean>(false);
  const [offset, setOffset] = useState(1)
  const [limit, setLimit] = useState(10)

  const { data, loading, error, refetch } = useQuery(GET_REGULARISATION_BY_ID, {
    variables: {
      approverId: userInfo.id,
      filterStatus: selectedRequestStatus,
      offset,
      limit
    },
  });
  useEffect(() => {
    refetch()
  }, [offset, limit, refetch])
  const [bulkApproveRejectRegularization] = useMutation(
    BULK_APPROVE_REJECT_REGULARIZATION
  );

  const [deleteRegularizeRequest] = useMutation(DELETE_REGULARIZE);

  const [mopen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [allChekboxClick, setAllChekboxClick] = useState<boolean>(false);
  const [bulkAprroveRejectIds, setBulkAprroveRejectIds] = useState<any>([]);

  useEffect(() => {
    setSelectedRequestStatus(selectedStatus);
    if (selectedStatus !== "PENDING") {
      setAllChekboxClick(false);
      setBulkAprroveRejectIds([]);
    }
  }, [selectedStatus]);

  const handleClickOpen = () => { };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDetails = async (item: any, event: any) => {
    if (event.target.textContent === "View") {
      setSelectedItem(item);
      setOpen(true);
    } else if (event.target.textContent === "Delete") {
      try {
        const response = await deleteRegularizeRequest({
          variables: {
            deleteRegularizeRequestId: item.id,
            deletedBy: "RM",
          },
        });
        if (response.data) {
          Toast("success", "Regularization cancelled Successfully");
          refetch();
        }
      } catch (error: any) {
        Toast("error", error?.message);
      }
    }
  };

  const handleBulkApproveRejectClick = (values: any) => {
    if (values.length && selectedRequestStatus === "PENDING") {
      setAllChekboxClick(true);
      setBulkAprroveRejectIds(values);
    } else {
      setAllChekboxClick(false);
      setBulkAprroveRejectIds([]);
    }
  };

  const handleBulkApprove = async () => {
    try {
      setLoaderForBulkApprove(true);

      let currentDate = moment();

      const allIds = bulkAprroveRejectIds.map((id: string) => ({
        id: id,
        input: {
          regularizeStatus: "APPROVED",
          approvedDate: currentDate.format("YYYY-MM-DD"),
        },
      }));

      const response = await bulkApproveRejectRegularization({
        variables: {
          updates: allIds,
        },
      });

      if (response.data) {
        Toast("success", "Regularization approved Successfully");
        setAllChekboxClick(false);
        setBulkAprroveRejectIds([]);
        refetch();
        setLoaderForBulkApprove(false);
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  const handleBulkReject = async () => {
    try {
      setLoaderForBulkReject(true);

      let currentDate = moment();
      const allIds = bulkAprroveRejectIds.map((id: string) => ({
        id: id,
        input: {
          regularizeStatus: "REJECTED",
          approvedDate: currentDate.format("YYYY-MM-DD"),
        },
      }));

      const response = await bulkApproveRejectRegularization({
        variables: {
          updates: allIds,
        },
      });

      if (response.data) {
        Toast("success", "Regularization rejected Successfully");
        setAllChekboxClick(false);
        setBulkAprroveRejectIds([]);
        refetch();
        setLoaderForBulkReject(false);
      }
    } catch (error: any) {
      Toast("error", error?.message);
    }
  };

  if (loading) {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "flex";
    }
  } else {
    const mainLoader = document.getElementById("mainloader");
    if (mainLoader) {
      mainLoader.style.display = "none";
    }
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid item container md={12} className={Styles.table}>
        {allChekboxClick && (
          <Grid className={regularizeStyles.bulkButtoncontainer}>
            <CustomButtons
              name="Bulk Approve"
              className={regularizeStyles.bulkApproveButton}
              variant="outlined"
              onClick={handleBulkApprove}
              loading={loaderForBulkApprove}
              disabled={loaderForBulkApprove}
              loaderClass={regularizeStyles.buttonLoader}
            />
            <CustomButtons
              name="Bulk Reject"
              className={regularizeStyles.bulkRejectButton}
              variant="outlined"
              onClick={handleBulkReject}
              loading={loaderForBulkReject}
              disabled={loaderForBulkReject}
              loaderClass={regularizeStyles.buttonLoader}
            />
          </Grid>
        )}
        <CustomTable
          data={data?.getRegularizeListById?.regularisationInfo || []}
          hidePagination={false}
          tableCellFirstColStyle={Styles.tableCellFirstColStyles}
          tableHeader={Styles.tableHeader}
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          count={data?.getRegularizeListById?.totalCount || 0}
          enableCheckBox={
            data?.getRegularizeListById?.regularisationInfo?.length
              ? selectedRequestStatus === "PENDING"
              : false
          }
          handleBulkApproveRejectClick={handleBulkApproveRejectClick}
          checkboxClassName={styles.checkboxCss}
          columns={[
            {
              Header: <Grid className={Styles.tableHead}>User Name</Grid>,
              accessor: "reason",
              Cell: ({ item, index }: any) => {
                return <Grid className={Styles.cell}>{item.username}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Approver</Grid>,
              accessor: "acceptedBy",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid className={Styles.cell}>{item.approver.name}</Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}> Request Type </Grid>,
              accessor: "requestType",
              Cell: ({ item, index }: any) => {
                return <Grid className={Styles.cell}>{item.requestType}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Applied Date</Grid>,
              accessor: "appliedFrom",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(
                  item.requestedDates[0].requestDate
                ).format("DD-MM-YYYY");
                return <Grid className={Styles.cell}>{formattedDate}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Total Hours</Grid>,
              accessor: "appliedFrom",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid className={Styles.cell}>
                    {item.requestedDates[0].totalHours}
                  </Grid>
                );
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Raised On</Grid>,
              accessor: "appliedTo",
              Cell: ({ item, index }: any) => {
                const formattedDate = moment(item.createdOn).format(
                  "DD-MM-YYYY"
                );
                return <Grid className={Styles.cell}>{formattedDate}</Grid>;
              },
            },
            {
              Header: <Grid className={Styles.tableHead}>Status</Grid>,
              accessor: "status",
              Cell: ({ item, index }: any) => {
                return (
                  <Grid
                    className={Styles.cell}
                    sx={{
                      color:
                        item.regularizeStatus === "REJECTED"
                          ? "red"
                          : item.regularizeStatus === "DELETED"
                            ? "darkred"
                            : item.regularizeStatus === "APPROVED"
                              ? "green"
                              : "orange",
                      borderRadius: "15px",
                      fontSize: "15px",
                    }}
                  >
                    {item.regularizeStatus}
                  </Grid>
                );
              },
            },
            {
              Header: "",
              Cell: ({ item }: any) => (
                <Grid item className={styles.moreVertIcon}>
                  <MenuItems
                    options={
                      item.regularizeStatus === "APPROVED"
                        ? ["View", "Delete"]
                        : ["View"]
                    }
                    onViewDetails={(e: any) => handleViewDetails(item, e)}
                  />
                </Grid>
              ),
            },
          ]}
        />
        {mopen && (
          <AdminRegularisationDetailsModel
            onClick={handleClickOpen}
            open={mopen}
            onClose={handleClose}
            selectedItem={selectedItem}
            setOpen={setOpen}
            refetch={refetch}
          />
        )}
      </Grid>
    </>
  );
};

export default AdminRegularisation;
