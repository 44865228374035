import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "src/Styles/Search.module.css";

function SearchBox({
  data,
  handleChange,
  handleClose,
  submitSearch,
  query,
  size,
  setQuery,
  customStyle,
  handleClick,
  borderRadius,
  InputProps,
  placeholder,
}: any) {
  return (
    <>
      <Autocomplete
        freeSolo
        id="combo-box-demo"
        options={data}
        className={customStyle ? customStyle : styles.autocomplete}
        renderInput={(params) => (
          <form onSubmit={submitSearch}>
            <TextField
              {...params}
              id="outlined-search"
              type="search"
              placeholder={placeholder}
              value={query}
              size={size}
              onChange={handleChange}
              InputProps={{
                style: {
                  borderRadius: borderRadius ? borderRadius : undefined,
                },
              }}
            />
          </form>
        )}
        onClose={handleClose}
        //done changes--------
      />
    </>
  );
}

export default SearchBox;
