import gql from "graphql-tag";

export const GET_REGULARISATION_BY_ID = gql`
  query GetRegularizeListById(
    $filterStatus: String
    $employeeId: ID
    $approverId: ID
    $offset:Int
    $limit:Int
  ) {
    getRegularizeListById(
      filterStatus: $filterStatus
      employeeId: $employeeId
      approverId: $approverId
      offset:$offset
      limit:$limit
    ) {
      regularisationInfo{
      id
      employeeId
      designation
      employeeCode
      requestedDates {
        requestDate
        regularizeFromTime
        regularizeToTime
        regularizeReason
        totalHours
      }
      regularizeStatus
      active
      reportingManagerId
      createdOn
      approver {
        name
        employeeCode
        profileImg
      }
      username
      requestType
      approvedDate
         
      }
      totalCount
    }
  }
`;

export const CREATE_REGULARISATION = gql`
  mutation Mutation($input: RegularizeRequestInput!) {
    createReguralizeRequest(input: $input) {
      id
      employeeId
      reportingManagerId
      requestedDates {
        requestDate
        regularizeFromTime
        regularizeToTime
        regularizeReason
        totalHours
      }
      regularizeStatus
      active
      createdOn
      approver {
        name
        employeeCode
        profileImg
      }
      approvedDate
      requestType
      username
    }
  }
`;

export const UPDATE_REGULARISATION = gql`
  mutation UpdateRegularizeRequest(
    $updateRegularizeRequestId: ID!
    $input: RegularizeRequestUpdateInput!
  ) {
    updateRegularizeRequest(id: $updateRegularizeRequestId, input: $input) {
      id
      employeeId
      reportingManagerId
      requestedDates {
        requestDate
        regularizeFromTime
        regularizeToTime
        regularizeReason
        totalHours
      }
      regularizeStatus
      active
      createdOn
      approver {
        name
        employeeCode
        profileImg
      }
      username
      requestType
      approvedDate
    }
  }
`;

export const BULK_APPROVE_REJECT_REGULARIZATION = gql`
  mutation BulkApproveRejectRegularization(
    $updates: [BulkApproveRejectRegularizeInput!]!
  ) {
    bulkApproveRejectRegularization(updates: $updates) {
      active
      approvedDate
      id
      regularizeStatus
      createdOn
    }
  }
`;

export const DELETE_REGULARIZE = gql`
  mutation DeleteRegularizeRequest(
    $deleteRegularizeRequestId: ID!
    $deletedBy: String!
  ) {
    deleteRegularizeRequest(
      id: $deleteRegularizeRequestId
      deletedBy: $deletedBy
    ) {
      id
    }
  }
`;
