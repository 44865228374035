import gql from "graphql-tag";

export const GET_JOBTITLE = gql`
  query GetJobTitles($offset:Int,$limit:Int) {
    getJobTitles(offset:$offset,limit:$limit) {
      jobTitleInfo{
      id
      isDeleted
      jobTitle
      status
      employeeCount
      }
      totalCount
    }
  }
`;

export const CREATE_JOBTITLE = gql`
  mutation CreateJobTitle($input: CreateJobTitleInput!) {
    createJobTitle(input: $input) {
      id
      isDeleted
      jobTitle
      status
    }
  }
`;

export const DELETE_JOBTITLE = gql`
  mutation DeleteJobTitle($workplaceId: ID!) {
    deleteJobTitle(workplaceId: $workplaceId) {
      id
    }
  }
`;

export const UPDATE_JOBTITLE = gql`
  mutation UpdateJobTitle($workplaceId: ID!, $input: CreateJobTitleInput!) {
    updateJobTitle(workplaceId: $workplaceId, input: $input) {
      id
      isDeleted
      jobTitle
      status
    }
  }
`;
