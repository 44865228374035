import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';

import { CustomTableProps, TableColumnTypes } from 'src/CustomDatatypes/datatypes';
import CheckBoxes from 'src/components/CustomCheckbox/Checkbox';
import { CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import style from 'src/Styles/table.module.css';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

function getProperty(propertyName: string, object: any) {
  if (!propertyName) return;
  var parts = propertyName.split('.'),
    length = parts.length,
    i,
    property = object;

  for (i = 0; i < length; i++) {
    property = property && property[parts[i]];
  }

  return property;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (!orderBy) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: any, b: any) => number {
  if (!orderBy) {
    return (a, b) => 1;
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// eslint-disable-next-line
function stableSort<T>(
  array: TableColumnTypes[],
  comparator: (a: TableColumnTypes, b: TableColumnTypes) => number,
  draggable: boolean
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [TableColumnTypes, number]);

  // sorting shouldn't work for dragable table
  if (!draggable) {
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string | any;
  rowCount: number;
  columns: any;
  enableCheckBox?: boolean;
  draggable?: boolean;
  tableHeader?: any;
  stickyHeader?: boolean;
  checkboxClassName?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    enableCheckBox,
    tableHeader,
    checkboxClassName,
    draggable, // sorting shouldn't work for dragable table
  } = props;
  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {draggable && <TableCell padding="checkbox"></TableCell>} */}
        {enableCheckBox && (
          <TableCell padding="checkbox" className={checkboxClassName}>
            <CheckBoxes
              checkboxProps={{
                indeterminate: numSelected > 0 && numSelected < rowCount,
              }}
              defaultChecked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns?.map((headCell: any, index: number) => {
          const { Header, headerClassName, tooltipTitle, accessor } = headCell;
          let headerValue = Header;
          if (Header && typeof Header === 'function') {
            headerValue = Header(headCell);
          }
          return (
            <TableCell
              key={index}
              sortDirection={orderBy && orderBy === columns[accessor] ? order : false}
              // className={tableHeader}
              className={`${tableHeader} `}
              style={{ position: 'relative' }}
            >
              {tooltipTitle ? (
                <Tooltip title={tooltipTitle}>
                  {accessor && !draggable ? (
                    <TableSortLabel
                      active={orderBy && orderBy === columns[accessor]}
                      direction={orderBy && orderBy === columns[accessor] ? order : 'asc'}
                      onClick={createSortHandler(accessor)}
                      className={headerClassName}
                    >
                      {headerValue}
                      {orderBy && orderBy === columns[accessor] ? (
                        <Grid component="span">{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Grid>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <Grid className={headerClassName}>{headerValue}</Grid>
                  )}
                </Tooltip>
              ) : (
                <>
                  {!draggable && accessor ? (
                    <TableSortLabel
                      active={orderBy && orderBy === columns[accessor]}
                      direction={orderBy && orderBy === columns[accessor] ? order : 'asc'}
                      onClick={createSortHandler(accessor)}
                      className={headerClassName}
                    >
                      {headerValue}
                      {orderBy && orderBy === columns[accessor] ? (
                        <Grid component="span">{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Grid>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <Grid className={headerClassName}>{headerValue}</Grid>
                  )}
                </>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/**
 *
 * @param CustomTable
 *
 * @returns Multi purpose Table component
 *
 * columns >>           should contain column structure of the table & header name
 * data >>              data which need to be shown
 * enableCheckBox >>    enables table with checkboxes
 * loading >>           loader for table
 * tableTitle >>        title for table
 * onReorder >>         updates table order on drag
 * draggable >>         to enable drag & drop table row function
 * hidePagination >>    for hiding pagination footer, table rows will be as long as data
 *
 */
export default function CustomTable({
  columns,
  data,
  enableCheckBox,
  loading,
  tableTitle,
  onReorder,
  draggable = false,
  hidePagination = true,
  tableCellFirstColStyle,
  tableBorder = {},
  tableHeader,
  tableContainerClass,
  checkboxClassName,
  stickyHeader,
  className,
  handleBulkApproveRejectClick,
}: CustomTableProps) {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState<any>();
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  // const [query, setQuery] = React.useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(hidePagination ? data?.length + 1 : 10);

  const [tableRows, setTableRows] = React.useState(data);

  const [draggedIndex, setDraggedIndex] = React.useState(-1);

  const handleDragStart = (index: any) => {
    setDraggedIndex(index);
  };
  React.useEffect(() => {
    setTableRows(data);
    setSelected([]);
  }, [data]);

  const handleDragOver = (index: any) => {
    if (draggedIndex === -1 || index === draggedIndex) {
      //   debugger;
      return;
    }
    // debugger;
    const items = Array.from(tableRows);
    const [draggedItem] = items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);

    setTableRows(items);
    onReorder && onReorder(items);
    setDraggedIndex(index);
  };
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tableRows.map((n: any) => n.id);
      setSelected(newSelected);
      if (handleBulkApproveRejectClick) {
        handleBulkApproveRejectClick(newSelected);
      }
      return;
    }
    if (handleBulkApproveRejectClick) {
      handleBulkApproveRejectClick([]);
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (!handleBulkApproveRejectClick) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    }
  };

  const handleCheckboxClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    if (handleBulkApproveRejectClick) {
      handleBulkApproveRejectClick(newSelected);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty data.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;

  React.useEffect(() => {
    const isLengthMismatch = tableRows?.length !== data?.length;

    if (isLengthMismatch) {
      setTableRows(data);
    }
  }, [data, tableRows?.length]);

  // useeffect added to update rowsPerPage if hidePagination is true update with tableRows.length(total data)
  React.useEffect(() => {
    setRowsPerPage(hidePagination ? tableRows?.length + 1 : 10);
  }, [hidePagination, tableRows?.length]);

  if (loading) {
    return (
      <Paper className={style.table_paper__loading}>
        <CircularProgress size={50} />
      </Paper>
    );
  } else
    return (
      <Paper
        className={`${tableContainerClass} ${style.table__paper}`}
        elevation={0}
        sx={{ width: '100%', overflow: 'hidden' }}
      >
        {tableTitle && (
          <Typography variant="h5" className={style.table__title}>
            Users
          </Typography>
        )}

        <TableContainer className={style.border}>
          <Table
            // sx={tableBorder}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRows?.length}
              columns={columns}
              enableCheckBox={enableCheckBox}
              draggable={draggable}
              tableHeader={tableHeader}
              checkboxClassName={checkboxClassName}
            // totalCount={totalCount}
            />
            {data.length > 0 ? (
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              data.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(
                  tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  getComparator(order, orderBy),
                  draggable
                )?.map((item: any, index: number) => {
                  const isItemSelected = isSelected(item.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let rowIndex = index;
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, item.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={item.id}
                        selected={isItemSelected}
                        style={{ position: 'relative' }}
                      >
                        {draggable && (
                          <TableCell
                            padding="checkbox"
                            draggable={draggable}
                            onDragStart={() => handleDragStart(rowIndex)}
                            onDragOver={() => handleDragOver(rowIndex)}
                            className={style.table__move_row}
                          >
                            <DragIndicatorIcon />
                          </TableCell>
                        )}
                        {enableCheckBox && (
                          <TableCell padding="checkbox">
                            <CheckBoxes
                              value={isItemSelected}
                              onChange={(event: any) => handleCheckboxClick(event, item.id)}
                              checkboxProps={{
                                inputProps: {
                                  'aria-labelledby': labelId,
                                },
                              }}
                            />
                          </TableCell>
                        )}
                        {columns.map((column, index) => {
                          let { accessor, Cell, className } = column;

                          let cellValue = getProperty(String(accessor), item);

                          if (Cell && typeof Cell === 'function') {
                            cellValue = Cell({ item, index: rowIndex });
                          }

                          return (
                            <React.Fragment key={index}>
                              <TableCell className={className} key={index}>
                                {cellValue}
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} className={style.errorText}>
                    <Typography className={style.errorTexttypo}>Data not found</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {!hidePagination && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={tableRows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    );
}
