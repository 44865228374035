import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import Styles from "src/Styles/EmployeDetailsModel.module.css";
import { formatNoData } from "src/utils/formatNoData";
import moment from "moment";

const EmployeePersonalInformation = ({ employee }: any) => {
  return (
    <Grid className={Styles.infromation}>
      <Grid container className={Styles.infromation_div}>
        <Grid xs={12} md={12} sm={42} lg={12}>
          <Typography className={Styles.sectionHeader}>
            Personal Details
          </Typography>
        </Grid>
      </Grid>
      <Divider className={Styles.infromation_div1} />
      <Grid container className={Styles.infromation_div}>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>First Name</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.firstName)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Last Name</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.lastName)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>
            Languages known
          </Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.languagesKnown)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Nationality</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.permanentAddress.country)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Mother Name</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.motherName)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Father Name</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.fatherName)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>
            Marriage Date
          </Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(
              employee?.dateOfMarriage
                ? moment(employee?.dateOfMarriage).format("YYYY-MM-DD")
                : null
            )}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>
            Marital Status
          </Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.maritalStatus)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>City</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.permanentAddress.city)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Zipcode</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.permanentAddress.zipCode)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>State</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.address.state)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Country</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.permanentAddress.country)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sm={4}
          lg={3}
          className={Styles.infromation_content}
        >
          <Typography className={Styles.employeeFields}>Address</Typography>
          <Typography className={Styles.personalInfoData}>
            {formatNoData(employee?.permanentAddress.addressDetails)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmployeePersonalInformation;
