import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import styles from 'src/Styles/sidebar.module.css';
import arokeeLogo from '../../images/assets/arokeelogo.jpeg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, ListItemIcon, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TabRoutes from 'src/common/TabsLanding/tabRoutes';
import { useAppSelector } from 'src/store/hooks';
import type { RootState } from 'src/store/store';
import { GET_USER_BY_ID } from 'src/graphql/user';
import { useQuery } from '@apollo/client';
import { Logout } from '@mui/icons-material';
import EmployeeDetailsModel from 'src/components/EmployeeDetailsModel';

const drawerWidth = 280;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
}
// Function to set sidebar index based on the current URL
function setSidebarIndex() {
  const currentPath = window.location.pathname;
  const sidebarItems = [...employeeSideBarData, ...adminSideBarData];
  const matchedItem = sidebarItems.find(item => item?.href === currentPath);
  if (matchedItem) {
    localStorage.setItem('sidebarIndex', matchedItem.tabName);
  }
}

// Call the function when the page loads
window.onload = setSidebarIndex;
const validRoutes = [
  '/',
  '/hrms/calendar',
  '/hrms/tasks/leave',
  '/hrms/tasks/regularization',
  '/hrms/tasks/work-from-home',
  '/hrms/documents',
  '/admin/hrms/employees/manage-employees',
  '/admin/hrms/employee/create',
  '/admin/hrms/employee/edit/:id',
  '/admin/hrms/employees/directory',
  '/admin/hrms/employees/org',
  '/admin/hrms/admin-calendar',
  '/admin/hrms/timeOff/leave',
  '/admin/hrms/timeOff/regularization',
  '/admin/hrms/timeOff/work-from-home',
  '/admin/hrms/admin-documents',
  '/admin/hrms/admin-documents/view-documents/:id',
  '/admin/hrms/settings'
];
const isValidRoutes = (pathname: string) => {
  // Check if pathname matches any route in validRoutes
  if (validRoutes.includes(pathname)) {
    return true;
  }
  // Skip checking for routes with dynamic parameters
  if (pathname.includes('/edit/') || pathname.includes('/view-documents/')) {
    return true;
  }


  return false;
};
const employeeSideBarData = [
  {
    id: 0,
    tabName: 'employeeDashboard',
    isFilter: false,
    label: (
      <Stack direction="row" spacing={2} alignSelf="start">
        <DashboardIcon />
        <Typography className={styles.tabs_typography}>Dashboard</Typography>
      </Stack>
    ),
    href: '/'
  },
  {
    id: 1,
    tabName: 'employeeCalendar',
    isFilter: false,
    label: (
      <Stack direction="row" spacing={2} alignSelf="start">
        <CalendarMonthIcon />
        <Typography className={styles.tabs_typography}>Calendar</Typography>
      </Stack>
    ),
    href: '/hrms/calendar',
  },
  {
    id: 2,
    tabName: 'employeeTasks',
    isFilter: false,
    label: (
      <Stack direction="row" spacing={2} alignSelf="start">
        <AssignmentIcon />
        <Typography className={styles.tabs_typography}>Tasks</Typography>
      </Stack>
    ),
    href: '/hrms/tasks/leave',
  },
  {
    id: 3,
    tabName: 'employeeDocuments',
    isFilter: false,
    label: (
      <Stack direction="row" spacing={2} alignSelf="start">
        <ContentCopyIcon />
        <Typography className={styles.tabs_typography}>Documents</Typography>
      </Stack>
    ),
    href: '/hrms/documents',
  },
];
const adminDefaultSideBarData = [
  {
    id: 4,
    tabName: 'organisation',
    isFilter: true,
    label: (
      <>
        <Grid className={styles.organization}>
          <Typography className={styles.organizationTypography}>ORGANIZATION</Typography>
        </Grid>
      </>
    ),
    noNavigation: true,
  },
];
const adminSideBarData = [
  {
    id: 5,
    tabName: 'employees',
    isFilter: true,
    label: (
      <Stack direction="row" alignSelf="start" spacing={2}>
        <GroupAddIcon />
        <Typography className={styles.tabs_typography}>Employees</Typography>
      </Stack>
    ),
    href: '/admin/hrms/employees/manage-employees',
  },
  {
    id: 6,
    tabName: 'calendar',
    isFilter: true,
    label: (
      <Stack direction="row" alignSelf="start" spacing={2}>
        <CalendarMonthIcon />
        <Typography className={styles.tabs_typography}>Calender</Typography>
      </Stack>
    ),
    href: '/admin/hrms/admin-calendar',
  },
  {
    id: 7,
    tabName: 'timeOff',
    isFilter: true,
    label: (
      <Stack direction="row" alignSelf="start" spacing={2}>
        <AssignmentIcon />
        <Typography className={styles.tabs_typography}>Time off</Typography>
      </Stack>
    ),
    href: '/admin/hrms/timeOff/leave',
  },

  {
    id: 8,
    tabName: 'documents',
    isFilter: true,
    label: (
      <Stack direction="row" alignSelf="start" spacing={2}>
        <ContentCopyIcon />
        <Typography className={styles.tabs_typography}>Documents</Typography>
      </Stack>
    ),
    href: '/admin/hrms/admin-documents',
  },
  {
    id: 9,
    tabName: 'settings',
    isFilter: true,
    label: (
      <Stack direction="row" alignSelf="start" spacing={2}>
        <SettingsIcon />
        <Typography className={styles.tabs_typography}>Settings</Typography>
      </Stack>
    ),
    href: '/admin/hrms/settings',
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
  className?: any;
  name?: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, name, ...other } = props;
  const activeSidebar = localStorage.getItem("sidebarIndex")

  return (
    <Grid
      role="tabpanel"
      hidden={name !== activeSidebar}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%', position: 'relative' }}
      className={styles.rootTabContainer}
    >
      {name === activeSidebar && (
        <Grid sx={{ p: 3, width: '100%' }} className={styles.rootContainer}>
          <TabRoutes />
        </Grid>
      )}
    </Grid>
  );
}
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const TabsLanding = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo }: any = useAppSelector((state: RootState) => state.userSlice);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [value, setValue] = React.useState("employeeDashboard");
  const [isValidRoute, setIsValidRoute] = useState(true);

  useEffect(() => {
    setIsValidRoute(isValidRoutes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (!isValidRoute) {
      navigate("/404")
    }
  }, [isValidRoute, navigate]);
  const { data } = useQuery(GET_USER_BY_ID, {
    variables: { userId: userInfo.id },
  });

  let userData = data?.getUserById;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUser = () => {
    setIsModalOpen(true);
  };

  const handleUserClose = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem("sidebarIndex")
    if (props.window && props.window()) {
      props.window().location.assign('/login');
    }
  };

  const filteredAdminSidebarData = adminSideBarData.filter((item) => {
    return data?.getUserById?.accessRules?.some((rule: any) => rule?.type === item?.tabName);
  });

  const filteredAdminDefaultSideBarData = adminDefaultSideBarData.filter((item) => {
    return data?.getUserById.accessRules.some((rule: any) => rule.active === true);
  });

  const sidebarData = [...employeeSideBarData, ...filteredAdminDefaultSideBarData, ...filteredAdminSidebarData];
  const activeTabName = localStorage.getItem("sidebarIndex");
  const activeTabIndex = sidebarData.findIndex(item => item.tabName === activeTabName);
  React.useEffect(() => {
    if (activeTabName !== null) {
      setValue(activeTabName);
    }
  }, [activeTabName]);

  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleTabClick = (newValue: any) => {
    localStorage.setItem("sidebarIndex", newValue)
    setMobileOpen(false);
  };

  const drawer = (
    <div className={styles.drawerContainer}>
      <Grid container className={styles.sidebarTop} onClick={() => navigate('/')}>
        <Stack className={styles.avatar1Container}>
          <img src={arokeeLogo} alt="mainAvatar" className={styles.avatar1} />
        </Stack>
        <Stack>
          <Typography variant="h2" className={styles.hrmsHeaderLogo}>
            HRMS
          </Typography>
        </Stack>
      </Grid>

      <Divider />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeTabIndex}
        aria-label="Vertical tabs example"
      >
        {sidebarData.map((item: any) => (
          <ListItem key={item.id} className={styles.sidebarListTab}>
            <>
              {item?.noNavigation && item?.label}
              {!item?.noNavigation && (
                <Tab
                  key={item?.id}
                  label={item?.label}
                  {...a11yProps(item?.id)}
                  className={styles.sidebarDataTab}
                  component={Link}
                  to={item?.href}
                  onClick={() => handleTabClick(item?.tabName)}
                />
              )}
            </>
          </ListItem>
        ))}
      </Tabs>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Grid container className={styles.mainContainer}>
        <CssBaseline />
        {/* header starts here*/}
        {isValidRoute ?
          <>
            <AppBar
              position="fixed"
              sx={{
                backgroundColor: '#f7f8fa',
                boxShadow: 'none',
              }}
            >
              <Toolbar className={styles.toolBar}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={styles.menuButton}
                >
                  <MenuIcon />
                  <Typography className={styles.hrmsLogoSideBar}>HRMS</Typography>
                </IconButton>
                <Grid className={styles.texfieldGrid}>
                  <Grid className={styles.rightIconsGrid}>
                    <Grid className={styles.logoutButton}>
                      <React.Fragment>
                        <Grid className={styles.headerGrid}>
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              sx={{ padding: 0 }}
                              aria-controls={open ? 'account-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                            >
                              <Avatar className={styles.profile}>{userData?.firstName?.charAt(0)}</Avatar>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            className: styles.menuPaper,
                          }}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                          <MenuItem onClick={handleUser}>
                            <Avatar className={styles.menuAvatar}>{userData?.firstName?.charAt(0)}</Avatar>
                            Profile
                          </MenuItem>

                          <Divider />

                          <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                              <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Box
              component="nav"
              sx={{
                flexShrink: { sm: 0 },
              }}
              className={styles.sidebarContainer}
              aria-label="mailbox folders"
            >
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  display: { xs: 'block', sm: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}
                className={styles.showSidebarData}
                open
              >
                {drawer}
              </Drawer>
            </Box>
          </>
          : null}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
          className={styles.rightMainContainer}
        >
          <Grid className={styles.rightWrap}>
            <Grid className={styles.sidebar_box}>
              {sidebarData.map((item: any) => {
                return (
                  <TabPanel key={item?.id} value={value} index={item?.id} name={item?.tabName}>
                    {item?.component}
                  </TabPanel>
                );
              })}
            </Grid>
          </Grid>
        </Box>
        {/* rightbar ends here*/}
      </Grid>
      {isModalOpen && <EmployeeDetailsModel onClose={handleUserClose} employeeDetails={userData} open={isModalOpen} />}
    </>
  );
};

export default TabsLanding;
